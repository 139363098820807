import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../../../router.path';
import {Router} from '@angular/router';
import {InitRenderService} from '../../../service/renderingService/init-render.service';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.css']
})
export class MainNavigationComponent implements OnInit {

  constructor(private http: HttpClient,
              private router: Router,
              private render: InitRenderService) {
  }

  ngOnInit() {
  }

  editing() {
    // this.http.get(baseUrl.api + `get-order-content/${232}`).subscribe((res) => {
    //   this.render.object = res['payload'];
    //   this.render.rerender = true;
    //   this.router.navigate(['designer'], {queryParams: {id: res['payload']['order_type_id']}});
    // });
    this.router.navigate(['order']);
  }
}
