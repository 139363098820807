import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {CoefService} from '../../service/coef.service';
import {PriceService} from '../../service/price.service';
import {InitRenderService} from '../../service/renderingService/init-render.service';
import {SelectItem} from 'primeng/api';
import {CoefDoorService} from '../../service/door service/coef-door.service';
import * as _ from 'lodash';
import {baseUrl} from '../../router.path';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-first-stage',
  templateUrl: './first-stage.component.html',
  styleUrls: ['./first-stage.component.css']
})
export class FirstStageComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() allWardrobe;
  @Input() renders;
  @Input() Settings;
  @Output() SecondStage: EventEmitter<any> = new EventEmitter();
  form = false;
  selectOpenDoor = null;
  selectSystemDoor = null;
  disabledHeight = 'block';
  disabledDepth = 'block';
  disabledWidth = 'block';
  doorSelect = 'block';
  doorSystem = 'block';
  public wrapperSettings = {
    width: 2000,
    height: 2000,
    depth: 500,
    lamps: 1,
    bolt_id: 1,
    content_object: {},
    facade_object: {},
    init_types: [],
    price: 400,
    countDoor: 2,
    schem_id: 5,
    color_id: null,
    f_color_id: null,
    legs_count: null,
    legs_step: null,
    doors_typ_id: 1
  };
  disabled = false;
  init;
  selectDoor = [];
  valsidSlects = false;
  img_hover_src = null;
  img_hover = false;
  arrayComodOption = {2: 14, 3: 15, 4: 16, 5: 17};
  arrayCommodOption2 = {3: 34, 4: 35,};
  arrayCommod = [14, 15, 16, 17, 34, 35];
  arrayFoot;
  drawerCount = [];
  arrayStone;
  all_valid = false;
  arrayNeo = {14: 2, 15: 3};
  min;
  max;
  sub;
  clients = [];
  demoVersion = false;

  @HostListener('document:pointermove', ['$event']) onPointerMove(event): void {
    if (event.target.attributes['data-hover']) {
      this.img_hover_src = event.srcElement.currentSrc;
      this.img_hover = true;
    } else {
      this.img_hover = false;
      this.img_hover_src = null;
    }
  }

  constructor(private formBuilder: FormBuilder,
              public coef: CoefService,
              private priceService: PriceService,
              private render: InitRenderService,
              private http: HttpClient,
              private coefDoor: CoefDoorService) {
  }


  ngOnInit() {
    this.coef.widthSep = '';
    this.coefDoor.reDesign = false;
    this.coefDoor.manualTravelElement = false;
    this.http.get(baseUrl.api + 'user-selector').subscribe((res: any) => {
      if (res.success) {
        this.clients = this.creatArrayUser(res.payload);
        this.coef.clients = this.creatArrayUser(res.payload);
      }
    });
  }

  creatArrayUser(array) {
    const newArray = [];
    for (const item of array) {
      newArray.push({label: {name: item.name, surname: item.surname, last_name: item.last_name}, value: String(item.id)});
    }
    return newArray;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.demoVersion = window.location.href.indexOf('calculator?') === -1;
    if (this.Settings) {
      if (this.coef.pramsId == 2 || this.coef.pramsId == 15) {
        this.wrapperSettings.content_object['leftCorner'] = {
          subId: 'null',
          width: 0,
        };
        this.wrapperSettings.content_object['rightCorner'] = {
          subId: 'null',
          width: 0,
        };
      }
      this.init = this.formBuilder.group(
        {
          'width': ['', [Validators.required, Validators.min(this.Settings['min_width']), Validators.max(this.Settings['max_width'])]],
          'height': ['', [Validators.required, Validators.min(this.Settings['min_height']), Validators.max(this.Settings['max_height'])],],
          'depth': ['', [Validators.required, Validators.min(this.Settings['min_depth']), Validators.max(this.Settings['max_depth'])],],
        });
      this.form = true;
      this.displayNone();
      this.sub = this.init.valueChanges.subscribe(() => {
        this.arrayCountDoor();
      });
      if (this.allWardrobe) {
        if (this.coef.pramsId != 7) {
          this.all_valid = true;
        }
        if (!this.coef.doorIs.includes(+this.coef.pramsId)) {
          this.doorSystem = 'none';
        } else {
          this.doorSystem = 'block';
        }
        if (!this.coef.arrayNoneDoor.includes(+this.coef.pramsId)) {
          this.doorSelect = 'none';
        } else {
          this.doorSelect = 'block';
        }
        if (this.allWardrobe.spec_values && this.allWardrobe.spec_values['two_boot_box_height']) {
          this.arrayFoot = {
            5: this.allWardrobe.spec_values['two_boot_box_height'],
            6: this.allWardrobe.spec_values['three_boot_box_height'],
            18: this.allWardrobe.spec_values['four_boot_box_height']
          };
        }
        if (!this.render.rerender) {
          if (this.allWardrobe.init_types.length > 0) {
            for (const item of this.allWardrobe.init_types) {
              for (const val of item.options) {
                if (!val.disabled) {
                  item.value = val.value;
                  break;
                }
              }
            }
            if (this.coef.pramsId == 9) {
              this.addHeight();
              this.all_valid = true;
              this.valsidSlects = true;
            }
            
          }
        }
        if (this.allWardrobe.spec_values && this.allWardrobe.spec_values['first_scheme_height']) {
          this.arrayStone = {
            1: {
              height: this.allWardrobe.spec_values['first_scheme_height'],
              width: this.allWardrobe.spec_values['first_scheme_width'],
              depth: this.allWardrobe.spec_values['first_scheme_depth']
            },
            2: {
              height: this.allWardrobe.spec_values['second_scheme_height'],
              width: this.allWardrobe.spec_values['second_scheme_width'],
              depth: this.allWardrobe.spec_values['second_scheme_depth']
            },
            3: {
              height: this.allWardrobe.spec_values['third_scheme_height'],
              width: this.allWardrobe.spec_values['third_scheme_width'],
              depth: this.allWardrobe.spec_values['third_scheme_depth']
            },
            4: {
              height: this.allWardrobe.spec_values['fourth_scheme_height'],
              width: this.allWardrobe.spec_values['fourth_scheme_width'],
              depth: this.allWardrobe.spec_values['fourth_scheme_depth']
            },
          };
        }
        if(this.coef.pramsId == 10){
              this.validators()
        }
        if (changes.renders) {
          if (this.render.rerender) {
            this.setings(this.render.object);
          }
        }
      }
    }
  }

  displayNone() {
    this.disabledDepth = 'block';
    this.disabledHeight = 'block';
    this.disabledWidth = 'block';
    if (this.coef.pramsId == 9) {
      this.disabledHeight = 'none';
      this.init.get('height').disable();
    } else {
      this.init.get('height').enable();
    }
    if (this.coef.pramsId == 10) {
      this.disabledDepth = 'none';
      this.disabledHeight = 'none';
      this.disabledWidth = 'none';
      this.init.value.depth = '0';
    }
    if (this.coef.pramsId == 13) {
      this.disabledDepth = 'none';
      this.init.value.depth = '0';
      this.init.setValue(this.init.value);
    }
    if (this.coef.pramsId == 10) {
      this.disabledDepth = 'none';
      this.disabledHeight = 'none';
      this.disabledWidth = 'none';
      this.init.value.depth = '0';
    }
  }

  ngAfterViewInit() {
  }

  validators() {
    this.valsidSlects = this.val();
    this.addHeight();
    this.addSizeStone();
    this.NeoDrawer();
  }

  NeoDrawer() {
    for (const item of this.allWardrobe.init_types) {
      if (item.id === 4) {
        this.coef.selectDrawer = this.arrayNeo[item.value];
      }
    }
  }

  addSizeStone() {
    if (this.coef.pramsId == 10) {
      for (const select of this.allWardrobe.init_types) {
        if (select.id === 5 && select.value) {
          this.init.value.height = this.arrayStone[select.value].height;
          this.init.value.width = this.arrayStone[select.value].width;
          this.init.value.depth = this.arrayStone[select.value].depth;
          this.wrapperSettings.schem_id = select.value;
          this.init.setValue(this.init.value);
        }
      }
    }
  }

  addHeight() {
    if (this.coef.pramsId == 9) {
      for (const select of this.allWardrobe.init_types) {
        if (select.id === 5 && select.value) {
          this.init.value.height = this.arrayFoot[select.value];
          this.wrapperSettings.schem_id = select.value;
          this.init.setValue(this.init.value);
        }
      }
    }
  }

  val() {
    if (this.allWardrobe.init_types.length > 0) {

    }
    for (const select of this.allWardrobe.init_types) {
      if (select.id === 5 && select.value) {
      }
      if (!select.value && select.value != null) {
        return false;
      }
    }
    return true;
  }

  goToSecondStage() {

    this.sub.unsubscribe();
    this.disabled = true;
    this.wrapperSettings.depth = this.init.get('depth').value;
    this.wrapperSettings.height = this.init.get('height').value;
    this.wrapperSettings.width = this.init.get('width').value;
    this.wrapperSettings.countDoor = this.selectOpenDoor;
    this.coef.door.mincountDoor = this.selectOpenDoor;
    this.wrapperSettings.init_types = this.allWardrobe.init_types;

    this.getInitOptions();
    this.getInitDoorOptions(this.wrapperSettings.height);
    this.SecondStage.emit(this.wrapperSettings);
    this.init.get('height').enable();
  }

  findID(array) {
    const idArray = [14, 15, 16, 17];
    for (const option of array) {
      if (idArray.includes(option)) {
        return option;
      }
    }
  }

  parsCorner(objects) {
    const returnObject = {
      leftCorner: {
        subId: 'null',
        width: 0,
      },
      rightCorner: {
        subId: 'null',
        width: 0,
      }
    };
    if (objects.leftCorner) {
      returnObject.leftCorner = objects.leftCorner;
    }
    if (objects.rightCorner) {
      returnObject.rightCorner = objects.rightCorner;
    }
    return returnObject;
  }

  setings(object) {
    this.sub.unsubscribe();
    this.disabled = true;
    if (object.facade_object) {
      this.selectOpenDoor = object.facade_object.length;
    }

    this.wrapperSettings.depth = object.depth;
    this.init.value.depth = object.depth;
    this.wrapperSettings.height = object.height;
    this.init.value.height = object.height;
    this.wrapperSettings.width = object.width;
    this.init.value.width = object.width;
    this.init.setValue(this.init.value);
    if (this.coef.pramsId == 4) {
      this.coef.LuxPosition = this.findSide(object.content_object);
    }
    if (this.coef.pramsId == 2 || this.coef.pramsId == 15) {
      this.wrapperSettings.content_object = this.parsCorner(object.content_object[0]);
    }
    if (this.coef.pramsId == 7) {
      this.coef.widthSep = object.facade_object[0].width - this.coef.mainSetting.neo_prohoja_door_width_diff;
    }
    if (object.facade_object) {
      this.wrapperSettings.countDoor = object.facade_object.length;
      this.coef.door.mincountDoor = object.facade_object.length;
    }
    if (this.findID(object.init_option_ids)) {
      this.coef.comodCountDrawer = this.findID(object.init_option_ids);
    }
    this.wrapperSettings.init_types = this.allWardrobe.init_types;
    // this.getInitOptions();
    this.getOptinRender(object.scheme_id, object.lamp_id, object.init_option_ids);
    if (!object.system_id) {
    this.getInitDoorOptions();
    }
    if (this.coef.pramsId == 9) {
      this.wrapperSettings.schem_id = object.scheme_id;
    }
    if (object.system_id) {
        const findSystemType = this.findSystemType(object.system_id);
        console.log(findSystemType)
    this.http.get(baseUrl.api + 'door-systems/' + findSystemType ).subscribe((res: any) => {
      if (res.success) {
        this.coef.mainSetting.door_additional_size_two = res.payload.door_additional_size_coef_two;
        this.coef.mainSetting.door_additional_size_three = res.payload.door_additional_size_coef_three;
        this.coef.mainSetting.door_additional_size_four = res.payload.door_additional_size_coef_four;
        this.coef.mainSetting.door_additional_size_five = res.payload.door_additional_size_coef_five;
        this.coef.doorSystemsId = findSystemType;
        this.coefDoor.systemId = findSystemType;
        this.coefDoor.stoperDoor = object.stoper;
        this.coefDoor.puller = object.puller;
        this.coefDoor.has_puller = res.payload.has_puller;
        this.coefDoor.parsApiSettingsSystem( res.payload , res.payload.facade_materials)
        this.arrayCountDoor();
        this.coefDoor.parceSystemColor( res.payload.system_colors)
        this.getInitDoorOptions();
        this.SecondStage.emit(this.wrapperSettings);
      }
    });
  }
  if (!object.system_id) {
    this.SecondStage.emit(this.wrapperSettings);
  }
  }
findSystemType(id){
  for(const item of this.coef.systemsDoor){
    if(id == item.id){
      return id;
    }
  }
  return this.coef.systemsDoor[0].id;
}
  findSide(array) {
    for (const item of array) {
      if (item.params.type == 16) {
        return item.params.side;
      }
    }
  }

  getOptinRender(schem, lamp, option) {
    const initOptions = {
      init_option_ids: []
    };
    initOptions['scheme_id'] = schem;
    initOptions['lamp_id'] = lamp;
    initOptions.init_option_ids = option;
    this.setSchemid(schem);
    this.coef.storeInitParams(initOptions);
  }

  setSchemid(schem) {
    for (const item of this.wrapperSettings.init_types) {
      if (item.id === 5) {
        item.value = schem;
      }
    }
  }

  private getInitOptions(): void {
    const initOptions = {
      init_option_ids: []
    };
    this.allWardrobe.init_types.forEach((e) => {
      if (e.id === 5) {
        initOptions['scheme_id'] = +e.value;
      } else if (e.id === 2) {
        initOptions['lamp_id'] = +e.value;
      } else {
        if (e.value == 'null') {
          initOptions.init_option_ids.push(null);
        } else {
          if (e.id === 4) {
            this.coef.comodCountDrawer = e.value;
          }
          initOptions.init_option_ids.push(+e.value);
        }
      }
    });
    this.coef.storeInitParams(initOptions);
  }

  getmeatirials(id) {
    for (const obj of this.coefDoor.facade_materials) {
      if (obj.id === id) {
        return obj;
      }
    }
  }

  getInitDoorOptions(height?) {
    if (this.selectOpenDoor) {
      let doors = [];
      if (this.render.rerender) {
        if (this.render.object.facade_object) {
          for (const item of this.render.object.facade_object) {
            doors.push({
              width: item.width,
              content: item.content,
              door_type_id: item.door_type_id,
              sep_count: item.sep_count,
              materials: item.materials
            });
          }
          this.coefDoor.doorStag.stage = null;
        }
      } else {
        if (this.coef.pramsId == 7 || this.coef.pramsId == 6) {
          this.selectOpenDoor = 2;
        }
        if (this.coef.pramsId == 4) {
          this.selectOpenDoor = 1;
        }
        for (let i = 0; i < this.selectOpenDoor; i++) {
          if (this.coef.pramsId == 11) {
            doors.push({
              width: this.coef.getOneDoorWidth(this.selectOpenDoor, this.init.value.width, this.init.value.depth ) * this.coef.global_coef,
              door_type_id: 3,
              sep_count: 0,
              materials: [4]
            });
          } else {
            doors.push({
              width: this.coef.getOneDoorWidth(this.selectOpenDoor, this.init.value.width, this.init.value.depth ) * this.coef.global_coef,
              door_type_id: 2,
              sep_count: 0,
              materials: [2]
            });
          }
        }
      }
      this.coef.doorsArray = doors;
      this.priceService.setDoorOptions(doors , height);
    }
  }

  disabledOptionPenal() {
    for (const item of this.allWardrobe.init_types) {
      if (item.id === 5) {
        for (const option of item.options) {
          option.disabled = false;
          if (this.init.value.width > this.allWardrobe.spec_values.max_one_door_width) {
            if (option.id != 9) {
              option.disabled = true;
            }
          }
          if (this.init.value.width < this.allWardrobe.spec_values.min_two_doors_width) {
            if (option.id == 9) {
              option.disabled = true;
            }
          }
          if (item.value == option.id) {
            if (option.disabled) {
              this.all_valid = false;
            } else {
              this.all_valid = true;
            }
          }
        }
      }
    }
  }

  disabledOption() {
    const height_drawer = (this.init.value.height - this.coef.height_legs - this.coef.global_size_flat);
    const array = [];
    const countMax = Math.floor(height_drawer / this.allWardrobe.spec_values.min_shuttle_height);
    const countMin = Math.floor(height_drawer / this.allWardrobe.spec_values.max_shuttle_height);
    for (const count in this.arrayComodOption) {
      if (+count > countMin && +count < countMax) {
        array.push(this.arrayComodOption[count]);
      }
    }
    for (const count in this.arrayCommodOption2) {
      if (+count > countMin && +count < countMax) {
        array.push(this.arrayCommodOption2[count]);
      }
    }
    for (const item of this.allWardrobe.init_types) {
      if (item.id === 4) {
        for (const option of item.options) {
          option.disabled = false;
          if (!this.findproperty(array, option.id)) {
            option.disabled = true;
          } else {
            item.value = option.value;
          }
        }
      }
    }
  }

  findproperty(array, ids) {
    for (const id of array) {
      if (id === ids) {
        return true;
      }
    }
    return false;
  }

  arrayCountDoor() {
    const specValuesMin = this.coef.spec_values['side_section_min_width'];
    const countHoleWidth = this.init.value.width - (4 * this.coef.global_size_flat * this.coef.global_coef);
    const countMin = Math.ceil((countHoleWidth - this.coef.spec_values['inner_shuttle_max_width']) / 2);
    const countMax = Math.floor(countHoleWidth / 3);
    this.min = countMin > specValuesMin ? countMin : specValuesMin;
    this.max = countMax > specValuesMin ? countMax : specValuesMin;

    this.selectDoor = [];
    let door_max_width = 1300;
    let system_thickness = 38;
    if( this.coefDoor.glob_settings && this.coefDoor.glob_settings.door_max_width){
      door_max_width = this.coefDoor.glob_settings.door_max_width;
      system_thickness = this.coefDoor.glob_settings.system_thickness;
    }
    let maxDoorFour = door_max_width * 4;
    let maxDoorThree = door_max_width * 3;
    let maxDoorTwo = door_max_width * 2;
    let maxDoorFive = door_max_width * 5;
    if (this.coef.pramsId == 13) {
      maxDoorFour = (door_max_width - system_thickness * 2) * 4;
      maxDoorThree = (door_max_width - system_thickness * 2) * 3;
      maxDoorTwo = (door_max_width - system_thickness * 2) * 2;
      maxDoorFive = (door_max_width - system_thickness * 2) * 5;
    }
    if (this.coef.pramsId == 11) {
      maxDoorFour = (door_max_width- this.coef.mainSetting['door_additional_size_four']) * 4;
      maxDoorFive = (door_max_width- this.coef.mainSetting['door_additional_size_coef_five']) * 5;
      maxDoorThree = (door_max_width - this.coef.mainSetting['door_additional_size_three']) * 3;
      maxDoorTwo = (door_max_width - this.coef.mainSetting['door_additional_size_two']) * 2;
    }
    if (this.coef.pramsId == 1) {
      this.disabledOption();
    }
    if (this.coef.pramsId == 3) {
      this.disabledOptionPenal();
    }
    if (this.coef.pramsId == 7) {
      this.disabledOptionNeo();
    }
    if ( this.init.value.width <= door_max_width) {
      if (this.coef.pramsId == 13) {
        this.selectDoor.push(1);
      }
    }
   
    if (this.init.value.width >= 0 && this.init.value.width <= maxDoorTwo) {
      this.selectDoor.push(2);
    }
    if (this.init.value.width >= this.coef.mainSetting.min_door_size_three && this.init.value.width <= maxDoorThree) {
      this.selectDoor.push(3);
    }
    if (this.init.value.width >= this.coef.mainSetting.min_door_size_four && this.init.value.width <= maxDoorFour) {
      if (this.coef.pramsId != 11) {
        this.selectDoor.push(4);
      }
    }
    if (this.init.value.width >= this.coef.mainSetting.min_door_size_five && this.init.value.width <= maxDoorFive) {
      if (this.coef.pramsId == 13) {
        this.selectDoor.push(5);
      }
    }
    if (this.coef.pramsId != 9 && this.coef.pramsId != 10) {
      this.validators();
    }
    this.selectOpenDoor = this.selectDoor[0];
    if(!this.selectSystemDoor && this.coef.systemsDoor.length > 0){
      this.selectSystemDoor = this.coef.systemsDoor[0].id;
      this.getSystemSettings();
    }
  }

getSystemSettings(){
  if(!this.render.rerender){
  this.http.get(baseUrl.api + 'door-systems/' + this.selectSystemDoor ).subscribe((res: any) => {
    if (res.success) {
      this.coef.mainSetting.door_additional_size_two = res.payload.door_additional_size_coef_two;
      this.coef.mainSetting.door_additional_size_three = res.payload.door_additional_size_coef_three;
      this.coef.mainSetting.door_additional_size_four = res.payload.door_additional_size_coef_four;
      this.coef.mainSetting.door_additional_size_five = res.payload.door_additional_size_coef_five;
      this.coef.doorSystemsId = this.selectSystemDoor;
      this.coefDoor.systemId = this.selectSystemDoor;
      this.coefDoor.has_top_stopper = res.payload.has_top_stopper;
      this.coefDoor.has_puller = res.payload.has_puller;
      this.coefDoor.has_bottom_stopper = res.payload.has_bottom_stopper;
      if(res.payload.has_top_stopper && res.payload.has_bottom_stopper){
        this.coefDoor.stoperDoor = 'bottom';
      }else if(res.payload.has_top_stopper && !res.payload.has_bottom_stopper){
        this.coefDoor.stoperDoor = 'top';
      }else if(!res.payload.has_top_stopper && res.payload.has_bottom_stopper){
        this.coefDoor.stoperDoor = 'bottom';
      }else{
        this.coefDoor.stoperDoor = 'none';
      }
        this.coefDoor.puller = false;
      
      this.coefDoor.parsApiSettingsSystem( res.payload , res.payload.facade_materials)
      this.arrayCountDoor();
      this.coefDoor.parceSystemColor( res.payload.system_colors)

    }
  });
}
}

  disabledOptionNeo() {
    this.drawerCount = [];
    const height = this.init.value.height - this.coef.spec_values['middle_section_to_top_flat_size'] - this.coef.spec_values['t_section_min_height'] - this.coef.global_size_flat * this.coef.global_coef * 4 - this.coef.spec_values['inner_shuttle_bottom_section_diff'] - this.coef.height_legs * this.coef.global_coef;
    const tooDrawer = this.coef.spec_values['inner_shuttle_min_height'] * 2 + this.coef.spec_values['inner_shuttle_side_space'] * 2;
    const threeDrawer = this.coef.spec_values['inner_shuttle_min_height'] * 3 + this.coef.spec_values['inner_shuttle_side_space'] * 3;
    const forDrawer = this.coef.spec_values['inner_shuttle_min_height'] * 4 + this.coef.spec_values['inner_shuttle_side_space'] * 4;
    for (const item of this.allWardrobe.init_types) {
      if (item.id === 4) {
        for (const option of item.options) {
          option.disabled = true;
        }
      }
    }
    if (tooDrawer < height) {
      this.drawerCount.push(2);
      for (const item of this.allWardrobe.init_types) {
        if (item.id === 4) {
          for (const option of item.options) {
            if (option.id == 14) {
              option.disabled = false;
            }
          }
        }
      }
    }
    if (threeDrawer < height) {
      this.drawerCount.push(3);
      for (const item of this.allWardrobe.init_types) {
        if (item.id === 4) {
          for (const option of item.options) {
            if (option.id == 15) {
              option.disabled = false;
            }
          }
        }
      }
    }
    if (forDrawer < height) {
      const oneHeight = Math.floor(height / 4) / this.coef.global_coef;
      console.warn(oneHeight, this.coef.drawer.minSize, 'this.coef.drawer.minSize');
      if (oneHeight > this.coef.drawer.minSize) {
        this.drawerCount.push(4);
        for (const item of this.allWardrobe.init_types) {
          if (item.id === 4) {
            for (const option of item.options) {
              if (option.id == 15) {
                option.disabled = false;
              }
            }
          }
        }
      }
    }

    for (const item of this.allWardrobe.init_types) {
      if (item.id === 4) {
        for (const option of item.options) {
          if (!option.disabled) {
            item.value = option.value;
            this.coef.selectDrawer = this.arrayNeo[item.value];
          }
        }
      }
    }
  }


  cornerValid(type) {
    if (this.wrapperSettings.content_object['leftCorner'].subId != 'null' || this.wrapperSettings.content_object['rightCorner'].subId != 'null') {
      if (this.wrapperSettings.content_object['rightCorner'].subId == 'null') {
        if (this.wrapperSettings.content_object['leftCorner'].width >= this.Settings.cornerWidthMin && this.wrapperSettings.content_object['leftCorner'].width <= this.Settings.cornerWidthMax) {
          this.all_valid = true;
        } else {
          this.all_valid = false;
        }
      }
      if (this.wrapperSettings.content_object['leftCorner'].subId == 'null') {
        if (this.wrapperSettings.content_object['rightCorner'].width >= this.Settings.cornerWidthMin && this.wrapperSettings.content_object['rightCorner'].width <= this.Settings.cornerWidthMax) {
          this.all_valid = true;
        } else {
          this.all_valid = false;
        }
      }
      if (this.wrapperSettings.content_object['leftCorner'].subId != 'null' && this.wrapperSettings.content_object['rightCorner'].subId != 'null') {
        if (this.wrapperSettings.content_object['rightCorner'].width >= this.Settings.cornerWidthMin && this.wrapperSettings.content_object['rightCorner'].width <= this.Settings.cornerWidthMax && this.wrapperSettings.content_object['leftCorner'].width >= this.Settings.cornerWidthMin && this.wrapperSettings.content_object['leftCorner'].width <= this.Settings.cornerWidthMax) {
          this.all_valid = true;
        } else {
          this.all_valid = false;
        }
      }
    } else {
      this.all_valid = true;
    }
  }

  allValid() {
    if (this.coef.selectDrawer) {
      if (this.coef.widthSep >= this.min && this.coef.widthSep <= this.max) {
        this.all_valid = true;
      } else {
        this.all_valid = false;
      }
    } else {
      this.all_valid = false;
    }
  }
}
