import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {baseUrl} from '../router.path';
import {CoefService} from '../service/coef.service';
import {InitRenderService} from '../service/renderingService/init-render.service';
import {API} from '../common/api';

@Component({
  selector: 'app-order-type',
  templateUrl: './order-type.component.html',
  styleUrls: ['./order-type.component.css']
})
export class OrderTypeComponent implements OnInit {
  result;
  url;
  id;

  constructor(private http: HttpClient,
              private router: Router,
              private coef: CoefService,
              private render: InitRenderService
  ) {
    this.coef.preloaderUpdate(true);
    this.http.get(baseUrl.api + '/get-globals').subscribe((res) => {
      if (res['success']) {
        this.coef.parsGlobalSetting(res['payload'].main_setting);
        this.result = res['payload'].order_type_list;
        this.url = this.result[0].image_path;
        this.id = this.result[0].id;
        this.http.get(baseUrl.api + API.GET_USER).subscribe(user => {
          this.result = this.result.filter((item) => {
            if (item.id == 16) {
              if (user['payload'].manager) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          });
        });
        this.coef.preloaderUpdate(false);
      }
    }, (error) => {
      if (error.status === 401) {
        this.coef.preloaderUpdate(false);
        this.router.navigate(['']);
      }
    });
  }

  ngOnInit() {
  }

  goTo(id, active) {
    if (active) {
      const isClientPage = this.router.url.indexOf('order-type-calculator') !== -1;
      if (id != 16) {
        if (isClientPage) {
          this.router.navigate(['calculator'], {queryParams: {id: id}});
          this.render.rerender = false;
        } else {
          this.router.navigate(['designer'], {queryParams: {id: id}});
          this.render.rerender = false;
        }
      } else {
        if (isClientPage) {
          this.router.navigate(['custom-calculator'], {queryParams: {id: id}});
          this.render.rerender = false;
        } else {
          this.router.navigate(['custom'], {queryParams: {id: id}});
          this.render.rerender = false;
        }
      }
    }
  }

  getUrlImg(value) {
    for (const id of this.result) {
      if (id.id == value) {
        return id.image_path;
      }
    }
  }

}
