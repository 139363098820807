import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumberInputComponent),
  multi: true
};

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class NumberInputComponent implements OnInit, ControlValueAccessor {
  @Input() value: any;
  @Input() step = 1;
  @Input() disabled = false;
  @Input() errors: any;
  @Input() tabindex: any;
  @Input() float = false;

  @Output() onChange = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  writeValue(value: any) {
    if (value !== undefined && value !== null) {
      this.value = this.float ? value.toFixed(2) : value;
    }
  }

  propagateChange = (_: any) => {
  };

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  valueChange($event: any) {
    this.value = $event.target.value;
    this.propagateChange($event.target.value);
  }

  increment() {
    if (this.float) {
      this.value = (
        (this.value ? parseFloat(this.value) : 0) + this.step
      ).toFixed(2);
    } else {
      this.value = (Number(this.value) ? Number(this.value) : 0) + this.step;
    }

    this.propagateChange(this.value);
    this.onChange.emit();
  }

  changeManual(e) {
    if (e.code != 'Tab') {
      this.onChange.emit();
    }
  }

  decrement() {
    if (this.value - this.step >= 1) {
      if (this.float) {
        this.value = (
          (this.value ? parseFloat(this.value) : 1) - this.step
        ).toFixed(2);
      } else {
        this.value = (Number(this.value) ? Number(this.value) : 1) - this.step;
      }

      this.propagateChange(this.value);
      this.onChange.emit();
    }
  }
}
