import {Injectable} from '@angular/core';
import * as Konva from '../../assets/konva';
import {CoefService} from './coef.service';
import {Layer} from '../../assets/konva';
import {MinSizeService} from './min-size.service';
import {PriceService} from './price.service';
import {DrawerLevelService} from './drawer-level.service';
import {RemoveService} from './remove.service';
import {tryCatch} from 'rxjs/internal-compatibility';


@Injectable({
  providedIn: 'root'
})
export class CreateService {
  public widthTrumpetBefore = 2;
  public pantographWidthLand = 4;
  events;

  constructor(private coef: CoefService, private minSize: MinSizeService, private price: PriceService, private Level: DrawerLevelService) {
  }

  priceSend() {
    this.price.sendObjectPrice();
  }

  createElement(settings) {
    this.coef.countNameElement++;
    let horizonVertical = null;
    let wrapperType = true;
    let color = this.coef.color;
    const This = this;
    const global_size_retreat = this.coef.global_size_retreat;
    if (settings.color) {
      color = settings.color;
    }
    if (settings.wrapper) {
      wrapperType = false;
    }
    if (settings.params.type === this.coef.id_element.shelf) {
      horizonVertical = function (pos) {
        if (!settings.params.stopDrags) {
          return {
            x: this.getAbsolutePosition().x,
            y: pos.y
          };
        } else {
          return {
            x: this.getAbsolutePosition().x,
            y: this.getAbsolutePosition().y
          };
        }
      };
    } else if (settings.params.type === this.coef.id_element.partition) {
      horizonVertical = function (pos) {
        return {
          x: pos.x,
          y: this.getAbsolutePosition().y
        };
      };
    }
    const object = new Konva.Rect({
      x: settings.leftPosition,
      y: settings.topPosition,
      width: settings.width,
      height: settings.height,
      name: 'element' + this.coef.countNameElement,
      fill: color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: settings.params,
      draggable: wrapperType,
      dragBoundFunc: horizonVertical
    });
    settings.params.old_position = {x: settings.leftPosition, y: settings.topPosition};
    if (settings.pos) {
      const isOnWhat = this.isOnWhat(settings.pos, object, this.coef.shapesLayerw);
      if (isOnWhat) {
        if (settings.params.type === this.coef.id_element.shelf) {
          if (settings.onYou) {
            if (isOnWhat.attrs.params.type === 1) {
              object['attrs'].dash = [10, 5];
              object['attrs'].fill = 'rgba(255,255,255,0)';
              object['attrs'].params.onWhat = isOnWhat.attrs.params.type;
            }
            if (isOnWhat.attrs.params.type === 4) {
              object['attrs'].params.onWhat = isOnWhat.attrs.params.type;
            }
          }
        }

      }
      if (settings.params.handing) {
        object['attrs'].dash = [10, 5];
      }
    }
    if (settings.contours) {
      this.bindingTocounters(
        settings.contours.left,
        settings.contours.right,
        settings.contours.top,
        settings.contours.bottom,
        object,
        settings.type);
    }
    settings.layer.add(object);
    settings.layer.batchDraw();
    return object;
  }

  rightEvent(left, right, top, bottom, element, type, base?) {
    const This = this;
    if (element.attrs.params.type === This.coef.id_element.top_wall || element.attrs.params.type === This.coef.id_element.bottom_wall) {
      element.width(right.attrs.x - element.attrs.x + This.coef.global_size_flat);
    } else {
      element.width(right.attrs.x - element.attrs.x);
      element.attrs.width = right.attrs.x - element.attrs.x;
    }
    if (element.children) {
      switch (element.attrs.params.type) {
        case 1: {
          if (element.attrs.params.subId === This.coef.element_Sub_id.singleDoorLeft || element.attrs.params.subId === This.coef.element_Sub_id.singleDoorLeftLock) {
            element.children[0].width(right.attrs.x - element.attrs.x);
            element.children[1].x(17);
          }
          if (element.attrs.params.subId === This.coef.element_Sub_id.singleDoorLeftD || element.attrs.params.subId === This.coef.element_Sub_id.singleDoorLeftLockD) {
            element.children[0].width(right.attrs.x - element.attrs.x);
            element.children[1].x(17);
            element.children[2].x((right.attrs.x - element.attrs.x) - 17);
          }
          if (element.attrs.params.subId === This.coef.element_Sub_id.singleDoorRight || element.attrs.params.subId === This.coef.element_Sub_id.singleDoorRightLock) {
            element.children[0].width(right.attrs.x - element.attrs.x);
            element.children[1].x((right.attrs.x - element.attrs.x) - 17);
          }
          if (element.attrs.params.subId === This.coef.element_Sub_id.singleDoorRightD || element.attrs.params.subId === This.coef.element_Sub_id.singleDoorRightLockD) {
            element.children[0].width(right.attrs.x - element.attrs.x);
            element.children[1].x((right.attrs.x - element.attrs.x) - 17);
            element.children[2].x((right.attrs.x - element.attrs.x) - 17);
          }
          if (element.attrs.params.subId === This.coef.element_Sub_id.twoDoor || element.attrs.params.subId === This.coef.element_Sub_id.twoDoorLock) {
            element.children[0].width(right.attrs.x - element.attrs.x);
            element.children[1].x((right.attrs.x - element.attrs.x) / 2 + 17);
            element.children[2].x((right.attrs.x - element.attrs.x) / 2 - 17);
            element.children[3].x((right.attrs.x - element.attrs.x) / 2 - 1);
          }
          if (element.attrs.params.subId === This.coef.element_Sub_id.twoDoorD || element.attrs.params.subId === This.coef.element_Sub_id.twoDoorLockD) {
            element.children[0].width(right.attrs.x - element.attrs.x);
            element.children[1].x((right.attrs.x - element.attrs.x) / 2 + 17);
            element.children[2].x((right.attrs.x - element.attrs.x) / 2 - 17);
            element.children[3].x((right.attrs.x - element.attrs.x) / 2 - 1);
            if(element.children[6]){
              element.children[6].x((right.attrs.x - element.attrs.x) - 17);
            }else{
              element.children[4].x((right.attrs.x - element.attrs.x) - 17);
            }
          }
        }
          break;
        case 2: {
          const width = (right.attrs.x - left.attrs.x - This.coef.global_size_flat) / (element.children.length + 1);
          for (let i = 1; i < element.children.length + 1; i++) {
            element.children[i - 1].x((width * i) - 6 / 2);
          }
        }
          break;
        case 3:
          const width = (right.attrs.x - left.attrs.x - This.coef.global_size_flat) / (element.children.length + 1);
          // for (let i = 1; i < element.children.length; i++) {
          //   element.children[i].x(((element['attrs'].width) / 2) * (i) - (This.coef.hook.hookWidth / 2));
          // }
          if (element.children.length === 2) {
            element.children[0].x((element['attrs'].width) / 3 - (This.coef.hook.hookWidth / 2));
            element.children[1].x(((element['attrs'].width) / 3) * 2 - (This.coef.hook.hookWidth / 2));
          }
          if (element.children.length === 3) {
            element.children[0].x(((element['attrs'].width) / 4) - (This.coef.hook.hookWidth / 2));
            element.children[1].x(((element['attrs'].width) / 4) * 2 - (This.coef.hook.hookWidth / 2));
            element.children[2].x(((element['attrs'].width) / 4) * 3 - (This.coef.hook.hookWidth / 2));
          }
          if (element.children.length === 5) {
            element.children[0].x(((element['attrs'].width) / 4) - (This.coef.hook.hookWidth / 2));
            element.children[1].x(((element['attrs'].width) / 4) * 2 - (This.coef.hook.hookWidth / 2));
            element.children[2].x(((element['attrs'].width) / 4) * 3 - (This.coef.hook.hookWidth / 2));
            for (let i = element.children.length - 1; i < element.children.length + 1; i++) {
              element.children[i - 1].x(((element['attrs'].width) / 3) * (i - 3) - (This.coef.hook.hookWidth / 2));
            }
          }
          break;
        case 4: {
          if (left.attrs.params.type === This.coef.id_element.lateral_wall) {
            element.children[0].width(right.attrs.x - element.attrs.x - This.coef.look2dPosition);
          } else {
            element.children[0].width(right.attrs.x - element.attrs.x);
          }
        }
          break;
        case 5: {
          element.children[1].width(element.width() - This.widthTrumpetBefore * 10);
          element.children[2].x(right.attrs.x - element.attrs.x - This.widthTrumpetBefore * 5);
          element.children[3].x(right.attrs.x - element.attrs.x - This.widthTrumpetBefore * 5);
          element.children[0].x((right.attrs.x - left.attrs.x) / 2 - This.pantographWidthLand);
        }
          break;
        case 6: {

        }
          break;
        case 7: {
        }
          break;
        case 8: {
          if(element.attrs.params.subId && element.attrs.params.subId == 25){
            element.children[0].width(right.attrs.x - element.attrs.x);
            element.children[1].width(right.attrs.x - element.attrs.x);
            element.children[3].x(right.attrs.x - element.attrs.x - element.children[3].width());
          }else{
            element.children[0].width(right.attrs.x - element.attrs.x);
            element.children[2].x(right.attrs.x - element.attrs.x - element.children[2].width());
          }
          
        }
          break;
        case 9: {
          element.children[0].width(right.attrs.x - element.attrs.x);
            try {
              if(element.attrs.params.subId == This.coef.element_Sub_id.drawerSimpleSpec || element.attrs.params.subId == This.coef.element_Sub_id.drawerSimpleSpecD){
                element.children[1].width(right.attrs.x - element.attrs.x);
                if(element.attrs.params.subId == This.coef.element_Sub_id.drawerSimpleSpecD){
                  element.children[2].x((right.attrs.x - element.attrs.x)- 12);
                }
              }else{
                if ((right.attrs.x - element.attrs.x) * this.coef.global_coef >= this.coef.drawer.secondSeparattor) {
                  element.children[1].x(((right.attrs.x - element.attrs.x) / 3) - 8);
                  element.children[2].show();
                  element.children[2].x((((right.attrs.x - element.attrs.x) / 3) * 2) - 8);
                  
                  if(element.attrs.params.subId == This.coef.element_Sub_id.drawerWithLength || element.attrs.params.subId == This.coef.element_Sub_id.drawerWithLengthLock){
                    element.children[3].x((right.attrs.x - element.attrs.x)- 12);
                  }
                } else {
                  element.children[1].x((right.attrs.x - element.attrs.x) / 2 - 16 / 2);
                  element.children[2].hide();
                  if(element.attrs.params.subId == This.coef.element_Sub_id.drawerWithLength || element.attrs.params.subId == This.coef.element_Sub_id.drawerWithLengthLock){
                    element.children[3].x((right.attrs.x - element.attrs.x)- 12);
                  }
                }
              }
            
            } catch (e) {
              console.log(e);
            }
        }
        case 10: {
        }
          break;
      }
    }
  }

  leftEvent(left, right, top, bottom, element, type, base?) {
    const This = this;
    if (element.attrs.params.type === This.coef.id_element.top_wall || element.attrs.params.type === This.coef.id_element.bottom_wall) {
      element.x(left.attrs.x + left.attrs.width - This.coef.global_size_flat);
      if (This.coef.pramsId == 11) {
        element.width(right.attrs.x - element.attrs.x);
      } else {
        if (base && element.attrs.params.type === This.coef.id_element.bottom_wall) {
          element.width(right.attrs.x - element.attrs.x);
        } else {
          element.width(right.attrs.x - element.attrs.x + right.attrs.width);
        }
      }
    } else {
      element.x(left.attrs.x + left.attrs.width);
      element.width(right.attrs.x - element.attrs.x);
    }
    if (element.children) {
      if (element.children) {
        switch (element.attrs.params.type) {
          case 1: {
            if (element.attrs.params.subId === This.coef.element_Sub_id.singleDoorLeft || element.attrs.params.subId === This.coef.element_Sub_id.singleDoorLeftLock) {
              element.children[0].width(right.attrs.x - element.attrs.x);
              element.children[1].x(17);
            }
            if (element.attrs.params.subId === This.coef.element_Sub_id.singleDoorRight || element.attrs.params.subId === This.coef.element_Sub_id.singleDoorRightLock) {
              element.children[0].width(right.attrs.x - element.attrs.x);
              element.children[1].x((right.attrs.x - element.attrs.x) - 17);
            }
            if (element.attrs.params.subId === This.coef.element_Sub_id.twoDoor || element.attrs.params.subId === This.coef.element_Sub_id.twoDoorLock) {
              element.children[0].width(right.attrs.x - element.attrs.x);
              element.children[1].x((right.attrs.x - element.attrs.x) / 2 + 17);
              element.children[2].x((right.attrs.x - element.attrs.x) / 2 - 17);
              element.children[3].x((right.attrs.x - element.attrs.x) / 2 - 1);
            }


            if (element.attrs.params.subId === This.coef.element_Sub_id.singleDoorLeftD || element.attrs.params.subId === This.coef.element_Sub_id.singleDoorLeftLockD) {
              element.children[0].width(right.attrs.x - element.attrs.x);
              element.children[1].x(17);
              element.children[2].x((right.attrs.x - element.attrs.x) - 17);
            }
            if (element.attrs.params.subId === This.coef.element_Sub_id.singleDoorRightD || element.attrs.params.subId === This.coef.element_Sub_id.singleDoorRightLockD) {
              element.children[0].width(right.attrs.x - element.attrs.x);
              element.children[1].x((right.attrs.x - element.attrs.x) - 17);
              element.children[2].x((right.attrs.x - element.attrs.x) - 17);
            }
            if (element.attrs.params.subId === This.coef.element_Sub_id.twoDoorD || element.attrs.params.subId === This.coef.element_Sub_id.twoDoorLockD) {
              element.children[0].width(right.attrs.x - element.attrs.x);
              element.children[1].x((right.attrs.x - element.attrs.x) / 2 + 17);
              element.children[2].x((right.attrs.x - element.attrs.x) / 2 - 17);
              element.children[3].x((right.attrs.x - element.attrs.x) / 2 - 1);
              if(element.children[6]){
                element.children[6].x((right.attrs.x - element.attrs.x) - 17);
              }else{
                element.children[4].x((right.attrs.x - element.attrs.x) - 17);
              }
             
            }
          }
            break;
          case 2: {
            const width = (right.attrs.x - left.attrs.x - This.coef.global_size_flat) / (element.children.length + 1);
            for (let i = 1; i < element.children.length + 1; i++) {
              element.children[i - 1].x((width * i) - 6 / 2);
            }
          }
            break;
          case 3:
            const width = (right.attrs.x - left.attrs.x - This.coef.global_size_flat) / (element.children.length + 1);
            // for (let i = 1; i < element.children.length - 1; i++) {
            //   element.children[i - 1].x(((element['attrs'].width) / 4) * i - (This.coef.hook.hookWidth / 2));
            // }
            if (element.children.length === 2) {
              element.children[0].x((element['attrs'].width) / 3 - (This.coef.hook.hookWidth / 2));
              element.children[1].x(((element['attrs'].width) / 3) * 2 - (This.coef.hook.hookWidth / 2));
            }
            if (element.children.length === 3) {
              element.children[0].x(((element['attrs'].width) / 4) - (This.coef.hook.hookWidth / 2));
              element.children[1].x(((element['attrs'].width) / 4) * 2 - (This.coef.hook.hookWidth / 2));
              element.children[2].x(((element['attrs'].width) / 4) * 3 - (This.coef.hook.hookWidth / 2));
            }
            if (element.children.length === 5) {
              element.children[0].x(((element['attrs'].width) / 4) - (This.coef.hook.hookWidth / 2));
              element.children[1].x(((element['attrs'].width) / 4) * 2 - (This.coef.hook.hookWidth / 2));
              element.children[2].x(((element['attrs'].width) / 4) * 3 - (This.coef.hook.hookWidth / 2));
              for (let i = element.children.length - 1; i < element.children.length + 1; i++) {
                element.children[i - 1].x(((element['attrs'].width) / 3) * (i - 3) - (This.coef.hook.hookWidth / 2));
              }
            }
            break;
          case 4: {
            if (right.attrs.params.type === This.coef.id_element.lateral_wall) {
              element.children[0].width(right.attrs.x - element.attrs.x - This.coef.look2dPosition);
            } else {
              element.children[0].width(right.attrs.x - element.attrs.x);
            }
          }
            break;
          case 5: {
            element.children[1].width(element.width() - This.widthTrumpetBefore * 10);
            element.children[2].x(right.attrs.x - element.attrs.x - This.widthTrumpetBefore * 5);
            element.children[3].x(right.attrs.x - element.attrs.x - This.widthTrumpetBefore * 5);
            element.children[0].x((right.attrs.x - left.attrs.x) / 2 - This.pantographWidthLand);
          }
            break;
          case 6: {

          }
            break;
          case 7: {

          }
            break;
          case 8: {
            if(element.attrs.params.subId && element.attrs.params.subId == 25){
              element.children[0].width(right.attrs.x - element.attrs.x);
              element.children[1].width(right.attrs.x - element.attrs.x);
              element.children[3].x(right.attrs.x - element.attrs.x - element.children[3].width());
            }else{
              element.children[0].width(right.attrs.x - element.attrs.x);
              element.children[2].x(right.attrs.x - element.attrs.x - element.children[2].width());
            }
            
          }
            break;
          case 9: {
            element.children[0].width(right.attrs.x - element.attrs.x);
              try {
                if(element.attrs.params.subId == This.coef.element_Sub_id.drawerSimpleSpec || element.attrs.params.subId == This.coef.element_Sub_id.drawerSimpleSpecD){
                  element.children[1].width(right.attrs.x - element.attrs.x);
                  if(element.attrs.params.subId == This.coef.element_Sub_id.drawerSimpleSpecD){
                    element.children[2].x((right.attrs.x - element.attrs.x)- 12);
                  }
                }else{
                  if ((right.attrs.x - element.attrs.x) * this.coef.global_coef >= this.coef.drawer.secondSeparattor) {
                    element.children[1].x(((right.attrs.x - element.attrs.x) / 3) - 8);
                    element.children[2].show();
                    element.children[2].x((((right.attrs.x - element.attrs.x) / 3) * 2) - 8);
                    console.log(element.attrs.params.subId  ,      element.attrs, 'element.attrs.params.subId')
                    if(element.attrs.params.subId == This.coef.element_Sub_id.drawerWithLength || element.attrs.params.subId == This.coef.element_Sub_id.drawerWithLengthLock){
                      element.children[ 3].x((right.attrs.x - element.attrs.x)- 12);
                    }
                  } else {
                    element.children[1].x((right.attrs.x - element.attrs.x) / 2 - 16 / 2);
                    element.children[2].hide();
                    if(element.attrs.params.subId == This.coef.element_Sub_id.drawerWithLength || element.attrs.params.subId == This.coef.element_Sub_id.drawerWithLengthLock){
                      element.children[3].x((right.attrs.x - element.attrs.x)- 12);
                    }
                  }
                }
              } catch (e) {
                console.log(e);
              }
           
          }
            break;
        }
      }
    }
  }

  bindingTocounters(left, right, top, bottom, element, type, base?) {
    const This = this;
    if (type === 'horizon') {
      right.attrs.bind.push(element);
      left.attrs.bind.push(element);
      right.on('dragmove', function (e) {
        This.rightEvent(left, right, top, bottom, element, type, base);
      });
      left.on('dragmove', function () {
        This.leftEvent(left, right, top, bottom, element, type, base);
      });
    } else {
      if (element.attrs.params.type !== this.coef.id_element.trumpet) {
        if (top.attrs.params.type !== this.coef.id_element.trumpet) {
          top.attrs.bind.push(element);
        }
        if (bottom.attrs.params.type !== this.coef.id_element.trumpet) {
          bottom.attrs.bind.push(element);
        }
      }
      bottom.on('dragmove', function () {
        element.height(bottom.attrs.y - element.attrs.y);
      });
      top.on('dragmove', function () {
        element.y(top.attrs.y + top.attrs.height);
        element.height(bottom.attrs.y - element.attrs.y);
      });
    }
    if (element.attrs.params.type === this.coef.id_element.open_door) {
      top.attrs.bind.push(element);
      bottom.attrs.bind.push(element);
      bottom.on('dragmove', () => {
        element.attrs.params.height = (bottom.attrs.y - element.attrs.y);
        element['children'][0].height(bottom.attrs.y - element.attrs.y);
        element['children'][1].y((bottom.attrs.y - element.attrs.y) / 2 - 16 / 2);
        const e = element.attrs.params.subId;
        if (e === this.coef.element_Sub_id.twoDoorLock || e === this.coef.element_Sub_id.twoDoor) {
          element['children'][2].y((bottom.attrs.y - top.attrs.y - top.attrs.height) / 2 - 16 / 2);
          element['children'][3].height(bottom.attrs.y - top.attrs.y - top.attrs.height);
        }
        if (e === this.coef.element_Sub_id.twoDoorLockD || e === this.coef.element_Sub_id.twoDoorD) {
          element['children'][2].y((bottom.attrs.y - top.attrs.y - top.attrs.height) / 2 - 16 / 2);
          element['children'][3].height(bottom.attrs.y - top.attrs.y - top.attrs.height);
        }
      });
      top.on('dragmove', () => {
        element.attrs.params.height = (bottom.attrs.y - element.attrs.y);
        element['children'][1].y((bottom.attrs.y - top.attrs.y - top.attrs.height) / 2 - 16 / 2);
        element['children'][0].height(bottom.attrs.y - top.attrs.y - top.attrs.height);
        element.y(top.attrs.y + top.attrs.height);
        const e = element.attrs.params.subId;
        if (e === this.coef.element_Sub_id.twoDoorLock || e === this.coef.element_Sub_id.twoDoor) {
          element['children'][2].y((bottom.attrs.y - top.attrs.y - top.attrs.height) / 2 - 16 / 2);
          element['children'][3].height(bottom.attrs.y - top.attrs.y - top.attrs.height);
        }
        if (e === this.coef.element_Sub_id.twoDoorLockD || e === this.coef.element_Sub_id.twoDoorD) {
          element['children'][2].y((bottom.attrs.y - top.attrs.y - top.attrs.height) / 2 - 16 / 2);
          element['children'][3].height(bottom.attrs.y - top.attrs.y - top.attrs.height);
        }
      });
    }
    if (element.attrs.params.type === this.coef.id_element.back_wall) {
      top.attrs.bind.push(element);
      bottom.attrs.bind.push(element);
      bottom.on('dragmove', () => {
        element.attrs.params.height = (bottom.attrs.y - element.attrs.y);
        if (top.attrs.params.type === this.coef.id_element.top_wall) {
          element['children'][0].height(bottom.attrs.y - element.attrs.y - this.coef.look2dPosition);
        } else {
          element['children'][0].height(bottom.attrs.y - element.attrs.y);
        }
      });
      top.on('dragmove', () => {
        element.attrs.params.height = (bottom.attrs.y - element.attrs.y);
        if (bottom.attrs.params.type === this.coef.id_element.bottom_wall) {
          element['children'][0].height(bottom.attrs.y - top.attrs.y - this.coef.look2dPosition - top.attrs.height);
          element.y(top.attrs.y + top.attrs.height);
        } else {
          element['children'][0].height(bottom.attrs.y - top.attrs.y - top.attrs.height);
          element.y(top.attrs.y + top.attrs.height);
        }
      });
    }
    if (element.attrs.params.type === this.coef.id_element.hooks) {
      bottom.on('dragmove', () => {
        // element.y((top.attrs.y + top.attrs.height + This.coef.hook.top_wall) * 1.4);
      });
      top.on('dragmove', () => {
        element.attrs.params.height = (bottom.attrs.y - element.attrs.y);
        element.y(top.attrs.y + top.attrs.height + This.coef.hook.top_wall);
      });
    }
    if (element.attrs.params.type === this.coef.id_element.retractable_pipe) {
      top.attrs.bind.push(element);
      bottom.attrs.bind.push(element);
      bottom.on('dragmove', () => {
        for (let i = 1; i < element.children.length + 1; i++) {
          element.y(top.attrs.y + top.attrs.height);
        }
      });
      top.on('dragmove', () => {
        for (let i = 1; i < element.children.length + 1; i++) {
          element.y(top.attrs.y + top.attrs.height);
        }
      });
    }
    if (element.attrs.params.type === this.coef.id_element.drawer) {
      top.attrs.bind.push(element);
      // bottom.attrs.bind.push(element);
      element.attrs.params.bind = [];
      if (top.attrs.params.type === this.coef.id_element.drawer) {
        element.attrs.params.bind.push(top);
      }

      bottom.on('dragmove', () => {
        if (element.attrs.bind.length > 0) {
          let height = top.attrs.height
          if(!height){
            height =  top.attrs.params.height
          }
          const test = top.attrs.y + height;
          const test2 = element.attrs.y + element.attrs.params.height;
          element.y(test);
          element.attrs.bind[0].y(test2);
        }
      });
      top.on('dragmove', () => {
        element.y(top.attrs.y + top.attrs.height);
        this.changeTopdrawer(element);
      });
    }
  }

  isBase(array) {
    for (const item of array) {
      if (item.id === 6 && item.value === 33) {
        return true;
      }
    }
    return false;
  }

  createLegs(e, stage) {
    this.coef.legs = new Layer();
    const base = this.isBase(e.init_types);
    const centerPosition = (this.coef.widthWrapper - e.width / this.coef.global_coef) / 2;
    const topLegs = this.coef.topPositaionWrapper + e.height / this.coef.global_coef - this.coef.height_legs;
    const width = (e.width / this.coef.global_coef) - (this.coef.edgeRetreat * 2);
    const countLegsSubspace = Math.ceil(width / this.coef.edgeLegs);
    const sizeAfterLegs = width / countLegsSubspace;
    const countLegs = countLegsSubspace - 1;
    let widthLegs = e.width / this.coef.global_coef;
    let xPosition = centerPosition;
    if (base) {
      xPosition = centerPosition + this.coef.global_size_flat;
      widthLegs = e.width / this.coef.global_coef - this.coef.global_size_flat * 2;
    }
    const bottomRect = new Konva.Rect({
      x: xPosition,
      y: topLegs,
      name: 'element' + this.coef.countNameElement,
      width: widthLegs,
      height: this.coef.height_legs,
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    this.coef.legs.add(bottomRect);
    stage.add(this.coef.legs);
  }

  createLamps(e, lamp, stage) {
    if (e.lamps == 2) {
      lamp = new Layer();
    }

    const centerPosition = (this.coef.widthWrapper - e.width / this.coef.global_coef) / 2;
    let lamps;
    if (e.lamps == 2) {
      lamps = new Konva.Rect({
        x: centerPosition,
        y: this.coef.topPositaionWrapper - 4.5,
        width: (e.width / this.coef.global_coef),
        height: 4.5,
        name: 'element' + this.coef.countNameElement,
        fill: '#FFEEBC',
        bind: [],
        strokeWidth: 1,
        stroke: 'black',
        shadowColor: '#FFEEBC',
        shadowBlur: 20,
        shadowOffset: {x: 0, y: -2},
        shadowOpacity: 1
      });
    }
    if (e.lamps == 2) {
      lamp.add(lamps);
      stage.add(lamp);
    } else {
      if (lamps) {
        lamp.removeChildren();
        lamp.batchDraw();
      }
    }
  }

  getTangentElement(pos, mainElement?, parents?, drawer?) {
    let elemLeft;
    let elemRight;
    let elemTop;
    let elemBottom;
    let left = 0;
    let top = 0;
    let right = 99999;
    let bottom = 99999;
    const positionElementX = pos.evt.layerX;
    const positionElementY = pos.evt.layerY;
    for (const element of parents.children) {
      if (this.checkHorizontalVertical(element)) {
        if (positionElementY >= element.attrs.y && positionElementY < (element.attrs.y + (element.attrs.height ? element.attrs.height : element.attrs.params.height))) {
          if (element.attrs.params.type === 13) {
            // console.log(element);
          }
          if (positionElementX > element.attrs.x && element.attrs.x > left) {
            left = element.attrs.x;
            elemLeft = element;
          }
          if (positionElementX < element.attrs.x && element.attrs.x < right) {
            right = element.attrs.x;
            elemRight = element;
          }
        }
      } else {
        if (element.attrs.name !== mainElement.attrs.name) {
          if (positionElementX >= element.attrs.x && positionElementX < (element.attrs.x + element.attrs.width)) {
            if (positionElementY > element.attrs.y && element.attrs.y > top) {
              if (drawer) {
                if (element.attrs.params.type === this.coef.id_element.shelf || element.attrs.params.type === this.coef.id_element.top_wall) {
                  top = element.attrs.y;
                  elemTop = element;
                }
              } else {
                top = element.attrs.y;
                elemTop = element;
              }


            }
            if (positionElementY < element.attrs.y && element.attrs.y < bottom) {
              if (drawer) {
                if (element.attrs.params.type === this.coef.id_element.shelf || element.attrs.params.type === this.coef.id_element.bottom_wall) {
                  bottom = element.attrs.y;
                  elemBottom = element;
                }
              } else {
                bottom = element.attrs.y;
                elemBottom = element;
              }
            }
          }
        }
      }
    }
    if (this.coef.pramsId != 4) {
      if (elemLeft && elemRight && elemTop && elemBottom) {
        return {top: elemTop, right: elemRight, bottom: elemBottom, left: elemLeft};
      } else {
        return false;
      }
    } else {
      return {top: elemTop, right: elemRight, bottom: elemBottom, left: elemLeft};
    }

  }

  checkHorizontalVertical(element) {
    switch (element.attrs.params.type) {
      case 1:
        return false;
      case 3:
        return false;
      case 4:
        return false;
      case this.coef.id_element.pantograph:
        return false;
      case this.coef.id_element.partition:
        return true;
      case this.coef.id_element.shelf:
        return false;
      case this.coef.id_element.trumpet:
        return false;
      case this.coef.id_element.lateral_wall:
        return true;
      case this.coef.id_element.top_wall:
        return false;
      case this.coef.id_element.bottom_wall:
        return false;
      case this.coef.id_element.double_partition:
        return true;
      default:
        return false;
    }
  }

  isOnWhat(pos, element, array) {
    for (const elem of array.children) {
      if (elem.attrs.x <= pos.evt.layerX && (elem.attrs.x + elem.attrs.width) >= pos.evt.layerX) {
        if (elem.attrs.y <= pos.evt.layerY && (elem.attrs.y + (elem.attrs.params.height || elem.attrs.height)) >= pos.evt.layerY) {
          return elem;
        }
      }
    }
    return false;
  }

  changeTopdrawer(element) {
    if (element) {
      if (element.attrs.bind.length > 0) { 
        element.attrs.bind[0].y(element.attrs.y + element.attrs.params.height);
          this.changeTopdrawer(element.attrs.bind[0]);
      }
    }
  }

  EventTravel;

  ManualTravel() {
    this.coef.shapesLayerw.on('click', (e) => {
      if (e.target.attrs &&
        e.target.attrs.params &&
        e.target.attrs.params.drawer &&
        e.target.attrs.params.drawer.elementBottom &&
        e.target.attrs.params.drawer.elementBottom.attrs.params.stopDrags) {
        return;
      }
      if (e.target.attrs && e.target.attrs.params && e.target.attrs.params.removable) {
        this.coef.manualTravelElement = e.target;
      }
      if (e.target.attrs && e.target.attrs.params && !e.target.attrs.params.removable && e.target.attrs.params.type !== 6) {
        this.coef.manualTravelElement = e.target;
      }
      if (this.coef.manualTravelElement.parent && this.coef.manualTravelElement.parent.nodeType && this.coef.manualTravelElement.parent.nodeType === 'Group' && this.coef.manualTravelElement.parent.attrs.params.type === 13) {
        this.coef.manualTravelElement = this.coef.manualTravelElement.parent;
      }
      for (const item of this.coef.shapesLayerw.children) {
        if (!this.coef.manualTravelElement.partialText) {
          item.attrs.strokeWidth = 1;
          item.attrs.stroke = this.coef.blackColor;
        }
      }
      if (this.coef.manualTravelElement && this.coef.manualTravelElement.parent.nodeType !== 'Group' && !this.coef.manualTravelElement.partialText) {
        this.coef.manualTravelElement.attrs.strokeWidth = 2;
        this.coef.manualTravelElement.attrs.stroke = 'green';
        this.coef.shapesLayerw.batchDraw();
      }
    });

    this.EventTravel = (event) => {
      if (this.coef.manualTravelElement && this.coef.manualTravelElement.attrs &&
        this.coef.manualTravelElement.attrs.params &&
        this.coef.manualTravelElement.attrs.params.drawer &&
        this.coef.manualTravelElement.attrs.params.drawer.elementBottom &&
        this.coef.manualTravelElement.attrs.params.drawer.elementBottom.attrs.params.stopDrags) {
        return;
      }
      if (this.coef.manualTravelElement) {
        console.log(event.keyCode, 'event.keyCode');
        switch (event.keyCode) {
          case 37:
            event.preventDefault();
            if (this.topLeftBottomRight(this.ifGroup(this.coef.manualTravelElement).attrs.params.type) === 2) {
              this.goTo('x', this.coef.manualTravelElement, -1);
            }
            if (!this.StopKeyV(this.coef.manualTravelElement)) {
              this.goTo('x', this.coef.manualTravelElement, 1);
            }
            break;
          case 38:
            event.preventDefault();
            if (!this.coef.manualTravelElement.attrs.params.stopDrags) {
              if (this.topLeftBottomRight(this.ifGroup(this.coef.manualTravelElement).attrs.params.type) === 1) {
                this.goTo('y', this.coef.manualTravelElement, -1);
              }
              if (!this.StopKeyH(this.coef.manualTravelElement)) {
                this.goTo('y', this.coef.manualTravelElement, 1);
              }
            }
            break;
          case 39:
            event.preventDefault();
            if (this.topLeftBottomRight(this.ifGroup(this.coef.manualTravelElement).attrs.params.type) === 2) {
              this.goTo('x', this.coef.manualTravelElement, 1);
            }
            if (!this.StopKeyV(this.coef.manualTravelElement)) {
              this.goTo('x', this.coef.manualTravelElement, -1);
            }
            break;
          case 40:
            event.preventDefault();
            if (!this.coef.manualTravelElement.attrs.params.stopDrags) {
              if (this.topLeftBottomRight(this.ifGroup(this.coef.manualTravelElement).attrs.params.type) === 1) {
                this.goTo('y', this.coef.manualTravelElement, 1);
              }
              if (!this.StopKeyH(this.coef.manualTravelElement)) {
                this.goTo('y', this.coef.manualTravelElement, -1);
              }
            }
            break;
          default:
        }
      }
    };
    document.addEventListener('keydown', this.EventTravel);
  }

  // 1 trawel top and bottom
  // 2 trawel left and right
  topLeftBottomRight(id) {
    switch (id) {
      // case 8 :
      //   return 1;
      case 6:
        return 2;
      case 250:
        return 2;
      case 7:
        return 1;
      case 13:
        return 2;
      default:
        return 0;
    }
  }

  ifGroup(element) {
    if (element.parent.nodeType === 'Group') {
      return element.parent;
    } else {
      return element;
    }
  }

  StopKeyH(element) {
    if (this.ifGroup(element).attrs.params.type === 7) {
      this.Level.getLeftRightBind(this.coef.manualTravelElement);
      this.coef.manualTravelElement.attrs.params.old_position = {
        x: this.coef.manualTravelElement.attrs.x,
        y: this.coef.manualTravelElement.attrs.y
      };
      return !this.Level.checkBlockShelf(this.coef.manualTravelElement);
    }
    return true;
  }

  StopKeyV(element) {
    if (this.ifGroup(element).attrs.params.type === 6) {
      this.Level.getLeftRightBind(this.coef.manualTravelElement, true);
      this.coef.manualTravelElement.attrs.params.old_position = {
        x: this.coef.manualTravelElement.attrs.x,
        y: this.coef.manualTravelElement.attrs.y
      };
      return !this.Level.checkBlockPartition(this.coef.manualTravelElement);
    }
    return true;
  }

  goTo(x, manualTravelElement, number) {
    if (manualTravelElement.attrs.params.type == 250) {
      this.resizeCornerEnding(x, manualTravelElement.attrs.params.bind, number, manualTravelElement);
    } else {
      const drag = new Event('dragmove');
      manualTravelElement[x](manualTravelElement[x]() + number / this.coef.global_coef);
      this.minSize.minSizeManualTravel(this.coef.shapesLayerw, {target: manualTravelElement}, true);
      manualTravelElement.dispatchEvent(drag);
      this.coef.shapesLayerw.batchDraw();
      this.price.sendObjectPrice();
    }
  }


  blockSelf(countours, xy, target?) {
    if (this.coef.pramsId == 11) {
      return false;
    }
    this.Level.arrayBind = [];
    this.Level.getLeftRightBind(target ? target : {}, false, countours ? countours : false);
    const targets = {attrs: {y: xy.y, height: xy.height, params: {subId: 20}}};
    if (target) {
      return this.Level.checkBlockShelf(target);
    }
    return this.Level.checkBlockShelf(targets);
  }

  removeElement(name) {
    // this.remove.deleteElement(name, this.coef.shapesLayerw);
  }

  resizeCornerEnding(x, group, dir, manualTravelElement) {
    const width = group.children[1].width() + (dir / this.coef.global_coef);
    console.log(width, width <= this.coef.ending.max_width, 'width');
    if (group.attrs.params.position === 'right') {
      if (width <= this.coef.ending.max_width && width >= this.coef.ending.min_width_riall) {
        group.attrs.width = group.attrs.width + (dir / this.coef.global_coef);
        this.resizWidth(width, group);
        const drag = new Event('dragmove');
        manualTravelElement[x](manualTravelElement[x]() + dir / this.coef.global_coef);
        this.minSize.minSizeManualTravel(this.coef.shapesLayerw, {target: manualTravelElement}, true);
        manualTravelElement.dispatchEvent(drag);
        this.coef.shapesLayerw.batchDraw();
        this.price.sendObjectPrice();
      }
    }
    if (group.attrs.params.position === 'left') {
      if (-1 * width <= this.coef.ending.max_width && -1 * width >= this.coef.ending.min_width_riall) {
        this.resizWidth(width, group);
        const drag = new Event('dragmove');
        manualTravelElement[x](manualTravelElement[x]() + dir / this.coef.global_coef);
        this.minSize.minSizeManualTravel(this.coef.shapesLayerw, {target: manualTravelElement}, true);
        manualTravelElement.dispatchEvent(drag);
        this.coef.shapesLayerw.batchDraw();
        this.price.sendObjectPrice();
      }
    }
  }

  resizWidth(width, group) {
    for (let i = 1; i < group.children.length; i++) {
      if (group.children[i].className !== 'Text') {
        group.children[i].width(width);
        group.children[i].x(0);
      }
    }
    if (width < 0) {
      group.children[group.children.length - 1].x(-15);
    } else {
      group.children[group.children.length - 1].x(width - 15);
    }
  }
}
