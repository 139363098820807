import {Injectable} from '@angular/core';
import {CoefService} from './coef.service';
import {PriceService} from './price.service';
import {Sizes} from '../class/sizes/sizes';
import {CreateService} from './create.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class RemoveService {
  size;

  constructor(private coef: CoefService, private price: PriceService, private create: CreateService) {
    this.size = new Sizes(this.coef, this.create);
  }

  arrayElement = [];

  deleteElement(elementName, parent, row?) {
    if (this.coef.startRemove) {
      let index;
      let elementCornerDir = null;
      for (const elements of parent.children) {
        if (elements.attrs.name === elementName) {
          if (elements.attrs && elements.attrs.params && elements.attrs.params.type && elements.attrs.params.type == 15) {
            elementCornerDir = elements.attrs.params.position;
          }
          index = parent.children.indexOf(elements);
        }
      }
      if (index) {
        const indexSize = index;
        if (parent.children[index].attrs.params.removable) {
          if (parent.children[index].attrs.params.type === this.coef.id_element.drawer) {
            const countors = this.create.getTangentElement({
                evt: {
                  layerX: parent.children[index].attrs.x,
                  layerY: parent.children[index].attrs.y
                }
              },
              parent.children[index], this.coef.shapesLayerw);
            if (countors && countors['top'].attrs.params.type === this.coef.id_element.shelf) {
              if (countors['top'].attrs.params.drawer.elementBottom.attrs.params.stopDrags) {
                countors['top'].attrs.params.drawer.elementBottom.attrs.params.stopDrags = false;
              }
              delete countors['top'].attrs.params['drawer'];
              countors['top'].attrs.params['drawerArray'] = [];
            }
          }
          const name = parent.children[index].attrs.name;
          this.coef.deleted_older_content_square = this.coef.deleted_older_content_square + this.elementType(parent.children[index]);
          this.arrayElement.push(name);
          parent.children.splice(index, 1);
          this.removeBindElement(this.arrayElement);
          this.price.sendObjectPrice();
          if (elementCornerDir) {
            this.size.deleteItem(elementCornerDir);
          }else{
            this.size.deleteItem();
          }

        }
        if (row) {
          if (!parent.children[index].attrs.params.removable) {
            if (parent.children[index].attrs.params.type === 250) {
              const name = parent.children[index].attrs.name;
              this.coef.deleted_older_content_square = this.coef.deleted_older_content_square + this.elementType(parent.children[index]);
              this.arrayElement.push(name);
              parent.children.splice(index, 1);
              this.removeBindElement(this.arrayElement);
              this.price.sendObjectPrice();
            }
          }
        }
      }
      parent.batchDraw();
    }
  }

  _getSquare(element, firstParam, secondParam) {
    return (element[firstParam] * element[secondParam]) / 1000000;
  }


  elementType(element) {
    if (!element.attrs.params.older) return 0;
    switch (element.attrs.params.type) {
      case 1:
        return this._getSquare(element.attrs.params.older, 'height', 'width');
      case 6:
        return this._getSquare(element.attrs.params.older, 'depth', 'height');
      case 7:
        return this._getSquare(element.attrs.params.older, 'width', 'depth');
      case 8 :
        this.coef.deleted_older_content_elements.tubes++;
        return 0;
      default:
        return 0;
    }
  }


  deleteElements(elementsArray, parent, drawer?, row?) {
    this.arrayElement = [];
    const bind = [];
    for (const elemetArray of elementsArray) {
      if (elemetArray.attrs.bind.length !== 0) {
        this.deleteElements(elemetArray.attrs.bind, parent);
      }
      if (drawer) {
        if (elemetArray.attrs.params.bind) {
          if (elemetArray.attrs.params.bind.length !== 0) {
            this.deleteElements(elemetArray.attrs.params.bind, parent, true);
          }
        }
      }
      bind.push(elemetArray.attrs.name);
    }
    for (const name of bind) {
      this.deleteElement(name, parent, row);
    }
  }

  removeColor(array, color, color2?, parent?) {
    parent.batchDraw();
  }

  startElement(parent) {
    this.coef.startRemove = !this.coef.startRemove;
    if (this.coef.startRemove) {
      this.removeColor(parent.children, 'rgba(0, 255, 0, 1 )', 'red', parent);
    } else {
      this.removeColor(parent.children, this.coef.color, this.coef.color, parent);
    }
  }


  mousOnColor(array, type) {
    for (const element of array) {
      if (element.nodeType === 'Group') {
        if (type) {
          for (const children of element.children) {
            if (children.nodeType !== 'Group') {
              children.fill(this.chanchOpacity(children.fill(), 0.1));
            }
          }
        } else {
          for (const children of element.children) {
            if (children.nodeType !== 'Group') {
              children.fill(this.chanchOpacity(children.fill(), 1));
            }
          }
        }
      } else {
        if (type) {
          element.fill(this.chanchOpacity(element.fill(), 0.1));
        } else {
          element.fill(this.chanchOpacity(element.fill(), 1));
        }
        if (element.attrs.bind.length > 0) {
          this.mousOnColor(element.attrs.bind, type);
        }
      }
    }
  }

  chanchOpacity(string, opacity) {
    let respons = string.split(',');
    let opac = respons[respons.length - 1].split(')')[0];
    respons.splice(respons.length - 1, 1);
    respons.toString();
    respons = respons + ',' + opacity + ')';
    return respons;
  }

  removeBindElement(array) {
    for (const deleteName of array) {
      for (const element of this.coef.shapesLayerw.children) {
        for (const bindElem of element.attrs.bind) {
          let index;
          if (bindElem.attrs.name === deleteName) {
            index = element.attrs.bind.indexOf(bindElem);
            element.attrs.bind.splice(index, 1);
          }
        }
      }
    }
  }
}
