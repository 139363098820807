import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.css']
})
export class ErrorModalComponent implements OnInit {

  @Input() defaultValue: string;
  @Input() typeValue: string;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    
  }

  onSubmit(close) {
    if(close){
      this.modalClosed.emit(false);
    }else{
      this.modalClosed.emit(this.typeValue);
    }
    
  }

}
