import {animate, keyframes, query, stagger, style, transition, trigger} from '@angular/animations';

export const FADE_IN_OUT = trigger('easeInOut', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate('.2s ease-in-out', style({
      opacity: 1,
    }))
  ]),
  transition(':leave', [
    style({
      opacity: 1
    }),
    animate('.2s ease-in-out', style({
      opacity: 0
    }))
  ])
]);

export const STAGGER = trigger('listAnimation', [
  transition('* => *', [

    query(':enter', style({opacity: 0}), {optional: true}),

    query(':enter', stagger('500ms', [
      animate('.2s ease-in', keyframes([
        style({opacity: 0, transform: 'translateY(-15%)', offset: 0}),
        // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
        style({opacity: 1, transform: 'translateY(0)', offset: 1.0}),
      ]))]), {optional: true}),
    /*query(':leave', stagger('500ms', [
      animate('.2s ease-in', keyframes([
        style({opacity: 1, transform: 'translateY(0)', offset: 0}),
        style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
        style({opacity: 0, transform: 'translateY(-15%)', offset: 1.0}),
        style({opacity: 0, transform: 'translateY(-15%)', offset: 1.0}),
      ]))]), {optional: true})*/
  ])
]);
