import * as Konva from '../../../assets/konva';
import * as _ from 'lodash';


export class Sizes {
  coef;
  create;
  coefDoor;

  constructor(coef, create?  , coefDoor?) {
    this.coef = coef;
    this.create = create;
    this.coefDoor = coefDoor;
  }

  NewHorizont(bind, element) {
    this.coef.countNameElement++;
    const groupsL = new Konva.Group(
      {
        x: bind['left'].attrs.x + bind['left'].attrs.width,
        y: bind['bottom'].attrs.y - 10,
        draggable: false,
        width: element.attrs.x - bind['left'].attrs.x - bind['left'].attrs.width,
        name: 'element' + this.coef.countNameElement,
        params: {
          removable: true,
        },
        bind: [],
      }
    );
    const LineL = new Konva.Rect({
      x: 0,
      y: 0,
      width: groupsL['attrs'].width,
      height: 1,
      name: 'width',
      fill: 'black',

    });
    const polyLL = new Konva.Line({
      points: [
        10, -3,
        0, 0,
        10, 3,
        10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyL = new Konva.Line({
      points: [
        10, -3,
        0, 0,
        10, 3,
        10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const groupsR = new Konva.Group(
      {
        x: element.attrs.x + element.attrs.width,
        y: bind['bottom'].attrs.y - 10,
        draggable: false,
        width: bind['right'].attrs.x - element.attrs.x - element.attrs.width,
        name: 'element' + this.coef.countNameElement,
        params: {
          removable: true,
        },
        bind: [],
      }
    );
    const polyR = new Konva.Line({
      points: [
        groupsR['attrs'].width - 10, -3,
        groupsR['attrs'].width - 0, 0,
        groupsR['attrs'].width - 10, 3,
        groupsR['attrs'].width - 10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyRR = new Konva.Line({
      points: [
        groupsL['attrs'].width - 10, -3,
        groupsL['attrs'].width - 0, 0,
        groupsL['attrs'].width - 10, 3,
        groupsL['attrs'].width - 10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const LineR = new Konva.Rect({
      x: 0,
      y: 0,
      width: groupsR['attrs'].width,
      height: 1,
      name: 'width',
      fill: 'black',
    });
    const TextL = new Konva.Text({
      x: groupsL.getWidth() / 2 - 15,
      y: -15,
      text: JSON.stringify(_.round(groupsL.getWidth() * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: 'black'
    });
    const TextR = new Konva.Text({
      x: groupsR.getWidth() / 2 - 15,
      y: -15,
      text: JSON.stringify(_.round(groupsR.getWidth() * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: 'black'
    });
    groupsL.add(LineL, TextL, polyLL, polyRR);
    groupsR.add(LineR, TextR, polyL, polyR);
    this.removeOldElement(groupsL);
    element.attrs.params.size.push(groupsL, groupsR);
    if (this.bindPositin(bind['top'].attrs.bind, bind['top'], 'top')) {
      const contours = this.create.getTangentElement({
          evt: {
            layerX: bind['top'].attrs.x,
            layerY: bind['top'].attrs.y
          }
        },
        bind['top'], this.coef.shapesLayerw
      );
      if (contours && contours['left'] && contours['right']) {
        const topSize = this.createOneSizeHorizont(contours, bind['top']);
        this.removeOldElement(topSize);
        this.coef.Size.add(topSize);
        this.bindSIzeElemOneTop(contours, bind['top'], topSize);
      }
    }
    this.coef.Size.add(groupsL, groupsR);
    this.coef.Size.batchDraw();
    this.bindSizeElementRL(bind, element, groupsR, groupsL);
  }

  bindSIzeElemOneTop(bind, element, L) {
    bind['left'].on('dragmove', (e) => {
      this.resize(bind['left'], bind['right'], bind['right'], L);
    });
    bind['right'].on('dragmove', (e) => {
      this.resize(bind['left'], bind['right'], bind['right'], L);
    });
    element.on('dragmove', (e) => {
      L.y(element.y() - 10);
    });
  }

  bindSIzeElemOneLeft(bind, element, L) {
    bind['top'].on('dragmove', (e) => {
      this.resizeTOP(bind['top'], bind['bottom'], L);
    });
    bind['bottom'].on('dragmove', (e) => {
      this.resizeTOP(bind['top'], bind['bottom'], L);
    });
    element.on('dragmove', (e) => {
      L.x(element.x() - 16);
    });
  }

  bindSizeElementRL(bind, element, R, L) {
    bind['left'].on('dragmove', (e) => {
      this.resize(bind['left'], element, element, L);
    });
    element.on('dragmove', (e) => {
      this.resize(bind['left'], element, element, L);
      this.resize(element, bind['right'], bind['right'], R);
    });
    bind['right'].on('dragmove', (e) => {
      this.resize(element, bind['right'], bind['right'], R);
    });
    bind['bottom'].on('dragmove', (e) => {
      R.y(bind['bottom'].attrs.y - 10);
      L.y(bind['bottom'].attrs.y - 10);
    });
  }

  resize(left, right, element, on) {
    on.x(left.attrs.x + left.attrs.width);
    on.width(right.attrs.x - left.attrs.x - left.attrs.width);
    on.children[0].width(on.width());
    on.children[1].x(on.width() / 2 - 15);
    on.children[1].text(JSON.stringify(_.round(on.width() * this.coef.global_coef, 1)));
    // on.children[3].x(0);
    on.children[3].attrs.points = [on['attrs'].width - 10, -3,
      on['attrs'].width - 0, 0,
      on['attrs'].width - 10, 3,
      on['attrs'].width - 10, -3];
    this.coef.Size.batchDraw();
  }

  //
  NewVertical(bind, element) {
    this.coef.countNameElement++;
    if (bind['top'].attrs.params.type !== 7 && bind['top'].attrs.params.type !== 10) {
      const contours = this.create.getTangentElement({
          evt: {
            layerX: bind['top'].attrs.x,
            layerY: bind['top'].attrs.y
          }
        },
        bind['top'], this.coef.shapesLayerw, true
      );
      bind['top'] = contours['top'];
    }
    if (bind['bottom'].attrs.params.type !== 7 && bind['bottom'].attrs.params.type !== 11) {
      const contours = this.create.getTangentElement({
          evt: {
            layerX: bind['bottom'].attrs.x,
            layerY: bind['bottom'].attrs.y
          }
        },
        bind['bottom'], this.coef.shapesLayerw, true
      );
      bind['bottom'] = contours['bottom'];
    }
    /// top line Size
    const groupsT = new Konva.Group({
      x: bind['left'].attrs.x + (bind['right'].attrs.x - bind['left'].attrs.x) - 16,
      y: bind['top'].attrs.y + bind['top'].attrs.height,
      draggable: false,
      width: 4,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        height: element.attrs.y - bind['top'].attrs.height - bind['top'].attrs.y
      },
      bind: [],
    });
    const LineT = new Konva.Rect({
      x: 0,
      y: 0,
      width: 1,
      height: groupsT['attrs'].params.height,
      name: 'width',
      fill: 'black',
    });
    const TextT = new Konva.Text({
      x: -16,
      y: groupsT['attrs'].params.height / 2 + 14,
      text: JSON.stringify(_.round(groupsT['attrs'].params.height * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: 'black',
      rotation: -90
    });
    const polyT = new Konva.Line({
      points: [
        -3, 10,
        0, 0,
        3, 10,
        -3, 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyTT = new Konva.Line({
      points: [
        -3, groupsT['attrs'].params.height - 10,
        -0, groupsT['attrs'].params.height - 0,
        3, groupsT['attrs'].params.height - 10,
        -3, groupsT['attrs'].params.height - 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    ///bottom line Size
    const groupsB = new Konva.Group({
      x: bind['left'].attrs.x + (bind['right'].attrs.x - bind['left'].attrs.x) - 16,
      y: element.attrs.y + element.attrs.height,
      draggable: false,
      width: 4,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        height: bind['bottom'].attrs.y - element.attrs.height - element.attrs.y
      },
      bind: [],
    });
    const LineB = new Konva.Rect({
      x: 0,
      y: 0,
      width: 1,
      height: groupsB['attrs'].params.height,
      name: 'width',
      fill: 'black',
    });
    const polyB = new Konva.Line({
      points: [
        -3, 10,
        0, 0,
        3, 10,
        -3, 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyTB = new Konva.Line({
      points: [
        -3, groupsB['attrs'].params.height - 10,
        -0, groupsB['attrs'].params.height - 0,
        3, groupsB['attrs'].params.height - 10,
        -3, groupsB['attrs'].params.height - 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const TextB = new Konva.Text({
      x: -16,
      y: groupsB['attrs'].params.height / 2 + 14,
      text: JSON.stringify(_.round(groupsB['attrs'].params.height * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: 'black',
      rotation: -90
    });
    groupsT.add(LineT, TextT, polyT, polyTT);
    groupsB.add(LineB, TextB, polyB, polyTB);
    this.removeOldElement(groupsT);
    element.attrs.params.size.push(groupsT, groupsB);
    if (this.bindPositin(bind['left'].attrs.bind, bind['left'], 'left')) {
      const contours = this.create.getTangentElement({
          evt: {
            layerX: bind['left'].attrs.x,
            layerY: bind['left'].attrs.y
          }
        },
        bind['left'], this.coef.shapesLayerw, true
      );
      if (contours && contours['left'] && contours['right']) {
        const leftSize = this.createOneSizeVertical(contours, bind['left']);
        this.removeOldElement(leftSize);
        this.coef.Size.add(leftSize);
        const contTop = this.create.getTangentElement({
            evt: {
              layerX: bind['left'].attrs.x,
              layerY: bind['left'].attrs.y
            }
          },
          bind['left'], this.coef.shapesLayerw
        );
        this.bindSIzeElemOneLeft(contTop, bind['left'], leftSize);
      }
    }
    this.coef.Size.add(groupsT, groupsB);
    this.coef.Size.batchDraw();
    this.bindSizeElementTB(bind, element, groupsT, groupsB);
  }

  bindSizeElementTB(bind, element, T, B) {
    if (T) {
      bind['top'].on('dragmove', (e) => {
        this.resizeTOP(bind['top'], element, T);
      });
    }
    element.on('dragmove', (e) => {
      if (T) {
        this.resizeTOP(bind['top'], element, T);
      }
      this.resizeTOP(element, bind['bottom'], B);
    });
    bind['bottom'].on('dragmove', (e) => {
      this.resizeTOP(element, bind['bottom'], B);
    });
    bind['left'].on('dragmove', (e) => {
      B.x(bind['left'].attrs.x + (bind['right'].attrs.x - bind['left'].attrs.x) - 16);
      if (T) {
        T.x(bind['left'].attrs.x + (bind['right'].attrs.x - bind['left'].attrs.x) - 16);
      }
    });
    bind['right'].on('dragmove', (e) => {
      B.x(bind['left'].attrs.x + (bind['right'].attrs.x - bind['left'].attrs.x) - 16);
      if (T) {
        T.x(bind['left'].attrs.x + (bind['right'].attrs.x - bind['left'].attrs.x) - 16);
      }
    });
  }

  resizeTOP(top, bottom, on) {
    on.y(top.attrs.y + (top.attrs.height ? top.attrs.height : top.attrs.params.height));
    on.attrs.params.height = bottom.attrs.y - top.attrs.y - (top.attrs.height ? top.attrs.height : top.attrs.params.height);
    on.children[0].height((on.attrs.height ? on.attrs.height : on.attrs.params.height));
    on.children[1].y((on.attrs.height ? on.attrs.height : on.attrs.params.height) / 2 + 14);
    on.children[1].text(JSON.stringify(_.round((on.attrs.height ? on.attrs.height : on.attrs.params.height) * this.coef.global_coef, 1)));
    on.children[3].attrs.points = [
      -3, on['attrs'].params.height - 10,
      -0, on['attrs'].params.height - 0,
      3, on['attrs'].params.height - 10,
      -3, on['attrs'].params.height - 10
    ];
    this.coef.Size.batchDraw();
  }

  removeOldElement(T) {
    for (const size of this.coef.Size.children) {
      if (size.attrs.x === T.attrs.x && size.attrs.y === T.attrs.y) {
        this.deleteOneSize(size);
      }
    }
  }

  deleteOneSize(element) {
    const index = this.coef.Size.children.indexOf(element);
    if (index >= 0) {
      this.coef.Size.children.splice(index, 1);
      this.coef.Size.batchDraw();
    }
  }

  removeFilter(e, corner) {
    if (corner) {
      if (e.attrs && e.attrs.params && e.attrs.params.name && e.attrs.params.name != corner) {
        return false;
      }
    }
    return true;
  }

  deleteItem(corner?) {
    if (corner) {
      console.log(corner);
    }
    _.remove(this.coef.Size.children, e => this.removeFilter(e, corner));
    this.coef.Size.batchDraw();
    for (const item  of  this.coef.shapesLayerw.children) {
      if (item.attrs.params.type === 6 || item.attrs.params.type === 7) {
        const contourshorizon = this.create.getTangentElement({
            evt: {
              layerX: item.attrs.x + item.attrs.width - 16,
              layerY: item.attrs.y
            }
          },
          item, this.coef.shapesLayerw, true
        );
        const contoursVertical = this.create.getTangentElement({
            evt: {
              layerX: item.attrs.x,
              layerY: item.attrs.y
            }
          },
          item, this.coef.shapesLayerw, true
        );
        if (this.coef.pramsId != 4) {
          if (item.attrs.params.type === 6) {
            new Sizes(this.coef, this.create).NewHorizont(contoursVertical, item);
          } else {
            new Sizes(this.coef, this.create).NewVertical(contourshorizon, item);
          }
        } else {
          for (const item of this.coef.shapesLayerw.children) {
            if (!contoursVertical['left']) {
              if (item.attrs.params && item.attrs.params.type == 6) {
                contoursVertical['left'] = item;
              }
            }
            if (!contoursVertical['right']) {
              if (item.attrs.params && item.attrs.params.type == 6) {
                contoursVertical['right'] = item;
              }
            }
          }
          if (item.attrs.params.type === 6) {
            new Sizes(this.coef, this.create).NewHorizont(contoursVertical, item);
          } else {
            new Sizes(this.coef, this.create).NewVertical(contourshorizon, item);
          }
          for (const delet of this.coef.Size.children) {
            if (delet.children[1].className === 'Text' && +delet.children[1].attrs.text < 0) {
              const index = this.coef.Size.children.indexOf(delet);
              this.coef.Size.children.splice(index, 1);
            }
          }
        }
      }
    }
  }

  globalSize(top, left, door?) {
    this.coef.countNameElement++;
    const groupsL = new Konva.Group(
      {
        x: top.left,
        y: top.top - 20,
        draggable: false,
        width: top.width,
        name: 'element' + this.coef.countNameElement,
        params: {
          removable: true,
        },
        bind: [],
      }
    );
    const LineL = new Konva.Rect({
      x: 0,
      y: 0,
      width: groupsL['attrs'].width,
      height: 1,
      name: 'width',
      fill: 'black',

    });
    const polyL = new Konva.Line({
      points: [
        10, -3,
        0, 0,
        10, 3,
        10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyR = new Konva.Line({
      points: [
        groupsL['attrs'].width - 10, -3,
        groupsL['attrs'].width - 0, 0,
        groupsL['attrs'].width - 10, 3,
        groupsL['attrs'].width - 10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });

    const TextL = new Konva.Text({
      x: groupsL.getWidth() / 2 - 15,
      y: -15,
      text: JSON.stringify(_.round(groupsL.getWidth() * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: 'black'
    });
    const groupsT = new Konva.Group({
        x: left.right + 165,
        y: left.top,
        draggable: false,
        width: 4,
        name: 'element' + this.coef.countNameElement,
        params: {
          removable: true,
          height: left.height
        },
        bind: [],
      }
    );
    const LineT = new Konva.Rect({
      x: 0,
      y: 0,
      width: 1,
      height: groupsT['attrs'].params.height,
      name: 'width',
      fill: 'black',
    });

    const TextT = new Konva.Text({
      x: -16,
      y: groupsT['attrs'].params.height / 2 + 14,
      text: JSON.stringify(_.round(groupsT['attrs'].params.height * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: 'black',
      rotation: -90
    });
    const polyT = new Konva.Line({
      points: [
        -3, 10,
        0, 0,
        3, 10,
        -3, 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyTB = new Konva.Line({
      points: [
        -3, groupsT['attrs'].params.height - 10,
        -0, groupsT['attrs'].params.height - 0,
        3, groupsT['attrs'].params.height - 10,
        -3, groupsT['attrs'].params.height - 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const LineSiz = new Konva.Rect({
      x: 0,
      y: -5,
      width: 1,
      height: 15,
      name: 'width',
      fill: 'black',
    });
    const LineSiz2 = new Konva.Rect({
      x: top.width,
      y: -5,
      width: 1,
      height: 15,
      name: 'width',
      fill: 'black',
    });
    const LineSiz3 = new Konva.Rect({
      x: 5,
      y: 0,
      width: -45,
      height: 1,
      name: 'width',
      fill: 'black',
    });
    const LineSiz4 = new Konva.Rect({
      x: 5,
      y: left.height,
      width: -45,
      height: 1,
      name: 'width',
      fill: 'black',
    });
    groupsL.add(LineL, polyL, polyR, TextL, LineSiz2, LineSiz);
    groupsT.add(LineT, polyT, polyTB, TextT, LineSiz3, LineSiz4);
    if (door) {
      return [groupsL, groupsT];
    } else {
      this.coef.GlobalSize.add(groupsL, groupsT);
      this.coef.GlobalSize.batchDraw();
    }
  }

  cornerEndingSizes(group_create, poly, l?) {
    const x_old = group_create.attrs.x;
    let x = group_create.attrs.x;
    if (l) {
      x = group_create.attrs.x - group_create.attrs.width;
    }
    this.coef.countNameElement++;
    let dir = 'right';
    if (l) {
      dir = 'left';
    }
    const groupsL = new Konva.Group(
      {
        x: x,
        y: group_create.attrs.y - 20,
        draggable: false,
        width: group_create.attrs.width,
        name: 'element' + this.coef.countNameElement,
        params: {
          removable: true,
          name: dir
        },
        bind: [],
      }
    );
    const LineL = new Konva.Rect({
      x: 0,
      y: 0,
      width: groupsL['attrs'].width,
      height: 1,
      name: 'width',
      fill: 'black',

    });
    const polyL = new Konva.Line({
      points: [
        10, -3,
        0, 0,
        10, 3,
        10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyR = new Konva.Line({
      points: [
        groupsL['attrs'].width - 10, -3,
        groupsL['attrs'].width - 0, 0,
        groupsL['attrs'].width - 10, 3,
        groupsL['attrs'].width - 10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const TextL = new Konva.Text({
      x: groupsL.getWidth() / 2 - 15,
      y: -15,
      text: JSON.stringify(_.round(groupsL.getWidth() * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: 'black'
    });

    groupsL.add(LineL, polyL, polyR, TextL);
    this.coef.Size.add(groupsL);
    this.coef.Size.batchDraw();
    poly.on('dragmove', (pos) => {
      if (l) {
        groupsL.x(x_old);
        TextL.x(group_create.children[1].getWidth() / 2 - 15);
        TextL.text(JSON.stringify(_.round(-group_create.children[1].getWidth() * this.coef.global_coef, 1)));
        LineL.width(group_create.children[1].getWidth());
        polyR['attrs'].points = [
          group_create.children[1].getWidth() + 10, -3,
          group_create.children[1].getWidth() - 0, 0,
          group_create.children[1].getWidth() + 10, 3,
          group_create.children[1].getWidth() + 10, -3
        ];
        polyL['attrs'].points = [
          -10, -3,
          -0, 0,
          -10, 3,
          -10, -3
        ];
      } else {
        TextL.x(group_create.getWidth() / 2 - 15);
        TextL.text(JSON.stringify(_.round(group_create.getWidth() * this.coef.global_coef, 1)));
        LineL.width(group_create['attrs'].width);
        polyR['attrs'].points = [
          group_create['attrs'].width - 10, -3,
          group_create['attrs'].width - 0, 0,
          group_create['attrs'].width - 10, 3,
          group_create['attrs'].width - 10, -3
        ];
      }
      this.coef.Size.batchDraw();
    });
  }

  createOneSizeTop(height, top, left, parent) {
    const groupsT = new Konva.Group({
        x: left,
        y: top,
        draggable: false,
        width: 4,
        name: 'element' + this.coef.countNameElement,
        params: {
          removable: true,
          height: height
        },
        bind: [],
      }
    );
    const LineT = new Konva.Rect({
      x: 0,
      y: 0,
      width: 1,
      height: groupsT['attrs'].params.height,
      name: 'width',
      fill: 'black',
    });

    const TextT = new Konva.Text({
      x: -16,
      y: groupsT['attrs'].params.height / 2 + 14,
      text: JSON.stringify(_.round(groupsT['attrs'].params.height * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: 'black',
      rotation: -90
    });
    const polyT = new Konva.Line({
      points: [
        -3, 10,
        0, 0,
        3, 10,
        -3, 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyTB = new Konva.Line({
      points: [
        -3, groupsT['attrs'].params.height - 10,
        -0, groupsT['attrs'].params.height - 0,
        3, groupsT['attrs'].params.height - 10,
        -3, groupsT['attrs'].params.height - 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    groupsT.add(LineT, TextT, polyTB, polyT);
    parent.add(groupsT);
  }

  createOneSizeVertical(bind, element, drawer?, params?, comod?) {
    let height = element.attrs.height;
    let position;
    if (bind) {
      position = {y: bind['top'].attrs.y + bind['top'].attrs.height, x: element.attrs.x - 16};
    }
    if (comod) {
      position = {y: element.attrs.y, x: element.attrs.width - 10};
    }
    if (comod === 2) {
      height = element.attrs.params.height;
      position = {y: element.attrs.y + element.attrs.height, x: element.attrs.width - 10};
    }
    let spec = 0;
    if (drawer) {
      let sideSpace = this.coef.drawer.sideSpace;
      if(element.attrs.params.subId == 26 || element.attrs.params.subId == 27){
        sideSpace = this.coef.drawer.sideSpaceSpec;
        spec = this.coef.drawer.sideSpaceSpec
      }
      height = element.attrs.params.height - sideSpace;
      position = {y: 0, x: +16};
    }
    const groupsT = new Konva.Group({
      x: position.x,
      y: position.y,
      draggable: false,
      width: 4,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        height: height
      },
      bind: [],
    });
    if (params) {
      groupsT['attrs'].params.remove = params.remove;
      groupsT['attrs'].params.type = params.type;
      groupsT['attrs'].params.idDoor = params.idDoor;
      groupsT['attrs'].params.idForDelete = params.idForDelete;
    }
    const LineT = new Konva.Rect({
      x: 0,
      y: 0,
      width: 1,
      height: groupsT['attrs'].params.height +spec,
      name: 'width',
      fill: this.coef.blackColor,
    });

    const TextT = new Konva.Text({
      x: -16,
      y: groupsT['attrs'].params.height / 2 + 14,
      text: JSON.stringify(_.round(groupsT['attrs'].params.height * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: this.coef.blackColor,
      rotation: -90
    });
    let bg;
    if (params) {
      bg = new Konva.Rect({
        x: TextT.x(),
        y: TextT.y(),
        width: TextT.getWidth(),
        height: 14,
        params: {type: params.type, remove: true},
        fill: 'white',
        rotation: -90
      });
    }
    const polyT = new Konva.Line({
      points: [
        -3, 10,
        0, 0,
        3, 10,
        -3, 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyTT = new Konva.Line({
      points: [
        -3, groupsT['attrs'].params.height + spec - 10,
        -0, groupsT['attrs'].params.height + spec  - 0,
        3, groupsT['attrs'].params.height + spec  - 10,
        -3, groupsT['attrs'].params.height + spec  - 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    if (params) {
      groupsT.add(bg);
    }
    groupsT.add(LineT, TextT, polyT, polyTT);

    return groupsT;
  }
  createOneSizeVerticalDoor(bind, element, drawer?, params?, comod?) {
    let height = element.attrs.height;
    let position;
    if (bind) {
      position = {y: bind['top'].attrs.y + bind['top'].attrs.height, x: element.attrs.x - 16};
    }
    if (comod) {
      position = {y: element.attrs.y, x: element.attrs.width - 10};
    }
    if (comod === 2) {
      height = element.attrs.params.height;
      position = {y: element.attrs.y + element.attrs.height, x: element.attrs.width - 10};
    }
    if (drawer) {
      let sideSpace = this.coef.drawer.sideSpace;
      if(element.attrs.params.subId == 26 || element.attrs.params.subId == 27){
        sideSpace = this.coef.drawer.sideSpaceSpec;
      }
      height = element.attrs.params.height - sideSpace;
      position = {y: 0, x: +16};
    }
    const groupsT = new Konva.Group({
      x: position.x,
      y: position.y,
      draggable: false,
      width: 4,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        height: height
      },
      bind: [],
    });
    if (params) {
      groupsT['attrs'].params.remove = params.remove;
      groupsT['attrs'].params.type = params.type;
      groupsT['attrs'].params.idDoor = params.idDoor;
      groupsT['attrs'].params.idForDelete = params.idForDelete;
    }
    const LineT = new Konva.Rect({
      x: 0,
      y: 0,
      width: 1,
      height: groupsT['attrs'].params.height,
      name: 'width',
      fill: this.coef.blackColor,
    });

    const TextT = new Konva.Text({
      x: -16,
      y: groupsT['attrs'].params.height / 2 + 14,
      text: JSON.stringify(_.round(groupsT['attrs'].params.height * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: this.coef.blackColor,
      rotation: -90
    });
    let bg;
    if (params) {
      bg = new Konva.Rect({
        x: TextT.x(),
        y: TextT.y(),
        width: TextT.getWidth(),
        height: 14,
        params: {type: params.type, remove: true},
        fill: 'white',
        rotation: -90
      });
    }
    const polyT = new Konva.Line({
      points: [
        -3, 10,
        0, 0,
        3, 10,
        -3, 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyTT = new Konva.Line({
      points: [
        -3, groupsT['attrs'].params.height - 10,
        -0, groupsT['attrs'].params.height - 0,
        3, groupsT['attrs'].params.height - 10,
        -3, groupsT['attrs'].params.height - 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    if (params) {
      groupsT.add(bg);
    }
    groupsT.add(LineT, TextT, polyT, polyTT);

    return groupsT;
  }
  createOneSizeVerticalPanal(bind, element, drawer?, params?, comod?) {
    const height = bind['bottom'].attrs.y - bind['top'].attrs.y - bind['top'].attrs.height;
    let position;
    if (bind) {
      position = {y: bind['top'].attrs.y + bind['top'].attrs.height, x: bind['top'].attrs.x + bind['top'].attrs.width - 16};
    }
    const groupsT = new Konva.Group({
      x: position.x,
      y: position.y,
      draggable: false,
      width: 4,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        height: height
      },
      bind: [],
    });
    if (params) {
      groupsT['attrs'].params.remove = params.remove;
      groupsT['attrs'].params.type = params.type;
    }
    const LineT = new Konva.Rect({
      x: 0,
      y: 0,
      width: 1,
      height: groupsT['attrs'].params.height,
      name: 'width',
      fill: 'black',
    });

    const TextT = new Konva.Text({
      x: -16,
      y: groupsT['attrs'].params.height / 2 + 14,
      text: JSON.stringify(_.round(groupsT['attrs'].params.height * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: 'black',
      rotation: -90
    });
    let bg;
    if (params) {
      bg = new Konva.Rect({
        x: TextT.x(),
        y: TextT.y(),
        width: TextT.getWidth(),
        height: 14,
        params: {type: params.type, remove: true},
        fill: 'white',
        rotation: -90
      });
    }
    const polyT = new Konva.Line({
      points: [
        -3, 10,
        0, 0,
        3, 10,
        -3, 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyTT = new Konva.Line({
      points: [
        -3, groupsT['attrs'].params.height - 10,
        -0, groupsT['attrs'].params.height - 0,
        3, groupsT['attrs'].params.height - 10,
        -3, groupsT['attrs'].params.height - 10
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    if (params) {
      groupsT.add(bg);
    }
    groupsT.add(LineT, TextT, polyT, polyTT);

    return groupsT;
  }

  createOneSizeHorizont(bind, element ,params?) {
    let newParams = { removable: true};
    if(params){
      newParams = {...params, removable: true}
    }
    const groupsL = new Konva.Group(
      {
        x: bind['left'].attrs.x + bind['left'].attrs.width,
        y: element.attrs.y - 10,
        draggable: false,
        width: element.attrs.width,
        name: 'element' + this.coef.countNameElement,
        params: newParams,
        bind: [],
      }
    );
    const LineL = new Konva.Rect({
      x: 0,
      y: 0,
      width: groupsL['attrs'].width,
      height: 1,
      name: 'width',
      fill: 'black',

    });
    const polyLL = new Konva.Line({
      points: [
        10, -3,
        0, 0,
        10, 3,
        10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyR = new Konva.Line({
      points: [
        groupsL['attrs'].width - 10, -3,
        groupsL['attrs'].width - 0, 0,
        groupsL['attrs'].width - 10, 3,
        groupsL['attrs'].width - 10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });

    const TextL = new Konva.Text({
      x: groupsL.getWidth() / 2 - 15,
      y: -15,
      text: JSON.stringify(_.round(groupsL.getWidth() * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: 'black'
    });
    groupsL.add(LineL, TextL, polyLL, polyR);
    return groupsL;
  }
  createOneSizeHorizontDoor(bind, element ,params? , texture?) {
    let newParams = { removable: true};
    if(params){
      newParams = {...params, removable: true}
    }
    const groupsL = new Konva.Group(
      {
        x: bind['left'].attrs.x + bind['left'].attrs.width,
        y: element.attrs.y - 10,
        draggable: false,
        width: element.attrs.width,
        name: 'element' + this.coef.countNameElement,
        params: newParams,
        bind: [],
      }
    );
    const LineL = new Konva.Rect({
      x: 0,
      y: 0,
      width: groupsL['attrs'].width,
      height: 1,
      name: 'width',
      fill: 'black',

    });
    const polyLL = new Konva.Line({
      points: [
        10, -3,
        0, 0,
        10, 3,
        10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyR = new Konva.Line({
      points: [
        groupsL['attrs'].width - 10, -3,
        groupsL['attrs'].width - 0, 0,
        groupsL['attrs'].width - 10, 3,
        groupsL['attrs'].width - 10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });

    const TextL = new Konva.Text({
      x: groupsL.getWidth() / 2 - 15,
      y: -15,
      text: JSON.stringify(_.round(groupsL.getWidth() * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: 'black'
    });
   const bg = new Konva.Rect({
      x: TextL.x(),
      y: TextL.y(),
      width: TextL.getWidth(),
      height: 14,
      params: {type: params.type, remove: true},
      fill: 'white',
    });
    
    groupsL.add(LineL, bg,TextL, polyLL, polyR);

    return groupsL;
  }
  createOneSizePanal(setings) {
    const groupsL = new Konva.Group(
      {
        x: setings.x,
        y: setings.y,
        draggable: false,
        width: setings.width,
        name: 'element' + this.coef.countNameElement,
        params: {
          removable: true,
        },
        bind: [],
      }
    );
    const LineL = new Konva.Rect({
      x: 0,
      y: 0,
      width: groupsL['attrs'].width,
      height: 1,
      name: 'width',
      fill: 'black',

    });
    const polyLL = new Konva.Line({
      points: [
        10, -3,
        0, 0,
        10, 3,
        10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyR = new Konva.Line({
      points: [
        groupsL['attrs'].width - 10, -3,
        groupsL['attrs'].width - 0, 0,
        groupsL['attrs'].width - 10, 3,
        groupsL['attrs'].width - 10, -3
      ],
      fill: 'rgba(0,0,0,1)',
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });

    const TextL = new Konva.Text({
      x: groupsL.getWidth() / 2 - 15,
      y: -15,
      text: JSON.stringify(_.round(groupsL.getWidth() * this.coef.global_coef, 1)),
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: 'black'
    });
    groupsL.add(LineL, TextL, polyLL, polyR);
    return groupsL;
  }

  bindPositin(bind, element, position) {
    if (position === 'left') {
      for (const el of bind) {
        if (el['attrs'].x < element['attrs'].x && el['attrs'].params.type === 7) return false;
      }
      return true;
    }
    if (position === 'top') {
      for (const el of bind) {
        if (el['attrs'].y < element['attrs'].y) return false;
      }
      return true;
    }
  }
}
