import {Component, HostListener, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CoefService} from '../../service/coef.service';
import {CreateService} from '../../service/create.service';
import {MinSizeService} from '../../service/min-size.service';
import {RemoveService} from '../../service/remove.service';
import {ElementsService} from '../../service/elements.service';
import {DomSanitizer} from '@angular/platform-browser';
import * as Konva from '../../../assets/konva';
import {SocketPriceService} from '../../service/socket-price.service';
import {PriceService} from '../../service/price.service';
import {ParserService} from '../../service/parser.service';
import {SwiperComponent} from 'angular2-useful-swiper';
import {CoefDoorService} from '../../service/door service/coef-door.service';
import {CornerСabinet} from '../../class/order/cornerсabinet';


@Component({
  selector: 'app-second-stage',
  templateUrl: './second-stage.component.html',
  styleUrls: ['./second-stage.component.css'],
})
export class SecondStageComponent implements OnInit {
  @Input() allWardrobe: any;
  public stage;
  public colorID;
  public facade_color_id;
  price;
  public img;
  colorAraay = [];
  filterName = '';
  element = {
    drawer: false,
    shell: false,
    trumpet: false,
    door: false,
    cornerEnding: false,
    hook: false,
    pipe: false,
    color: false,
    facad: false
  };

  elementMap = {
    drawer: 9,
    shell: 7,
    door: 1,
    cornerEnding: 15,
    hook: 3,
    pipe: 2,
  };

  config: SwiperOptions = {
    slidesPerView: 4,
    spaceBetween: 5,
    pagination: '.swiper-pagination',
    direction: 'vertical',
    paginationClickable: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
  };
  configss: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 5,
    pagination: '.swiper-pagination',
    paginationClickable: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
  };
  panel = {isActive: false};
  @ViewChild('usefulSwiper') usefulSwiper: SwiperComponent;
  img_hover_src = null;
  img_hover = false;

  constructor(public coef: CoefService,
              private priceService: PriceService,
              public parserService: ParserService,
              private create: CreateService,
              private minSize: MinSizeService,
              public remove: RemoveService,
              public elements: ElementsService,
              private _sanitizer: DomSanitizer,
              private socket: SocketPriceService,
              private coefDoor: CoefDoorService) {
    this.socket.price.subscribe((data) => {
      this.price = data ;
    });
  }

  @HostListener('document:pointermove', ['$event']) onPointerMove(event): void {
    if (event.target.attributes['data-hover']) {
      this.img_hover_src = event.srcElement.currentSrc;
      this.img_hover = true;
    } else {
      this.img_hover = false;
      this.img_hover_src = null;
    }
  }

  ngOnInit() {
    this.panel.isActive = true;
    this.stage = new Konva.Stage({
      container: 'container',
      width: this.coef.widthWrapper,
      height: this.coef.heightWrapper
    });
    const stag = {stage: this.stage};
    this.coef.stage = stag;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.allWardrobe.color_id = 1;
    this.allWardrobe.f_color_id = 1;
  }

  public isGroupDisabled(id): boolean {
    return (!this.coef.disabledItemMap[id] || !this.coef.disabledItemMap[id].active);
  }

  isTrumpetGroupDisabled(): boolean {
    return this.isItemDisabled(6)
      && this.isItemDisabled(8)
      && this.isItemDisabled(4)
      && this.isItemDisabled(5);
  }

  public isItemDisabled(id, subId = null): boolean {
    if (!this.coef.disabledItemMap[id] || !this.coef.disabledItemMap[id].active) {
      return true;
    }
    if (this.coef.disabledItemMap[id].subItems && subId) {
      return !this.coef.disabledItemMap[id].subItems[subId].active;
    }
    return false;
  }

  public onColorChange(id , active): void {
    if(active){
      this.colorID = id;
      let selectedColor;
      this.coef.settingsColor.forEach(color => {
        if (color['id'] === +this.colorID) {
          selectedColor = color['rgb'];
        }
      });
  
      this.coef.colorInput = this.coef.addOpacyti(selectedColor);
      this.coef.color = this.coef.addOpacyti(selectedColor);
      this.coef.colorID = +this.colorID;
      if (this.coef.pramsId == 2 ) {
        const objects = {
          leftCorner: this.coef.setingsWardrobe.content_object['leftCorner'].subId == 'null' ? null : this.coef.setingsWardrobe.content_object['leftCorner'],
          rightCorner: this.coef.setingsWardrobe.content_object['rightCorner'].subId == 'null' ? null : this.coef.setingsWardrobe.content_object['rightCorner'],
        };
        new CornerСabinet(this.coef, this.create, objects).reImg(this.stage, this.coef.shapesLayerw);
      } else if (this.coef.pramsId == 15) {
        const objects = {
          leftCorner: this.coef.setingsWardrobe.content_object['leftCorner'].subId == 'null' ? null : this.coef.setingsWardrobe.content_object['leftCorner'],
          rightCorner: this.coef.setingsWardrobe.content_object['rightCorner'].subId == 'null' ? null : this.coef.setingsWardrobe.content_object['rightCorner'],
        };
        new CornerСabinet(this.coef, this.create, objects).reImgSmall(this.stage);
      } else {
        this.elements.recolor(this.coef.color, this.coef.shapesLayerw, this.coef.look2d);
        this.elements.recolor(this.coef.color, this.coef.legs, this.coef.look2d);
      }
  
        this.priceService.sendObjectPrice();
    }
   


  }

  public onColorChangeFacad(id  , active): void {
    if(active){
      this.facade_color_id = id;
      let selectedColor;
      this.coef.settingsColor.forEach(color => {
        if (color['id'] === +this.facade_color_id) {
          selectedColor = color['rgb'];
        }
      });
      this.coef.colorFacad = this.coef.addOpacyti(selectedColor);
      this.coef.facade_color_id = +this.facade_color_id;
      for (const wrapper of this.stage.children) {
        this.replaceColor(wrapper);
       
      }
  
      this.stage.batchDraw();
      this.priceService.sendObjectPrice();
    }
   
  }

  replaceColor(wrapper) {
    for (const item of wrapper.children) {
      if (item.nodeType === 'Group') {
        for (const group of item.children) {
          if (group.attrs && group.attrs.params && group.attrs.params.type == 1) {
            console.warn(group, 'asdad');
          }
          if (group.attrs && group.attrs.params && group.attrs.params.facad) {
            if (group.attrs.fill !== 'black') {
              group.attrs.fill = this.coef.colorFacad;
            }
          }
          if (group.nodeType === 'Group') {
            this.replaceColor(group);
          }
        }
      }
      if (item.attrs && item.attrs.params && item.attrs.params.facad) {
        if (item.attrs.fill !== 'black') {
          item.attrs.fill = this.coef.colorFacad;
        }
      }
    }
  }

  getBackground(rgb) {
    return this._sanitizer.bypassSecurityTrustStyle(rgb);
  }

  openModal(element) {
    if (element === 'trumpet' && this.isTrumpetGroupDisabled()) {
      return;
    }
    if (element !== 'trumpet' && element !== 'color' && element !== 'facad' && this.isGroupDisabled(this.elementMap[element])) {
      return;
    }
    for (const option in this.element) {
      this.element[option] = false;
    }
    this.element[element] = true;
    this.filterName = '';
    if (this.usefulSwiper) {
      this.usefulSwiper.swiper.update();
    }
  }

  download() {
    const dataURL = this.stage.toDataURL('jpg', 1);
    const dataUrlDoor = this.coefDoor.doorStag.stage.toDataURL('png', 1);
    // this.scail(this.coef.shapesLayerw, this.stage, true);
    this.img = dataURL;
    this.coef.contentImage = this.img;
    this.coef.facadeImage = dataUrlDoor;
    // this.dleteAll();
    // this.scail(this.coef.shapesLayerw, this.stage, false);
  }

  dleteAll() {
    this.coef.startRemove = true;
    for (const evt of this.coef.shapesLayerw.children) {
      this.elements.deleteElement(evt, this.coef.shapesLayerw);
    }
    this.coef.startRemove = false;
  }

  scail(layer, stage, revert?) {
    const padding = 10;

// get bounding rectangle
    const box = layer.getClientRect({relativeTo: stage});
    const scale = Math.min(
      stage.width() / (box.width + padding * 2),
      stage.height() / (box.height + padding * 2)
    );
    if (revert) {
      stage.setAttrs({
        x: -box.x * scale + padding * scale,
        y: -box.y * scale + padding * scale,
        scaleX: scale,
        scaleY: scale
      });
    } else {
      stage.setAttrs({
        x: box.x * scale + padding * scale,
        y: box.y * scale + padding * scale,
        scaleX: 1,
        scaleY: 1
      });
    }
  }

  onDragEnd(event) {
    console.log(event);
  }

  generate(array) {
    const newArray = array;
    // let oneArray = [];
    // for (let i = 0; i < array.length; i++) {
    //   oneArray.push(array[i]);
    //   if (i != 0) {
    //     if (((i + 1) % 6) === 0) {
    //       newArray.push(oneArray);
    //       oneArray = [];
    //     }
    //   }
    // }
    // if (oneArray.length > 0 && oneArray.length < 6) {
    //   newArray.push(oneArray);
    //   oneArray = [];
    // }
    console.log(newArray , 'newArray')
    return newArray;
  }
}
