import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '../../../service/notification.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {baseUrl} from '../../../router.path';
import {API} from '../../../common/api';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {

  passwordForm: any;
  

  public email: string;

  @Input() phone: string;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient,
              private notification: NotificationService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group(
      {
        'old_password': [null, Validators.required],
        'passwords': this.formBuilder.group({
          'new_password': [null, Validators.required],
          'repeat_password': [null, Validators.required]
        }, {validator: this.checkPasswords})
      });
  }

  private checkPasswords(group: FormGroup) {
    const pass = group.controls.new_password.value;
    const confirmPass = group.controls.repeat_password.value;

    return pass === confirmPass ? null : {notSame: true};
  }

  public validateRepeatPassword() {
    if (this.passwordForm.controls.passwords.controls.new_password.valid && this.passwordForm.controls.passwords.controls.repeat_password.touched && this.passwordForm.controls.passwords.invalid) return true;
  }

  public onSubmit(): void {
    this.http.post(baseUrl.api + API.CHANGE_PASSWORD, {
      old_password: this.passwordForm.controls.old_password.value,
      new_password: this.passwordForm.controls.passwords.controls.new_password.value
    }).subscribe(res => {
      this.notification.triggerNotification(res['payload']);
      this.modalClosed.emit();
    }, error => {
      this.notification.triggerNotification(error['error']['error'], true);
    });
  }

}
