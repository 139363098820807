import {Injectable} from '@angular/core';
import {CoefService} from './coef.service';
import {HttpClient} from '@angular/common/http';
import {baseUrl, PAGE} from '../router.path';
import * as _ from 'lodash';
import {CoefDoorService} from './door service/coef-door.service';
import {PriceService} from './price.service';
import {InitRenderService} from './renderingService/init-render.service';
import {RoutStepService} from './rout-step.service';
import {Router} from '@angular/router';
import {NotificationService} from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class ParserService {
  objectToPars: Object = {};

  constructor(
    private coef: CoefService,
    private http: HttpClient,
    private coefDoor: CoefDoorService,
    private price: PriceService,
    private render: InitRenderService,
    private rout: RoutStepService,
    private router: Router,
    private notification: NotificationService
  ) {
  }

  private setSettingParams(): void {
    return this.arrayToObjectParams(['width', 'height', 'depth', 'order_type_id'], 'setingsWardrobe');
  }

  private setSecondParams(): void {
    return this.arrayToObjectParams([
      'scheme_id', 'lamp_id', 'init_option_ids', 'system_color_id', 'colorID', 'facade_color_id',
      'deleted_older_content_square', 'deleted_older_content_elements', 'global_coef', 'legs_count', 'comment'
    ]);
  }

  private arrayToObjectParams(array, path = null): void {
    for (let i = 0; i < array.length; i++) {
      this.objectToPars[array[i]] = path ? this.coef[path][array[i]] : this.coef[array[i]];
    }
  }


  private setContent(Layerw, contentString , wardrobe?): void {
    for (const item of Layerw.children) {
      if (item.attrs.params && item.attrs.params.type === 15) {
        if (item.attrs.params.position == 'left') {
          if (item.children[1].attrs.width < 0) {
            item.attrs.width = item.children[1].attrs.width * -1;
          }
        }
      }
    }
    this.price.sendObjectPrice();
    this.objectToPars['door_count'] = [...this.coef.doorsArray];
    const content = _.cloneDeep(Layerw.children.map(children => {
      return _.cloneDeep(children.attrs);
    }));
    for (let i = 0; i < content.length; i++) {
      for (const obj in content[i].params) {
        if (_.isObject(content[i].params[obj])) {
          if (obj === 'shelfPositions' || obj === 'older') {

          } else {
            delete content[i].params[obj];
          }
        }
      }
      if (content[i].bind) {
        for (let j = 0; j < content[i].bind.length; j++) {
          for (const obj in content[i].bind[j].attrs.params) {
            if (_.isObject(content[i].bind[j].attrs.params[obj])) {
              if (obj !== 'shelfPositions') {
                delete content[i].bind[j].attrs.params[obj];
              }
            }
          }
          content[i].bind[j] = content[i].bind[j].attrs;
          if (content[i].bind[j].bind) {
            delete content[i].bind[j].bind;
          }
        }
      }
    }
    this.objectToPars[contentString] = content;
  }


  // setDoor(Layerw) {
  //   const content = _.cloneDeep(Layerw);
  //   delete content.attrs.sceneFunc;
  //   if (content.attrs.hitFunc) {
  //     delete content.attrs.hitFunc;
  //     delete content.attrs.image;
  //   }
  //   return content.attrs;
  // }
  setDoor(Layerw) {
    
    const content = this.copyIntStringParams(Layerw.attrs);
    content['params'] = this.copyIntStringParams(Layerw.attrs.params);
    return content;
  }
  copyIntStringParams(source) {
    const result = {};

    for (const key in source) {
        if (source.hasOwnProperty(key)) {
            const value = source[key];
            if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                result[key] = value;
            }
        }
    }

    return result;
}

  sendDoor() {
    // this.objectToPars['scheme_id'] = this.coefDoor.colorSystemId;
    this.objectToPars['system_id'] = this.coefDoor.systemId;
    let index = 0;
    for (const door of this.objectToPars['door_count']) {
      index = index + 1;
      door.materials = [];
      door.content = [];
      door.y = this.coef.topPositaionWrapper;
      door.separators = [];
      for (const elem of this.coefDoor.DoorWrapper.children) {
        if (elem.attrs.params && elem.attrs.params.type === index && elem.attrs.params.price) {
          door.door_type_id = elem.attrs.params.typeDoor;
          door.materials.push(elem.attrs.params.id);
          door.content.push(this.setDoor(elem));
        }
        if (elem.attrs.params && elem.attrs.params.type === index && elem.attrs.params.part) {
          door.separators.push(this.setDoor(elem));
        }
      }
      if (this.coefDoor.sepArray.includes(door.door_type_id)) {
        door.sep_count = door.materials.length - 1;
      } else {
        door.sep_count = 0;
      }
    }
  }

  public sendInfo(): void {
    this.setSettingParams();
    this.setSecondParams();
    this.setContent(this.coef.shapesLayerw, 'content');

    this.sendDoor();
    this.createImg();
    this.objectToPars['contentImage'] = this.coef.contentImage;
    this.objectToPars['facadeImage'] = this.coef.facadeImage;
    this.objectToPars['system_color_id'] = this.coefDoor.colorSystemId;
    this.objectToPars['stoper'] = this.coefDoor.stoperDoor;
    this.objectToPars['puller'] = this.coefDoor.puller;
    this.objectToPars['roller'] = this.coefDoor.rollerDoor;
    this.objectToPars['system_color_id'] = this.coefDoor.colorSystemId;
    if (this.coef.pramsId == 13 || this.coef.pramsId == 2 || this.coef.pramsId == 15) {
      this.objectToPars['content'] = [];
    }
    if (this.coef.pramsId == 13) {
      this.objectToPars['colorID'] = null;
    }
    if (this.coef.arrayNotFacadColor.includes(this.coef.pramsId)) {
      this.objectToPars['facade_color_id'] = null;
    }
    if (this.coef.arrayNotSystemColor.includes(+this.coef.pramsId)) {
      this.objectToPars['system_color_id'] = null;
    }
    if (this.coef.pramsId == 2 || this.coef.pramsId == 15) {
      const objects = {
        leftCorner: this.coef.setingsWardrobe.content_object['leftCorner'].subId == 'null' ? null : this.coef.setingsWardrobe.content_object['leftCorner'],
        rightCorner: this.coef.setingsWardrobe.content_object['rightCorner'].subId == 'null' ? null : this.coef.setingsWardrobe.content_object['rightCorner'],
      };
      this.objectToPars['content'].push(objects);
    }
    if (this.coef.end_client) {
      this.objectToPars['user'] = Number(this.coef.end_client);
    }
    console.log(this.objectToPars);
    if (this.render.changeSize && this.render.object) {
      this.objectToPars['orderId'] = this.render.object.id;
      this.render.changeSize = false;
      this.http.post(baseUrl.api + 'edit-order', this.objectToPars).subscribe(res => {
        if (res['success']) {
          this.coef.preloaderUpdate(false);
          this.notification.triggerNotification(res['payload'], false);
          this.router.navigate([PAGE.ORDER]);
        }
      }, (error) => {
        if (error.status === 401) {
          this.router.navigate(['']);
        }
      });
    } else {
      this.http.post(baseUrl.api + 'save-order', this.objectToPars).subscribe(res => {
        if (res['success']) {
          this.coef.preloaderUpdate(false);
          this.notification.triggerNotification(res['payload'], false);
          this.router.navigate([PAGE.ORDER]);
        }
      }, (error) => {
        if (error.status === 401) {
          this.router.navigate(['']);
        } else {
          this.coef.preloaderUpdate(false);
        }
      });
    }
  }

  deleteObject() {
    this.http.get(baseUrl.api + `cancel-order/${this.render.object.id}`).subscribe(res => {
      if (res['success']) {
        this.coef.preloaderUpdate(false);
        this.notification.triggerNotification(res['payload'], false);
        this.router.navigate([PAGE.ORDER]);
      }
    }, (error) => {
      if (error.status === 401) {
        this.router.navigate(['']);
      }
    });
    ;
  }


  public reSendInfo(): void {
    this.setSettingParams();
    this.setSecondParams();
    this.setContent(this.coef.shapesLayerw, 'content');
    this.sendDoor();
    this.createImg();
    this.objectToPars['contentImage'] = this.coef.contentImage;
    this.objectToPars['facadeImage'] = this.coef.facadeImage;
    this.objectToPars['system_color_id'] = this.coefDoor.colorSystemId;
    this.objectToPars['orderId'] = this.render.object.id;
    this.objectToPars['init_option_ids'] = this.render.object.init_option_ids;
    this.objectToPars['lamp_id'] = this.render.object.lamp_id;
    this.objectToPars['stoper'] = this.coefDoor.stoperDoor;
    this.objectToPars['puller'] = this.coefDoor.puller;
    this.objectToPars['roller'] = this.coefDoor.rollerDoor;
    this.objectToPars['scheme_id'] = this.render.object.scheme_id;
    if (this.coef.pramsId == 13 || this.coef.pramsId == 2 || this.coef.pramsId == 15) {
      this.objectToPars['content'] = [];
    }
    if (this.coef.pramsId == 13) {
      this.objectToPars['colorID'] = null;
    }
    if (this.coef.arrayNotFacadColor.includes(this.coef.pramsId)) {
      this.objectToPars['system_color_id'] = null;
    }
    if (this.coef.arrayNotSystemColor.includes(+this.coef.pramsId)) {
      this.objectToPars['system_color_id'] = null;
    }
    console.log(this.objectToPars, 'objectToParsrender');
    this.http.post(baseUrl.api + 'edit-order', this.objectToPars).subscribe(res => {
      if (res['success']) {
        this.coef.preloaderUpdate(false);
        this.notification.triggerNotification(res['payload'], false);
        this.router.navigate([PAGE.ORDER]);
      }
    }, (error) => {
      if (error.status === 401) {
        this.router.navigate(['']);
      }
    });
  }

  createImg() {
    if (this.coefDoor.DoorWrapper) {
      if (this.coefDoor.reDesign) {
        this.coefDoor.reDesign.attrs.strokeWidth = 1;
        this.coefDoor.reDesign.attrs.stroke = 'black';
        // this.coefDoor.reDesign = false;
      }
      if(this.coefDoor.manualTravelElement){
        this.coefDoor.manualTravelElement.attrs.fill = this.coefDoor.colorSystem;
        this.coefDoor.manualTravelElement.attrs.stroke = 'black';
        // this.coefDoor.manualTravelElement = false;
      }
      this.coefDoor.DoorWrapper.batchDraw();
      this.coef.facadeImage = this.coefDoor.DoorWrapper.toDataURL('png', 1);
    }
    if (this.coef.stage) {
      if (this.coef.manualTravelElement) {
        this.coef.manualTravelElement.attrs.strokeWidth = 1;
        this.coef.manualTravelElement.stroke('black');
      }
      this.coef.stage.stage.batchDraw();
      setTimeout(() => {
        this.coef.contentImage = this.coef.stage.stage.toDataURL('png', 1);
      }, 200);
      this.coef.stage.stage.batchDraw();
    }
  }
}

