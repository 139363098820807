import { Component, OnInit } from '@angular/core';
import {CoefService} from '../../service/coef.service';

@Component({
  selector: 'app-door-right',
  templateUrl: './door-right.component.html',
  styleUrls: ['./door-right.component.scss']
})
export class DoorRightComponent implements OnInit {

  constructor(public coef: CoefService) { }

  ngOnInit() {
  }

}
