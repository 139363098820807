import {AllCalculations} from './allCalculations';

export class Classic extends AllCalculations {
  coef;
  create;
  arrayElement = [];

  constructor(coef, create, minSize, render?) {
    super(coef, create, minSize, render);
    this.coef = coef;
    this.create = create;
  }


  lamelSonat() {
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef,
      x: this.centerPosition + this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef + this.coef.global_size_flat * 2,
      x: this.centerPosition + this.coef.global_size_flat * 2,
      type: 2, subId: 11
    });
    this.arrayElement.push({
      y: this.bottom - 250 / this.coef.global_coef - this.coef.global_size_flat,
      x: this.centerPosition + this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef,
      x: this.rightPosition - this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef + this.coef.global_size_flat * 2,
      x: this.rightPosition - this.coef.global_size_flat * 2 - 100,
      type: 2, subId: 11
    });
    this.arrayElement.push({
      y: this.bottom - 250 / this.coef.global_coef - this.coef.global_size_flat,
      x: this.rightPosition - this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });

    this.createElement(this.arrayElement);
  }

  lamelluxL() {
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef,
      x: this.rightPosition - this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef + this.coef.global_size_flat * 2,
      x: this.rightPosition - this.coef.global_size_flat * 2,
      type: 2, subId: 11
    });
    this.arrayElement.push({
      y: this.bottom - 250 / this.coef.global_coef - this.coef.global_size_flat,
      x: this.rightPosition - this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });

    this.createElement(this.arrayElement);
  }

  lamelluxR() {
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef,
      x: this.centerPosition + this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef + this.coef.global_size_flat * 2,
      x: this.centerPosition + this.coef.global_size_flat * 2,
      type: 2, subId: 11
    });
    this.arrayElement.push({
      y: this.bottom - 250 / this.coef.global_coef - this.coef.global_size_flat,
      x: this.centerPosition + this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });

    this.createElement(this.arrayElement);
  }

  typWidth() {
    if (this.coef.setingsWardrobe.width > 2700) {
      return this.bigWardrobe();
    }
    if (this.coef.setingsWardrobe.width > 2500) {
      return this.midllWardrobe();
    }
    if (this.coef.setingsWardrobe.width > 2000) {
      return this.midWardrobe();
    }
    return this.smallWardrobe();
  }

  typeNeo(type) {
    const array = this.findYForElementTop(4);
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef,
      x: this.centerPosition + this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    for (let i = 0; i < array.length; i++) {
      if (i == 1) {
        this.arrayElement.push({y: array[i], x: this.rightPosition - this.coef.global_size_flat * 2, type: 7, subId: 20});
      } else {
        this.arrayElement.push({y: array[i], x: this.rightPosition - this.coef.global_size_flat * 2, type: 7, subId: 21});
      }
    }

    const height = this.coef.setingsWardrobe.height - this.coef.spec_values['middle_section_to_top_flat_size'] - this.coef.spec_values['inner_shuttle_min_height'] - this.coef.global_size_flat * this.coef.global_coef * 4 - this.coef.spec_values['inner_shuttle_bottom_section_diff'] - this.coef.height_legs * this.coef.global_coef;
    const forDrawer = this.coef.spec_values['inner_shuttle_max_height'] * 4 + this.coef.spec_values['inner_shuttle_side_space'] * 4;
    const threeDrawer = this.coef.spec_values['inner_shuttle_max_height'] * 3 + this.coef.spec_values['inner_shuttle_side_space'] * 3;
    const tooDrawer = this.coef.spec_values['inner_shuttle_max_height'] * 2 + this.coef.spec_values['inner_shuttle_side_space'] * 2;
    let top = this.coef.spec_values['middle_section_to_top_flat_size'];
    if (this.coef.selectDrawer == 2) {
      if (height > tooDrawer) {
        top = this.coef.setingsWardrobe.height - tooDrawer - this.coef.global_size_flat * 5 * this.coef.global_coef - this.coef.spec_values.t_section_min_height - this.coef.height_legs * this.coef.global_coef;
      }
    }
    if (this.coef.selectDrawer == 3) {
      if (height > threeDrawer) {
        top = this.coef.setingsWardrobe.height - threeDrawer - this.coef.global_size_flat * 5 * this.coef.global_coef - this.coef.spec_values.t_section_min_height - this.coef.height_legs * this.coef.global_coef;
      }
    }
    if (this.coef.selectDrawer == 4) {
      if (height > forDrawer) {
        top = this.coef.setingsWardrobe.height - forDrawer - this.coef.global_size_flat * 5 * this.coef.global_coef - this.coef.spec_values.t_section_min_height - this.coef.height_legs * this.coef.global_coef;
      }
    }
    this.arrayElement.push({
      y: this.top + top / this.coef.global_coef,
      x: this.centerPosition + (+this.coef.widthSep) / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7, subId: 20,
      neo: true,
      deletes: true,
    });


    this.arrayElement.push({
      y: this.top + this.coef.spec_values['top_section_initialization_height'] / this.coef.global_coef,
      x: this.centerPosition + (+this.coef.widthSep) / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7, subId: 20,
      neo: true
    });
    this.arrayElement.push({
      y: this.top + this.coef.spec_values['top_section_initialization_height'] / this.coef.global_coef - this.coef.global_size_flat,
      x: this.centerPosition + (+this.coef.widthSep) / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 1, subId: type ? 30:5,
      neo: true
    });

    this.arrayElement.push({
      y: this.top + this.coef.spec_values['top_section_initialization_height'] / this.coef.global_coef - Math.floor(this.coef.spec_values['top_section_initialization_height'] / 2) / this.coef.global_coef - this.coef.global_size_flat / 2,
      x: this.centerPosition + (+this.coef.widthSep) / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7, subId: 20,
      neo: true,
      innerShell: true
    });


    this.arrayElement.push({
      y: this.top + top / this.coef.global_coef + this.coef.spec_values.t_section_min_height / this.coef.global_coef + this.coef.global_size_flat,
      x: this.centerPosition + (+this.coef.widthSep) / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7, subId: 20,
      neo: true,
    });
    for (let i = 0; i < (+this.coef.selectDrawer); i++) {
      this.arrayElement.push({
        y: this.bottom - 240 / this.coef.global_coef + this.coef.global_size_flat,
        x: this.centerPosition + (+this.coef.widthSep) / this.coef.global_coef + this.coef.global_size_flat * 2,
        type: 9, subId: type ? 8:7,
        neo: true
      });
    }
    const center = Math.floor((this.coef.setingsWardrobe.width - (+this.coef.widthSep) * 2 - this.coef.global_size_flat * 5 * this.coef.global_coef) / 2) / this.coef.global_coef;
    this.arrayElement.push({
      y: this.top + top / this.coef.global_coef + this.coef.global_size_flat * 2,
      x: this.centerPosition + (+this.coef.widthSep) / this.coef.global_coef + this.coef.global_size_flat + center,
      type: 6,
      neo: true,
      deletes: true
    });
    this.arrayElement.push({
      y: this.bottom - 250 / this.coef.global_coef - this.coef.global_size_flat,
      x: this.centerPosition + this.coef.global_size_flat * 2,
      type: 7, subId: 21
    });
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef + this.coef.global_size_flat * 4,
      x: this.centerPosition + this.coef.global_size_flat * 2,
      type: 8
    });
    this.createElement(this.arrayElement);
  }


  typWidthTop() {
    if (this.coef.setingsWardrobe.width > 2700) {
      return this.bigBigWardrobeTop();
    }
    if (this.coef.setingsWardrobe.width > 2400) {
      return this.bigWardrobeTop();
    }
    if (this.coef.setingsWardrobe.width > 2100) {
      return this.midllWardrobeTop();
    }
    if (this.coef.setingsWardrobe.width > 1600) {
      return this.midWardrobeTop();
    }
    return this.smallWardrobeTop();
  }

  smallWardrobe() {
    this.arrayElement.push({y: this.top + 350 / this.coef.global_coef, x: this.centerPosition, type: 7, subId: 20});
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.rightPosition - this.coef.global_size_flat - 450 / this.coef.global_coef,
      type: 6
    });
    const array = this.findYForElement(3);
    for (let i = 0; i < array.length; i++) {

      if (i == 1) {
        this.arrayElement.push({y: array[i], x: this.rightPosition - 450 / this.coef.global_coef, type: 7, subId: 20});
      } else {
        this.arrayElement.push({y: array[i], x: this.rightPosition - 450 / this.coef.global_coef, type: 7, subId: 21});
      }
    }
    // for (const elementShelf of this.findYForElement(3)) {
    //   this.arrayElement.push({y: elementShelf, x: this.rightPosition - 450 / this.coef.global_coef, type: 7, subId: 20});
    // }
    this.arrayElement.push({
      y: this.bottom - 250 / this.coef.global_coef - this.coef.global_size_flat,
      x: this.centerPosition,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: this.topForShelf + this.coef.global_size_flat * 2,
      x: this.centerPosition + this.coef.global_size_flat * 2,
      type: 8
    });
    this.createElement(this.arrayElement);
  }

  smallWardrobeTop() {
    const array = this.findYForElementTop(5);
    const center = Math.floor((this.coef.setingsWardrobe.width - this.coef.global_size_flat * this.coef.global_coef * 3) / 2) / this.coef.global_coef;
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.centerPosition + center,
      type: 6
    });
    this.arrayElement.push({y: array[0], x: this.centerPosition, type: 7, subId: 20});
    for (let i = 0; i < array.length; i++) {
      this.arrayElement.push({y: array[i], x: this.centerPosition + center + this.coef.global_size_flat * 2, type: 7, subId: 20});
    }
    this.arrayElement.push({
      y: array[array.length - 1],
      x: this.centerPosition,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[0] + this.coef.global_size_flat * 4,
      x: this.centerPosition + this.coef.global_size_flat * 2,
      type: 8
    });
    this.createElement(this.arrayElement);
  }

  midWardrobe() {
    this.arrayElement.push({y: this.top + 350 / this.coef.global_coef, x: this.centerPosition, type: 7, subId: 20});
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.rightPosition - this.coef.global_size_flat - 450 / this.coef.global_coef,
      type: 6
    });
    const array = this.findYForElement(3);
    for (let i = 0; i < array.length; i++) {

      if (i == 1) {
        this.arrayElement.push({y: array[i], x: this.rightPosition - 450 / this.coef.global_coef, type: 7, subId: 20});
      } else {
        this.arrayElement.push({y: array[i], x: this.rightPosition - 450 / this.coef.global_coef, type: 7, subId: 21});
      }
    }
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.centerPosition + 450 / this.coef.global_coef,
      type: 6
    });
    for (let i = 0; i < array.length; i++) {

      if (i == 1) {
        this.arrayElement.push({y: array[i], x: this.centerPosition, type: 7, subId: 20});
      } else {
        this.arrayElement.push({y: array[i], x: this.centerPosition, type: 7, subId: 21});
      }
    }
    this.arrayElement.push({
      y: this.bottom - 250 / this.coef.global_coef - this.coef.global_size_flat,
      x: this.centerPosition + 450 / this.coef.global_coef + this.coef.global_size_flat,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: this.topForShelf + this.coef.global_size_flat * 2,
      x: this.centerPosition + 450 / this.coef.global_coef + this.coef.global_size_flat * 3,
      type: 8
    });
    this.createElement(this.arrayElement);
  }

  midWardrobeTop() {
    const array = this.findYForElementTop(5);
    const center = Math.floor((this.coef.setingsWardrobe.width - this.coef.global_size_flat * this.coef.global_coef * 3) / 2) / this.coef.global_coef;
    const centerWidthRight = this.coef.setingsWardrobe.width - center * this.coef.global_coef - this.coef.global_size_flat * this.coef.global_coef * 3;
    const centerRight = Math.floor((centerWidthRight - this.coef.global_size_flat * this.coef.global_coef) / 2) / this.coef.global_coef;
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.centerPosition + center,
      type: 6
    });
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.centerPosition + centerRight + center + this.coef.global_size_flat,
      type: 6
    });
    this.arrayElement.push({y: array[0], x: this.centerPosition, type: 7, subId: 20});
    this.arrayElement.push({y: array[0], x: this.centerPosition + center + this.coef.global_size_flat * 2, type: 7, subId: 20});
    for (let i = 0; i < array.length; i++) {
      this.arrayElement.push({
        y: array[i],
        x: this.centerPosition + centerRight + center + this.coef.global_size_flat * 2,
        type: 7,
        subId: 20
      });
    }
    this.arrayElement.push({
      y: array[array.length - 1],
      x: this.centerPosition,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[array.length - 1],
      x: this.centerPosition + center + this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[0] + this.coef.global_size_flat * 4,
      x: this.centerPosition + this.coef.global_size_flat * 2,
      type: 8
    });
    this.arrayElement.push({
      y: array[0] + this.coef.global_size_flat * 4,
      x: this.centerPosition + center + this.coef.global_size_flat * 2,
      type: 8
    });
    this.createElement(this.arrayElement);
  }

  midllWardrobe() {
    console.warn('asdddddddd');
    this.arrayElement.push({y: this.top + 350 / this.coef.global_coef, x: this.centerPosition, type: 7, subId: 20});
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef + this.coef.global_size_flat,
      x: Math.floor(((this.rightPosition - this.coef.global_size_flat - this.centerPosition) / 2 + this.centerPosition) * this.coef.global_coef) / this.coef.global_coef,
      type: 6
    });
    // this.arrayElement.push({
    //   y: this.top + 350 / this.coef.global_coef,
    //   x: this.rightPosition - this.coef.global_size_flat - 450 / this.coef.global_coef,
    //   type: 7, subId: 20
    // });
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.rightPosition - this.coef.global_size_flat - 450 / this.coef.global_coef,
      type: 6
    });
    const array = this.findYForElement(3);
    for (let i = 0; i < array.length; i++) {

      if (i == 1) {
        this.arrayElement.push({y: array[i], x: this.rightPosition - 450 / this.coef.global_coef, type: 7, subId: 20});
      } else {
        this.arrayElement.push({y: array[i], x: this.rightPosition - 450 / this.coef.global_coef, type: 7, subId: 21});
      }
    }

    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.centerPosition + 450 / this.coef.global_coef,
      type: 6
    });
    for (let i = 0; i < array.length; i++) {

      if (i == 1) {
        this.arrayElement.push({y: array[i], x: this.centerPosition, type: 7, subId: 20});
      } else {
        this.arrayElement.push({y: array[i], x: this.centerPosition, type: 7, subId: 21});
      }
    }
    this.arrayElement.push({
      y: this.bottom - 250 / this.coef.global_coef - this.coef.global_size_flat,
      x: this.centerPosition + 450 / this.coef.global_coef + this.coef.global_size_flat,
      type: 7, subId: 21
    });
    this.arrayElement.push({
      y: this.bottom - 250 / this.coef.global_coef - this.coef.global_size_flat,
      x: this.rightPosition - 450 / this.coef.global_coef - this.coef.global_size_flat * 2,
      type: 7, subId: 21
    });
    this.arrayElement.push({
      y: this.top + 50 / this.coef.global_coef - this.coef.global_size_flat,
      x: this.centerPosition + 600 / this.coef.global_coef,
      type: 6
    });
    this.arrayElement.push({
      y: this.top + 50 / this.coef.global_coef - this.coef.global_size_flat,
      x: this.rightPosition - 600 / this.coef.global_coef - this.coef.global_size_flat,
      type: 6
    });
    this.arrayElement.push({
      y: array[0] + this.coef.global_size_flat * 4,
      x: this.centerPosition + 900 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 8
    });
    this.arrayElement.push({
      y: array[0] + this.coef.global_size_flat * 4,
      x: this.rightPosition + this.coef.global_size_flat * 2 - 900 / this.coef.global_coef,
      type: 8
    });
    this.createElement(this.arrayElement);
  }

  midllWardrobeTop() {
    const array = this.findYForElementTop(5);
    const center = Math.floor((this.coef.setingsWardrobe.width - this.coef.global_size_flat * this.coef.global_coef * 3) / 2) / this.coef.global_coef;
    const centerWidthRight = this.coef.setingsWardrobe.width - center * this.coef.global_coef - this.coef.global_size_flat * this.coef.global_coef * 3;
    const centerRight = Math.floor((centerWidthRight - this.coef.global_size_flat * this.coef.global_coef) / 2) / this.coef.global_coef;
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.centerPosition + 600 / this.coef.global_coef,
      type: 6
    });
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.rightPosition - this.coef.global_size_flat - 600 / this.coef.global_coef,
      type: 6
    });
    this.arrayElement.push({
      y: array[0],
      x: this.centerPosition + 600 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7,
      subId: 20
    });
    for (let i = 0; i < array.length; i++) {
      this.arrayElement.push({y: array[i], x: this.centerPosition + this.coef.global_size_flat * 2, type: 7, subId: 20});
    }
    for (let i = 0; i < array.length; i++) {
      this.arrayElement.push({y: array[i], x: this.rightPosition - this.coef.global_size_flat * 2, type: 7, subId: 20});
    }
    this.arrayElement.push({
      y: array[array.length - 1],
      x: this.centerPosition + 600 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[0] + this.coef.global_size_flat * 4,
      x: this.centerPosition + 600 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 8
    });
    this.createElement(this.arrayElement);
  }

  bigWardrobe() {
    this.arrayElement.push({y: this.top + 350 / this.coef.global_coef, x: this.centerPosition, type: 7, subId: 20});
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef,
      x: this.rightPosition - this.coef.global_size_flat - 450 / this.coef.global_coef,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.rightPosition - this.coef.global_size_flat - 450 / this.coef.global_coef,
      type: 6
    });
    const array = this.findYForElement(3);
    for (let i = 0; i < array.length; i++) {

      if (i == 1) {
        this.arrayElement.push({y: array[i], x: this.rightPosition - 450 / this.coef.global_coef, type: 7, subId: 20});
      } else {
        this.arrayElement.push({y: array[i], x: this.rightPosition - 450 / this.coef.global_coef, type: 7, subId: 21});
      }
    }

    this.arrayElement.push({
      y: this.top + 350 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.centerPosition + 450 / this.coef.global_coef,
      type: 6
    });
    for (let i = 0; i < array.length; i++) {

      if (i == 1) {
        this.arrayElement.push({y: array[i], x: this.centerPosition, type: 7, subId: 20});
      } else {
        this.arrayElement.push({y: array[i], x: this.centerPosition, type: 7, subId: 21});
      }
    }
    this.arrayElement.push({
      y: this.bottom - 250 / this.coef.global_coef - this.coef.global_size_flat,
      x: this.centerPosition + 450 / this.coef.global_coef + this.coef.global_size_flat,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: this.bottom - 250 / this.coef.global_coef - this.coef.global_size_flat,
      x: this.rightPosition - 450 / this.coef.global_coef - this.coef.global_size_flat * 3,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: this.topForShelf + this.coef.global_size_flat * 2,
      x: this.centerPosition + 450 / this.coef.global_coef + this.coef.global_size_flat * 3,
      type: 8
    });
    this.arrayElement.push({
      y: this.topForShelf + this.coef.global_size_flat * 2,
      x: this.rightPosition - 450 / this.coef.global_coef - this.coef.global_size_flat * 2,
      type: 8
    });
    this.createElement(this.arrayElement);
  }

  bigWardrobeTop() {
    const array = this.findYForElementTop(5);
    const center = Math.floor((this.coef.setingsWardrobe.width - this.coef.global_size_flat * this.coef.global_coef * 3) / 2) / this.coef.global_coef;
    const centerWidthRight = this.coef.setingsWardrobe.width - center * this.coef.global_coef - this.coef.global_size_flat * this.coef.global_coef * 3;
    const centerRight = Math.floor((centerWidthRight - this.coef.global_size_flat * this.coef.global_coef) / 2) / this.coef.global_coef;
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.centerPosition + 450 / this.coef.global_coef,
      type: 6
    });
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.centerPosition + 900 / this.coef.global_coef + this.coef.global_size_flat,
      type: 6
    });
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.rightPosition - this.coef.global_size_flat - 450 / this.coef.global_coef,
      type: 6
    });
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.rightPosition - this.coef.global_size_flat * 2 - 900 / this.coef.global_coef,
      type: 6
    });
    this.arrayElement.push({
      y: array[0],
      x: this.centerPosition + 450 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7,
      subId: 20
    });
    this.arrayElement.push({
      y: array[0],
      x: this.centerPosition + 900 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7,
      subId: 20
    });
    this.arrayElement.push({
      y: array[0],
      x: this.rightPosition + this.coef.global_size_flat * 2 - 900 / this.coef.global_coef,
      type: 7,
      subId: 20
    });
    for (let i = 0; i < array.length; i++) {
      this.arrayElement.push({y: array[i], x: this.centerPosition + this.coef.global_size_flat * 2, type: 7, subId: 20});
    }
    for (let i = 0; i < array.length; i++) {
      this.arrayElement.push({y: array[i], x: this.rightPosition - this.coef.global_size_flat * 2, type: 7, subId: 20});
    }
    this.arrayElement.push({
      y: array[array.length - 1],
      x: this.centerPosition + 450 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[array.length - 2],
      x: this.centerPosition + 450 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[array.length - 1],
      x: this.centerPosition + 900 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[array.length - 1],
      x: this.rightPosition + this.coef.global_size_flat * 2 - 900 / this.coef.global_coef,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[array.length - 2],
      x: this.rightPosition + this.coef.global_size_flat * 2 - 900 / this.coef.global_coef,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[0] + this.coef.global_size_flat * 4,
      x: this.centerPosition + 450 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 8
    });
    this.arrayElement.push({
      y: array[0] + this.coef.global_size_flat * 4,
      x: this.centerPosition + 900 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 8
    });
    this.arrayElement.push({
      y: array[0] + this.coef.global_size_flat * 4,
      x: this.rightPosition + this.coef.global_size_flat * 2 - 900 / this.coef.global_coef,
      type: 8
    });
    this.createElement(this.arrayElement);
  }

  bigBigWardrobeTop() {
    const array = this.findYForElementTop(5);
    const center = Math.floor((this.coef.setingsWardrobe.width - this.coef.global_size_flat * this.coef.global_coef * 3) / 2) / this.coef.global_coef;
    const centerWidthRight = this.coef.setingsWardrobe.width - center * this.coef.global_coef - this.coef.global_size_flat * this.coef.global_coef * 3;
    const centerRight = Math.floor((centerWidthRight - this.coef.global_size_flat * this.coef.global_coef) / 2) / this.coef.global_coef;
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.centerPosition + 450 / this.coef.global_coef,
      type: 6
    });
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.rightPosition - this.coef.global_size_flat - 450 / this.coef.global_coef,
      type: 6
    });
    this.arrayElement.push({
      y: this.top + 334 / this.coef.global_coef + this.coef.global_size_flat,
      x: this.rightPosition - this.coef.global_size_flat * 2 - 900 / this.coef.global_coef,
      type: 6
    });
    this.arrayElement.push({
      y: array[0],
      x: this.centerPosition + 450 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7,
      subId: 20
    });
    this.arrayElement.push({
      y: array[0],
      x: this.centerPosition + 900 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7,
      subId: 20
    });
    this.arrayElement.push({
      y: array[0],
      x: this.rightPosition + this.coef.global_size_flat * 2 - 900 / this.coef.global_coef,
      type: 7,
      subId: 20
    });
    for (let i = 0; i < array.length; i++) {
      this.arrayElement.push({y: array[i], x: this.centerPosition + this.coef.global_size_flat * 2, type: 7, subId: 20});
    }
    for (let i = 0; i < array.length; i++) {
      this.arrayElement.push({y: array[i], x: this.rightPosition - this.coef.global_size_flat * 2, type: 7, subId: 20});
    }
    this.arrayElement.push({
      y: array[array.length - 1],
      x: this.centerPosition + 450 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[array.length - 2],
      x: this.centerPosition + 450 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[array.length - 1],
      x: this.centerPosition + 900 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[array.length - 1],
      x: this.rightPosition + this.coef.global_size_flat * 2 - 900 / this.coef.global_coef,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[array.length - 2],
      x: this.rightPosition + this.coef.global_size_flat * 2 - 900 / this.coef.global_coef,
      type: 7, subId: 20
    });
    this.arrayElement.push({
      y: array[0] + this.coef.global_size_flat * 4,
      x: this.centerPosition + 450 / this.coef.global_coef + this.coef.global_size_flat * 2,
      type: 8
    });
    this.arrayElement.push({
      y: array[0] + this.coef.global_size_flat * 4,
      x: this.centerPosition + 900 / this.coef.global_coef + this.coef.global_size_flat * 4,
      type: 8
    });
    this.arrayElement.push({
      y: array[0] + this.coef.global_size_flat * 4,
      x: this.rightPosition + this.coef.global_size_flat * 2 - 900 / this.coef.global_coef,
      type: 8
    });
    this.createElement(this.arrayElement);
  }

  reinit(object) {
    for (const elem of object) {
      if (elem.params && elem.params.neo) {
        if (this.coef.pramsId != 4 && this.coef.pramsId != 6) {
          if(elem.params.type == 9){
            console.log(elem.params,'test2')
        }
          let older = false;
          if (elem.params.older) {
            older = elem.params.older;
          }
          const obj = {
            y: elem.y,
            x: elem.x,
            type: elem.params.type,
            older: older,
            width: (elem.width ? elem.width : false),
            subId: (elem.params.subId ? elem.params.subId : false),
            object: elem,
            neo: (elem.params.neo ? elem.params.neo : false),
            deletes: elem.params.deletes ? elem.params.deletes : false,
          };
          if (elem.params.innerShell) {
            obj['innerShell'] = true;
          }
          this.arrayElement.push(obj);
        }
      }
      if (elem.params && elem.params.removable && !elem.params.deletes) {
        if(elem.params.type == 9){
            console.log(elem.params,'test')
        }
        let older = 'render';
        if (elem.params.older) {
          older = elem.params.older;
        }
        const obj = {
          y: elem.y,
          x: elem.x,
          type: elem.params.type,
          older: older,
          width: (elem.width ? elem.width : false),
          subId: (elem.params.subId ? elem.params.subId : false),
          object: elem,
          neo: (elem.params.neo ? elem.params.neo : false),
          deletes: elem.params.deletes ? elem.params.deletes : false
        };
        if (elem.params.innerShell) {
          obj['innerShell'] = true;
        }
        this.arrayElement.push(obj);
      }
    }
    console.warn(this.arrayElement ,object, 'arrayElement')
    this.createElement(this.arrayElement);
  }
}
