import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '../../../service/notification.service';
import {baseUrl} from '../../../router.path';
import {API} from '../../../common/api';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-update-phone',
  templateUrl: './update-phone.component.html',
  styleUrls: ['./update-phone.component.css']
})
export class UpdatePhoneComponent implements OnInit {
  phoneForm: any;

  public email: string;

  @Input() phone: string;
  @Input() defaultValue: string;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient,
              private notification: NotificationService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.phoneForm = this.formBuilder.group(
      {
        'phone': [this.defaultValue || null, Validators.required],
      });
  }

  onSubmit() {
    this.http.post(baseUrl.api + API.CHANGE_INFO, {param_name: 'phone_number', value: this.phoneForm.controls.phone.value}).subscribe(res => {
      this.notification.triggerNotification(res['payload']);
      this.modalClosed.emit();
    }, error => {
      this.notification.triggerNotification(error['error']['error'], true);
    });
  }

}
