import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../../router.path';
import {API} from '../../common/api';
import {NotificationService} from '../../service/notification.service';
import {FADE_IN_OUT} from '../../common/animations';
import {CoefService} from '../../service/coef.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  animations: [FADE_IN_OUT]
})
export class ProfileComponent implements OnInit {
  public modals: Object = {
    updatePhoneIsOpened: false,
    updateCompanyIsOpened: false,
    updateAddressIsOpened: false,
    updatePasswordIsOpened: false,
  };
  public user: Object = {};


  constructor(private http: HttpClient,
              private notification: NotificationService,
              private coef: CoefService) {
  }

  ngOnInit() {
    this.coef.preloaderUpdate(true);
    this.getUser();
    this.coef.preloaderUpdate(false);
  }

  public getBirthDay(): string {
    if (this.user['birthday']) return this.user['birthday'].slice(0, this.user['birthday'].indexOf('T'));
  }

  private getUser(): void {
    this.http.get(baseUrl.api + API.GET_USER).subscribe(user => {
      this.user = user['payload'];
      this.coef.preloaderUpdate(false);
    }, err => {
      this.notification.triggerNotification('Невідома помилка. Спробуйте пізніше.', true);
    });
  }

  private closeAllModals(): void {
    for (const modal in this.modals) {
      this.modals[modal] = false;
    }
  }

  private onModalClosed(): void {
    this.closeAllModals();
    this.getUser();
  }

}
