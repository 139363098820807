export class BlockCreate {
  public coef;
  public create;
  public arraytopBottomTangent;
  public arraytopBottomTangentDrawer;
  remove;

  constructor(coef, create?, remove?) {
    this.coef = coef;
    this.create = create;
    this.remove = remove;
    this.arraytopBottomTangent = [this.coef.id_element.top_wall, this.coef.id_element.bottom_wall, this.coef.id_element.shelf];
    this.arraytopBottomTangentDrawer = [this.coef.id_element.top_wall, this.coef.id_element.bottom_wall, this.coef.id_element.shelf, this.coef.id_element.drawer];
  }

  mintTopLimit(e) {
    const top = (this.coef.topPositaionWrapper + (this.coef.heightWardrobe / this.coef.global_coef)) - this.coef.pantographMinTop;
    if (top >= e.attrs.y) {
      return true;
    } else {
      this.coef.errorTrigger(`Елемент встановлено вище допустимої висоти.`);
      return false;
    }
  }

  findType(array) {
    for (const item of array) {
      if (item.id == 5) {
        return item.value;
      }
    }
    return false;
  }

  cof(number) {
    return number / this.coef.global_coef;
  }

  minTopLimitDrawer(e) {
    const top = (this.coef.topPositaionWrapper + (this.coef.heightWardrobe / this.coef.global_coef)) - this.coef.drawer.maxTopPos - this.coef.global_size_flat;
    if (top <= e.attrs.y) {
      return true;
    } else {
      this.coef.errorTrigger(`Елемент встановлено вище допустимої висоти.`);
      return false;
    }
  }

  topBottomTangent(contours, drawer?) {
    let array = this.arraytopBottomTangent;
    if (drawer) {
      array = this.arraytopBottomTangentDrawer;
    }
    let stautsTop = false;
    let stautsBottom = false;
    for (const item of array) {
      if (contours['top'].attrs.params.type === item) {
        stautsTop = true;
      }
      if (contours['bottom'].attrs.params.type === item) {
        stautsBottom = true;
      }
    }
    if (stautsTop && stautsBottom) {
      return true;
    } else {
      this.coef.errorTrigger('Неможливо встановити елемент поверх даного покриття.');
      return false;
    }
  }
  blockDifDawer(top , sub_items){
    console.log(top.attrs.params.subId , sub_items , 'sub_items')
    if(top.attrs.params.type == this.coef.id_element.drawer){
      if((sub_items == 26 || sub_items == 27)&& top.attrs.params.subId == sub_items){
        return true
      }else if(sub_items != 26 && top.attrs.params.subId != 26 && sub_items != 27 && top.attrs.params.subId != 27){
        return true
      } else if((sub_items == 26  || sub_items == 27) && !(top.attrs.params.subId != 27 && top.attrs.params.subId != 26)){
        return true
      }  else{
        this.coef.errorTrigger(`${this.coef.disabledItemMap[9].subItems[26].name} не може бути поєднана з іншими типами шухляд`);
        return false
      }
    }else{
      return true;
    }
    
  }
  deleteElement(elementName, top) {
    this.coef.errorTrigger('Не можливо встановити елемент у звязку з мінімально-допустими розмірами.');
    let index;
    const arrayElement = [];
    for (const elements of this.coef.shapesLayerw.children) {
      if (elements.attrs.name === elementName) {
        index = this.coef.shapesLayerw.children.indexOf(elements);
      }
    }
    if (index) {
      if (this.coef.shapesLayerw.children[index].attrs.params.removable) {
        const name = this.coef.shapesLayerw.children[index].attrs.name;
        arrayElement.push(name);
        top.attrs.params.drawerArray.splice(top.attrs.params.drawerArray.length - 1, 1);
        this.coef.shapesLayerw.children.splice(index, 1);
        this.removeBindElement(arrayElement);
      }
    }
    this.coef.shapesLayerw.batchDraw();
  }

  removeBindElement(array) {
    for (const deleteName of array) {
      for (const element of this.coef.shapesLayerw.children) {
        for (const bindElem of element.attrs.bind) {
          let index;
          if (bindElem.attrs.name === deleteName) {
            index = element.attrs.bind.indexOf(bindElem);
            element.attrs.bind.splice(index, 1);
          }
        }
      }
    }
  }

  chachWidthDrawer(countors , pos , id) {
    if (this.coef.pramsId == 7 && !id) {
      const centerPosition = ((this.coef.widthWrapper - this.coef.setingsWardrobe.width / this.coef.global_coef) / 2) + this.coef.global_size_flat;
      const left = centerPosition + this.coef.global_size_flat + (+this.coef.widthSep) / this.coef.global_coef;
      const right = centerPosition + this.coef.setingsWardrobe.width / this.coef.global_coef - (+this.coef.widthSep) / this.coef.global_coef - this.coef.global_size_flat * 3;
      if (pos.evt.layerX > left && pos.evt.layerX < right) {
        this.coef.errorTrigger('Додати елементи до центральної секції даного замовлення заборонено.');
        return false;
      }
    }
    const width = countors['right'].attrs.x - countors['left'].attrs.x - countors['left'].attrs.width;
    if (width <= this.coef.drawer.maxWidth && width >= this.coef.drawer.minWidth) {
      return true;
    } else {
      const error = `Неможливо розмістити елемент. Мінімальна ширина встановлення - ${this.coef.drawer.minWidth * this.coef.global_coef}мм . Максимальна ширина встановлення  - ${this.coef.drawer.maxWidth * this.coef.global_coef}мм`;
      this.coef.errorTrigger(error);
      return false;
    }
  }

  chechLeftRight(countors) {
    if (this.checkOneWrapper(countors, 'left') && this.checkOneWrapper(countors, 'right')) {
      return true;
    } else {
      this.coef.errorTrigger('Для розміщення даного елементу, прилегла перегородка не може бути опертою на полицю, та мусить бути опертою на дно.');
      return false;
    }
  }

  checkOneWrapper(countors, direction) {
    const contours = this.create.getTangentElement({
      evt: {
        layerX: countors[direction].attrs.x,
        layerY: countors[direction].attrs.y
      }
    }, countors[direction], this.coef.shapesLayerw);
    let Wrapper = true;
    if (countors[direction].attrs.params.type === this.coef.id_element.lateral_wall) {
      Wrapper = true;
    } else {
      if (contours['bottom'].attrs.params.type === this.coef.id_element.bottom_wall) {
        Wrapper = true;
      } else {
        Wrapper = false;
      }
    }
    return Wrapper;
  }

  twoOpenDoorlimitCreate(countrs) {
    if (this.foundWidth(countrs) >= this.coef.openDoor.min_section_width_for_two_doors && this.foundWidth(countrs) <= this.coef.openDoor.max_section_width_for_two_doors) {
      return true;
    } else {
      this.coef.errorTrigger(`Ширина проєму є заламою для встановлення двохдверного варіанту. Мінімальна ширина проєму - ${this.coef.openDoor.min_section_width_for_two_doors}мм`);
      return false;
    }
  }

  foundWidth(countors) {
    return countors['right'].attrs.x - countors['left'].attrs.x - this.coef.global_size_flat;
  }

  paritationInShelflimitCreate(countors, id?) {
    if (id) {
      return true;
    }
    if (countors['bottom'].attrs.params.type !== this.coef.id_element.bottom_wall) {
      if (this.foundHeight(countors) >= this.coef.max_hanging_height) {
        this.coef.errorTrigger(`Перегородка висотою більше ${this.coef.max_hanging_height * this.coef.global_coef}мм не може бути опертою  на полицю.`);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  openDoorMaxHeight(countors) {
    if (this.foundHeight(countors) > this.coef.openDoor.max_height) {
      this.coef.errorTrigger(`Максимальна висота проєму для встановлення елементу - ${this.coef.openDoor.max_height * this.coef.global_coef}мм`);
      return false;
    } else {
      return true;
    }
  }

  foundHeight(countors) {
    return countors['bottom'].attrs.y - countors['top'].attrs.y - this.coef.global_size_flat;
  }

  oneOpenDoorlimitCrate(countrs) {
    if (this.foundWidth(countrs) <= this.coef.openDoor.max_section_width_for_one_door) {
      return true;
    } else {
      this.coef.errorTrigger(`Ширина проєму є завеликою для встановлення однодверного варіанту. Маскимальна ширина проєму - ${this.coef.openDoor.max_section_width_for_one_door}мм`);
      return false;
    }
  }


  backWallHeightBlock(countrs) {
    const wrapperHeight = this.foundHeight(countrs);
    return (wrapperHeight >= this.coef.back_wall_min_height);
  }

  stopCreateOnLevel(countrs) {
    if (countrs['top'].attrs.params.stopDrags) {
      this.coef.errorTrigger('Неможливо встановити шухляди над полицею до якої уже прив\'язана група шухляд');
      return false;
    }
    if (countrs['bottom'].attrs.params.drawerArray && countrs['bottom'].attrs.params.drawerArray > 0) {
      this.coef.errorTrigger('Неможливо встановити шухляди під полицею до якої уже прив\'язана група шухляд');
      return false;
    }
    return true;
  }

  createMaxWidthHeight(countrs) {
    if (this.foundWidth(countrs) > this.coef.back_wall_max_width || this.foundHeight(countrs) > this.coef.back_wall_max_height) {
      this.coef.errorTrigger(`Розміри проєму є завеликими для встановлення даного елементу. Максимально-допустима ширина проєму - ${this.coef.back_wall_max_width * this.coef.global_coef}мм, та висота проєму - ${this.coef.back_wall_max_height * this.coef.global_coef}мм.`);
      return false;
    }
    return true;
  }

  blockEnding(position) {
    for (const item of this.coef.shapesLayerw.children) {
      if (item.attrs.params.position) {
        if (item.attrs.params.position === position) return false;
      }
    }
    return true;
  }

  blockSelfs(countrs, xy) {
    return !this.create.blockSelf(countrs, xy);
  }

  partitionBlockLevel(pos, countrs) {
    return this.BlockLevel(pos, countrs['top']) && this.BlockLevel(pos, countrs['bottom']);

  }

  BlockLevel(pos, countrs) {
    if (countrs.attrs.bind.length > 0) {
      for (let bind of countrs.attrs.bind) {
        if (bind !== 13) {
          if (bind.attrs.params.type === 7 || bind.attrs.params.type === 6) {
            if (pos.x < bind.attrs.x + bind.attrs.width + this.coef.two_in_row_diapason && pos.x + this.coef.global_size_flat > bind.attrs.x - this.coef.two_in_row_diapason) {
              return true;
            }
          }
        }
      }
    }
    return true;
  }
}
