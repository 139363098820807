import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CookieService} from 'angular2-cookie/core';
import {baseUrl, PAGE} from '../../router.path';
import {HttpClient} from '@angular/common/http';
import {CoefService} from '../../service/coef.service';
import {DomSanitizer} from '@angular/platform-browser';
import {CoefDoorService} from '../../service/door service/coef-door.service';
import {InitRenderService} from '../../service/renderingService/init-render.service';
import {CornerСabinet} from '../../class/order/cornerсabinet';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  readonly PAGE = PAGE;

  @Input() calculator = false;
  @Input() cabinet = false;
  @Input() typeItem = {};
  @Input() orderType = false;
  @Input() custom = false;


  constructor(private router: Router,
              private cookieService: CookieService,
              private coef: CoefService,
              private sanitizer: DomSanitizer,
              private doorCoef: CoefDoorService,
              private render: InitRenderService,
              private http: HttpClient) {

  }

  textInfo;

  // set coef(name: string) {
  //   console.log('prev value: ', this.textInfo);
  //   console.log('got name: ', name);
  //   this.textInfo = name;
  // }
  isClientPage = false;
  ngOnInit() {
    const isClientPage = this.router.url.indexOf('calculator') !== -1;
    this.isClientPage = !isClientPage;
    this.coef.innfoStatus.subscribe((e) => {
      this.updateInfo();
    });
    if (this.coef.info) {
      this.updateInfo();
    }
    this.http.get(baseUrl.local + 'get-constructor-status').subscribe((response) => {
      if (response['success'] && !response['payload']) {
        this.router.navigate(['order']);
        this.coef.errorTrigger('Можливість користуватись калькулятором призупинена. Спробуйте будь ласка пізніше.');
      }
    }, (error) => {
      if (error.status === 401) {
        // this.router.navigate(['']);
      }
    });
  }

  updateInfo() {
    switch (this.router.url) {
      case '/' + PAGE.ORDER:
        this.textInfo = this.sanitizer.bypassSecurityTrustHtml(this.coef.info.active_orders);
        break;
      case '/' + PAGE.PROFILE:
        this.textInfo = this.sanitizer.bypassSecurityTrustHtml(this.coef.info.profile);
        break;
      case '/' + PAGE.ORDER_HISTORY:
        this.textInfo = this.sanitizer.bypassSecurityTrustHtml(this.coef.info.order_history);
        break;
      case '/' + PAGE.ORDERTYPE:
        this.textInfo = this.sanitizer.bypassSecurityTrustHtml(this.coef.info.order_type_setting);
        break;
      default:
        this.textInfo = this.sanitizer.bypassSecurityTrustHtml(this.coef.info.calculator);
        break;
    }
  }

  public logOut(): void {
    localStorage.clear();
    this.cookieService.remove('jwt');
    this.goTO(this.PAGE.LOGIN);
  }

  public goTO(page: string): void {
    this.destroyCulck(page);
    this.destroyCulck(page);
    this.router.navigate([page]);
  }

  reloadPage(page) {
    this.router.navigate([page])
      .then(() => {
        window.location.reload();
      });
  }
  destroyCulck(page) {
    if (page === 'order' && this.coef.stage) {
      for (let i = this.coef.look2d.children.length - 1; i >= 0; i--) {
        this.coef.look2d.children[i].destroy();
      }
      for (let i = this.coef.GlobalSize['children'].length - 1; i >= 0; i--) {
        this.coef.GlobalSize['children'][i].destroy();
      }
      for (const item of this.coef.stage.stage.children) {
        item.destroy();
      }
      if (page === 'order' && this.doorCoef.doorStag.stage) {
        for (let i = this.doorCoef.doorStag.stage.children.length - 1; i >= 0; i--) {
          this.doorCoef.doorStag.stage.children[i].destroy();
        }
      }
      this.render.rerender = false;
    }
  }
}
