import {Injectable} from '@angular/core';
import {CoefService} from './coef.service';
import {MinSizeService} from './min-size.service';
import {PriceService} from './price.service';
import * as _ from 'lodash';
import {CreateService} from './create.service';

@Injectable({
  providedIn: 'root'
})
export class DrawerLevelService {
  arrayBind = [];
  arrayBindTop = [];
  back = false;
  backTop = false;

  constructor(private coef: CoefService, private minSize: MinSizeService, private price: PriceService) {
  }

  start() {
    this.coef.shapesLayerw.on('dragstart', (e) => {
      if (this.coef.pramsId == 11) return;
      this.arrayBind = [];
      this.arrayBindTop = [];
      this.getLeftRightBind(e.target);
      this.getLeftRightBind(e.target, true);
    });
    this.coef.shapesLayerw.on('dragmove', (e) => {
      if (this.coef.pramsId == 11) return;
      this.backTop = this.checkBlockPartition(e.target);
      this.back = this.checkBlockShelf(e.target);
      if (this.back || this.backTop) {
        e.target.attrs.fill = 'red';
      } else {
        e.target.attrs.fill = this.coef.color;
      }
    });
    this.coef.shapesLayerw.on('dragend', (e) => {
      if (this.coef.pramsId == 11) return;
      this.arrayBind = [];
      this.arrayBindTop = [];
      if (this.back || this.backTop) {
        if (e.target.attrs.params.type === this.coef.id_element.partition) {
          this.goBack(e, true);
        } else {
          this.goBack(e);
        }
      }
      e.target.attrs.params.old_position = {x: e.target.attrs.x, y: e.target.attrs.y};
    });
  }

  goBack(e, top?) {
    this.arrayBind = [];
    e.target.attrs.fill = this.coef.color;
    const drag = new Event('dragmove');
    if (top) {
      e.target.x(e.target.attrs.params.old_position.x);
      // this.minSize.minSizeManualTravel(this.coef.shapesLayerw, {target: e.target}, true);
      e.target.dispatchEvent(drag);
      this.coef.shapesLayerw.batchDraw();
      this.price.sendObjectPrice();
    } else {
      e.target.y(e.target.attrs.params.old_position.y);
      e.target.x(e.target.x());
      // this.minSize.minSizeManualTravel(this.coef.shapesLayerw, {target: e.target}, true);
      e.target.dispatchEvent(drag);
      this.coef.shapesLayerw.batchDraw();
      this.price.sendObjectPrice();
    }
  }

  getLeftRightBind(target, top?, contour?) {
    let contours;
    if (contour) {
      contours = contour;
    } else {
      contours = this.getTangentElement({
          evt: {
            layerX: target.attrs.x,
            layerY: target.attrs.y
          }
        },
        target, this.coef.shapesLayerw
      );
    }
    if (contours) {
      for (const right  of contours['right'].attrs.bind) {
        if (right.attrs.x > contours['right'].attrs.x) {
          if (contours['right'].attrs.params.type !== 13) {
            if (right.attrs.params.type === this.coef.id_element.shelf) {
              if (right.attrs.params.subId === 20) {
                this.arrayBind.push(right.attrs);
              }
            }
          }
        }
      }
      for (const left  of contours['left'].attrs.bind) {
        if (left.attrs.x < contours['left'].attrs.x) {
          if (contours['left'].attrs.params.type !== 13) {
            if (left.attrs.params.type === this.coef.id_element.shelf) {
              if (left.attrs.params.subId === 20) {
                this.arrayBind.push(left.attrs);
              }
            }
          }
        }
      }
      if (top) {
        for (const topElem  of contours['top'].attrs.bind) {
          if (topElem.attrs.y < contours['top'].attrs.y) {
            if (topElem.attrs.params.type === this.coef.id_element.partition) {
              this.arrayBindTop.push(topElem.attrs);
            }
          }
        }
        for (const bottom  of contours['bottom'].attrs.bind) {
          if (bottom.attrs.y > contours['bottom'].attrs.y) {
            if (bottom.attrs.params.type === this.coef.id_element.partition) {
              this.arrayBindTop.push(bottom.attrs);
            }
          }
        }
      }
    }
  }

  checkBlockShelf(target) {
    if (target.attrs.params.subId === 20) {
      if (this.coef.pramsId == 4 || this.coef.pramsId == 6) {
        const top = this.coef.topPositaionWrapper + this.coef.spec_values['top_anteroom_section_height'] / this.coef.global_coef;
        const bottom = this.coef.topPositaionWrapper + this.coef.global_size_flat + (this.coef.setingsWardrobe.height - this.coef.global_size_flat * this.coef.global_coef * 2 - this.coef.height_legs * this.coef.global_coef) / this.coef.global_coef - this.coef.spec_values['bottom_anteroom_section_height'] / this.coef.global_coef;
        if (target.attrs.y + target.attrs.height > top - this.coef.two_in_row_diapason && target.attrs.y < top + this.coef.two_in_row_diapason + this.coef.global_size_flat) {
          return true;
        }
        if (target.attrs.y + target.attrs.height > bottom - this.coef.two_in_row_diapason && target.attrs.y < bottom + this.coef.two_in_row_diapason + this.coef.global_size_flat) {
          return true;
        }
      }
      for (const level of this.arrayBind) {
        if (target.attrs.y + target.attrs.height > level.y - this.coef.two_in_row_diapason && target.attrs.y < level.y + this.coef.two_in_row_diapason + level.height) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  checkBlockPartition(target) {
    if (target.attrs.params.type === 6) {
      for (const level of this.arrayBindTop) {
        if (target.attrs.x < level.x + level.width + this.coef.two_in_row_diapason && target.attrs.x + target.attrs.width > level.x - this.coef.two_in_row_diapason) return true;
      }
      return false;
    }
    return false;
  }

  getTangentElement(pos, mainElement?, parents?, drawer?) {
    let elemLeft;
    let elemRight;
    let elemTop;
    let elemBottom;
    let left = 0;
    let top = 0;
    let right = 99999;
    let bottom = 99999;
    const positionElementX = pos.evt.layerX;
    const positionElementY = pos.evt.layerY;
    for (const element of parents.children) {
      if (this.checkHorizontalVertical(element)) {
        if (positionElementY >= element.attrs.y && positionElementY < (element.attrs.y + (element.attrs.height ? element.attrs.height : element.attrs.params.height))) {
          if (element.attrs.params.type === 13) {
            // console.log(element);
          }
          if (positionElementX > element.attrs.x && element.attrs.x > left) {
            left = element.attrs.x;
            elemLeft = element;
          }
          if (positionElementX < element.attrs.x && element.attrs.x < right) {
            right = element.attrs.x;
            elemRight = element;
          }
        }
      } else {
        if (element.attrs.name !== mainElement.attrs.name) {
          if (positionElementX >= element.attrs.x && positionElementX < (element.attrs.x + element.attrs.width)) {
            if (positionElementY > element.attrs.y && element.attrs.y > top) {
              if (drawer) {
                if (element.attrs.params.type === this.coef.id_element.shelf || element.attrs.params.type === this.coef.id_element.top_wall) {
                  top = element.attrs.y;
                  elemTop = element;
                }
              } else {
                top = element.attrs.y;
                elemTop = element;
              }
            }
            if (positionElementY < element.attrs.y && element.attrs.y < bottom) {
              if (drawer) {
                if (element.attrs.params.type === this.coef.id_element.shelf || element.attrs.params.type === this.coef.id_element.bottom_wall) {
                  bottom = element.attrs.y;
                  elemBottom = element;
                }
              } else {
                bottom = element.attrs.y;
                elemBottom = element;
              }
            }
          }
        }
      }
    }
    if (elemLeft && elemRight && elemTop && elemBottom) {
      return {top: elemTop, right: elemRight, bottom: elemBottom, left: elemLeft};
    } else {
      return false;
    }
  }

  checkHorizontalVertical(element) {
    switch (element.attrs.params.type) {
      case 1:
        return false;
      case 3:
        return false;
      case 4:
        return false;
      case this.coef.id_element.pantograph:
        return false;
      case this.coef.id_element.partition:
        return true;
      case this.coef.id_element.shelf:
        return false;
      case this.coef.id_element.trumpet:
        return false;
      case this.coef.id_element.lateral_wall:
        return true;
      case this.coef.id_element.top_wall:
        return false;
      case this.coef.id_element.bottom_wall:
        return false;
      case this.coef.id_element.double_partition:
        return true;
    }
  }
}
