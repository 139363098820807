export const baseUrl = {
  local: '/app/',
  api: '/api/',
  photo: ''
};

// export const baseUrl = {
//   local: 'https://svapi.papaptunio.xyz/app/',
//   api: 'https://svapi.papaptunio.xyz/api/',
//   photo: 'https://svapi.papaptunio.xyz'
// };
// export const baseUrl = {
//   local: 'http://176.223.143.141:55200/app/',
//   api: 'http://176.223.143.141:55200/api/',
//   photo: 'http://176.223.143.141:55200'
// };
// export const baseUrl = {
//   local: 'http://b7ba6a06f3b2.ngrok.io/app/',
//   api: 'http://b7ba6a06f3b2.ngrok.io/api/',
//   photo: 'http://b7ba6a06f3b2.ngrok.io'
// };

export const PAGE = {
  LOGIN: '',
  ORDERTYPE: 'order-type',
  ORDERTYPEFree: 'order-type-calculator',
  MechanicianComponent: 'designer',
  MechanicianComponentFree: 'calculator',
  Authorization: 'authorization',
  PROFILE: 'profile',
  ORDER: 'order',
  ORDER_HISTORY: 'history',
  CUSTOM: 'custom',
  CUSTOMFree: 'custom-calculator'
};

export const PAGE_PATH = {
  // LOGIN: './pages/portfolio/tempo/tempo.module#TempoModule',
};
