import {Component, Input, OnInit} from '@angular/core';
import {FADE_IN_OUT} from '../../../common/animations';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  animations: [FADE_IN_OUT]
})
export class InfoComponent implements OnInit {
  public infoOpened = false;
  @Input() text;

  constructor() {
  }

  ngOnInit() {
  }

}
