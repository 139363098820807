import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CalendarModule} from 'primeng/calendar';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_ROUTES_CONST} from './app.routes';
import {SocketIoConfig, SocketIoModule} from 'ng-socket-io';
import {CoefService} from './service/coef.service';
import {CreateService} from './service/create.service';
import {MinSizeService} from './service/min-size.service';
import {ErrorService} from './service/error.service';
import {NotificationService} from './service/notification.service';
import {ElementsService} from './service/elements.service';
import {RemoveService} from './service/remove.service';
import {SocketPriceService} from './service/socket-price.service';
import {TokenInterceptorService} from './service/token-interceptor.service';
import {CookieService} from 'angular2-cookie/core';
import {TabsModule} from 'ngx-bootstrap';
import {AutoCompleteModule, DropdownModule} from 'primeng/primeng';
import {AuthorizationComponent} from './authorization/authorization.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {MechanicianComponent} from './mechanician/mechanician.component';
import {SecondStageComponent} from './mechanician/second-stage/second-stage.component';
import {ThirdStageComponent} from './mechanician/third-stage/third-stage.component';
import {OrderTypeComponent} from './order-type/order-type.component';
import {ErrorComponent} from './error/error.component';
import {HeaderComponent} from './components/header/header.component';
import {ForgotPassModalComponent} from './login/forgot-pass-modal/forgot-pass-modal.component';
import {NotificationComponent} from './components/notification/notification.component';
import {SingleNotificationComponent} from './components/notification/single-notification/single-notification.component';
import {UpdateCompanyComponent} from './pages/profile/update-company/update-company.component';
import {UpdateAddressComponent} from './pages/profile/update-address/update-address.component';
import {UpdatePasswordComponent} from './pages/profile/update-password/update-password.component';
import {MainNavigationComponent} from './components/header/main-navigation/main-navigation.component';
import {CalculatorStepsComponent} from './components/header/calculator-steps/calculator-steps.component';
import {LoginComponent} from './login/login.component';
import {FirstStageComponent} from './mechanician/first-stage/first-stage.component';
import {UpdatePhoneComponent} from './pages/profile/update-phone/update-phone.component';
import {ErrorModalComponent} from'./mechanician/error-modal/error-modal.component';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {FilterByNamePipe} from './common/filterByName';
import {InfoComponent} from './components/header/info/info.component';
import {DoorStageComponent} from './mechanician/door-stag/door-stage.component';
import {SwiperModule} from 'angular2-useful-swiper';
import {DragDirective} from './service/door service/drag.directive';
import {svgComponent} from './svgComponent';
import {ColorSystemComponent} from './components/color-system/color-system.component';
import {DragContentDirective} from './service/door service/drag-content.directive';
import {BackWallComponent} from './svg/back-wall/back-wall.component';
import {OrderComponent} from './pages/order/order.component';
import {AgGridModule} from 'ag-grid-angular';
import {ImgTableComponent} from './components/img-table/img-table.component';
import {ButtonTableComponent} from './components/button-table/button-table.component';
import {TextTableComponent} from './components/text-table/text-table.component';
import {HistoryComponent} from './pages/history/history.component';
import {DataTableComponent} from './components/data-table/data-table.component';
import {PriceTableComponent} from './components/price-table/price-table.component';
import {PreloaderComponent} from './components/preloader/preloader.component';
import {ButtonTable2Component} from './components/button-table2/button-table2.component';
import {PopapComponent} from './components/popap/popap.component';
import { Page404Component } from './pages/page404/page404.component';
import {NgxPrintModule} from 'ngx-print';
import { CustomOrderComponent } from './pages/custom-order/custom-order.component';
import {InputTextModule} from 'primeng/inputtext';
import {NumberInputComponent} from './components/number-input/number-input.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule  } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import {TabViewModule} from 'primeng/tabview';
const config: SocketIoConfig = {url: '', options: {}};
// const config: SocketIoConfig = {url: 'https://svapi.papaptunio.xyz', options: {}};
// const config: SocketIoConfig = {url: 'http://b7ba6a06f3b2.ngrok.io', options: {}};

@NgModule({
  declarations: [
    AppComponent,
    AuthorizationComponent,
    LoginComponent,
    ProfileComponent,
    MechanicianComponent,
    FirstStageComponent,
    DoorStageComponent,
    SecondStageComponent,
    ThirdStageComponent,
    OrderTypeComponent,
    FilterByNamePipe,
    OrderComponent,
    ErrorComponent,
    HeaderComponent,
    ForgotPassModalComponent,
    NotificationComponent,
    SingleNotificationComponent,
    UpdatePhoneComponent,
    ErrorModalComponent,
    UpdateCompanyComponent,
    UpdateAddressComponent,
    UpdatePasswordComponent,
    MainNavigationComponent,
    CalculatorStepsComponent,
    InfoComponent,
    svgComponent,
    DragDirective,
    ColorSystemComponent,
    DragContentDirective,
    BackWallComponent,
    ImgTableComponent,
    ButtonTableComponent,
    TextTableComponent,
    HistoryComponent,
    DataTableComponent,
    PriceTableComponent,
    PreloaderComponent,
    NumberInputComponent,
    ButtonTable2Component,
    PopapComponent,
    Page404Component,
    CustomOrderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TabViewModule,
    SwiperModule,
    AgGridModule.withComponents(
      [ImgTableComponent, ButtonTableComponent, ButtonTable2Component, TextTableComponent, DataTableComponent, PriceTableComponent]
    ),
    APP_ROUTES_CONST,
    AngularFontAwesomeModule,
    SocketIoModule.forRoot(config),
    TabsModule.forRoot(),
    DropdownModule,
    InputTextModule,
    InputSwitchModule ,
    CheckboxModule ,
    ButtonModule,
    NgxPrintModule,
    AutoCompleteModule
  ],
  providers: [
    Validators,
    CoefService,
    CreateService,
    MinSizeService,
    RemoveService,
    ErrorService,
    NotificationService,
    ElementsService,
    CookieService,
    SocketPriceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
