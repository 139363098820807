import * as Konva from '../../assets/konva';
import {Create} from './create';
import {Partition} from './partition';
import {Sizes} from './sizes/sizes';

export class Shelf extends Create {
  group;
  coef;
  create;
  subId;


  constructor(coef, create, active, subId) {
    super(coef, false);
    this.coef = coef;
    this.create = create;
    this.subId = subId;
    switch (this.subId) {
      case 20: {
        this.createShelf(active);
      }
        break;
      case 21: {
        this.hangingShelf(active);
      }
    }
  }

  createShelf(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Rect({
      x: 100,
      y: -500,
      width: 75,
      height: 8,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      draggable: active,
      params: {
        removable: false,
        type: this.coef.id_element.shelf,
        subId: 20
      }
    });
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.createShell(pos, xy, active);
    });
  }

  hangingShelf(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Rect({
      x: 100,
      y: -500,
      width: 75,
      height: 8,
      dash: [10, 5],
      stroke: 'black',
      strokeWidth: 1,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      draggable: active,
      params: {
        removable: false,
        type: this.coef.id_element.shelf,
        subId: 21

      }
    });

    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.createHangingShell(pos, xy, active);
    });
  }

  createShell(pos, xy, active, paritation?, older?, neo?, deletes?, innerShell?) {
    this.coef.countNameElement++;
    let id = false;
    if (neo) {
      id = true;
    }
    const isOnWhat = this.isOnWhat(pos, this.group, this.coef.shapesLayerw);
    let onhwats = true;
    if (isOnWhat) {
      if (isOnWhat.attrs.params.type == 9 || isOnWhat.attrs.params.type == 5) {
        onhwats = false;
      }
    }

    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    let width;
    if (contours && onhwats && this.limitAddElement(xy, this.coef.id_element.shelf, contours, id) && this.blockSelfs(contours, {
      y: xy.y,
      height: this.coef.global_size_flat
    })) {
      width = contours.right.attrs.x - contours.left.attrs.x - contours.left.attrs.width;
      let olderObject = null;
      if (older && !older.width) {
        olderObject = {
          width: width * this.coef.global_coef,
          height: this.coef.global_size_flat * this.coef.global_coef,
          depth: parseInt(this.coef.setingsWardrobe.depth) - (this.coef.spec_values['inner_depth_diff'] ? this.coef.spec_values['inner_depth_diff'] : 0)
        };
      } else if (older && older.width) {
        olderObject = older;
      }

      let removable;
      if (neo && !deletes) {
        removable = false;
      } else {
        removable = true;
      }
      const object = this.create.createElement({
        leftPosition: contours.left.attrs.x + contours.left.attrs.width,
        topPosition: xy.y,
        width: width,
        height: this.coef.global_size_flat,
        type: 'horizon',
        contours: contours,
        layer: this.coef.shapesLayerw,
        params: {
          removable: removable,
          type: this.coef.id_element.shelf,
          distance: {min: 250},
          onYou: false,
          subId: 20,
          older: olderObject,
          size: [],
          neo: neo ? neo : false,
          deletes: deletes ? deletes : false,

        },
        onYou: true,
        pos: pos
      });
      if (innerShell) {
        object.attrs.params.innerShell = true;
      }
      new Sizes(this.coef, this.create).NewVertical(contours, object);
      if (!paritation) {
        if (width >= this.coef.min_width_for_partition) {
          const ParitationX = width / 2 + contours.left.attrs.x;
          new Partition(this.coef, this.create, active.active).createFromParitation({
            evt: {
              layerX: ParitationX,
              layerY: xy.y + this.coef.global_size_flat
            }
          }, {x: ParitationX, y: xy.y + this.coef.global_size_flat});
        }
      }

      this.create.priceSend();
    } else {
      this.coef.errorTrigger('Відстані до сусідніх елементів не відповідають допустимим.');
    }
    this.coef.shapesLayerw.batchDraw();
  }

  createHangingShell(pos, xy, active, paritation?, older?) {
    if (this.coef.pramsId != 11) {
      this.coef.countNameElement++;
      const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
      let width;
      const isOnWhat = this.isOnWhat(pos, this.group, this.coef.shapesLayerw);
      let onhwats = true;
      if (isOnWhat) {
        if (isOnWhat.attrs.params.type == 9 || isOnWhat.attrs.params.type == 5) {
          onhwats = false;
        }
      }
      if (contours && onhwats && this.limitAddElement(xy, this.coef.id_element.shelf, contours)) {
        width = contours.right.attrs.x - contours.left.attrs.x - contours.left.attrs.width;
        let olderObject = null;
        if (older && !older.width) {
          olderObject = {
            width: width * this.coef.global_coef,
            height: this.coef.global_size_flat * this.coef.global_coef,
            depth: parseInt(this.coef.setingsWardrobe.depth) - (this.coef.spec_values['inner_depth_diff'] ? this.coef.spec_values['inner_depth_diff'] : 0)
          };
        }
        if (older && older.width) {
          olderObject = older;
        }

        const object = this.create.createElement({
          leftPosition: contours.left.attrs.x + contours.left.attrs.width,
          topPosition: xy.y,
          width: width,
          height: this.coef.global_size_flat,
          type: 'horizon',
          contours: contours,
          layer: this.coef.shapesLayerw,
          params: {
            removable: true,
            type: this.coef.id_element.shelf,
            distance: {min: 250},
            onYou: false,
            subId: 21,
            older: olderObject,
            handing: true,
            size: []
          },
          onYou: true,
          pos: pos
        });
        new Sizes(this.coef, this.create).NewVertical(contours, object);
        if (!paritation) {
          if (width >= this.coef.min_width_for_partition) {
            const ParitationX = width / 2 + contours.left.attrs.x;
            new Partition(this.coef, this.create, active.active).createFromParitation({
              evt: {
                layerX: ParitationX,
                layerY: xy.y + this.coef.global_size_flat
              }
            }, {x: ParitationX, y: xy.y + this.coef.global_size_flat});
          }
        }
        this.create.priceSend();
      } else {
        this.coef.errorTrigger('Відстані до сусідніх елементів не відповідають допустимим.');
      }
      this.coef.shapesLayerw.batchDraw();
    } else {
      this.coef.errorTrigger('Неможливо розмістити даний тип елементу у даному типу замовлення');
      this.coef.shapesLayerw.batchDraw();
    }
  }
}
