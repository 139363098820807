import {Component, OnInit} from '@angular/core';
import {CoefService} from '../../service/coef.service';

@Component({
  selector: 'app-two-door',
  templateUrl: './two-door.component.html',
  styleUrls: ['./two-door.component.scss']
})
export class TwoDoorComponent implements OnInit {

  constructor(public coef: CoefService) {
  }

  ngOnInit() {
  }

}
