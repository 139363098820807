import {Injectable} from '@angular/core';
import {CoefService} from './coef.service';


@Injectable({
  providedIn: 'root'
})
export class MinSizeService {
  public oldPositionX = 0;

  constructor(private coef: CoefService) {
  }

  minSizeManualTravel(parents, pos, typeTravel?) {
    let e = {...pos};
    if (pos.target.parent.nodeType === 'Group') {
      e = {target: pos.target.parent};
    }
    const parent = this.getTangetMinSize({evt: {layerX: e.target.attrs.x, layerY: e.target.attrs.y}}, e.target, parents);
    const parentfromHook = this.coef.getTangentElement({
      evt: {
        layerX: e.target.attrs.x,
        layerY: e.target.attrs.y
      }
    }, e.target, this.coef.shapesLayerw);
    if (parentfromHook['top']) {
      if (parentfromHook['top'].attrs.params.type === 3) {
        parent['top'] = parentfromHook['top'];
      }
    }

    const parentold = {...parent};
    if (parent) {
      if (parent['top'].attrs.params.type === this.coef.id_element.open_door || parent['top'].attrs.params.type === this.coef.id_element.back_wall) {
        parent['top'] = this.getTangetMinSize({
          evt: {
            layerX: parent['top'].attrs.x,
            layerY: parent['top'].attrs.y
          }
        }, parent['top'], parents)['top'];
      }
    }
    let ofset = this.getOfset(this.coef.arrayOfsset, e.target.attrs, parent, parent);
    let parentBefore;
    let parentNext;
    let ofsettNext;

    if (parent) {
      if (parent['top'].attrs.params.type === 1 || parent['top'].attrs.params.type === 4) {
        parentBefore = this.getTangetMinSize({evt: {layerX: e.target.attrs.x, layerY: e.target.attrs.y - 30}}, parent['top'], parents);
      }
      if (parent['bottom'].attrs.params.type === 2 ||
        parent['bottom'].attrs.params.type === 1 ||
        parent['bottom'].attrs.params.type === 4 ||
        parent['bottom'].attrs.params.type === 9) {
        parentNext = this.getTangetMinSize({
          evt: {
            layerX: e.target.attrs.x,
            layerY: parent['bottom'].attrs.y + 20
          }
        }, parent['bottom'], parents);
        ofsettNext = this.getOfset(this.coef.arrayOfsset, parentNext['top'].attrs, parentNext, parent);
      }


      const This = this.coef;
      const This_obj = this;
      let newY;
      const position = {newY: null};
      if (!this.checkHorizontalVertical(e.target)) {
        if (typeTravel) {
          This_obj.horizontalVlockDrag(e, This, ofset, {
            x: pos.target.attrs.x,
            y: pos.target.attrs.y
          }, parent, This_obj, position, parentBefore, parentNext);
        }
        e.target.attrs.dragBoundFunc = function (pos) {
          const horizontOfset = This_obj.bindMaxHeight(parent, e.target, ofset);
          if (e.target.attrs.params.type === 7 && e.target.attrs.params.neo) {
            ofset.bottom = This_obj.coef.spec_values['t_section_min_height'] / This_obj.coef.global_coef;
            ofset.top = This_obj.coef.spec_values['t_section_min_height'] / This_obj.coef.global_coef;
          }

          let positionTopStop = parent['top'].attrs.y + ofset.top + (parent['top'].attrs.height ? parent['top'].attrs.height : parent['top'].attrs.params.height);
          let positionBottomStop = parent['bottom'].attrs.y - ofset.bottom - (e.target.attrs.height ? e.target.attrs.height : e.target.attrs.params.height);

          if (horizontOfset.top > positionTopStop) {
            positionTopStop = horizontOfset.top;
          }
          if (horizontOfset.bottom < positionBottomStop) {
            positionBottomStop = horizontOfset.bottom;
          }

          if (pos.y < positionTopStop && pos.y < positionBottomStop) {
            newY = positionTopStop;
            if (parent['top'].attrs.params.type === 1 || parent['top'].attrs.params.type === 4) {
              newY = pos.y;
              if (parentBefore['top'].attrs.y + (ofset.bottom['top'] ? ofset.bottom['top'] : This.shellOfsetFormBottom) + e.target.attrs.height >= pos.y) {
                console.log(parentBefore['top'].attrs.y + (ofset.bottom['top'] ? ofset.bottom['top'] : This.shellOfsetFormBottom) + e.target.attrs.height,'positionBottomStop2')
                newY = parentBefore['top'].attrs.y + (ofset.bottom['top'] ? ofset.bottom['top'] : This.shellOfsetFormBottom) + e.target.attrs.height;
              }
            }
          } else {
            newY = pos.y;
          }
          if (pos.y >= positionBottomStop && pos.y > positionTopStop) {
            newY = positionBottomStop;
          }
          if (!e.target.attrs.params.stopDrags) {
            return {
              x: this.getAbsolutePosition().x,
              y: newY
            };
          }
          return {
            x: this.getAbsolutePosition().x,
            y: this.getAbsolutePosition().y
          };
        };
      } else {
        if (typeTravel) {
          this.verticalBlockDrag(e, This, ofset, {x: pos.target.attrs.x, y: pos.target.attrs.y}, parent);
        }
        e.target.attrs.dragBoundFunc = function (pos) {
          // from opend min width
          if (e.target.attrs.bind.length) {
            for (let item of e.target.attrs.bind) {
              if (item.attrs.params.type === 1) {
                if (item.attrs.params.subId === 5 || item.attrs.params.subId === 6 || item.attrs.params.subId === 30 || item.attrs.params.subId === 33) {
                  if (item.attrs.x > e.target.attrs.x) {
                    ofset.right = This.openDoor.min_section_width_for_two_doors;
                  } else if (item.attrs.x < e.target.attrs.x) {
                    ofset.left = This.openDoor.min_section_width_for_two_doors;
                  }
                }
              }
            }
          }
          // close open door min width
          const newPos = (x) => {
            return {
              x,
              y: this.getAbsolutePosition().y
            };
          };
          if (e.target.attrs.params.type === 13) {
            const centerPosition = ((This_obj.coef.widthWrapper - This_obj.coef.setingsWardrobe.width / This_obj.coef.global_coef) / 2) + This_obj.coef.global_size_flat;
            const split_width = This_obj.coef.convertCoef(This_obj.coef.min_double_split_width);
            if (split_width + centerPosition < parent['right'].attrs.x - ofset.right - e.target.attrs.width) {
              ofset.right = parent['right'].attrs.x - (split_width + centerPosition);
            }
            const rightEndPosition = centerPosition - This_obj.coef.global_size_flat + This_obj.coef.setingsWardrobe.width / This_obj.coef.global_coef;
            if (rightEndPosition - split_width > parent['left'].attrs.x + ofset.left + parent['left'].attrs.width) {
              ofset.left = rightEndPosition - split_width - parent['left'].attrs.x - e.target.attrs.width;
            }
          }
          const leftCheck = pos.x <= parent['left'].attrs.x + ofset.left + parent['left'].attrs.width;
          const rightCheck = pos.x >= parent['right'].attrs.x - ofset.right - e.target.attrs.width;
          const leftLeftToDoor = parent['right'].attrs.x - ofset.right - This.global_size_flat < This.limitLinFromDoor.left_left;
          const rightLeftToDoor = parent['right'].attrs.x - ofset.right - This.global_size_flat < This.limitLinFromDoor.right_left;
          const leftRightToDoor = parent['left'].attrs.x + ofset.left + This.global_size_flat > This.limitLinFromDoor.left_right - This.global_size_flat;
          const rightRightToDoor = parent['left'].attrs.x + ofset.left + This.global_size_flat > This.limitLinFromDoor.right_right - This.global_size_flat;

          if (e.target.attrs.bind.length) {
            for (const item of e.target.attrs.bind) {
              if (item.attrs.params.type === 1 || item.attrs.params.type === 9 || item.attrs.params.type === 5) {
                const plusChecker = (firstPart) => (item.attrs.x < This.limitLinFromDoor[`${firstPart}_left`]) && pos.x > This.limitLinFromDoor[`${firstPart}_left`];
                const minusChecker = (firstPart) => (item.attrs.x > This.limitLinFromDoor[`${firstPart}_right`] - This.global_size_flat) && pos.x < This.limitLinFromDoor[`${firstPart}_right`] - This.global_size_flat;
                const leftLeftBindCheck = plusChecker('left');
                const leftRightBindCheck = minusChecker('left');
                const rightLeftBindCheck = plusChecker('right');
                const rightRightBindCheck = minusChecker('right');

                const checkBindFunction = (firstCond, secondCond, param, side, plus) => {
                  if (firstCond && secondCond) return newPos(plus ? parent[side].attrs.x + ofset[side] + This.global_size_flat : parent[side].attrs.x - ofset[side] - This.global_size_flat);
                  return newPos(!plus ? This.limitLinFromDoor[param] : This.limitLinFromDoor[param] - This.global_size_flat);
                };
                if (rightRightBindCheck) return checkBindFunction(leftCheck, rightRightToDoor, 'right_right', 'left', true);
                if (leftRightBindCheck) return checkBindFunction(leftCheck, leftRightToDoor, 'left_right', 'left', true);
                if (leftLeftBindCheck) return checkBindFunction(rightCheck, leftLeftToDoor, 'left_left', 'right', false);
                if (rightLeftBindCheck) return checkBindFunction(rightCheck, rightLeftToDoor, 'right_left', 'right', false);

              }
            }
          }
          if (leftCheck) {
            return newPos(parent['left'].attrs.x + ofset.left + parent['left'].attrs.width);
          }
          if (rightCheck) {
            return newPos(parent['right'].attrs.x - ofset.right - e.target.attrs.width);
          }
          return newPos(pos.x);
        };
      }
    }
  }

  minsize(parents) {
    parents.on('dragmove', (pos) => {
      this.minSizeManualTravel(parents, pos);
    });
  }

  getTangetMinSize(pos, mainElement?, parents?) {
    let elemLeft;
    let elemRight;
    let elemTop;
    let elemBottom;
    let left = 0;
    let top = 0;
    let right = 99999;
    let bottom = 99999;
    const positionElementX = pos.evt.layerX;
    const positionElementY = pos.evt.layerY;
    for (const element of parents.children) {
      if (this.checkHorizontalVertical(element)) {
        if (element.attrs.name !== mainElement.attrs.name) {
          if (element.attrs.params.type === this.coef.id_element.partition || element.attrs.params.type === this.coef.id_element.lateral_wall || element.attrs.params.type === 13) {
            if (mainElement.attrs.y >= element.attrs.y && (mainElement.attrs.height ? mainElement.attrs.height : mainElement.attrs.params.height) + mainElement.attrs.y < element.attrs.y + (element.attrs.height ? element.attrs.height : element.attrs.params.height)) {
              if (positionElementX > element.attrs.x && element.attrs.x > left) {
                left = element.attrs.x;
                elemLeft = element;
              }
              if (positionElementX < element.attrs.x && element.attrs.x < right) {
                right = element.attrs.x;
                elemRight = element;
              }
            } else if (mainElement.attrs.y <= element.attrs.y && (mainElement.attrs.height ? mainElement.attrs.height : mainElement.attrs.params.height) + mainElement.attrs.y > (element.attrs.height ? element.attrs.height : element.attrs.params.height)) {
              if (positionElementX > element.attrs.x && element.attrs.x > left) {
                left = element.attrs.x;
                elemLeft = element;
              }
              if (positionElementX < element.attrs.x && element.attrs.x < right) {
                right = element.attrs.x;
                elemRight = element;
              }
            } else if (mainElement.attrs.y > element.attrs.y && mainElement.attrs.y < element.attrs.y + (element.attrs.height ? element.attrs.height : element.attrs.params.height)) {
              if (positionElementX > element.attrs.x && element.attrs.x > left) {
                left = element.attrs.x;
                elemLeft = element;
              }
              if (positionElementX < element.attrs.x && element.attrs.x < right) {
                right = element.attrs.x;
                elemRight = element;
              }
            }
          }
        }
      } else {
        if (element.attrs.name !== mainElement.attrs.name) {
          if (element.attrs.params.type === this.coef.id_element.shelf || element.attrs.params.type === this.coef.id_element.bottom_wall || element.attrs.params.type === this.coef.id_element.top_wall) {
            if (mainElement.attrs.x <= element.attrs.x && mainElement.attrs.width + mainElement.attrs.x > element.attrs.x) {
              if (positionElementY > element.attrs.y && element.attrs.y > top) {
                top = element.attrs.y;
                elemTop = element;
              }
              if (positionElementY < element.attrs.y && element.attrs.y < bottom) {
                bottom = element.attrs.y;
                elemBottom = element;
              }
            } else {
              if (mainElement.attrs.x > element.attrs.x && mainElement.attrs.x < element.attrs.x + element.attrs.width) {
                if (positionElementY > element.attrs.y && element.attrs.y > top) {
                  top = element.attrs.y;
                  elemTop = element;
                }
                if (positionElementY < element.attrs.y && element.attrs.y < bottom) {
                  bottom = element.attrs.y;
                  elemBottom = element;
                }
              }
            }
          }
        }
      }
    }
    if (elemLeft && elemRight && elemTop && elemBottom) {
      return {top: elemTop, right: elemRight, bottom: elemBottom, left: elemLeft};
    } else {
      return false;
    }
  }

  getTangetMinSizeNOShell(pos, mainElement?, parents?) {
    let elemLeft;
    let elemRight;
    let elemTop;
    let elemBottom;
    let left = 0;
    let top = 0;
    let right = 99999;
    let bottom = 99999;
    const positionElementX = pos.evt.layerX;
    const positionElementY = pos.evt.layerY;
    for (const element of parents.children) {
      if (this.checkHorizontalVertical(element)) {
        if (element.attrs.name !== mainElement.attrs.name) {
          if (element.attrs.params.type === this.coef.id_element.partition || element.attrs.params.type === this.coef.id_element.lateral_wall) {
            if (mainElement.attrs.y >= element.attrs.y && (mainElement.attrs.height ? mainElement.attrs.height : mainElement.attrs.params.height) + mainElement.attrs.y < element.attrs.y + element.attrs.height) {
              if (positionElementX > element.attrs.x && element.attrs.x > left) {
                left = element.attrs.x;
                elemLeft = element;
              }
              if (positionElementX < element.attrs.x && element.attrs.x < right) {
                right = element.attrs.x;
                elemRight = element;
              }
            } else if (mainElement.attrs.y <= element.attrs.y && (mainElement.attrs.height ? mainElement.attrs.height : mainElement.attrs.params.height) + mainElement.attrs.y > element.attrs.y) {
              if (positionElementX > element.attrs.x && element.attrs.x > left) {
                left = element.attrs.x;
                elemLeft = element;
              }
              if (positionElementX < element.attrs.x && element.attrs.x < right) {
                right = element.attrs.x;
                elemRight = element;
              }
            } else if (mainElement.attrs.y > element.attrs.y && mainElement.attrs.y < element.attrs.y + element.attrs.height) {
              if (positionElementX > element.attrs.x && element.attrs.x > left) {
                left = element.attrs.x;
                elemLeft = element;
              }
              if (positionElementX < element.attrs.x && element.attrs.x < right) {
                right = element.attrs.x;
                elemRight = element;
              }
            }
          }
        }
      } else {
        if (element.attrs.name !== mainElement.attrs.name) {
          if (element.attrs.params.type === this.coef.id_element.trumpet || element.attrs.params.type === this.coef.id_element.pantograph || element.attrs.params.type === this.coef.id_element.retractable_pipe) {
            if (mainElement.attrs.x <= element.attrs.x && mainElement.attrs.width + mainElement.attrs.x > element.attrs.x) {
              if (positionElementY > element.attrs.y && element.attrs.y > top) {
                top = element.attrs.y;
                elemTop = element;
              }
              if (positionElementY < element.attrs.y && element.attrs.y < bottom) {
                bottom = element.attrs.y;
                elemBottom = element;
              }
            } else {
              if (mainElement.attrs.x > element.attrs.x && mainElement.attrs.x < element.attrs.x + element.attrs.width) {
                if (positionElementY > element.attrs.y && element.attrs.y > top) {
                  top = element.attrs.y;
                  elemTop = element;
                }
                if (positionElementY < element.attrs.y && element.attrs.y < bottom) {
                  bottom = element.attrs.y;
                  elemBottom = element;
                }
              }
            }
          }
        }
      }
    }
    if (elemLeft && elemRight || elemTop || elemBottom) {
      return {top: elemTop, right: elemRight, bottom: elemBottom, left: elemLeft};
    } else {
      return false;
    }
  }

  getOfset(array, element, parent, paren_old?) {
    const objectOfset = {
      top: null,
      bottom: null,
      left: null,
      right: null
    };
    for (const items of array) {
      if (element.params.type === items.id) {
        for (const item of items.offsets) {
          if (item.target_id === parent['left'].attrs.params.type) {
            objectOfset.left = this.bindMinWidth(element, array, {right: item.bottom_right, left: item.top_left}, {
              left: parent['left'],
              right: parent['right']
            }).left;
          }
          if (item.target_id === parent['right'].attrs.params.type) {
            objectOfset.right = this.bindMinWidth(element, array, {left: item.top_left, right: item.bottom_right}, {
              left: parent['left'],
              right: parent['right']
            }).right;
          }
          if (item.target_id === parent['top'].attrs.params.type) {
            objectOfset.top = item.top_left / this.coef.global_coef;
          }
          if (item.target_id === parent['bottom'].attrs.params.type) {
            objectOfset.bottom = item.bottom_right / this.coef.global_coef;
          }
        }
      }
    }
    return objectOfset;
  }

  bindMinWidth(element, items, ofset, parent) {
    const min_width = {
      left: ofset.left,
      right: ofset.right
    };
    for (const bind of element.bind) {
      for (const item of  items) {
        if (element.x > bind.attrs.x + bind.attrs.width / 2) {
          if (bind.attrs.bind.length === 0) {
            if (item.id === bind.attrs.params.type) {
              let max_width = (parent.right.attrs.x - parent.left.attrs.x - parent.left.attrs.width) - item.max_width / this.coef.global_coef;
              if (max_width) {
                max_width = (max_width - this.coef.global_size_flat) * this.coef.global_coef;
                if (max_width > min_width.right) {
                  min_width.right = max_width;
                }
              } else {
                if (item.spec_values) {
                  if (item.spec_values.max_section_width_for_two_doors) {
                    if (bind.attrs.params.subId === 5 || bind.attrs.params.subId === 6 || bind.attrs.params.subId === 30 || bind.attrs.params.subId === 33) {
                      max_width = (parent.right.attrs.x - parent.left.attrs.x - parent.left.attrs.width) - item.spec_values.max_section_width_for_two_doors / this.coef.global_coef;
                      if (max_width) {
                        max_width = (max_width - this.coef.global_size_flat) * this.coef.global_coef;
                      }
                      if (max_width > min_width.right) {
                        min_width.right = max_width;
                      }
                    }
                    if (bind.attrs.params.subId !== 5 && bind.attrs.params.subId !== 6 && bind.attrs.params.subId !== 30 && bind.attrs.params.subId !== 33) {
                      max_width = (parent.right.attrs.x - parent.left.attrs.x - parent.left.attrs.width) - item.spec_values.max_section_width_for_one_door / this.coef.global_coef;
                      if (max_width) {
                        max_width = (max_width - this.coef.global_size_flat) * this.coef.global_coef;
                      }
                      if (max_width > min_width.right) {
                        min_width.right = max_width;
                      }
                    }
                  }
                }
              }
              if (item.min_width > min_width.left) {
                min_width.left = item.min_width;
              }
            }
          }
        } else if (element.x < bind.attrs.x + bind.attrs.width / 2) {
          if (bind.attrs.bind.length === 0) {
            if (item.id === bind.attrs.params.type) {
              let max_width = (parent.right.attrs.x - parent.left.attrs.x - parent.left.attrs.width) - item.max_width / this.coef.global_coef;
              if (max_width) {
                max_width = (max_width - this.coef.global_size_flat) * this.coef.global_coef;
                if (max_width > min_width.left) {
                  min_width.left = max_width;
                }
              } else {
                if (item.spec_values) {
                  if (item.spec_values.max_section_width_for_two_doors) {
                    if (bind.attrs.params.subId === 5 || bind.attrs.params.subId === 6 || bind.attrs.params.subId === 30 || bind.attrs.params.subId === 33) {
                      max_width = (parent.right.attrs.x - parent.left.attrs.x - parent.left.attrs.width) - item.spec_values.max_section_width_for_two_doors / this.coef.global_coef;
                      if (max_width) {
                        max_width = (max_width - this.coef.global_size_flat) * this.coef.global_coef;
                      }
                      if (max_width > min_width.left) {
                        min_width.left = max_width;
                      }
                    }
                    if (bind.attrs.params.subId !== 5 && bind.attrs.params.subId !== 6  && bind.attrs.params.subId !== 30 && bind.attrs.params.subId !== 33) {
                      max_width = (parent.right.attrs.x - parent.left.attrs.x - parent.left.attrs.width) - item.spec_values.max_section_width_for_one_door / this.coef.global_coef;
                      if (max_width) {
                        max_width = (max_width - this.coef.global_size_flat) * this.coef.global_coef;
                      }
                      if (max_width > min_width.left) {
                        min_width.left = max_width;
                      }
                    }
                  }
                }
              }
              if (item.min_width > min_width.right) {
                min_width.right = item.min_width;
              }
            }
          }
        }
      }
    }
    min_width.left = min_width.left / this.coef.global_coef;
    min_width.right = min_width.right / this.coef.global_coef;
    return min_width;
  }

  checkHorizontalVertical(elements) {
    switch (elements.attrs.params.type) {
      case 1:
        return false;
      case 3:
        return false;
      case 4:
        return false;
      case this.coef.id_element.pantograph:
        return false;
      case this.coef.id_element.partition:
        return true;
      case this.coef.id_element.shelf:
        return false;
      case this.coef.id_element.trumpet:
        return false;
      case this.coef.id_element.lateral_wall:
        return true;
      case this.coef.id_element.top_wall:
        return false;
      case this.coef.id_element.bottom_wall:
        return false;
      case this.coef.id_element.double_partition:
        return true;
      default:
        return false;
    }
  }

  verticalBlockDrag(e, This, ofset, pos, parent) {
    // from opend min width
    if (e.target.attrs.bind.length) {
      for (let item of e.target.attrs.bind) {
        if (item.attrs.params.type === 1) {
          if (item.attrs.params.subId === 5 || item.attrs.params.subId === 6 || item.attrs.params.subId === 30 || item.attrs.params.subId === 33) {
            if (item.attrs.x > e.target.attrs.x) {
              ofset.right = This.openDoor.min_section_width_for_two_doors;
            } else if (item.attrs.x < e.target.attrs.x) {
              ofset.left = This.openDoor.min_section_width_for_two_doors;
            }
          }

        }
      }
    }
    if (e.target.attrs.params.type === 13) {

      // ofset.right = This.openDoor.min_section_width_for_two_doors;
    }
    // close open door min width
    const newPos = (x) => {
      if (e.target) {
        e.target.x(x);
      }
      return {};
    };
    const leftCheck = pos.x <= parent['left'].attrs.x + ofset.left + This.global_size_flat;
    const rightCheck = pos.x >= parent['right'].attrs.x - ofset.right - This.global_size_flat;
    const leftLeftToDoor = parent['right'].attrs.x - ofset.right - This.global_size_flat < This.limitLinFromDoor.left_left;
    const rightLeftToDoor = parent['right'].attrs.x - ofset.right - This.global_size_flat < This.limitLinFromDoor.right_left;
    const leftRightToDoor = parent['left'].attrs.x + ofset.left + This.global_size_flat > This.limitLinFromDoor.left_right - This.global_size_flat;
    const rightRightToDoor = parent['left'].attrs.x + ofset.left + This.global_size_flat > This.limitLinFromDoor.right_right - This.global_size_flat;

    if (e.target.attrs.bind.length) {
      for (const item of e.target.attrs.bind) {
        if (item.attrs.params.type === 1 || item.attrs.params.type === 9 || item.attrs.params.type === 5) {
          const plusChecker = (firstPart) => (item.attrs.x < This.limitLinFromDoor[`${firstPart}_left`]) && pos.x > This.limitLinFromDoor[`${firstPart}_left`];
          const minusChecker = (firstPart) => (item.attrs.x > This.limitLinFromDoor[`${firstPart}_right`] - This.global_size_flat) && pos.x < This.limitLinFromDoor[`${firstPart}_right`] - This.global_size_flat;
          const leftLeftBindCheck = plusChecker('left');
          const leftRightBindCheck = minusChecker('left');
          const rightLeftBindCheck = plusChecker('right');
          const rightRightBindCheck = minusChecker('right');

          const checkBindFunction = (firstCond, secondCond, param, side, plus) => {
            if (firstCond && secondCond) return newPos(plus ? parent[side].attrs.x + ofset[side] + This.global_size_flat : parent[side].attrs.x - ofset[side] - This.global_size_flat);
            return newPos(!plus ? This.limitLinFromDoor[param] : This.limitLinFromDoor[param] - This.global_size_flat);
          };
          if (rightRightBindCheck) return checkBindFunction(leftCheck, rightRightToDoor, 'right_right', 'left', true);
          if (leftRightBindCheck) return checkBindFunction(leftCheck, leftRightToDoor, 'left_right', 'left', true);
          if (leftLeftBindCheck) return checkBindFunction(rightCheck, leftLeftToDoor, 'left_left', 'right', false);
          if (rightLeftBindCheck) return checkBindFunction(rightCheck, rightLeftToDoor, 'right_left', 'right', false);

        }
      }
    }
    if (leftCheck) {
      return newPos(parent['left'].attrs.x + ofset.left + This.global_size_flat);
    }
    if (rightCheck) {
      return newPos(parent['right'].attrs.x - ofset.right - This.global_size_flat);
    }
    return newPos(pos.x);
  }

  horizontalVlockDrag(e, This, ofset, pos, parent, This_obj, newY, parentBefore, parentNext) {
    const horizontOfset = This_obj.bindMaxHeight(parent, e.target, ofset);
    if (e.target.attrs.params.type === 7 && e.target.attrs.params.neo) {

    }
    let positionTopStop = parent['top'].attrs.y + ofset.top + (parent['top'].attrs.height ? parent['top'].attrs.height : parent['top'].attrs.params.height);
    let positionBottomStop = parent['bottom'].attrs.y - ofset.bottom - (e.target.attrs.height ? e.target.attrs.height : e.target.attrs.params.height);
    if (horizontOfset.top > positionTopStop) {
      positionTopStop = horizontOfset.top;
    }
    if (horizontOfset.bottom < positionBottomStop) {
      positionBottomStop = horizontOfset.bottom;
    }

    if (pos.y < positionTopStop && pos.y < positionBottomStop) {
      newY.newY = positionTopStop;
      if (parent['top'].attrs.params.type === 1 || parent['top'].attrs.params.type === 4) {
        newY.newY = pos.y;
        if (parentBefore['top'].attrs.y + (ofset.bottom['top'] ? ofset.bottom['top'] : This.shellOfsetFormBottom) + e.target.attrs.height >= pos.y) {
          newY.newY = parentBefore['top'].attrs.y + (ofset.bottom['top'] ? ofset.bottom['top'] : This.shellOfsetFormBottom) + e.target.attrs.height;
        }
      }
    } else {
      newY.newY = pos.y;
    }
    if (pos.y >= positionBottomStop && pos.y > positionTopStop) {
      newY.newY = positionBottomStop;
    }
    if (!e.target.attrs.params.stopDrags) {
      e.target.y(newY.newY);
    }
    if (newY.newY === pos.y) {
      e.target.y(newY.newY);
    }
  }

  bindMaxHeight(parent, elements, ofset) {
    if (ofset) {
      const max = {
        top: this.coef.topPositaionWrapper,
        bottom: 9999,
        offsetTop: ofset.top,
        offsetBottom: ofset.bottom
      };
      const noSell = this.getTangetMinSizeNOShell({
        evt: {
          layerX: elements.attrs.x,
          layerY: elements.attrs.y
        }
      }, elements, this.coef.shapesLayerw);
      for (const bind of elements.attrs.bind) {
        if (bind.attrs.params.type === this.coef.id_element.open_door) {
          if (bind.attrs.y > elements.attrs.y) {
            const maxTopStop = bind.attrs.params.contours['bottom'].attrs.y - this.coef.openDoor.max_height - this.coef.global_size_flat;
            if (maxTopStop > max.top) {
              max.top = maxTopStop;
            }
          } else {
            const maxBottomStop = bind.attrs.params.contours['top'].attrs.y + this.coef.openDoor.max_height + this.coef.global_size_flat;
            if (maxBottomStop < max.bottom) {
              max.bottom = maxBottomStop;
            }
          }
        }
        if (bind.attrs.params.type === this.coef.id_element.back_wall) {
          if (bind.attrs.y > elements.attrs.y) {
            const maxBottomStop = bind.attrs.params.contours['bottom'].attrs.y - this.coef.back_wall_min_height - this.coef.global_size_flat;
            if (maxBottomStop < max.bottom) {
              max.bottom = maxBottomStop;
              const bottomParent = this.getTangetMinSize({
                evt: {
                  layerX: bind.attrs.params.contours['top'].attrs.x,
                  layerY: bind.attrs.params.contours['top'].attrs.y
                }
              }, bind.attrs.params.contours['top'], this.coef.shapesLayerw);
              if (bottomParent['bottom'].attrs.params.type === this.coef.id_element.open_door || bottomParent['bottom'].attrs.params.type === this.coef.id_element.back_wall) {
                bottomParent['bottom'] = bottomParent['bottom'].attrs.params.contours['bottom'];
              }
              max.offsetBottom = bind.attrs.params.contours['bottom'].attrs.y - bottomParent['top'].attrs.y - (maxBottomStop - bottomParent['top'].attrs.y + this.coef.global_size_flat);
            }
          } else {
            const maxTopStop = bind.attrs.params.contours['top'].attrs.y + this.coef.back_wall_min_height + this.coef.global_size_flat;
            if (maxTopStop > max.top) {
              max.top = maxTopStop;
            }
          }
        }
        if (bind.attrs.params.type === this.coef.id_element.drawer) {
          if (bind.attrs.y > elements.attrs.y) {
            if (elements.attrs.params.drawer) {
              if (elements.attrs.params.drawer.topMinY > max.top) {
                max.top = elements.attrs.params.drawer.topMinY;
              }
              const lengthArr = elements.attrs.params.drawerArray.length;
              const bottomParentDrawer = this.getTangetMinSize({
                evt: {
                  layerX: elements.attrs.params.drawerArray[lengthArr - 1].attrs.x + 5,
                  layerY: elements.attrs.params.drawerArray[lengthArr - 1].attrs.y + 5
                }
              }, elements.attrs.params.drawerArray[lengthArr - 1], this.coef.shapesLayerw);
              let size = 0;
              if (bottomParentDrawer['bottom'].attrs.params.type !== elements.attrs.params.drawer.elementBottom.attrs.params.type && bottomParentDrawer['bottom'].attrs.params.type === 11) {
                size = this.coef.drawer.bottomSectionDiff;
              }
              if (bottomParentDrawer['bottom'].attrs.params.type !== elements.attrs.params.drawer.elementBottom.attrs.params.type && bottomParentDrawer['bottom'].attrs.params.type === 7) {
                size = -this.coef.drawer.bottomSectionDiff;
              }
              const stopPositionDrawer = bottomParentDrawer['bottom'].attrs.y - (elements.attrs.params.drawer.topStopDrag + size) - this.coef.global_size_flat;
              if (stopPositionDrawer < max.bottom) {
                max.bottom = stopPositionDrawer;
              }
            }
          }
        }
        if (bind.attrs.params.type === this.coef.id_element.partition) {
          if (bind.attrs.y > elements.attrs.y) {
            if (bind.attrs.params.contours['bottom'].attrs.params.type === this.coef.id_element.bottom_wall) {
              max.top = 0;
            }
            if (bind.attrs.params.contours['bottom'].attrs.params.type === this.coef.id_element.shelf) {
              const maxTopStop = bind.attrs.params.contours['bottom'].attrs.y - this.coef.max_hanging_height - this.coef.global_size_flat;
              if (maxTopStop > max.top) {
                max.top = maxTopStop;
              }
            }

            if (elements['attrs'].params.neo) {
              const maxTopStopneo = bind.attrs.params.contours['bottom'].attrs.y - this.coef.spec_values['t_section_max_height'] / this.coef.global_coef - this.coef.global_size_flat;
              max.top = maxTopStopneo;
              const maxStop = this.coef.topPositaionWrapper + this.coef.global_size_flat + this.coef.spec_values['middle_section_to_top_flat_size'] / this.coef.global_coef;
              if (maxStop > max.top) {
                max.top = maxStop;
              }
            }
          } else {
            const maxBottomStop = bind.attrs.params.contours['top'].attrs.y + this.coef.max_hanging_height + this.coef.global_size_flat;
            if (maxBottomStop < max.bottom) {
              max.bottom = maxBottomStop;
            }
            if (elements['attrs'].params.neo) {
              const maxBottomStopNeo = bind.attrs.params.contours['top'].attrs.y + this.coef.spec_values['t_section_max_height'] / this.coef.global_coef + this.coef.global_size_flat;
              if (maxBottomStopNeo < max.bottom) {
                max.bottom = maxBottomStopNeo;
              }
            }
          }
        }
      }
      if (noSell) {
        if (noSell.bottom && noSell.bottom.attrs.params.type === this.coef.id_element.trumpet) {

          let maxBottomStop = noSell.bottom.attrs.y - this.coef.ofset_trumpet.top - this.coef.global_size_flat;
          if (elements.attrs.params.type == 8) {
            maxBottomStop = noSell.bottom.attrs.y - this.coef.ofset_trumpet.bottom - this.coef.global_size_flat;
          }
          if (maxBottomStop < max.bottom) {
            max.bottom = maxBottomStop;
          }
        }
        if (noSell.top && noSell.top.attrs.params.type === this.coef.id_element.trumpet) {
          const maxTopStop = noSell.top.attrs.y + this.coef.ofset_trumpet.bottom + noSell.top.attrs.params.height;
          if (maxTopStop > max.top) {
            max.top = maxTopStop;
          }
        }
        if (noSell.bottom && noSell.bottom.attrs.params.type === this.coef.id_element.pantograph) {
          const maxBottomStop = noSell.bottom.attrs.y - this.coef.ofset_pantograph.top - this.coef.global_size_flat;
          if (maxBottomStop < max.bottom) {
            max.bottom = maxBottomStop;
          }
        }
        if (noSell.top && noSell.top.attrs.params.type === this.coef.id_element.pantograph) {
          const maxTopStop = noSell.top.attrs.y + this.coef.ofset_pantograph.bottom + noSell.top.attrs.params.height;
          if (maxTopStop > max.top) {
            max.top = maxTopStop;
          }
        }
        if (noSell.top && noSell.top.attrs.params.type === this.coef.id_element.retractable_pipe) {
          const maxTopStop = noSell.top.attrs.y + this.coef.ofset_retractable_pipe.bottom + noSell.top.attrs.params.height;
          if (maxTopStop > max.top) {
            max.top = maxTopStop;
          }
        }
        if (noSell.bottom && noSell.bottom.attrs.params.type === this.coef.id_element.retractable_pipe) {
          const parentPipe = this.getTangetMinSize({
            evt: {
              layerX: noSell.bottom.attrs.x,
              layerY: noSell.bottom.attrs.y
            }
          }, noSell.bottom, this.coef.shapesLayerw);
          const maxBottomStop = parentPipe['bottom'].attrs.y - this.coef.ofset_retractable_pipe.bottom - noSell.bottom.attrs.params.height - this.coef.global_size_flat;
          if (maxBottomStop < max.bottom) {
            max.bottom = maxBottomStop;
          }
        }
        if (parent['top'].attrs.params && parent['top'].attrs.params.drawer && parent['top'].attrs.params.drawerArray) {
          let sideSpace = this.coef.drawer.sideSpace;
          let drawHeight = this.coef.drawer.drawHeight;
          if(parent['top'].attrs.params.drawerArray[0].attrs.params.subId == 26 || parent['top'].attrs.params.drawerArray[0].attrs.params.subId == 27){
            sideSpace = this.coef.drawer.sideSpaceSpec;
            drawHeight  = this.coef.drawer.drawHeightSpec;
          }
          const sizeDrawer = parent['top'].attrs.params.drawerArray.length * drawHeight + sideSpace;
          const topStop = parent['top'].attrs.y + parent['top'].attrs.height + sizeDrawer;
          if (topStop > max.top) {
            max.top = topStop;
          }
        }
      }
      return max;
    }
  }

}
