import * as Konva from '../../../assets/konva';

export class Photo {
  photo;
  params;

  constructor(params) {
    this.params = params;
  }


  create(src, id, params) {
    const imageObj = new Image();
    return new Promise((resolve, reject) => {
      console.log(params, id, src,'photoooo');
      imageObj.onload = () => {
        this.photo = new Konva.Image({
          x: this.params.x,
          y: this.params.y,
          image: imageObj,
          width: this.params.width,
          height: this.params.height,
          params: id,
          strokeWidth: 1,
          stroke: 'black'
        });
        this.photo.attrs.params.subId = id.subId;
        this.photo.attrs.params.type = id.type;
        this.photo.attrs.params.index = id.index;
        this.photo.attrs.params.id = params.parentId;
        this.photo.attrs.params.position = params.idElement;
        this.photo.attrs.params.typeDoor = params.item.id;
        this.photo.attrs.params.remove = true;
        this.photo.attrs.params.price = true;
        resolve(this.photo);
      };
      imageObj.crossOrigin = 'Anonymous';
      imageObj.src = src;
    });
  }
}
