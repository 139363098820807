import {Component, OnInit} from '@angular/core';
import {CoefService} from '../../service/coef.service';

@Component({
  selector: 'app-corner-ending',
  templateUrl: './corner-ending.component.html',
  styleUrls: ['./corner-ending.component.scss']
})
export class CornerEndingComponent implements OnInit {


  constructor(public coef: CoefService) {
  }

  ngOnInit() {
  }

}
