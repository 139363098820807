import {Injectable} from '@angular/core';
import {CoefDoorService} from './coef-door.service';
import {CoefService} from '../coef.service';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class DragService {

  constructor(private coefDoor: CoefDoorService, private coef: CoefService) {

  }
changeSizeH(size,element,TopBind? , item?){
  if (size) {
    if(TopBind){
      size.y(TopBind.y() + TopBind.height());
    }
    size.attrs.params.height = (element.height());
    size.children[1].height((size.attrs.height ? size.attrs.height : size.attrs.params.height));
    size.children[2].y((size.attrs.height ? size.attrs.height : size.attrs.params.height) / 2 + 14);
    size.children[2].text(JSON.stringify(_.round((size.attrs.height ? size.attrs.height : size.attrs.params.height) * this.coef.global_coef , 1)));
    size.children[0].y((size.attrs.height ? size.attrs.height : size.attrs.params.height) / 2 + 14);
    size.children[0].width(size.children[2].getWidth());
    size.children[4].attrs.points = [
      -3, size['attrs'].params.height - 10,
      -0, size['attrs'].params.height - 0,
      3, size['attrs'].params.height - 10,
      -3, size['attrs'].params.height - 10
    ];
    if(item &&TopBind){
      item.attrs.params.text.y(TopBind.y() + TopBind.height() + 6);
      item.attrs.params.bgText.y(TopBind.y() + TopBind.height() + 6);
    }
  }
}
  bindElement(bg, simpleText, element, BottomBind?, TopBind?, bottomY?, size?) {
    if (TopBind) {
      TopBind.on('dragmove', (pos) => {
        element.y(TopBind.y() + TopBind.height());
        element.attrs.params.texturedH.y(TopBind.y() + TopBind.height())
        element.attrs.params.filter.y(TopBind.y() + TopBind.height())
        element.attrs.params.texturedV.y(TopBind.y() + TopBind.height())
        if(TopBind.attrs.params.bottomElem){
          for(const item of TopBind.attrs.params.bottomElem){
            item.y(TopBind.y() + TopBind.height());
            if(item.attrs.params.texturedH){
              item.attrs.params.texturedH.y(TopBind.y() + TopBind.height())
            }
            if(item.attrs.params.filter){
              item.attrs.params.filter.y(TopBind.y() + TopBind.height())
            }
            if(item.attrs.params.texturedV){
              item.attrs.params.texturedV.y(TopBind.y() + TopBind.height())
            }
            
            if(item.attrs.params.sizeH){
              this.changeSizeH(item.attrs.params.sizeH,element,TopBind  , item)
            }
          }
        }
        simpleText.y(TopBind.y() + TopBind.height() + 10);
        bg.y(TopBind.y() + TopBind.height() + 10);
        this.changeSizeH(size,element,TopBind)
        if (BottomBind) {
          element.height(BottomBind.y() - TopBind.y() - TopBind.height());
          if(element.attrs.params.texturedV){
            element.attrs.params.texturedV.height(BottomBind.y() - TopBind.y() - TopBind.height())
          }
          if(element.attrs.params.filter){
            element.attrs.params.filter.height(BottomBind.y() - TopBind.y() - TopBind.height())
          }
          if(TopBind.attrs.params.bottomElem){
            for(const item of TopBind.attrs.params.bottomElem){
              if(item.attrs.params.texturedV){
                item.attrs.params.texturedV.height(BottomBind.y() - TopBind.y() - TopBind.height())
              }
              if(item.attrs.params.filter){
                item.attrs.params.filter.height(BottomBind.y() - TopBind.y() - TopBind.height())
              }
              item.height(BottomBind.y() - TopBind.y() - TopBind.height())
            }
          }
         
        } else {
          
          element.height(bottomY - TopBind.y() - TopBind.height());
          if(element.attrs.params.texturedV){
            element.attrs.params.texturedV.height(bottomY - TopBind.y() - TopBind.height())
          }
          if(element.attrs.params.filter){
            element.attrs.params.filter.height(bottomY - TopBind.y() - TopBind.height())
          }
          if(TopBind.attrs.params.bottomElem){
            for(const item of TopBind.attrs.params.bottomElem){
              if(item.attrs.params.texturedV){
                item.attrs.params.texturedV.height(bottomY - TopBind.y() - TopBind.height())
              }
              if(item.attrs.params.filter){
                item.attrs.params.filter.height(bottomY - TopBind.y() - TopBind.height())
              }
              item.height(bottomY - TopBind.y() - TopBind.height())
            }
          }
        }
      });
    }
    if (BottomBind) {
      BottomBind.on('dragmove', (pos) => {
        element.height(BottomBind.y() - element.y());
        if(element.attrs.params.texturedV){
          element.attrs.params.texturedV.height(BottomBind.y() - element.y())
        }
        if(element.attrs.params.filter){
          element.attrs.params.filter.height(BottomBind.y() - element.y())
        }
        this.changeSizeH(element.attrs.params.sizeH,element,TopBind)
        if(BottomBind.attrs.params.topElem){
          for(const item of BottomBind.attrs.params.topElem){
            if(item.attrs.params.texturedV){
              item.attrs.params.texturedV.height(BottomBind.y() - element.y())
            }
            if(item.attrs.params.filter){
              item.attrs.params.filter.height(BottomBind.y() - element.y())
            }
            item.height(BottomBind.y() - element.y());
            if(item.attrs.params.sizeV){
              item.attrs.params.sizeV.y( element.y() + element.height() -10);
              if(item.attrs.params.sizeH){
                if (TopBind) { 
                  this.changeSizeH(item.attrs.params.sizeH,element,TopBind)
                } else {
                  this.changeSizeH(item.attrs.params.sizeH,element)
                }
              }
            }
          }
        }
        if(element.attrs.params.sizeV){
          element.attrs.params.sizeV.y( element.y() + element.height() -10);
        }
        if (size) {
          if (TopBind) { 
            this.changeSizeH(size,element,TopBind)
          } else {
            this.changeSizeH(size,element)
          }
        }
      });
    }
  }




changeSizeEleme(element, x , type){
  if(element.attrs.params.sizeV) {
    const size = element.attrs.params.sizeV;
    size.width(element.width())
    if(type){
      size.x(x)
    }
    size.children[0].width(element.width())
    size.children[4].attrs.points =  [
      element.width() - 10, -3,
      element.width() - 0, 0,
      element.width() - 10, 3,
      element.width() - 10, -3
    ];
    size.children[1].x(element.width() / 2 - 15);
    size.children[2].x(element.width() / 2 - 15);
    size.children[2].text(JSON.stringify(_.round(element.width() * this.coef.global_coef, 1)));
    if(type){
      element.attrs.params.text.x(x + 6);
      element.attrs.params.bgText.x(x + 6);
    }
   
}
}

  bindElementV(bg, simpleText, element, rightBind?, leftBind?, bottomY?) {
    if (leftBind) {
      leftBind.on('dragmove', (pos) => {
        element.x(leftBind.attrs.x + leftBind.attrs.width);
        element.attrs.params.texturedH.x(leftBind.attrs.x + leftBind.attrs.width)
        element.attrs.params.filter.x(leftBind.attrs.x + leftBind.attrs.width)
        element.attrs.params.texturedV.x(leftBind.attrs.x + leftBind.attrs.width)
    
        if(leftBind.attrs.params.rightElem){
          for(const item of leftBind.attrs.params.rightElem){
            item.x(leftBind.attrs.x + leftBind.attrs.width);
            if (item.attrs.params.texturedH)item.attrs.params.texturedH.x(leftBind.attrs.x + leftBind.attrs.width)
            if (item.attrs.params.filter)item.attrs.params.filter.x(leftBind.attrs.x + leftBind.attrs.width)
            if (item.attrs.params.texturedV)item.attrs.params.texturedV.x(leftBind.attrs.x + leftBind.attrs.width)
            this.changeSizeEleme(item , leftBind.attrs.x + leftBind.attrs.width , true)
          }
        }
      
        if (rightBind) {
          element.width(rightBind.attrs.x - leftBind.attrs.x - leftBind.attrs.width);
          if(element.attrs.params.texturedH){
            element.attrs.params.texturedH.width(rightBind.attrs.x - leftBind.attrs.x - leftBind.attrs.width)
          }
          if(element.attrs.params.filter){
            element.attrs.params.filter.width(rightBind.attrs.x - leftBind.attrs.x - leftBind.attrs.width)
          }
          if(leftBind.attrs.params.rightElem){
            for(const item of leftBind.attrs.params.rightElem){
              item.width(rightBind.attrs.x - leftBind.attrs.x - leftBind.attrs.width);
              if(item.attrs.params.texturedH){
                item.attrs.params.texturedH.width(rightBind.attrs.x - leftBind.attrs.x - leftBind.attrs.width)
              }
              if(item.attrs.params.filter){
                item.attrs.params.filter.width(rightBind.attrs.x - leftBind.attrs.x - leftBind.attrs.width)
              }
            }
            for(const item of leftBind.attrs.params.rightElem){
              this.changeSizeEleme(item , leftBind.attrs.x + leftBind.attrs.width , true)
            }
          }
        } else {
          element.width(bottomY - leftBind.attrs.x - leftBind.attrs.width);
          if(element.attrs.params.texturedH){
            element.attrs.params.texturedH.width(bottomY - leftBind.attrs.x - leftBind.attrs.width)
          }
          if(element.attrs.params.filter){
            element.attrs.params.filter.width(bottomY - leftBind.attrs.x - leftBind.attrs.width)
          }
          this.changeSizeEleme(element , leftBind.attrs.x + leftBind.attrs.width , true)
          if(leftBind.attrs.params.rightElem){
            for(const item of leftBind.attrs.params.rightElem){
              if(item.attrs.params.texturedH){
                item.attrs.params.texturedH.width(bottomY - leftBind.attrs.x - leftBind.attrs.width)
              }
              if(item.attrs.params.filter){
                item.attrs.params.filter.width(bottomY - leftBind.attrs.x - leftBind.attrs.width)
              }
              item.width(bottomY - leftBind.attrs.x - leftBind.attrs.width);
            }
            for(const item of leftBind.attrs.params.rightElem){
              this.changeSizeEleme(item , leftBind.attrs.x + leftBind.attrs.width , true)
            }
          }
        }
        this.changeSizeEleme(element , leftBind.attrs.x + leftBind.attrs.width , true)
      });
    }
    if (rightBind) {
      rightBind.on('dragmove', (pos) => {
        element.width(rightBind.attrs.x - element.x());
        if(element.attrs.params.texturedH){
          element.attrs.params.texturedH.width(rightBind.attrs.x - element.x())
        }
        if(element.attrs.params.filter){
          element.attrs.params.filter.width(rightBind.attrs.x - element.x())
        }
        if(rightBind.attrs.params.leftElem){
          for(const item of rightBind.attrs.params.leftElem){
            if(item.attrs.params.texturedH){
              item.attrs.params.texturedH.width(rightBind.attrs.x - element.x())
            }
            if(item.attrs.params.filter){
              item.attrs.params.filter.width(rightBind.attrs.x - element.x())
            }
            item.width(rightBind.attrs.x - element.x());
            if(item.attrs.params.sizeH){
              item.attrs.params.sizeH.x( element.x() + element.width() -6);
            }
            this.changeSizeEleme(item , false , false)
          
          }
        }
        if(element.attrs.params.sizeH){
          element.attrs.params.sizeH.x( element.x() + element.width() -6);
        }
        this.changeSizeEleme(element , false , false)
      });
    }
  }

  findPart(y, x, name) {
    let bindpart = null; // Ініціалізуємо значення bindpart зразу як null

    for (const parts of this.coefDoor.DoorWrapper.children) {
        if (parts.attrs.params && parts.attrs.params.part) {
            if (x >= parts.attrs.x && x < parts.attrs.x + parts.attrs.width) { 
                if (y > parts.attrs.y) {
                    if (name !== parts.attrs.name) {
                        // Порівнюємо значення y для знаходження найвищого елемента
                        if (!bindpart || parts.attrs.y > bindpart.attrs.y) {
                            bindpart = parts;
                        }
                    }
                }
            }
        }
    }
    return bindpart;
}
findPartLeft(y, x, name, type) {
  let bindpart = null; // Ініціалізуємо значення bindpart зразу як null

  for (const parts of this.coefDoor.DoorWrapper.children) {
      if (parts.attrs.params && parts.attrs.params.type === type) {
          if (parts.attrs.params.part || parts.attrs.params.main) {
              if (y >= parts.attrs.y && y < parts.attrs.y + parts.attrs.height) {
                  if (x > parts.attrs.x) {
                      if (name !== parts.attrs.name) {
                          // Порівнюємо значення x для знаходження найлівішого елемента
                          if (!bindpart || parts.attrs.x > bindpart.attrs.x) {
                              bindpart = parts;
                          }
                      }
                  }
              }
          }
      }
  }
  return bindpart;
}
  findPartRight(y, x, name , type) {
    let bindpart;
    for (const parts of this.coefDoor.DoorWrapper.children) {
      if (parts.attrs.params && (parts.attrs.params.type == type)) {
        if (parts.attrs.params.part || parts.attrs.params.main) {
          if (y >= parts.attrs.y && y < parts.attrs.y + parts.attrs.height) {
            if (!bindpart) {
              if (x < parts.attrs.x) {
                if (name !== parts.attrs.name) {
                  bindpart = parts;
                }
              }
            }
            if (x < parts.attrs.x) {
              if (bindpart) {
                if (bindpart.attrs.x > parts.attrs.x) {
                  if (name !== parts.attrs.name) {
                    bindpart = parts;
                  }
                }
              }
            }
          }
        }
      }
    }
    return bindpart;
  }

  findPartbottom(y, x, name) {
    let bindpart;
    let count = 0;
    for (const parts of this.coefDoor.DoorWrapper.children) {
      if (parts.attrs.params) {
        if (parts.attrs.params.part) {
          if (x >= parts.attrs.x && x < parts.attrs.x + parts.attrs.width) {
            count++;
            if (!bindpart) {
              if (y < parts.attrs.y) {
                if (name !== parts.attrs.name) {
                  bindpart = parts;
                }
              }
            }
            if (y < parts.attrs.y) {
              if (bindpart) {
                if (bindpart.attrs.y > parts.attrs.y) {
                  if (name !== parts.attrs.name) {
                    bindpart = parts;
                  }
                }
              }
            }
          }
        }
      }
    }
    return bindpart;
  }
}
