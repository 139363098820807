import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {baseUrl, PAGE} from '../router.path';
import {NotificationService} from '../service/notification.service';
import {Router} from '@angular/router';
import {CoefService} from '../service/coef.service';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.css']
})
export class AuthorizationComponent implements OnInit {
  authorizationUser: any;
  public isDealer = true;

  constructor(private formBuilder: FormBuilder,
              private http: HttpClient,
              private notification: NotificationService,
              private router: Router,
              private coef: CoefService
  ) {
    this.authorizationUser = this.formBuilder.group(
      {
        'name': ['', Validators.required],
        'last_name': ['', Validators.required],
        'surname': ['', Validators.required],
        'email': ['', Validators.required],
        'phone_number': ['', Validators.required],
        'company': [null, Validators.required],
        'dealer': [this.isDealer, Validators.required],
        'birthday': ['', Validators.required],
        'address': ['', Validators.required],
        'passwords': this.formBuilder.group({
          'password': ['', Validators.required],
          'password_repeat': ['', Validators.required]
        }, {validator: this.checkPasswords})
      });
  }

  ngOnInit() {
    this.coef.preloaderUpdate(false);
  }

  submitUser(e) {
    const formCopy = {...e.value};
    formCopy.password = e.value.passwords.password;

    this.http.post(baseUrl.local + 'send-request', formCopy).subscribe((res) => {
        // console.log(res);
        if (res['success']) {
          this.notification.triggerNotification(res['payload']);
          this.router.navigate([PAGE.LOGIN]);
        }
      },
      error => {
        this.notification.triggerNotification(error['error']['error'], true);
      });
  }

  private checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.password_repeat.value;

    return pass === confirmPass ? null : {notSame: true};
  }

  public validateRepeatPassword() {
    if (this.authorizationUser.controls.passwords.controls.password.valid && this.authorizationUser.controls.passwords.controls.password_repeat.touched && this.authorizationUser.controls.passwords.invalid) return true;
  }

  public onChange() {
    // console.log(this.isDealer);
    if (this.isDealer) {
      this.authorizationUser.controls.company.setValidators(Validators.required);
      this.authorizationUser.controls.company.setValue(null);
    } else {
      this.authorizationUser.controls.company.clearValidators();
      this.authorizationUser.controls.company.setValue('Відсутня');
      this.authorizationUser.controls.company.updateValueAndValidity();
    }
  }

  public goToLogin(): void {
    this.router.navigate([PAGE.LOGIN]);
  }

}
