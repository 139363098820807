import {Shelf} from '../shelf';
import {Partition} from '../partition';
import {Trumpet} from '../trumpet';
import {BackWall} from '../back-wall';
import {Pantograph} from '../pantograph';
import {OpenDoor} from '../open-door';
import {CornerEnding} from '../cornerEnding';
import {Hooks} from '../hooks';
import {RetractablePipe} from '../retractable-pipe';
import {Drawer} from '../drawer';

export class AllCalculations {
  coef;
  height;
  top;
  topForShelf;
  heightForShel;
  bottom;
  positionY = [];
  centerPosition;
  rightPosition;
  create;
  animate = 100;
  QuantityNumber = {
    1: {subId: 11},
    2: {subId: 12},
    3: {subId: 13},
    4: {subId: 14},
    5: {subId: 15},
  };
  QuantitySubid = {
    11: {number: 1},
    12: {number: 2},
    13: {number: 3},
    14: {number: 4},
    15: {number: 5},
  };
  minSize;
  render;

  constructor(coef, create, minSize, render?) {
    this.render = render;
    this.coef = coef;
    this.create = create;
    this.minSize = minSize;
    this.top = this.coef.topPositaionWrapper + this.coef.global_size_flat;
    this.topForShelf = this.coef.topPositaionWrapper + this.coef.global_size_flat * 2 + (350 / this.coef.global_coef);
    this.bottom = this.coef.topPositaionWrapper + (this.coef.heightWardrobe / this.coef.global_coef) - this.coef.global_size_flat - this.coef.height_legs;
    this.height = this.bottom - this.top;
    this.heightForShel = this.bottom - this.topForShelf;
    this.centerPosition = ((this.coef.widthWrapper - this.coef.setingsWardrobe.width / this.coef.global_coef) / 2) + this.coef.global_size_flat;
    this.rightPosition = this.centerPosition + (this.coef.setingsWardrobe.width / this.coef.global_coef) - this.coef.global_size_flat * 2;
  }

  findYForElement(count) {
    this.positionY = [];
    const onheight = Math.floor((this.heightForShel * this.coef.global_coef - (this.coef.global_size_flat * this.coef.global_coef * count)) / (count + 1)) / this.coef.global_coef;
    for (let i = 1; i <= count; i++) {
      if (i === 1) {
        this.positionY.push(this.topForShelf + onheight * i);
      } else {
        this.positionY.push(this.topForShelf + (this.coef.global_size_flat * (i - 1)) + onheight * i);
      }
    }
    return this.positionY;
  }

  findYForElementTop(count) {
    this.positionY = [];
    const onheight = Math.floor((this.height * this.coef.global_coef - (this.coef.global_size_flat * this.coef.global_coef * count)) / (count + 1)) / this.coef.global_coef;
    for (let i = 1; i <= count; i++) {
      if (i === 1) {
        this.positionY.push(this.top + onheight * i);
      } else {
        this.positionY.push(this.top + (this.coef.global_size_flat * (i - 1)) + onheight * i);
      }
    }
    return this.positionY;
  }

  createElement(arrayElement) {
    for (const element of arrayElement) {
      setTimeout(() => {
        this.createOneElement(element);
        this.create.priceSend();
      }, this.animate);
      this.animate = this.animate + 100;
    }

    this.animate = 100;
  }


  createOneElement(element) {
    console.log(element);
    let older = true;
    if (element.older && element.older.width) {
      older = element.older;
    }
    if (element.type === 7) {
      if (element.older === 'render') {
        older = false;
      }
      if (element.subId === 20) {
        if (!element.neo) {
          new Shelf(this.coef, this.create, true, 20).createShell({
            evt: {
              layerX: element.x + this.coef.global_size_flat,
              layerY: element.y
            }
          }, {x: element.x + this.coef.global_size_flat, y: element.y}, true, true, older);
        } else {
          if (!element.deletes) {
            if (element.innerShell) {
              new Shelf(this.coef, this.create, true, 20).createShell({
                evt: {
                  layerX: element.x + this.coef.global_size_flat,
                  layerY: element.y
                }
              }, {x: element.x + this.coef.global_size_flat, y: element.y}, true, true, older, true , false , element.innerShell );
            } else {
              new Shelf(this.coef, this.create, true, 20).createShell({
                evt: {
                  layerX: element.x + this.coef.global_size_flat,
                  layerY: element.y
                }
              }, {x: element.x + this.coef.global_size_flat, y: element.y}, true, true, older, true);
            }
          } else {
            new Shelf(this.coef, this.create, true, 20).createShell({
              evt: {
                layerX: element.x + this.coef.global_size_flat,
                layerY: element.y
              }
            }, {x: element.x + this.coef.global_size_flat, y: element.y}, true, true, older, true, true);
          }
        }
      }
      if (element.subId === 21) {
        new Shelf(this.coef, this.create, true, 20).createHangingShell({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        }, {x: element.x, y: element.y}, true, true, older);
      }
    }
    if (element.type === 6) {
      if (element.older === 'render') {
        older = false;
      }
      if (element.neo) {
        if (!element.deletes) {
          new Partition(this.coef, this.create, true).createFromParitation({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          }, {x: element.x, y: element.y}, older, true);
        } else {
          new Partition(this.coef, this.create, true).createFromParitation({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          }, {x: element.x, y: element.y}, older, true, true);
        }
      } else {
        new Partition(this.coef, this.create, true).createFromParitation({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        }, {x: element.x, y: element.y}, older);
      }
    }
    if (element.type === 8) {
      if (element.older === 'render') {
        older = false;
      }
      new Trumpet(this.coef, this.create, element.subId,true).createTrumpetlocal({
        evt: {
          layerX: element.x,
          layerY: element.y
        }
      }, {x: element.x, y: element.y}, older);
    }
    if (element.type === 4) {
      new BackWall(this.coef, this.create, true).createBackWallApi({
        evt: {
          layerX: element.x,
          layerY: element.y
        }
      });
    }
    if (element.type === 5) {
      new Pantograph(this.coef, this.create, true).createPantographBack({
        evt: {
          layerX: element.x,
          layerY: element.y
        }
      }, {x: element.x, y: element.y});
    }
    if (element.type === 1) {
      if (element.subId === 1) {
        new OpenDoor(1, this.coef, this.create, true).singleDoorRightApi({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        });
      }
      if (element.subId === 2) {
        new OpenDoor(2, this.coef, this.create, true).singleDoorLeftApi({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        });
      }
      if (element.subId === 3) {
        new OpenDoor(3, this.coef, this.create, true).singleDoorRightLockApi({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        });
      }
      if (element.subId === 4) {
        new OpenDoor(4, this.coef, this.create, true).singleDoorLeftLockApi({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        });
      }
      if (element.subId === 5) {
        if (element.neo) {
          new OpenDoor(5, this.coef, this.create, true).twoDoorApi({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          }, true);
        } else {
          new OpenDoor(5, this.coef, this.create, true).twoDoorApi({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          });
        }

      }
      if (element.subId === 6) {
        new OpenDoor(6, this.coef, this.create, true).twoDoorLockApi({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        });
      }
      if (element.subId === 28) {
        
        new OpenDoor(28, this.coef, this.create, true).singleDoorRightApiD({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        });
      }
      if (element.subId === 29) {
        
        new OpenDoor(29, this.coef, this.create, true).singleDoorLeftApiD({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        });
      }
      if (element.subId === 30) {
        if (element.neo) {
          new OpenDoor(30, this.coef, this.create, true).twoDoorApiD({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          }, true);
        } else {
          new OpenDoor(30, this.coef, this.create, true).twoDoorApiD({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          });
        }
      }
      if (element.subId === 33) {
        new OpenDoor(33, this.coef, this.create, true).twoDoorLockApiD({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        });
      }
      if (element.subId === 31) {
        new OpenDoor(31, this.coef, this.create, true).singleDoorRightLockApiD({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        });
      }
      if (element.subId === 32) {
        new OpenDoor(32, this.coef, this.create, true).singleDoorLeftLockApiD({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        });
      }
    }
    if (element.type === 15) {
      if (element && element.object && element.object.params.position == 'left') {
        // element.width = element.object.width * -1;
        console.log(element.object.width);
      }
      if (element.subId === 22) {
        new CornerEnding(this.coef, this.create, true).createSpecCornerEndingApi({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        }, false, element.width);
      }
      if (element.subId === 23) {
        new CornerEnding(this.coef, this.create, true, true).createSpecCornerEndingApi({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        }, true, element.width);
      }
    }
    if (element.type === 3) {
      if (element.subId === 16) {
        new Hooks(this.coef, this.create, 16, true).createTwoHooksApi({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        }, {x: element.x, y: element.y});
      }
      if (element.subId === 17) {
        new Hooks(this.coef, this.create, 17, true).createHooksApi({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        }, {x: element.x, y: element.y}, false);
      }
      if (element.subId === 18) {
        new Hooks(this.coef, this.create, 18, true).createHooksApi({
          evt: {
            layerX: element.x,
            layerY: element.y
          }
        }, {x: element.x, y: element.y}, true);
      }
    }
    if (element.type === 2) {
      new RetractablePipe(this.coef, this.create, this.QuantitySubid[element.subId].number, element.subId, true).createRetractablePipeApi({
        evt: {
          layerX: element.x + this.coef.global_size_flat * 2,
          layerY: element.y + this.coef.global_size_flat * 2
        }
      }, this.QuantitySubid[element.subId].number, {x: element.x, y: element.y});
    }
    if (element.type === this.coef.id_element.drawer) {
      if (element.subId === 7) {
        if (element.neo) {
          const height = this.coef.setingsWardrobe.height - this.coef.spec_values['middle_section_to_top_flat_size'] - this.coef.spec_values['inner_shuttle_min_height'] - this.coef.global_size_flat * this.coef.global_coef * 4 - this.coef.spec_values['inner_shuttle_bottom_section_diff'] - this.coef.height_legs * this.coef.global_coef;
          const threeDrawer = this.coef.spec_values['inner_shuttle_max_height'] * 3 + this.coef.spec_values['inner_shuttle_side_space'] * 3;
          const tooDrawer = this.coef.spec_values['inner_shuttle_max_height'] * 2 + this.coef.spec_values['inner_shuttle_side_space'] * 2;
          const forDrawer = this.coef.spec_values['inner_shuttle_max_height'] * 4 + this.coef.spec_values['inner_shuttle_side_space'] * 4;
          let top = this.coef.spec_values['middle_section_to_top_flat_size'];
          if (this.coef.selectDrawer == 2) {
            if (height > tooDrawer) {
              top = this.coef.setingsWardrobe.height - tooDrawer - this.coef.global_size_flat * 5 * this.coef.global_coef - this.coef.spec_values.t_section_min_height - this.coef.height_legs * this.coef.global_coef;
            }
          }
          if (this.coef.selectDrawer == 3) {
            if (height > threeDrawer) {
              top = this.coef.setingsWardrobe.height - threeDrawer - this.coef.global_size_flat * 5 * this.coef.global_coef - this.coef.spec_values.t_section_min_height - this.coef.height_legs * this.coef.global_coef;
            }
          }
          if (this.coef.selectDrawer == 4) {
            if (height > forDrawer) {
              top = this.coef.setingsWardrobe.height - forDrawer - this.coef.global_size_flat * 5 * this.coef.global_coef - this.coef.spec_values.t_section_min_height - this.coef.height_legs * this.coef.global_coef;
            }
          }
          const heightAfter = this.coef.setingsWardrobe.height - top - this.coef.spec_values.t_section_min_height - this.coef.global_size_flat * this.coef.global_coef * 4 - this.coef.spec_values['inner_shuttle_bottom_section_diff'] - this.coef.height_legs * this.coef.global_coef;
          console.warn(heightAfter, 'heightAfter', height, this.coef.spec_values['middle_section_to_top_flat_size'], top);
          let oneHeight = Math.floor(heightAfter / 2) / this.coef.global_coef;
          if (this.coef.selectDrawer == 3) {
            oneHeight = Math.floor(heightAfter / 3) / this.coef.global_coef;
          }
          if (this.coef.selectDrawer == 4) {
            oneHeight = Math.floor(heightAfter / 4) / this.coef.global_coef;
          }
          if (!this.render || !this.render.rerender) {
            new Drawer(element.subId, this.coef, this.create, true, true).createSimpleApi({
              evt: {
                layerX: element.x,
                layerY: element.y
              }
            }, oneHeight, true);
          } else {
            new Drawer(element.subId, this.coef, this.create, true, true).createSimpleApi({
              evt: {
                layerX: element.x,
                layerY: element.y
              }
            }, element.object.params.height, true);
          }
          return;
        }
        if (element.object.params.touches === 'Touched') {
          new Drawer(element.subId, this.coef, this.create, true, true).createSimpleApi({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          }, element.object.params.height);

        } else {
          new Drawer(element.subId, this.coef, this.create, true, true).createSimpleApi({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          });
        }
      }
      if (element.subId === 8) {
        if (element.neo) {
          const height = this.coef.setingsWardrobe.height - this.coef.spec_values['middle_section_to_top_flat_size'] - this.coef.spec_values['inner_shuttle_min_height'] - this.coef.global_size_flat * this.coef.global_coef * 4 - this.coef.spec_values['inner_shuttle_bottom_section_diff'] - this.coef.height_legs * this.coef.global_coef;
          const threeDrawer = this.coef.spec_values['inner_shuttle_max_height'] * 3 + this.coef.spec_values['inner_shuttle_side_space'] * 3;
          const tooDrawer = this.coef.spec_values['inner_shuttle_max_height'] * 2 + this.coef.spec_values['inner_shuttle_side_space'] * 2;
          const forDrawer = this.coef.spec_values['inner_shuttle_max_height'] * 4 + this.coef.spec_values['inner_shuttle_side_space'] * 4;
          let top = this.coef.spec_values['middle_section_to_top_flat_size'];
          if (this.coef.selectDrawer == 2) {
            if (height > tooDrawer) {
              top = this.coef.setingsWardrobe.height - tooDrawer - this.coef.global_size_flat * 5 * this.coef.global_coef - this.coef.spec_values.t_section_min_height - this.coef.height_legs * this.coef.global_coef;
            }
          }
          if (this.coef.selectDrawer == 3) {
            if (height > threeDrawer) {
              top = this.coef.setingsWardrobe.height - threeDrawer - this.coef.global_size_flat * 5 * this.coef.global_coef - this.coef.spec_values.t_section_min_height - this.coef.height_legs * this.coef.global_coef;
            }
          }
          if (this.coef.selectDrawer == 4) {
            if (height > forDrawer) {
              top = this.coef.setingsWardrobe.height - forDrawer - this.coef.global_size_flat * 5 * this.coef.global_coef - this.coef.spec_values.t_section_min_height - this.coef.height_legs * this.coef.global_coef;
            }
          }
          const heightAfter = this.coef.setingsWardrobe.height - top - this.coef.spec_values.t_section_min_height - this.coef.global_size_flat * this.coef.global_coef * 4 - this.coef.spec_values['inner_shuttle_bottom_section_diff'] - this.coef.height_legs * this.coef.global_coef;
         
          let oneHeight = Math.floor(heightAfter / 2) / this.coef.global_coef;
          if (this.coef.selectDrawer == 3) {
            oneHeight = Math.floor(heightAfter / 3) / this.coef.global_coef;
          }
          if (this.coef.selectDrawer == 4) {
            oneHeight = Math.floor(heightAfter / 4) / this.coef.global_coef;
          }
          if (!this.render || !this.render.rerender) {
            new Drawer(element.subId, this.coef, this.create, true, true).createWithLengthApi({
              evt: {
                layerX: element.x,
                layerY: element.y
              }
            }, oneHeight, true);
          } else {
            new Drawer(element.subId, this.coef, this.create, true, true).createWithLengthApi({
              evt: {
                layerX: element.x,
                layerY: element.y
              }
            }, element.object.params.height, true);
          }
          return;
        }
        if (element.object.params.touches === 'Touched') {
          new Drawer(element.subId, this.coef, this.create, true, true).createWithLengthApi({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          }, element.object.params.height);
        } else {
          new Drawer(element.subId, this.coef, this.create, true, true).createWithLengthApi({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          });
        }
      }
      if (element.subId === 9) {
        if (element.object.params.touches === 'Touched') {
          new Drawer(element.subId, this.coef, this.create, true, true).createSimpleLockApi({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          }, element.object.params.height);
        } else {
          new Drawer(element.subId, this.coef, this.create, true, true).createSimpleLockApi({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          });
        }
      }
      if (element.subId === 10) {
        if (element.object.params.touches === 'Touched') {
          new Drawer(element.subId, this.coef, this.create, true, true).createWithLengthLockApi({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          }, element.object.params.height);
        } else {
          new Drawer(element.subId, this.coef, this.create, true, true).createWithLengthLockApi({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          });
        }
      }
      if (element.subId === 26) {
        if (element.object.params.touches === 'Touched') {
          new Drawer(element.subId, this.coef, this.create, true, true).createSimpleApiSpec({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          }, element.object.params.height);
        } else {
          new Drawer(element.subId, this.coef, this.create, true, true).createSimpleApiSpec({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          });
        }
      }
      if (element.subId === 27) {
        if (element.object.params.touches === 'Touched') {
          new Drawer(element.subId, this.coef, this.create, true, true).createSimpleApiSpecD({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          }, element.object.params.height);
        } else {
          new Drawer(element.subId, this.coef, this.create, true, true).createSimpleApiSpecD({
            evt: {
              layerX: element.x,
              layerY: element.y
            }
          });
        }
      }
    }
  }
}
