import {Injectable} from '@angular/core';
import * as Konva from '../../assets/konva';
import {CoefService} from './coef.service';
import {CreateService} from './create.service';
import {MinSizeService} from './min-size.service';
import {AllCalculations} from '../class/BoundingUnitElements/allCalculations';
import {Classic} from '../class/BoundingUnitElements/classic';
import {DrawerLevelService} from './drawer-level.service';
import {Sizes} from '../class/sizes/sizes';
import {InitRenderService} from './renderingService/init-render.service';
import {Partition} from '../class/partition';
import {Prihoza} from '../class/prihoza';
import {Depth} from '../class/depth';


@Injectable({
  providedIn: 'root'
})
export class InitializationService {

  constructor(private coef: CoefService,
              private create: CreateService,
              private minSize: MinSizeService,
              private drawerLevel: DrawerLevelService,
              private render: InitRenderService) {
    this.drawerLevel.start();
  }

  initLook2d(e, look2d, stage, topPadding?) {
    const topPositaionWrapper = topPadding ? this.coef.topPositaionWrapper + topPadding / this.coef.global_coef : this.coef.topPositaionWrapper;
    const height = topPadding ? e.height - topPadding : e.height;
    let width = e.width;
    let centerPosition = (this.coef.widthWrapper - e.width / this.coef.global_coef) / 2;
    if (this.coef.pramsId == 4) {
      centerPosition = centerPosition + this.coef.global_size_flat;
      width = width - this.coef.global_size_flat * this.coef.global_coef * 2;
    }
    this.coef.look2dPosition = ((e.depth / this.coef.depthCoef) / this.coef.global_coef);
    const topLeft = {
      x: centerPosition,
      y: topPositaionWrapper
    };
    const topRight = {
      x: centerPosition + this.coef.global_size_flat + this.coef.global_size_flat + (width / this.coef.global_coef) - this.coef.global_size_flat * 2,
      y: topPositaionWrapper
    };
    const centerLeftTop = {
      x: centerPosition + this.coef.global_size_flat + ((e.depth / this.coef.depthCoef) / this.coef.global_coef),
      y: topPositaionWrapper + this.coef.global_size_flat + ((e.depth / this.coef.depthCoef) / this.coef.global_coef)
    };
    const centerRightTop = {
      x: centerPosition + this.coef.global_size_flat + (width / this.coef.global_coef) - this.coef.global_size_flat * 2 - ((e.depth / this.coef.depthCoef) / this.coef.global_coef),
      y: topPositaionWrapper + this.coef.global_size_flat + ((e.depth / this.coef.depthCoef) / this.coef.global_coef)
    };
    const centerRightbottom = {
      x: centerPosition + this.coef.global_size_flat + (width / this.coef.global_coef) - this.coef.global_size_flat * 2 - ((e.depth / this.coef.depthCoef) / this.coef.global_coef),
      y: topPositaionWrapper + this.coef.global_size_flat + ((e.depth / this.coef.depthCoef) / this.coef.global_coef) + (height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat * 2) - ((e.depth / this.coef.depthCoef) / this.coef.global_coef) * 2
    };
    const centerLeftbottom = {
      x: centerPosition + this.coef.global_size_flat + ((e.depth / this.coef.depthCoef) / this.coef.global_coef),
      y: topPositaionWrapper + this.coef.global_size_flat + ((e.depth / this.coef.depthCoef) / this.coef.global_coef) + (height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat * 2) - ((e.depth / this.coef.depthCoef) / this.coef.global_coef) * 2
    };
    const bottomLeft = {
      x: centerPosition,
      y: topPositaionWrapper + height / this.coef.global_coef - this.coef.height_legs
    };
    const bottomRight = {
      x: centerPosition + this.coef.global_size_flat + this.coef.global_size_flat + (width / this.coef.global_coef) - this.coef.global_size_flat * 2,
      y: topPositaionWrapper + height / this.coef.global_coef - this.coef.height_legs
    };
    const wrapper = new Konva.Rect({
      x: centerLeftTop.x,
      y: centerLeftTop.y,
      width: centerRightTop.x - centerLeftTop.x,
      height: centerLeftbottom.y - centerLeftTop.y,
      name: 'width',
      fill: this.chanchOpacity(this.coef.color, '0.5'),
      stroke: 'black',
      strokeWidth: 1
    });
    const polyBottom = new Konva.Line({
      points: [bottomLeft.x, bottomLeft.y, bottomRight.x, bottomRight.y, centerRightbottom.x, centerRightbottom.y, centerLeftbottom.x, centerLeftbottom.y],
      fill: this.chanchOpacity(this.coef.color, '0.4'),
      closed: true
    });

    const polyTop = new Konva.Line({
      points: [topLeft.x, topLeft.y, topRight.x, topRight.y, centerRightTop.x, centerRightTop.y, centerLeftTop.x, centerLeftTop.y],
      fill: this.chanchOpacity(this.coef.color, '0.9'),
      closed: true
    });
    const polyleft = new Konva.Line({
      points: [topLeft.x, topLeft.y, bottomLeft.x, bottomLeft.y, centerLeftbottom.x, centerLeftbottom.y, centerLeftTop.x, centerLeftTop.y],
      fill: this.chanchOpacity(this.coef.color, '0.7'),
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    const polyRight = new Konva.Line({
      points: [topRight.x, topRight.y, bottomRight.x, bottomRight.y, centerRightbottom.x, centerRightbottom.y, centerRightTop.x, centerRightTop.y],
      fill: this.chanchOpacity(this.coef.color, '0.7'),
      stroke: 'black',
      strokeWidth: 1,
      closed: true
    });
    look2d.add(wrapper, polyTop, polyBottom, polyleft, polyRight);
    stage.add(look2d);
  }

  isBase(array) {
    if (this.render.rerender) {
      if (this.render.object.init_option_ids.includes(33)) {
        return true;
      } else {
        return false;
      }
    } else {
      for (const item of array) {
        if (item.id === 6 && item.value === 33) {
          return true;
        }
      }
      return false;
    }
  }

  init(e, parent) {
    console.log(e, 'validators');
    const centerPosition = (this.coef.widthWrapper - e.width / this.coef.global_coef) / 2;

    const base = this.isBase(e.init_types);
    let heightLeftRight = e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat * 2;
    let widthBottomWall = (e.width / this.coef.global_coef);
    let xBottomWall = centerPosition;
    if (base) {
      heightLeftRight = e.height / this.coef.global_coef - this.coef.global_size_flat;
      widthBottomWall = (e.width / this.coef.global_coef) - this.coef.global_size_flat * 2;
      xBottomWall = centerPosition + this.coef.global_size_flat;
    }
    this.create.createElement({
      leftPosition: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
      topPosition: this.coef.topPositaionWrapper + this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: heightLeftRight,
      type: 'vertical',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 0},
        side: 'R'
      }
    });
    this.create.createElement({
      leftPosition: centerPosition,
      topPosition: this.coef.topPositaionWrapper + this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: heightLeftRight,
      type: 'vertical',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 0},
        side: 'L'
      }
    });
    this.create.createElement({
      leftPosition: centerPosition,
      topPosition: this.coef.topPositaionWrapper,
      width: (e.width / this.coef.global_coef),
      height: this.coef.global_size_flat,
      type: 'horizon',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.top_wall,
        distance: {min: 0}
      }
    });
    this.create.createElement({
      leftPosition: xBottomWall,
      topPosition: this.coef.topPositaionWrapper + (e.height / this.coef.global_coef) - this.coef.global_size_flat - this.coef.height_legs,
      width: widthBottomWall,
      height: this.coef.global_size_flat,
      type: 'horizon',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.bottom_wall,
        distance: {min: 0}
      }
    });
    if (!base) {
      this.coef.shapesLayerw.children[2].attrs.bind.push(this.coef.shapesLayerw.children[1], this.coef.shapesLayerw.children[0]);
      this.coef.shapesLayerw.children[3].attrs.bind.push(this.coef.shapesLayerw.children[1], this.coef.shapesLayerw.children[0]);
    } else {
      this.coef.shapesLayerw.children[2].attrs.bind.push(this.coef.shapesLayerw.children[1], this.coef.shapesLayerw.children[0]);
      this.coef.shapesLayerw.children[1].attrs.bind.push(this.coef.shapesLayerw.children[3]);
      this.coef.shapesLayerw.children[0].attrs.bind.push(this.coef.shapesLayerw.children[3]);
    }
    if (this.coef.pramsId != 13) {
      if (!this.render.rerender) {
        new Classic(this.coef, this.create, this.minSize).typWidth();
      }
      new Sizes(this.coef, this.create).globalSize(
        {
          top: this.coef.topPositaionWrapper,
          left: centerPosition,
          width: (e.width / this.coef.global_coef)
        }, {
          top: this.coef.topPositaionWrapper,
          right: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
          height: (e.height / this.coef.global_coef)
        });
    }
  }

  initLamel(e, parent, stage) {
    const centerPosition = (this.coef.widthWrapper - e.width / this.coef.global_coef) / 2;
    const base = this.isBase(e.init_types);
    let heightLeftRight = this.coef.spec_values['bottom_anteroom_section_height'] / this.coef.global_coef;
    let widthBottomWall = (e.width / this.coef.global_coef);
    let xBottomWall = centerPosition;
    if (base) {
      heightLeftRight = this.coef.spec_values['bottom_anteroom_section_height'] / this.coef.global_coef + this.coef.height_legs + this.coef.global_size_flat;
      widthBottomWall = (e.width / this.coef.global_coef) - this.coef.global_size_flat * 2;
      xBottomWall = centerPosition + this.coef.global_size_flat;
    }
    const Prihozas = new Prihoza(this.coef, this.create, true);
    const heightCentr = e.height - this.coef.global_size_flat * this.coef.global_coef - this.coef.height_legs * this.coef.global_coef;
    this.create.createElement({
      leftPosition: xBottomWall,
      topPosition: this.coef.topPositaionWrapper + (e.height / this.coef.global_coef) - this.coef.global_size_flat - this.coef.height_legs,
      width: widthBottomWall,
      height: this.coef.global_size_flat,
      type: 'horizon',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.bottom_wall,
        distance: {min: 0}
      }
    });
    const center = Prihozas.createPrihoza(
      e.width - this.coef.global_size_flat * 2 * this.coef.global_coef,
      heightCentr, {
        x: this.coef.global_size_flat,
        y: 0
      }, true);
    const group = new Konva.Group({
      x: centerPosition,
      y: this.coef.topPositaionWrapper,
      name: 'element66' + this.coef.countNameElement,
      width: e.width / this.coef.global_coef,
      draggable: false,
      params: {
        removable: false,
        type: 16,
        height: e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat,
      },
      bind: [],
    });
    const left = new Konva.Rect({
      x: 0,
      y: heightCentr / this.coef.global_coef - this.coef.spec_values['bottom_anteroom_section_height'] / this.coef.global_coef,
      width: this.coef.global_size_flat,
      height: heightLeftRight,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: this.coef.blackColor,
    });
    const right = new Konva.Rect({
      x: e.width / this.coef.global_coef - this.coef.global_size_flat,
      y: heightCentr / this.coef.global_coef - this.coef.spec_values['bottom_anteroom_section_height'] / this.coef.global_coef,
      width: this.coef.global_size_flat,
      height: heightLeftRight,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: this.coef.blackColor,
    });
    group.add(center, left, right);
    this.coef.shapesLayerw.add(group);
    this.coef.shapesLayerw.children[0].attrs.bind.push(this.coef.shapesLayerw.children[1]);
    this.coef.shapesLayerw.batchDraw();
    this.create.priceSend();
    new Sizes(this.coef, this.create).globalSize(
      {
        top: this.coef.topPositaionWrapper,
        left: centerPosition,
        width: (e.width / this.coef.global_coef)
      }, {
        top: this.coef.topPositaionWrapper,
        right: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
        height: (e.height / this.coef.global_coef)
      });
    stage.add(this.coef.shapesLayerw);
  }

  initLamelSonat(e, parent, stage) {
    const centerPosition = (this.coef.widthWrapper - e.width / this.coef.global_coef) / 2;

    const base = this.isBase(e.init_types);
    let heightLeftRight = e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat * 2;
    let widthBottomWall = (e.width / this.coef.global_coef);
    let xBottomWall = centerPosition;
    if (base) {
      heightLeftRight = e.height / this.coef.global_coef - this.coef.global_size_flat;
      widthBottomWall = (e.width / this.coef.global_coef) - this.coef.global_size_flat * 2;
      xBottomWall = centerPosition + this.coef.global_size_flat;
    }

    this.create.createElement({
      leftPosition: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
      topPosition: this.coef.topPositaionWrapper + this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: heightLeftRight,
      type: 'vertical',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 0},
        side: 'R'
      }
    });
    this.create.createElement({
      leftPosition: centerPosition,
      topPosition: this.coef.topPositaionWrapper + this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: heightLeftRight,
      type: 'vertical',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 0},
        side: 'L'
      }
    });
    this.create.createElement({
      leftPosition: centerPosition,
      topPosition: this.coef.topPositaionWrapper,
      width: (e.width / this.coef.global_coef),
      height: this.coef.global_size_flat,
      type: 'horizon',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.top_wall,
        distance: {min: 0}
      }
    });
    this.create.createElement({
      leftPosition: xBottomWall,
      topPosition: this.coef.topPositaionWrapper + (e.height / this.coef.global_coef) - this.coef.global_size_flat - this.coef.height_legs,
      width: widthBottomWall,
      height: this.coef.global_size_flat,
      type: 'horizon',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.bottom_wall,
        distance: {min: 0}
      }
    });
    if (!base) {
      this.coef.shapesLayerw.children[2].attrs.bind.push(this.coef.shapesLayerw.children[1], this.coef.shapesLayerw.children[0]);
      this.coef.shapesLayerw.children[3].attrs.bind.push(this.coef.shapesLayerw.children[1], this.coef.shapesLayerw.children[0]);
    } else {
      this.coef.shapesLayerw.children[2].attrs.bind.push(this.coef.shapesLayerw.children[1], this.coef.shapesLayerw.children[0]);
      this.coef.shapesLayerw.children[1].attrs.bind.push(this.coef.shapesLayerw.children[3]);
      this.coef.shapesLayerw.children[0].attrs.bind.push(this.coef.shapesLayerw.children[3]);
    }
    const Prihozas = new Prihoza(this.coef, this.create, true);
    const centerWidth = Math.floor((e.width - (this.coef.global_size_flat * this.coef.global_coef * 4)) / 3);
    const heightCentr = e.height - this.coef.global_size_flat * this.coef.global_coef * 2 - this.coef.height_legs * this.coef.global_coef;
    const center = Prihozas.createPrihoza(
      centerWidth,
      heightCentr, {
        x: 0,
        y: this.coef.global_size_flat
      });
    const group = new Konva.Group({
      x: centerPosition + centerWidth / this.coef.global_coef + this.coef.global_size_flat * 2,
      y: this.coef.topPositaionWrapper,
      name: 'element66' + this.coef.countNameElement,
      width: centerWidth / this.coef.global_coef,
      draggable: false,
      params: {
        removable: false,
        type: 16,
        height: e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat,
      },
      bind: [],
    });
    new Partition(this.coef, this.create, true).createFromParitation({
      evt: {
        layerX: centerPosition + this.coef.global_size_flat + centerWidth / this.coef.global_coef,
        layerY: this.coef.topPositaionWrapper + this.coef.global_size_flat * 5
      }
    }, {
      x: centerPosition + this.coef.global_size_flat + centerWidth / this.coef.global_coef,
      y: this.coef.topPositaionWrapper + this.coef.global_size_flat * 5
    }, false, true);
    new Partition(this.coef, this.create, true).createFromParitation({
      evt: {
        layerX: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat * 2 - centerWidth / this.coef.global_coef,
        layerY: this.coef.topPositaionWrapper + this.coef.global_size_flat * 5
      }
    }, {
      x: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat * 2 - centerWidth / this.coef.global_coef,
      y: this.coef.topPositaionWrapper + this.coef.global_size_flat * 5
    }, false, true);
    this.coef.shapesLayerw.children[this.coef.shapesLayerw.children.length - 1].attrs.bind.push(group);
    this.coef.shapesLayerw.children[this.coef.shapesLayerw.children.length - 2].attrs.bind.push(group);
    if (!this.render.rerender) {
      new Classic(this.coef, this.create, this.minSize).lamelSonat();
    }
    this.coef.shapesLayerw.children[2].attrs.bind.push(group);
    this.coef.shapesLayerw.children[3].attrs.bind.push(group);
    group.add(center);
    this.coef.shapesLayerw.add(group);
    this.coef.shapesLayerw.batchDraw();
    this.create.priceSend();
    new Sizes(this.coef, this.create).globalSize(
      {
        top: this.coef.topPositaionWrapper,
        left: centerPosition,
        width: (e.width / this.coef.global_coef)
      }, {
        top: this.coef.topPositaionWrapper,
        right: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
        height: (e.height / this.coef.global_coef)
      });
  }

  initLamelLux(e, parent, stage) {

    const centerPosition = (this.coef.widthWrapper - e.width / this.coef.global_coef) / 2;
    const base = this.isBase(e.init_types);
    let heightLeftRight = e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat * 2;
    let widthBottomWall = (e.width / this.coef.global_coef);
    let xBottomWall = centerPosition;
    if (base) {
      heightLeftRight = e.height / this.coef.global_coef - this.coef.global_size_flat;
      widthBottomWall = (e.width / this.coef.global_coef) - this.coef.global_size_flat * 2;
      xBottomWall = centerPosition + this.coef.global_size_flat;
    }
    if (this.coef.LuxPosition === 'R') {
      this.create.createElement({
        leftPosition: centerPosition,
        topPosition: this.coef.topPositaionWrapper + this.coef.global_size_flat,
        width: this.coef.global_size_flat,
        height: heightLeftRight,
        type: 'vertical',
        contours: null,
        wrapper: true,
        layer: parent,
        params: {
          removable: false,
          type: this.coef.id_element.lateral_wall,
          distance: {min: 0},
          side: 'L'
        }
      });
    } else {
      this.create.createElement({
        leftPosition: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
        topPosition: this.coef.topPositaionWrapper + this.coef.global_size_flat,
        width: this.coef.global_size_flat,
        height: heightLeftRight,
        type: 'vertical',
        contours: null,
        wrapper: true,
        layer: parent,
        params: {
          removable: false,
          type: this.coef.id_element.lateral_wall,
          distance: {min: 0},
          side: 'R'
        }
      });
    }
    this.create.createElement({
      leftPosition: centerPosition,
      topPosition: this.coef.topPositaionWrapper,
      width: (e.width / this.coef.global_coef),
      height: this.coef.global_size_flat,
      type: 'horizon',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.top_wall,
        distance: {min: 0}
      }
    });
    this.create.createElement({
      leftPosition: xBottomWall,
      topPosition: this.coef.topPositaionWrapper + (e.height / this.coef.global_coef) - this.coef.global_size_flat - this.coef.height_legs,
      width: widthBottomWall,
      height: this.coef.global_size_flat,
      type: 'horizon',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.bottom_wall,
        distance: {min: 0}
      }
    });
    this.coef.shapesLayerw.children[1].attrs.bind.push(this.coef.shapesLayerw.children[0]);
    if (base) {
      this.coef.shapesLayerw.children[0].attrs.bind.push(this.coef.shapesLayerw.children[2]);
    } else {
      this.coef.shapesLayerw.children[2].attrs.bind.push(this.coef.shapesLayerw.children[0]);
    }
    const Prihozas = new Prihoza(this.coef, this.create, true);
    const centerWidth = Math.floor((e.width - (this.coef.global_size_flat * this.coef.global_coef * 3)) / 2);
    const heightCentr = e.height - this.coef.global_size_flat * this.coef.global_coef * 2 - this.coef.height_legs * this.coef.global_coef;
    const center = Prihozas.createPrihoza(
      centerWidth,
      heightCentr, {
        x: 0,
        y: this.coef.global_size_flat
      }, true);

    let position = centerPosition + centerWidth / this.coef.global_coef + this.coef.global_size_flat * 2;
    if (this.coef.LuxPosition === 'L') {
      position = centerPosition + this.coef.global_size_flat;
    }
    const group = new Konva.Group({
      x: position,
      y: this.coef.topPositaionWrapper,
      name: 'element66' + this.coef.countNameElement,
      width: centerWidth / this.coef.global_coef,
      draggable: false,
      params: {
        removable: false,
        type: 16,
        height: e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat,
        side: this.coef.LuxPosition
      },
      bind: [],
    });
    new Partition(this.coef, this.create, true).createFromParitation({
      evt: {
        layerX: centerPosition + this.coef.global_size_flat + centerWidth / this.coef.global_coef,
        layerY: this.coef.topPositaionWrapper + this.coef.global_size_flat * 5
      }
    }, {
      x: centerPosition + this.coef.global_size_flat + centerWidth / this.coef.global_coef,
      y: this.coef.topPositaionWrapper + this.coef.global_size_flat * 5
    }, false, true);
    this.coef.shapesLayerw.children[this.coef.shapesLayerw.children.length - 1].attrs.bind.push(group);
    if (!this.render.rerender) {
      if (this.coef.LuxPosition === 'L') {
        new Classic(this.coef, this.create, this.minSize).lamelluxL();
      } else {
        new Classic(this.coef, this.create, this.minSize).lamelluxR();
      }
    }
    this.coef.shapesLayerw.children[2].attrs.bind.push(group);
    this.coef.shapesLayerw.children[1].attrs.bind.push(group);
    group.add(center);
    let heights = this.coef.spec_values['bottom_anteroom_section_height'] / this.coef.global_coef;
    if (base) {
      heights = this.coef.spec_values['bottom_anteroom_section_height'] / this.coef.global_coef + this.coef.height_legs + this.coef.global_size_flat;
    }
    if (this.coef.LuxPosition == 'R') {
      const right = new Konva.Rect({
        x: centerWidth / this.coef.global_coef,
        y: heightCentr / this.coef.global_coef - this.coef.spec_values['bottom_anteroom_section_height'] / this.coef.global_coef + this.coef.global_size_flat,
        width: this.coef.global_size_flat,
        height: heights,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      const rightTop = new Konva.Rect({
        x: centerWidth / this.coef.global_coef,
        y: this.coef.global_size_flat,
        width: this.coef.global_size_flat,
        height: this.coef.spec_values['top_side_flat_height'] / this.coef.global_coef,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      group.add(right, rightTop);
    } else {
      const left = new Konva.Rect({
        x: -this.coef.global_size_flat,
        y: heightCentr / this.coef.global_coef - this.coef.spec_values['bottom_anteroom_section_height'] / this.coef.global_coef + this.coef.global_size_flat,
        width: this.coef.global_size_flat,
        height: heights,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      const leftTop = new Konva.Rect({
        x: -this.coef.global_size_flat,
        y: this.coef.global_size_flat,
        width: this.coef.global_size_flat,
        height: this.coef.spec_values['top_side_flat_height'] / this.coef.global_coef,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });

      group.add(left, leftTop);
    }
    if (this.coef.LuxPosition == 'L') {
      const sizess = new Sizes(this.coef, this.create).createOneSizePanal({
        x: centerWidth / this.coef.global_coef + this.coef.global_size_flat,
        y: heightCentr / this.coef.global_coef - 10 + this.coef.global_size_flat,
        width: centerWidth / this.coef.global_coef
      });
      group.add(sizess);
    } else {
      const sizess = new Sizes(this.coef, this.create).createOneSizePanal({
        x: -centerWidth / this.coef.global_coef - this.coef.global_size_flat,
        y: heightCentr / this.coef.global_coef - 10 + this.coef.global_size_flat,
        width: centerWidth / this.coef.global_coef
      });
      group.add(sizess);
    }
    console.warn(this.coef.shapesLayerw);
    this.coef.shapesLayerw.add(group);
    this.coef.shapesLayerw.batchDraw();
    this.create.priceSend();
    new Sizes(this.coef, this.create).globalSize(
      {
        top: this.coef.topPositaionWrapper,
        left: centerPosition,
        width: (e.width / this.coef.global_coef)
      }, {
        top: this.coef.topPositaionWrapper,
        right: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
        height: (e.height / this.coef.global_coef)
      });
  }
  isDotyag(e){
    if(e.init_types){
        for(const item of e.init_types){
            if(item.id == 8  && item.value ==151){
              return true;
            }
        }
    }
    return false;
  }
  initNeo(e, parent) {
    console.log(e,parent , 'dasdasdasd111!!!')
    const isD = this.isDotyag(e)
    const centerPosition = (this.coef.widthWrapper - e.width / this.coef.global_coef) / 2;

    const base = this.isBase(e.init_types);
    let heightLeftRight = e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat * 2;
    let widthBottomWall = (e.width / this.coef.global_coef);
    let xBottomWall = centerPosition;
    if (base) {
      heightLeftRight = e.height / this.coef.global_coef - this.coef.global_size_flat;
      widthBottomWall = (e.width / this.coef.global_coef) - this.coef.global_size_flat * 2;
      xBottomWall = centerPosition + this.coef.global_size_flat;
    }
    this.create.createElement({
      leftPosition: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
      topPosition: this.coef.topPositaionWrapper + this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: heightLeftRight,
      type: 'vertical',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 0},
        side: 'R'
      }
    });
    this.create.createElement({
      leftPosition: centerPosition,
      topPosition: this.coef.topPositaionWrapper + this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: heightLeftRight,
      type: 'vertical',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 0},
        side: 'L'
      }
    });
    this.create.createElement({
      leftPosition: centerPosition,
      topPosition: this.coef.topPositaionWrapper,
      width: (e.width / this.coef.global_coef),
      height: this.coef.global_size_flat,
      type: 'horizon',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.top_wall,
        distance: {min: 0}
      }
    });
    this.create.createElement({
      leftPosition: xBottomWall,
      topPosition: this.coef.topPositaionWrapper + (e.height / this.coef.global_coef) - this.coef.global_size_flat - this.coef.height_legs,
      width: widthBottomWall,
      height: this.coef.global_size_flat,
      type: 'horizon',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.bottom_wall,
        distance: {min: 0}
      }
    });
    if (this.coef.pramsId != 13) {
      if (!this.render.rerender) {
        new Partition(this.coef, this.create, true).createFromParitation({
          evt: {
            layerX: centerPosition + this.coef.global_size_flat + (+this.coef.widthSep) / this.coef.global_coef,
            layerY: this.coef.topPositaionWrapper + this.coef.global_size_flat * 5
          }
        }, {
          x: centerPosition + this.coef.global_size_flat + (+this.coef.widthSep) / this.coef.global_coef,
          y: this.coef.topPositaionWrapper + this.coef.global_size_flat * 5
        }, false, true);
        new Partition(this.coef, this.create, true).createFromParitation({
          evt: {
            layerX: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat * 2 - this.coef.widthSep / this.coef.global_coef,
            layerY: this.coef.topPositaionWrapper + this.coef.global_size_flat * 5
          }
        }, {
          x: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat * 2 - this.coef.widthSep / this.coef.global_coef,
          y: this.coef.topPositaionWrapper + this.coef.global_size_flat * 5
        }, false, true);

        new Classic(this.coef, this.create, this.minSize).typeNeo(isD);
      }
      if (!base) {
        this.coef.shapesLayerw.children[2].attrs.bind.push(this.coef.shapesLayerw.children[1], this.coef.shapesLayerw.children[0]);
        this.coef.shapesLayerw.children[3].attrs.bind.push(this.coef.shapesLayerw.children[1], this.coef.shapesLayerw.children[0]);
      } else {
        this.coef.shapesLayerw.children[2].attrs.bind.push(this.coef.shapesLayerw.children[1], this.coef.shapesLayerw.children[0]);
        this.coef.shapesLayerw.children[1].attrs.bind.push(this.coef.shapesLayerw.children[3]);
        this.coef.shapesLayerw.children[0].attrs.bind.push(this.coef.shapesLayerw.children[3]);
      }
      new Sizes(this.coef, this.create).globalSize(
        {
          top: this.coef.topPositaionWrapper,
          left: centerPosition,
          width: (e.width / this.coef.global_coef)
        }, {
          top: this.coef.topPositaionWrapper,
          right: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
          height: (e.height / this.coef.global_coef)
        });
    }
  }

  initTop(e, parent) {
    const top_vertical_offset = this.coef.spec_values['top_vertical_offset'];
    const centerPosition = (this.coef.widthWrapper - e.width / this.coef.global_coef) / 2;
    this.create.createElement({
      leftPosition: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
      topPosition: this.coef.topPositaionWrapper,
      width: this.coef.global_size_flat,
      height: e.height / this.coef.global_coef,
      type: 'vertical',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 0},
        side: 'R'
      }
    });
    this.create.createElement({
      leftPosition: centerPosition,
      topPosition: this.coef.topPositaionWrapper,
      width: this.coef.global_size_flat,
      height: e.height / this.coef.global_coef,
      type: 'vertical',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 0},
        side: 'L'
      }
    });
    this.create.createElement({
      leftPosition: centerPosition + this.coef.global_size_flat,
      topPosition: this.coef.topPositaionWrapper + (top_vertical_offset / this.coef.global_coef),
      // topPosition: this.coef.topPositaionWrapper ,
      width: (e.width / this.coef.global_coef) - this.coef.global_size_flat * 2,
      height: this.coef.global_size_flat,
      type: 'horizon',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.top_wall,
        distance: {min: 0}
      }
    });
    this.create.createElement({
      leftPosition: centerPosition + this.coef.global_size_flat,
      topPosition: this.coef.topPositaionWrapper + (e.height / this.coef.global_coef) - this.coef.global_size_flat - this.coef.height_legs,
      width: (e.width / this.coef.global_coef) - this.coef.global_size_flat * 2,
      height: this.coef.global_size_flat,
      type: 'horizon',
      contours: null,
      wrapper: true,
      layer: parent,
      params: {
        removable: false,
        type: this.coef.id_element.bottom_wall,
        distance: {min: 0}
      }
    });
    this.coef.shapesLayerw.children[1].attrs.bind.push(this.coef.shapesLayerw.children[2], this.coef.shapesLayerw.children[3]);
    this.coef.shapesLayerw.children[0].attrs.bind.push(this.coef.shapesLayerw.children[2], this.coef.shapesLayerw.children[3]);
    if (this.coef.pramsId != 13) {
      if (!this.render.rerender) {
        // new Classic(this.coef, this.create, this.minSize).typWidthTop();
        const createClass = new Classic(this.coef, this.create, this.minSize);
        createClass.top = this.coef.topPositaionWrapper + this.coef.global_size_flat + (top_vertical_offset / this.coef.global_coef);
        createClass.topForShelf = (this.coef.topPositaionWrapper + (top_vertical_offset / this.coef.global_coef)) + this.coef.global_size_flat * 2 + (350 / this.coef.global_coef);
        createClass.height = createClass.bottom - createClass.top;

        createClass.typWidthTop();
      }
      new Sizes(this.coef, this.create).globalSize(
        {
          top: this.coef.topPositaionWrapper,
          left: centerPosition,
          width: (e.width / this.coef.global_coef)
        }, {
          top: this.coef.topPositaionWrapper,
          right: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
          height: (e.height / this.coef.global_coef)
        });
    }
  }

  initBigCabinet(e, parent) {
    const base = this.isBase(e.init_types);
    const centerPosition = (this.coef.widthWrapper - e.width / this.coef.global_coef) / 2;
    let heightLeftRight = e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat * 2;
    let widthBottomWall = (e.width / this.coef.global_coef) / 2;
    let xBottomWallLeft = centerPosition;
    this.coef.countNameElement++;
    if (base) {
      heightLeftRight = e.height / this.coef.global_coef - this.coef.global_size_flat;
      widthBottomWall = (e.width / this.coef.global_coef) / 2 - this.coef.global_size_flat;
      xBottomWallLeft = centerPosition + this.coef.global_size_flat;
    }
    const left_ateral_wall = new Konva.Rect({
      x: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
      y: this.coef.topPositaionWrapper + this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: heightLeftRight,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 0},
        side: 'R'

      },
      draggable: false,
    });
    this.coef.countNameElement++;
    const right_ateral_wall = new Konva.Rect({
      x: centerPosition,
      y: this.coef.topPositaionWrapper + this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: heightLeftRight,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 0},
        side: 'L'

      },
      draggable: false,
    });
    this.coef.countNameElement++;

    const left_top_wall_render = {x: centerPosition, width: (e.width / this.coef.global_coef) / 2};
    const left_bottom_wall_render = {x: xBottomWallLeft, width: widthBottomWall};
    const right_bottom_wall_render = {
      x: ((e.width / this.coef.global_coef) - this.coef.global_size_flat * 2) / 2 + centerPosition + this.coef.global_size_flat,
      width: widthBottomWall
    };
    const right_top_wall_render = {
      x: ((e.width / this.coef.global_coef) - this.coef.global_size_flat * 2) / 2 + centerPosition + this.coef.global_size_flat,
      width: (e.width / this.coef.global_coef) / 2
    };
    if (this.render.rerender) {
      for (const item of this.render.object.content_object) {
        if (item && item.params.type === this.coef.id_element.top_wall && item.params.side === 'L') {
          left_top_wall_render.x = item.x;
          left_top_wall_render.width = item.width;
        }
        if (item && item.params.type === this.coef.id_element.bottom_wall && item.params.side === 'L') {
          left_top_wall_render.x = item.x;
          left_top_wall_render.width = item.width;
        }
        if (item && item.params.type === this.coef.id_element.bottom_wall && item.params.side === 'R') {
          right_bottom_wall_render.x = item.x;
          right_bottom_wall_render.width = item.width;
        }
        if (item && item.params.type === this.coef.id_element.top_wall && item.params.side === 'R') {
          right_top_wall_render.x = item.x;
          right_top_wall_render.width = item.width;
        }
      }
    }

    const left_top_wall = new Konva.Rect({
      x: left_top_wall_render.x,
      y: this.coef.topPositaionWrapper,
      width: left_top_wall_render.width,
      height: this.coef.global_size_flat,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.top_wall,
        distance: {min: 0},
        side: 'L'
      },
      draggable: false,
    });
    this.coef.countNameElement++;
    const left_bottom_wall = new Konva.Rect({
      x: left_bottom_wall_render.x,
      y: this.coef.topPositaionWrapper + (e.height / this.coef.global_coef) - this.coef.global_size_flat - this.coef.height_legs,
      width: left_bottom_wall_render.width,
      height: this.coef.global_size_flat,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.bottom_wall,
        distance: {min: 0},
        side: 'L'
      },
      draggable: false,
    });
    this.coef.countNameElement++;
    const right_bottom_wall = new Konva.Rect({
      x: right_bottom_wall_render.x,
      y: this.coef.topPositaionWrapper + (e.height / this.coef.global_coef) - this.coef.global_size_flat - this.coef.height_legs,
      width: right_bottom_wall_render.width,
      height: this.coef.global_size_flat,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.bottom_wall,
        distance: {min: 0},
        side: 'R'
      },
      draggable: false,
    });
    this.coef.countNameElement++;
    const right_top_wall = new Konva.Rect({
      x: right_top_wall_render.x,
      y: this.coef.topPositaionWrapper,
      width: right_top_wall_render.width,
      height: this.coef.global_size_flat,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.top_wall,
        distance: {min: 0},
        side: 'R'
      },
      draggable: false,
    });
    this.coef.countNameElement++;
    let x = ((e.width / this.coef.global_coef) - this.coef.global_size_flat * 2) / 2 + centerPosition;
    x = Math.floor(x * this.coef.global_coef) / this.coef.global_coef;

    if (this.render.rerender) {
      for (const item of this.render.object.content_object) {
        if (item && item.params.type == this.coef.id_element.double_partition) {
          x = item.x;
        }
      }
    }
    const double_partition = new Konva.Group({
      x: x,
      y: this.coef.topPositaionWrapper + this.coef.global_size_flat,
      width: this.coef.global_size_flat * 2,
      name: 'element' + this.coef.countNameElement,
      bind: [left_top_wall, left_bottom_wall, right_bottom_wall, right_top_wall],
      params: {
        removable: false,
        type: this.coef.id_element.double_partition,
        distance: {min: 250},
        height: e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat * 2
      },
      draggable: true,
      dragBoundFunc: function (pos) {
        return {
          x: pos.x,
          y: this.getAbsolutePosition().y
        };
      }
    });
    this.coef.countNameElement++;
    const left_double_partition = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.coef.global_size_flat,
      height: e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat * 2,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 250}
      },
      draggable: false,
    });
    this.coef.countNameElement++;
    const right_double_partition = new Konva.Rect({
      x: this.coef.global_size_flat,
      y: 0,
      width: this.coef.global_size_flat,
      height: e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat * 2,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 250}
      },
      draggable: false,
    });
    this.coef.countNameElement++;
    this.create.bindingTocounters(
      left_ateral_wall,
      double_partition,
      null,
      null,
      left_top_wall,
      'horizon',
      base
    );
    this.create.bindingTocounters(
      left_ateral_wall,
      double_partition,
      null,
      null,
      left_bottom_wall,
      'horizon',
      base
    );
    this.create.bindingTocounters(
      double_partition,
      left_ateral_wall,
      null,
      null,
      right_top_wall,
      'horizon',
      base
    );
    this.create.bindingTocounters(
      double_partition,
      left_ateral_wall,
      null,
      null,
      right_bottom_wall,
      'horizon',
      base
    );
    if (this.coef.pramsId != 13) {
      this.deleteBind(right_ateral_wall);
      this.deleteBind(left_ateral_wall);
      double_partition.add(left_double_partition);
      double_partition.add(right_double_partition);
      this.coef.shapesLayerw.add(left_ateral_wall);
      this.coef.shapesLayerw.add(right_ateral_wall);
      this.coef.shapesLayerw.add(left_top_wall);
      this.coef.shapesLayerw.add(left_bottom_wall);
      this.coef.shapesLayerw.add(right_bottom_wall);
      this.coef.shapesLayerw.add(right_top_wall);
      this.coef.shapesLayerw.add(double_partition);
      this.coef.shapesLayerw.batchDraw();


      right_bottom_wall['attrs'].bind.push(double_partition);
      right_top_wall['attrs'].bind.push(left_ateral_wall, double_partition);
      left_bottom_wall['attrs'].bind.push(double_partition);
      left_top_wall['attrs'].bind.push(right_ateral_wall, double_partition);
      if (!base) {
        left_bottom_wall['attrs'].bind.push(right_ateral_wall);
        right_bottom_wall['attrs'].bind.push(left_ateral_wall);
      } else {
        left_ateral_wall['attrs'].bind.push(left_bottom_wall);
        right_ateral_wall['attrs'].bind.push(right_bottom_wall);
      }

      this.minSize.minsize(this.coef.shapesLayerw);

      new Sizes(this.coef, this.create).globalSize(
        {
          top: this.coef.topPositaionWrapper,
          left: centerPosition,
          width: (e.width / this.coef.global_coef)
        }, {
          top: this.coef.topPositaionWrapper,
          right: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
          height: (e.height / this.coef.global_coef)
        });

      if (!this.render.rerender) {
        new Classic(this.coef, this.create, this.minSize).typWidth();
      }
    }
  }

  initBigCabinetTop(e, parent) {
    const top_vertical_offset = this.coef.spec_values['top_vertical_offset'];
    const centerPosition = (this.coef.widthWrapper - e.width / this.coef.global_coef) / 2;
    this.coef.countNameElement++;
    const width = 900 / this.coef.global_coef + this.coef.global_size_flat;
    const rightDubl = centerPosition + width;
    const left_ateral_wall = new Konva.Rect({
      x: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
      y: this.coef.topPositaionWrapper,
      width: this.coef.global_size_flat,
      height: e.height / this.coef.global_coef,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 0},
        side: 'R'

      },
      draggable: false,
    });
    this.coef.countNameElement++;
    const right_ateral_wall = new Konva.Rect({
      x: centerPosition,
      y: this.coef.topPositaionWrapper,
      width: this.coef.global_size_flat,
      height: e.height / this.coef.global_coef,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 0},
        side: 'L'

      },
      draggable: false,
    });
    this.coef.countNameElement++;


    const left_top_wall_render = {x: centerPosition + this.coef.global_size_flat, width: width + this.coef.global_size_flat};
    const left_bottom_wall_render = {x: centerPosition + this.coef.global_size_flat, width: width + this.coef.global_size_flat};
    const right_bottom_wall_render = {
      x: centerPosition + width + this.coef.global_size_flat * 2,
      width: (e.width / this.coef.global_coef) - width - this.coef.global_size_flat * 3
    };
    const right_top_wall_render = {
      x:  centerPosition + width + this.coef.global_size_flat * 2,
      width: (e.width / this.coef.global_coef) - width - this.coef.global_size_flat * 3
    };
    if (this.render.rerender) {
      for (const item of this.render.object.content_object) {
        if (item && item.params.type === this.coef.id_element.top_wall && item.params.side === 'L') {
          left_top_wall_render.x = item.x;
          left_top_wall_render.width = item.width;
        }
        if (item && item.params.type === this.coef.id_element.bottom_wall && item.params.side === 'L') {
          left_bottom_wall_render.x = item.x;
          left_bottom_wall_render.width = item.width;
        }
        if (item && item.params.type === this.coef.id_element.bottom_wall && item.params.side === 'R') {
          right_bottom_wall_render.x = item.x;
          right_bottom_wall_render.width = item.width;
        }
        if (item && item.params.type === this.coef.id_element.top_wall && item.params.side === 'R') {
          right_top_wall_render.x = item.x;
          right_top_wall_render.width = item.width;
        }
      }
    }
    const left_top_wall = new Konva.Rect({
      x: left_top_wall_render.x,
      y: this.coef.topPositaionWrapper + (top_vertical_offset / this.coef.global_coef),
      width: left_top_wall_render.width,
      height: this.coef.global_size_flat,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.top_wall,
        distance: {min: 0},
        side: 'L'
      },
      draggable: false,
    });
    this.coef.countNameElement++;
    const left_bottom_wall = new Konva.Rect({
      x: left_bottom_wall_render.x,
      y: this.coef.topPositaionWrapper + (e.height / this.coef.global_coef) - this.coef.global_size_flat - this.coef.height_legs,
      width: left_bottom_wall_render.width ,
      height: this.coef.global_size_flat,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.bottom_wall,
        distance: {min: 0},
        side: 'L'
      },
      draggable: false,
    });
    this.coef.countNameElement++;
    const right_bottom_wall = new Konva.Rect({
      x: right_bottom_wall_render.x,
      y: this.coef.topPositaionWrapper + (e.height / this.coef.global_coef) - this.coef.global_size_flat - this.coef.height_legs,
      width: right_bottom_wall_render.width,
      height: this.coef.global_size_flat,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.bottom_wall,
        distance: {min: 0},
        side: 'R'
      },
      draggable: false,
    });
    this.coef.countNameElement++;
    const right_top_wall = new Konva.Rect({
      x: right_top_wall_render.x,
      y: this.coef.topPositaionWrapper + (top_vertical_offset / this.coef.global_coef),
      width: right_top_wall_render.width,
      height: this.coef.global_size_flat,
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.top_wall,
        distance: {min: 0},
        side: 'R'
      },
      draggable: false,
    });
    this.coef.countNameElement++;
    let x = centerPosition + width + this.coef.global_size_flat;


    if (this.render.rerender) {
      for (const item of this.render.object.content_object) {
        if (item && item.params.type === this.coef.id_element.double_partition) {
          x = item.x;
        }
      }
    }

    const double_partition = new Konva.Group({
      x: x,
      y: this.coef.topPositaionWrapper + this.coef.global_size_flat + (top_vertical_offset / this.coef.global_coef),
      width: this.coef.global_size_flat * 2,
      name: 'element' + this.coef.countNameElement,
      bind: [left_top_wall, left_bottom_wall, right_bottom_wall, right_top_wall],
      params: {
        removable: false,
        type: this.coef.id_element.double_partition,
        distance: {min: 250},
        height: e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat * 2
      },
      draggable: true,
      dragBoundFunc: function (pos) {
        return {
          x: pos.x,
          y: this.getAbsolutePosition().y
        };
      }
    });
    this.coef.countNameElement++;
    const left_double_partition = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.coef.global_size_flat,
      height: (e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat * 2) - (top_vertical_offset / this.coef.global_coef),
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 250}
      },
      draggable: false,
    });
    this.coef.countNameElement++;
    const right_double_partition = new Konva.Rect({
      x: this.coef.global_size_flat,
      y: 0,
      width: this.coef.global_size_flat,
      height: (e.height / this.coef.global_coef - this.coef.height_legs - this.coef.global_size_flat * 2) - (top_vertical_offset / this.coef.global_coef),
      name: 'element' + this.coef.countNameElement,
      fill: this.coef.color,
      stroke: 'black',
      strokeWidth: 1,
      bind: [],
      params: {
        removable: false,
        type: this.coef.id_element.lateral_wall,
        distance: {min: 250}
      },
      draggable: false,
    });
    this.coef.countNameElement++;
    this.create.bindingTocounters(
      left_ateral_wall,
      double_partition,
      null,
      null,
      left_top_wall,
      'horizon'
    );
    this.create.bindingTocounters(
      left_ateral_wall,
      double_partition,
      null,
      null,
      left_bottom_wall,
      'horizon'
    );
    this.create.bindingTocounters(
      double_partition,
      left_ateral_wall,
      null,
      null,
      right_top_wall,
      'horizon'
    );
    this.create.bindingTocounters(
      double_partition,
      left_ateral_wall,
      null,
      null,
      right_bottom_wall,
      'horizon'
    );
    if (this.coef.pramsId != 13) {
      this.deleteBind(right_ateral_wall);
      this.deleteBind(left_ateral_wall);
      double_partition.add(left_double_partition);
      double_partition.add(right_double_partition);
      this.coef.shapesLayerw.add(left_ateral_wall);
      this.coef.shapesLayerw.add(right_ateral_wall);
      this.coef.shapesLayerw.add(left_top_wall);
      this.coef.shapesLayerw.add(left_bottom_wall);
      this.coef.shapesLayerw.add(right_bottom_wall);
      this.coef.shapesLayerw.add(right_top_wall);
      this.coef.shapesLayerw.add(double_partition);
      this.coef.shapesLayerw.batchDraw();
      // right_bottom_wall['attrs'].bind.push(left_ateral_wall, double_partition);
      // right_top_wall['attrs'].bind.push(left_ateral_wall, double_partition);
      // left_bottom_wall['attrs'].bind.push(right_ateral_wall, double_partition);
      // left_top_wall['attrs'].bind.push(right_ateral_wall, double_partition);
      left_ateral_wall['attrs'].bind.push(left_bottom_wall, left_top_wall);
      right_ateral_wall['attrs'].bind.push(right_top_wall, right_bottom_wall);

      this.minSize.minsize(this.coef.shapesLayerw);

      new Sizes(this.coef, this.create).globalSize(
        {
          top: this.coef.topPositaionWrapper,
          left: centerPosition,
          width: (e.width / this.coef.global_coef)
        }, {
          top: this.coef.topPositaionWrapper,
          right: centerPosition + e.width / this.coef.global_coef - this.coef.global_size_flat,
          height: (e.height / this.coef.global_coef)
        });

      if (!this.render.rerender) {
        // new Classic(this.coef, this.create, this.minSize).typWidthTop();
        const createClass = new Classic(this.coef, this.create, this.minSize);
        createClass.top = this.coef.topPositaionWrapper + this.coef.global_size_flat + (top_vertical_offset / this.coef.global_coef);
        createClass.topForShelf = (this.coef.topPositaionWrapper + (top_vertical_offset / this.coef.global_coef)) + this.coef.global_size_flat * 2 + (350 / this.coef.global_coef);
        ;
        createClass.height = createClass.bottom - createClass.top;
        createClass.typWidthTop();
      }
    }
  }

  deleteBind(element) {
    element.attrs.bind = [];
  }

  // chanchOpacity(string, opacity) {
  //   let respons = string.split(')');
  //   respons = respons[0] + ',' + opacity + ')';
  //   return respons;
  // }

  chanchOpacity(string, opacity) {
    let respons = string.split(',');
    let opac = respons[respons.length - 1].split(')')[0];
    respons.splice(respons.length - 1, 1);
    respons.toString();
    respons = respons + ',' + opacity + ')';
    return respons;
  }
}
