import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from '../../service/notification.service';
import {Subscription} from 'rxjs';
import {Notification} from '../../interfaces/notification.interface';
import {FADE_IN_OUT, STAGGER} from '../../common/animations';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  animations: [FADE_IN_OUT, STAGGER]
})
export class NotificationComponent implements OnInit, OnDestroy {
  public notificationsArray: Notification[] = [];
  private notificationSubscription: Subscription;

  constructor(public notification: NotificationService) {
  }

  ngOnInit() {
    this.notificationSubscription = this.notification.notificationTriggered.subscribe((notification: Notification) => {
      this.notificationsArray.push(notification);
    });
  }

  private onNotificationClose(index: number): void {
    this.notificationsArray.splice(index, 1);
  }

  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
  }

}
