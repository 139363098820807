import {Sizes} from '../sizes/sizes';
import {BlockCreate} from '../block-create';
import * as Konva from '../../../assets/konva';
import {Depth} from '../depth';

export class Superstructure extends BlockCreate {
  public startPosition;
  public coef;
  public create;
  remove;
  comod_group;

  constructor(coef, create?, remove?) {
    super(coef, create, remove);
    this.coef = coef;
    this.create = create;
    this.remove = remove;
  }

  createSuper(width, height, depth, stage, layer) {
    const centerPosition = (this.coef.widthWrapper - width) / 2;
    this.coef.countNameElement++;
    this.comod_group = new Konva.Group({
      x: centerPosition,
      y: this.coef.topPositaionWrapper,
      width: width,
      draggable: false,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: false,
      },
      bind: [],
    });
    const left = new Konva.Rect({
      x: 0,
      y: 0,
      width: width / 2,
      height: height,
      name: 'width',
      fill: this.coef.colorFacad, params: {facad: true},
      strokeWidth: 1,
      stroke: 'black',
    });
    const right = new Konva.Rect({
      x: width / 2,
      y: 0,
      width: width / 2,
      height: height,
      name: 'width',
      fill: this.coef.colorFacad, params: {facad: true},
      strokeWidth: 1,
      stroke: 'black',
    });
    const leftRect = new Konva.Rect({
      x: width / 2 - 20,
      y: height / 2 - 10,
      width: 10,
      height: 20,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: 'black',
    });
    const rightRect = new Konva.Rect({
      x: width / 2 + 10,
      y: height / 2 - 10,
      width: 10,
      height: 20,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: 'black',
    });
    const y = Math.floor((height / 2 - this.coef.global_size_flat / 2) * this.coef.global_coef) / this.coef.global_coef;
    const centerRect = new Konva.Rect({
      x: this.coef.global_size_flat,
      y: y,
      width: width - this.coef.global_size_flat * 2,
      height: this.coef.global_size_flat,
      params: {
        height: height - y - this.coef.global_size_flat * 2,
        facad: true
      },
      name: 'width',
      fill: this.coef.colorFacad,
      dash: [10, 5],
      strokeWidth: 1,
      stroke: 'black',
    });
    const topRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: width,
      height: this.coef.global_size_flat,
      name: 'width',
      fill: this.coef.colorFacad,
      params: {
        height: centerRect['attrs'].y - this.coef.global_size_flat,
        facad: true
      },
      dash: [10, 5],
      strokeWidth: 1,
      stroke: 'black',
    });
    const bottomrect = new Konva.Rect({
      x: 0,
      y: height - this.coef.global_size_flat,
      width: width,
      height: this.coef.global_size_flat,
      name: 'width',
      fill: this.coef.colorFacad, params: {facad: true},
      dash: [10, 5],
      strokeWidth: 1,
      stroke: 'black',
    });
    const leftrect = new Konva.Rect({
      x: 0,
      y: this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: height - this.coef.global_size_flat * 2,
      name: 'width',
      fill: this.coef.colorFacad, params: {facad: true},
      dash: [10, 5],
      strokeWidth: 1,
      stroke: 'black',
    });
    const rightrect = new Konva.Rect({
      x: width - this.coef.global_size_flat,
      y: this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: height - this.coef.global_size_flat * 2,
      name: 'width',
      fill: this.coef.colorFacad, params: {facad: true},
      dash: [10, 5],
      strokeWidth: 1,
      stroke: 'black',
    });

    const frameColor = new Konva.Rect({
      x: 0,
      y: height + 20,
      width: width,
      height: 30,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });

    this.comod_group.add(left, right, centerRect, leftRect, rightRect, topRect, bottomrect, leftrect, rightrect, frameColor);
    const size = new Sizes(this.coef, this.create).createOneSizeVertical(false, topRect, false, false, 2);
    const size2 = new Sizes(this.coef, this.create).createOneSizeVertical(false, centerRect, false, false, 2);
    this.comod_group.add(size, size2);
    layer.add(this.comod_group);
    stage.add(layer);
    new Sizes(this.coef, this.create).globalSize(
      {
        top: this.coef.topPositaionWrapper,
        left: centerPosition,
        width: (width)
      }, {
        top: this.coef.topPositaionWrapper,
        right: centerPosition + width - 110,
        height: (height)
      });
    const depths = new Depth(this.coef);
    depths.createDepth(height * this.coef.global_coef, layer, stage);
  }

  _roundUpToLovest(value, step) {
    const biggest = (~~((value + (step - 1)) / step) * step);
    return biggest === +value ? biggest : biggest - step;
  }
}
