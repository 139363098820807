import * as Konva from '../../../assets/konva';
import {Photo} from './photo';

export class ColorElement {
  coefDoor;
  coef;

  constructor(coefDoor, coef) {
    this.coefDoor = coefDoor;
    this.coef = coef;
  }

  createElmentColor(id, params, type  , fill?) {
    console.log( fill , type.subId , params.isImage,'this.coefDoor.facade_materials')
    for (const materials of this.coefDoor.facade_materials) {
      if (id == materials.id) {
        if (params.item.id !== 1) {
          params.fill = materials.default_option.rgb;
          params.idElement = materials.default_option.id;
        } else {
          params.fill = this.coef.color;
          params.idElement = this.coef.colorID;
        }
        if (materials.options[0].images && (type.subId === 8 || type.subId === 5 || type.subId === 11 || type.subId === 2)) {
          if (materials.options[0].images[0].path && materials.default_option.images) {
              params.fill = materials.default_option.images[0].path;
              params.idElement = 0;
              params.parentId = materials.default_option.id;
          }else{
            if(!fill){
              params.isImage = false;
            }
          
          }
        }
        if(fill){
          params.fill = fill;
        }
        return this.create(params, type);
      }
    }
    return false;
  }

  create(params, type) {
    console.log(params , 'asdsad')
    if (!params.isImage) {
      const color = new Konva.Rect({
        x: params.x,
        y: params.y,
        width: params.width,
        height: params.height,
        name: 'door' + this.coefDoor.name,
        params: type,
        fill: params.fill,
        strokeWidth: 1,
        stroke: 'black'
      });
      color['attrs'].params.remove = true;
      color['attrs'].params.id = params.idElement;
      color['attrs'].params.typeDoor = params.item.id;
      color['attrs'].params.price = true;
      return color;
    } else {
      console.log(params.fill , '123teasd123')
      // return await Promise.all([ new Photo(params).create(params.fill, type) ])
      return new Photo(params).create(params.fill, type, params).then((e) => {
        return e;
      });
    }
  }
}
