import {Injectable} from '@angular/core';
import {Socket} from 'ng-socket-io';
import {map} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CoefService} from './coef.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SocketPriceService {
  price = new Subject();

  constructor(private socket: Socket, private coef: CoefService, private router: Router) {
    this.getMessage();
  }

  sendMessage(obj: any) {
    console.log( this.coef.end_client, 'end_client');
    if (this.coef.end_client) {
      obj.user = Number(this.coef.end_client);
    }
    if (obj.user_id === 'false') {
      delete obj.user_id;
    }
    const isClientPage = this.router.url.indexOf('calculator?') !== -1;
    if (isClientPage) {
      delete obj.user_id;
    }
    this.socket.emit('count', obj);
  }

  getMessage() {
    return this.socket.on('result', (data) => {
      if (isNaN(data)) {
        // this.coef.errorTrigger('Розірвано зєднання з сервером, спробуйте знову.');
        // this.router.navigate(['order']);
      }
      this.price.next(data);
    });
  }
}
