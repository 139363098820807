import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByName'
})
export class FilterByNamePipe implements PipeTransform {
    transform(items: any[], searchText: string | null): any[] {
        if (!searchText) {
          return items; // No filter, return all items
        }
    
        return items.filter(item => {
            let words;
            if(item.names){
                words  = item.names.split(' ');
            }else{
                 words = item.name.split(' ');
            }
            console.log(item)
            return words.some(word => word.toLowerCase().startsWith(searchText.toLowerCase()));
          });
      }
}