import {Injectable} from '@angular/core';
import * as Konva from '../../../assets/konva';
import {CoefDoorService} from './coef-door.service';
import {CoefService} from '../coef.service';
import {CreateMaterials} from '../../class/doorMaterials/create-materials';
import {Subject} from 'rxjs';
import {DragService} from './drag.service';
import {CreateService} from '../create.service';
import {Sizes} from '../../class/sizes/sizes';
import {PriceService} from '../price.service';
import {InitRenderService} from '../renderingService/init-render.service';
import * as _ from 'lodash';
import { ColorElement } from '../../class/doorMaterials/color-element';
import { async } from 'rxjs/internal/scheduler/async';
@Injectable({
  providedIn: 'root'
})
export class InitDoorService {
  openSlid = new Subject();

  constructor(private coefDoor: CoefDoorService,
              private coef: CoefService,
              private dragService: DragService,
              private create: CreateService,
              private price: PriceService,
              private drag: DragService,
              private render: InitRenderService) {

  }

 async intDoor(stage ,changeSystem?) {
    this.coefDoor.heightDoor = (this.coef.setingsWardrobe.height / this.coef.global_coef) - this.coef.height_legs - this.coef.global_size_flat * 2 - (this.coefDoor.glob_settings.system_thickness / this.coef.global_coef);
    if (this.coef.pramsId == 13) {
      this.coefDoor.heightDoor = (this.coef.setingsWardrobe.height / this.coef.global_coef) - (this.coefDoor.glob_settings.system_thickness / this.coef.global_coef);
    }
    if (this.coef.pramsId == 11) {
      //need chack
      this.coefDoor.heightDoor = (this.coef.setingsWardrobe.height / this.coef.global_coef) - this.coefDoor.glob_settings.system_thickness / this.coef.global_coef;
    }
    if(!changeSystem){
      this.ManualTravel();
    }
  
    this.coefDoor.getStage(stage);
    let positionX = 0;
    for (const door of this.coef.doorsArray) {
      await this.oneDoor(door['width'], positionX, this.coef.doorsArray.indexOf(door) , changeSystem);
        positionX = positionX + (door['width'] / this.coef.global_coef) + 2;
    }
    // this.coef.doorsArray.forEach(async door => {
    //   await this.oneDoor(door['width'], positionX, this.coef.doorsArray.indexOf(door));
    //   positionX = positionX + (door['width'] / this.coef.global_coef) + 2;
    // });
    this.coefDoor.DoorWrapper.on('click', (e) => {
      
     
      if (e.target.attrs.params && e.target.attrs.params.part) {
        if( this.coefDoor.manualTravelElement &&  this.coefDoor.manualTravelElement.attrs){
          this.coefDoor.manualTravelElement.attrs.fill = this.coefDoor.colorSystem;
        }
        this.coefDoor.manualTravelElement = e.target;
        console.log(e.target)
        this.coefDoor.manualTravelElement.attrs.fill = 'green';
      }

      if (this.coefDoor.reDesign && this.coefDoor.reDesign.attrs && this.coefDoor.reDesign.attrs.params) {
        this.coefDoor.reDesign.attrs.strokeWidth = 1;
        this.coefDoor.reDesign.attrs.stroke = 'black';
        this.coefDoor.DoorWrapper.batchDraw();
      }
      this.coefDoor.reDesign = e.target;
      if(e.target.attrs.params && (e.target.attrs.params.isTexture || e.target.attrs.params.isFilter)){
        this.coefDoor.reDesign = e.target.attrs.params.objectSelect;
      }
      if (this.coefDoor.reDesign.attrs && this.coefDoor.reDesign.attrs.params && this.coefDoor.reDesign.attrs.params.subId) {
        this.coefDoor.reDesign.attrs.strokeWidth = 5;
        this.coefDoor.reDesign.attrs.stroke = 'red';
        this.coefDoor.DoorWrapper.batchDraw();
        if (this.coefDoor.reDesign.attrs.params.typeDoor !== 1) {
          this.openSlid.next({id: this.coefDoor.reDesign.attrs.params.subId, obj: this.coefDoor.reDesign});
        } else {
          this.openSlid.next({id: false, obj: this.coefDoor.reDesign});
        }
      }else{
        this.openSlid.next(false);
      }
    });
  }

  findItem(id) {
   
    return {id:null , content:null , element_min_height: 100 ,flat_element_max_height: 2000 , flat_element_min_height:100};

}
findItemMat(id,array){
  for(const item of array){
    if(id == item.id){
      return item
    }
  }
}
 async changItem(id, color, subId, parentId? ,texduredReserved?) {
    if (this.coefDoor.reDesign.attrs.params.subId) {
      if(this.coefDoor.reDesign.attrs.params.printId){
        this.coefDoor.reDesign.attrs.params.filter.visible(false);
        this.coefDoor.reDesign.attrs.params.printId = false;
        this.coefDoor.reDesign.attrs.params.position = false;
      }
        if(subId == 1 || subId == 10 || subId == 4){
          var SOURCE2 = 'assets/img/texture/sclo.jpg';
          const filter = this.coefDoor.reDesign.attrs.params.filter
          const imageObj = new Image();
          imageObj.onload = () => {
            filter.image(imageObj);
            this.price.sendObjectPrice();
            this.coefDoor.DoorWrapper.batchDraw();
          };
          imageObj.crossOrigin = 'Anonymous';
          imageObj.src = SOURCE2;
          filter.visible(true);
          filter.opacity(0.5);
        }else{
          this.coefDoor.reDesign.attrs.params.filter.visible(false);
        }
     const matDef =  this.findMat(subId,1 , this.coefDoor.facade_materials)
     const textured = this.findItemMat(parentId ||id,matDef.options).textured;
     console.log(id, color, subId, parentId,this.coefDoor.reDesign.className  , 'texduredReserved?')
    if(this.coefDoor.reDesign.className != "Image"  &&  parentId){
      const obj:any = await new ColorElement(this.coefDoor, this.coef).createElmentColor(subId, {
          width:this.coefDoor.reDesign.attrs.width,
          y: this.coefDoor.reDesign.attrs.y,
          x:  this.coefDoor.reDesign.attrs.x,
          height:  this.coefDoor.reDesign.attrs.height,
          item: this.coefDoor.reDesign.attrs.params.item,
          index: this.coefDoor.reDesign.attrs.params.index,
          isImage:true,
        }, {subId:Number(subId) } , color);
        obj.attrs.params = this.coefDoor.reDesign.attrs.params;
        obj.attrs.params.subId = subId;
        obj.attrs.params.id = parentId;
        obj.attrs.params.position = id;
        obj.attrs.params.istextured = false;
        obj.attrs.params.isTextureReversed = false;
        obj.attrs.params.texturedV.visible(false) ;
        obj.attrs.params.texturedH.visible(false) ;
        const top =  this.coefDoor.reDesign.attrs.params.topElem;
        const bottom =  this.coefDoor.reDesign.attrs.params.bottomElem;
        const left =  this.coefDoor.reDesign.attrs.params.leftElem;
        const right =  this.coefDoor.reDesign.attrs.params.rightElem;
        const sizeV = this.coefDoor.reDesign.attrs.params.sizeV;
        const sizeH = this.coefDoor.reDesign.attrs.params.sizeH;
        const texturedH = this.coefDoor.reDesign.attrs.params.texturedH;
        const texturedV = this.coefDoor.reDesign.attrs.params.texturedV;
        const bgText = this.coefDoor.reDesign.attrs.params.bgText;
        const text = this.coefDoor.reDesign.attrs.params.text;
        if(textured){
          this.coefDoor.reDesign.attrs.params.istextured = true;
         const  reservedtextured = texduredReserved || this.coefDoor.reDesign.attrs.params.isTextureReversed
         this.coefDoor.reDesign.attrs.params.isTextureReversed = reservedtextured;
         if(reservedtextured){
          this.coefDoor.reDesign.attrs.params.istextured = true;
          texturedV.visible(false) ;
          texturedH.visible(true);
        }else{
          texturedV.visible(true) ;
          texturedH.visible(false);
        }
      }else{
        this.coefDoor.reDesign.attrs.params.istextured = false;
        this.coefDoor.reDesign.attrs.params.isTextureReversed = false;
        texturedH.visible(false) ;
        texturedV.visible(false) ;
      }
        if(top){
          if( top.attrs.params.bottomElem){
            top.attrs.params.bottomElem.push(obj)
          }else{
            top.attrs.params.bottomElem = [obj]
          }
        }
        if(left){
          if( left.attrs.params.rightElem){
            left.attrs.params.rightElem.push(obj)
          }else{
            left.attrs.params.rightElem = [obj]
          }
        }
        if(right){
          if( right.attrs.params.leftElem){
            right.attrs.params.leftElem.push(obj)
          }else{
            right.attrs.params.leftElem = [obj]
          }
        }
        if(bottom){
          if( bottom.attrs.params.topElem){
            bottom.attrs.params.topElem.push(obj)
          }else{
            bottom.attrs.params.topElem = [obj]
          }
        }
          this.coefDoor.reDesign.remove()
          this.coefDoor.reDesign = obj;
          this.coefDoor.reDesign.attrs.strokeWidth = 5;
          this.coefDoor.reDesign.attrs.stroke = 'red';
          this.coefDoor.DoorWrapper.add(obj);
          this.coefDoor.DoorWrapper.batchDraw();
          obj.moveToTop( );
          texturedH.moveToTop()
          texturedV.moveToTop()
          sizeH.moveToTop( );
          bgText.moveToTop( );
          text.moveToTop( );
          sizeV.moveToTop( );

    }else if (this.coefDoor.reDesign.className == "Image"  && !parentId) {
      console.log('test first ')
      const obj:any = await new ColorElement(this.coefDoor, this.coef).createElmentColor(subId, {
        width:this.coefDoor.reDesign.attrs.width,
        y: this.coefDoor.reDesign.attrs.y,
        x:  this.coefDoor.reDesign.attrs.x,
        height:  this.coefDoor.reDesign.attrs.height,
        item: this.coefDoor.reDesign.attrs.params.item,
        index: this.coefDoor.reDesign.attrs.params.index,
        isImage:false,
      }, {subId:Number(subId) } , color);
      obj.attrs.params = this.coefDoor.reDesign.attrs.params;
      obj.attrs.params.subId = subId;
      obj.attrs.params.id = id;
      obj.attrs.params.isTextureReversed = false;
      const top =  this.coefDoor.reDesign.attrs.params.topElem;
      const bottom =  this.coefDoor.reDesign.attrs.params.bottomElem;
      const left =  this.coefDoor.reDesign.attrs.params.leftElem;
      const right =  this.coefDoor.reDesign.attrs.params.rightElem;
      const sizeV = this.coefDoor.reDesign.attrs.params.sizeV;
      const sizeH = this.coefDoor.reDesign.attrs.params.sizeH;
      const bgText = this.coefDoor.reDesign.attrs.params.bgText;
      const text = this.coefDoor.reDesign.attrs.params.text;
      const texturedH = this.coefDoor.reDesign.attrs.params.texturedH;
      const texturedV = this.coefDoor.reDesign.attrs.params.texturedV;
      const filter = this.coefDoor.reDesign.attrs.params.filter;
      filter.attrs.params.objectSelect = obj;
      if(textured){
        this.coefDoor.reDesign.attrs.params.istextured = true;
       const  reservedtextured = texduredReserved || this.coefDoor.reDesign.attrs.params.isTextureReversed
       this.coefDoor.reDesign.attrs.params.isTextureReversed = reservedtextured;
       if(reservedtextured){
        this.coefDoor.reDesign.attrs.params.istextured = true;
        texturedV.visible(false) ;
        texturedH.visible(true);
      }else{
        texturedV.visible(true) ;
        texturedH.visible(false);
      }
    }else{
      this.coefDoor.reDesign.attrs.params.istextured = false;
      this.coefDoor.reDesign.attrs.params.isTextureReversed = false;
      texturedH.visible(false) ;
      texturedV.visible(false) ;
    }
     
      if(top){
        if( top.attrs.params.bottomElem){
          top.attrs.params.bottomElem.push(obj)
        }else{
          top.attrs.params.bottomElem = [obj]
        }
      }
      if(left){
        if( left.attrs.params.rightElem){
          left.attrs.params.rightElem.push(obj)
        }else{
          left.attrs.params.rightElem = [obj]
        }
      }
      if(right){
        if( right.attrs.params.leftElem){
          right.attrs.params.leftElem.push(obj)
        }else{
          right.attrs.params.leftElem = [obj]
        }
      }
      if(bottom){
        if( bottom.attrs.params.topElem){
          bottom.attrs.params.topElem.push(obj)
        }else{
          bottom.attrs.params.topElem = [obj]
        }
      }
        this.coefDoor.reDesign.remove()
        this.coefDoor.reDesign = obj;
        this.coefDoor.reDesign.attrs.strokeWidth = 5;
        this.coefDoor.reDesign.attrs.stroke = 'red';
        this.coefDoor.DoorWrapper.add(obj);
        this.coefDoor.DoorWrapper.batchDraw();
        obj.moveToTop( );
        texturedH.moveToTop()
        texturedV.moveToTop()
        filter.moveToTop();
        sizeH.moveToTop( );
        bgText.moveToTop( );
        text.moveToTop( );
        sizeV.moveToTop( );
    } else {
      if (this.coefDoor.reDesign.className == "Image" && parentId) {
       console.log('asdasdasdasd')
       this.coefDoor.reDesign.attrs.params.isTextureReversed = false;
       const texturedH = this.coefDoor.reDesign.attrs.params.texturedH;
        const texturedV = this.coefDoor.reDesign.attrs.params.texturedV;
        if(textured){
          this.coefDoor.reDesign.attrs.params.istextured = true;
         const  reservedtextured = texduredReserved || this.coefDoor.reDesign.attrs.params.isTextureReversed
         this.coefDoor.reDesign.attrs.params.isTextureReversed = reservedtextured;
         console.log(reservedtextured ,texduredReserved , this.coefDoor.reDesign.attrs.params.isTextureReversed )
          if(reservedtextured){
            this.coefDoor.reDesign.attrs.params.istextured = true;
            texturedV.visible(false) ;
            texturedH.visible(true)
          }else{
            texturedV.visible(true) ;
            texturedH.visible(false)
          }
       
      }else{
        this.coefDoor.reDesign.attrs.params.istextured = false;
        this.coefDoor.reDesign.attrs.params.isTextureReversed = false;
        texturedH.visible(false) ;
        texturedV.visible(false) ;
      }
      await this.loadImageAndSetToKonva(color , subId , parentId , id)
      } else {
        this.coefDoor.reDesign.fill(color);
        this.coefDoor.reDesign.attrs.params.subId = subId;
        this.coefDoor.reDesign.attrs.params.id = id;
        this.coefDoor.reDesign.attrs.params.isTextureReversed = false;
        const texturedH = this.coefDoor.reDesign.attrs.params.texturedH;
        const texturedV = this.coefDoor.reDesign.attrs.params.texturedV;
       
        if(textured){
            this.coefDoor.reDesign.attrs.params.istextured = true;
           const  reservedtextured = texduredReserved || this.coefDoor.reDesign.attrs.params.isTextureReversed
           this.coefDoor.reDesign.attrs.params.isTextureReversed = reservedtextured;
           console.log(reservedtextured ,texduredReserved , this.coefDoor.reDesign.attrs.params.isTextureReversed )
            if(reservedtextured){
              this.coefDoor.reDesign.attrs.params.istextured = true;
              texturedV.visible(false) ;
              texturedH.visible(true)
            }else{
              texturedV.visible(true) ;
              texturedH.visible(false)
            }
         
        }else{
          this.coefDoor.reDesign.attrs.params.istextured = false;
          this.coefDoor.reDesign.attrs.params.isTextureReversed = false;
          texturedH.visible(false) ;
          texturedV.visible(false) ;
        }
      }
    }
   
      this.coefDoor.DoorWrapper.batchDraw();
    }
    this.price.sendObjectPrice();
  }




  async  loadImageAndSetToKonva(imageUrl, subId, parentId, id) {
    return new Promise((resolve, reject) => {
      const imageObj = new Image();
  
      imageObj.onload = () => {
        this.coefDoor.reDesign.image(imageObj);
        this.coefDoor.reDesign.attrs.params.subId = subId;
        this.coefDoor.reDesign.attrs.params.id = parentId;
        this.coefDoor.reDesign.attrs.params.position = id;
  
        // Виконайте подальші дії, коли зображення завантажено і встановлено в Konva
  
        resolve(1); // Вирішення обіцянки після завершення операцій
      };
  
      imageObj.crossOrigin = 'Anonymous';
      imageObj.src = imageUrl;
    });
  }

  async changItemCustom(id, color, parentId? ,texduredReserved?) {
    this.coefDoor.reDesign.attrs.params.printId = parentId;
    this.coefDoor.reDesign.attrs.params.position = id;
    const filter = this.coefDoor.reDesign.attrs.params.filter;
    const imageObj = new Image();
    imageObj.onload = () => {
      filter.image(imageObj);
      this.price.sendObjectPrice();
      this.coefDoor.DoorWrapper.batchDraw();
    };
    imageObj.crossOrigin = 'Anonymous';
    imageObj.src = color;
    filter.visible(true) ;
    filter.opacity(0.3);
  }

  changItemRender(id, color, subId, parentId?, item?) {
    console.log(parentId, id);
    if (item.attrs.params.subId) {
      if (subId === 8 || subId === 5 || subId === 11) {
        const imageObj = new Image();
        imageObj.onload = () => {
          item.image(imageObj);
          item.attrs.params.id = id;
          item.attrs.params.position = parentId;
          this.price.sendObjectPrice();
          this.coefDoor.DoorWrapper.batchDraw();
        };
        imageObj.crossOrigin = 'Anonymous';
        imageObj.src = color;
      } else {
        item.fill(color);
        item.attrs.params.id = id;
      }
      this.coefDoor.DoorWrapper.batchDraw();
    }
    this.price.sendObjectPrice();
  }

  cursor() {
    this.coefDoor.DoorWrapper.on('mouseover', function (evt) {
      if (evt.target.attrs.params && evt.target.attrs.params.part) {
        document.body.style.cursor = 'pointer';
      }
    });
    this.coefDoor.DoorWrapper.on('mouseout', function (evt) {
      if (evt.target.attrs.params && evt.target.attrs.params.part) {
        document.body.style.cursor = 'default';
      }
    });
  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async oneDoor(width, position, text,changeSystem?) {

    this.coefDoor.name++;
    const allWidth = (width / this.coef.global_coef) * this.coef.doorsArray.length;
    const centerPosition = (this.coef.widthWrapper - allWidth) / 2;
    const door_object = new Konva.Rect({
      x: centerPosition + position,
      y: this.coef.topPositaionWrapper,
      width: width / this.coef.global_coef,
      height: this.coefDoor.heightDoor,
      name: 'door' + this.coefDoor.name,
      params: {
        id: text + 1
      },
      fill: this.coefDoor.color,
    });
    const simpleText = new Konva.Text({
      x: centerPosition + position + ((width / this.coef.global_coef) / 2),
      y: this.coef.topPositaionWrapper - 30,
      text: `${text + 1}`,
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: 'black'
    });
    this.coefDoor.DoorWrapper.add(door_object, simpleText);
    console.log(door_object,this.coefDoor.heightDoor , 'door_object')
    const conourrs = this.createContours({
      x: door_object['attrs'].x,
      width: door_object['attrs'].width,
      id: text + 1
    });
    this.coefDoor.DoorWrapper.add(conourrs);
    if (text === 0) {
      const size = new Sizes(this.coef, this.create).globalSize(
        {
          top: this.coef.topPositaionWrapper - 15,
          left: centerPosition,
          width: width / this.coef.global_coef
        }, {
          top: this.coef.topPositaionWrapper,
          right: centerPosition + width / this.coef.global_coef - this.coef.global_size_flat + (width / this.coef.global_coef) * (this.coef.doorsArray.length - 1) - 95,
          height: this.coefDoor.heightDoor
        }, true);
      this.coefDoor.DoorWrapper.add(size[0], size[1]);
    }
    this.coefDoor.DoorWrapper.batchDraw();
    if (this.render.rerender && !changeSystem) {


      this.coef.preloaderUpdate(true);
      const door = this.findidDoor(text + 1);
      this.findBadMat()
        

      
        const contentLength = door.separators.length;
        if(!contentLength){
          await new CreateMaterials(this.coefDoor, this.coef, this.dragService, this.create, this.price).defaultMaterials({ id: 31, content: [2] }, conourrs, false);
        }
        for (let i = 0; i < contentLength; i++) {
          const item = door.separators[i];
          let firstV  = false;
          if(item.width < item.height && i == 0){
            firstV = true
            await new CreateMaterials(this.coefDoor, this.coef, this.dragService, this.create, this.price).defaultMaterials({ id: 31, content: [2] }, conourrs, false);
          }
          if(i != 0 || firstV){
            const x =  item.x  + item.width/2;
            const y = item.y + item.height/2;
            for(const a of this.coefDoor.DoorWrapper.children){
                if(a.attrs && a.attrs.params && a.attrs.params.section){
                  if(x > a.attrs.x && x <  a.attrs.x + a.attrs.width && y > a.attrs.y && y <  a.attrs.y + a.attrs.height){
                    this.coefDoor.reDesign = a;
                    this.coefDoor.reDesign.attrs.strokeWidth = 5;
                    this.coefDoor.reDesign.attrs.stroke = 'red';
                    this.coefDoor.DoorWrapper.batchDraw();
                  }
                }
            }
            firstV = false;
          }
         
          if(item.width > item.height){
            await new CreateMaterials(this.coefDoor, this.coef, this.dragService, this.create, this.price).defaultMaterials({ id: 31, content: [2, 2] }, conourrs, false);
          }else{
            await new CreateMaterials(this.coefDoor, this.coef, this.dragService, this.create, this.price).defaultMaterialsV({ id: 31, content: [2, 2] }, conourrs,2, false);
          }
         
          for (let i = this.coefDoor.DoorWrapper.children.length - 1; i >= 0; i--) {
            const element = this.coefDoor.DoorWrapper.children[i];
            if (element.attrs && element.attrs.params && element.attrs.params.part && element.attrs.params.type == item.params.type) {
              if(item.width > item.height){
                const drag = new Event('dragmove');
                element['y'](item.y);
                element.dispatchEvent(drag);
              }else{
                const drag = new Event('dragmove');
                element['x'](item.x);
                element.dispatchEvent(drag);
              }
              break;
            }
        }
      }

      for(const item  of door.content){
          if(item.params.type == text + 1){
            for(const itemKonva of  this.coefDoor.DoorWrapper.children){
              if(itemKonva.attrs&&itemKonva.attrs.params&&itemKonva.attrs.params.section && itemKonva.attrs.params.type == item.params.type && item.params.numberItem ==itemKonva.attrs.params.numberItem ){
                  this.coefDoor.reDesign = itemKonva;
                  this.coefDoor.reDesign.attrs.params.istextured = item.params.istextured;
                  this.coefDoor.reDesign.attrs.params.isTextureReversed = item.params.isTextureReversed;
                  this.coefDoor.reDesign.attrs.strokeWidth = 5;
                  this.coefDoor.reDesign.attrs.stroke = 'red';
                  this.coefDoor.DoorWrapper.batchDraw();
                  console.log('teast1231231212312')
              }
            }
            if(item.params.subId == 8 || item.params.subId == 5 || item.params.subId == 11 || item.params.subId == 2){
              const path = this.findPath(item.params.subId, item.params.id,item.params.position);
              console.log(path , 'pathpathpath')
              if(path){
                await this.changItem(item.params.position, path, item.params.subId, item.params.id , item.params.isTextureReversed )
              }else{
                await this.changItem(item.params.id, item.fill, item.params.subId  ,false,item.params.isTextureReversed)
              }
            
            }else{
              await this.changItem(item.params.id, item.fill, item.params.subId  ,false,item.params.isTextureReversed)
            }
            if(item.params.printId){
              const path = this.findPathCustom(item.params.printId, item.params.position,);
              this.changItemCustom(item.params.position ,path ,item.params.printId)
            }
            this.coefDoor.reDesign.attrs.strokeWidth = 1;
            this.coefDoor.reDesign.attrs.stroke = 'black';
            this.coefDoor.reDesign = false;
          }
      }
      
      this.coef.preloaderUpdate(false);
    } else {
        const mat = this.findMat(2,1 , this.coefDoor.facade_materials_system)
        const matDef = this.findMat(mat.id,1 , this.coefDoor.facade_materials)
        await new CreateMaterials(this.coefDoor, this.coef, this.dragService, this.create, this.price).defaultMaterials({id:matDef.default_option.id,content:[mat.id]}, conourrs);
     
    }
    this.coefDoor.DoorWrapper.batchDraw();

    this.cursor();
  }
  findBadMat(){
    for(const door of  this.render.object.facade_object){
        for(const item of door.content){
          const section =  item.params;
          const mat = this.findMatActive(section.subId, this.coefDoor.facade_materials_system)
          if(!mat){
            const mat = this.findMat(2,1 , this.coefDoor.facade_materials_system)
            const matDef = this.findMat(mat.id,1 , this.coefDoor.facade_materials)
            if(matDef.id != 8 && matDef.id != 5 && matDef.id != 11){
              item.params.subId = matDef.id;
              item.params.id = matDef.default_option.id;
              item.fill = matDef.default_option.rgb;
            }else{
             
              item.params.subId = matDef.id;
               item.params.id = matDef.default_option.id;
               item.params.position = 0
            }
          }
          if(mat){
            const isActive = this.findMatActiveItem(section.subId , section.id ,this.coefDoor.facade_materials )
            if(!isActive){
              const matDef = this.findMat(mat.id,1 , this.coefDoor.facade_materials)
              if(matDef.id != 8 && matDef.id != 5 && matDef.id != 11){
                item.params.subId = matDef.id;
                item.params.id = matDef.default_option.id;
                item.fill = matDef.default_option.rgb;
              }else{
                 item.params.subId = matDef.id;
                 item.params.id = matDef.default_option.id;
                 item.params.position = 0
              }
            }
          }
         
         
        }
    }
  }

  findMatActive(id,array){
    for(const item of array){
      if(item.id == id){
          return item
      } 
    }
    return false;
  }

  findMatActiveItem(id,subId,array){
    for(const item of array){
      if(item.id == id){
          for(const matSub of item.options){
            if(matSub.id == subId){
              return true;
            }
          }
      } 
    }
    return false;
  }
  findMat(id , secondId , array){
  for(const item of array){
    if(item.id == id){
        return item
    } 
  }
  for(const item of array){
    if(item.id == secondId){
      return item;
    }
  }
  
  return this.coefDoor.facade_materials[0]
  }
  findPath(subid,id,index){
    console.log(subid,id,index )
    for(const item of this.coefDoor.facade_materials){
      if(item.id == subid){
        for(const sub of item.options){
          if(sub.id == id){
            if(sub.images &&  sub.images[index] &&  sub.images[index].path){
              return  sub.images[index].path;
            }
            }
        }
      }
    }
    return false;
  }
  findPathCustom(id,index){
        for(const sub of this.coefDoor.print_allowed.options){
          if(sub.id == id){
            return sub.images[index].path;
          }
        }
  }
  findDoorType(id) {
    for (const item of this.coefDoor.door_structure) {
      if (item.id === id) {
        if (item.separable) {
          return item;
        }
      }
    }
    return false;
  }

  pars_separation(max, idMat) {
    const array = [];
    for (let i = 1; i <= max; i++) {
      array.push(idMat);
    }
    return array;
  }

  chengPosSep(sep, pos) {
    if (sep) {
      const drag = new Event('dragmove');
      const dragend = new Event('dragend');
      sep.y(pos.y);
      sep.dispatchEvent(drag);
      sep.dispatchEvent(dragend);
      this.coefDoor.DoorWrapper.batchDraw();
    }
  }

  findSeparator(pos, type) {
    for (const mat of this.coefDoor.DoorWrapper.children) {
      if (mat.attrs.params && mat.attrs.params.part && mat.attrs.params.position === pos && mat.attrs.params.type === type) {
        return mat;
      }
    }
  }

  findColor(idMatirial, id, position) {
    console.log(position);
    for (const mat of this.coefDoor.facade_materials) {
      if (idMatirial !== 8 && idMatirial !== 5 && idMatirial !== 5) {
        if (mat.id === idMatirial) {
          for (const color of mat.options) {
            if (color.id === id) {
              return color.rgb;
            }
          }
        }
      } else {
        if (mat.id === idMatirial) {
          for (const color of mat.options) {
            if (color.id === id) {
              return color.images[position].path;
            }
          }
        }
      }
    }
  }

  findElementCheng(index, type) {
    for (const item of this.coefDoor.DoorWrapper.children) {
      if (item.attrs.params && item.attrs.params.type === type && item.attrs.params.index === index) {
        return item;
      }
    }
  }

  findidDoor(index) {
    for (const door of this.render.object.facade_object) {
      if (door.content[0].params.type === index) {
        return door;
      }
    }
  }

  createContours(params) {
    this.coefDoor.name++;
    const group = new Konva.Group({
      x: params.x,
      y: this.coef.topPositaionWrapper,
      width: params.width,
      name: 'element' + this.coefDoor.name,
      draggable: false,
      params: {
        removable: false,
        contours:true,
        type: params.id,
        height: this.coefDoor.heightDoor,
        idDoor: params.id
      },
      bind: [],
    });
    const top = new Konva.Rect({
      x: this.con(this.coefDoor.glob_settings.left_hang_width),
      y: 0,
      width: group['attrs'].width - this.con(this.coefDoor.glob_settings.left_hang_width)  - this.con(this.coefDoor.glob_settings.right_hang_width),
      height: this.con(this.coefDoor.glob_settings.top_plant_height),
      name: 'door' + this.coefDoor.name,
      params: {
        main:true},
      fill: this.coefDoor.colorSystem,
      strokeWidth: 1,
      stroke: 'black'
    });
    const bottom = new Konva.Rect({
      x: this.con(this.coefDoor.glob_settings.left_hang_width),
      y: group['attrs'].params.height - this.con(this.coefDoor.glob_settings.bottom_plant_height),
      width: group['attrs'].width - this.con(this.coefDoor.glob_settings.left_hang_width)  - this.con(this.coefDoor.glob_settings.right_hang_width),
      height: this.con(this.coefDoor.glob_settings.bottom_plant_height),
      name: 'door' + this.coefDoor.name,
      params: {main:true},
      fill: this.coefDoor.colorSystem,
      strokeWidth: 1,
      stroke: 'black'
    });

    group.add(bottom , 
      top,
      this.createLR(0,
        0,
        group['attrs'].params.height ,this.con(this.coefDoor.glob_settings.left_hang_width)),
      this.createLR(group['attrs'].width  - this.con(this.coefDoor.glob_settings.right_hang_width),
        0,
        group['attrs'].params.height,this.con(this.coefDoor.glob_settings.right_hang_width)),
    );
    return group;
  }

  createLR(x, y, height , width) {
    const obj = new Konva.Rect({
      x: x,
      y: y,
      width: width,
      height: height,
      name: 'door' + this.coefDoor.name,
      params: { main:true},
      fill: this.coefDoor.colorSystem,
      strokeWidth: 1,
      stroke: 'black'

    });
    return obj;
  }

  // createOneDoorMaterials(item) {
  //   console.log(item , 'adsads')
  //   const isOnWhat = this.isOnWhat({x: item.event.clientX, y: item.event.clientY}, this.coefDoor.DoorWrapper);
  //   if (isOnWhat && isOnWhat.attrs.params && isOnWhat.attrs.params.id) {
  //     const group = this.findGroup(isOnWhat.attrs.params.id, this.coefDoor.DoorWrapper);
  //     this.deletegroup(isOnWhat.attrs.params.id);
  //     if (this.getnNormalTypeDoor(item.item.id)) {
  //       console.log()
  //       new CreateMaterials(this.coefDoor, this.coef, this.dragService, this.create, this.price).defaultMaterials(item.item, group);
  //     } else {
  //       new CreateMaterials(this.coefDoor, this.coef, this.dragService, this.create, this.price).defaultMaterials(item.item, group, true);
  //     }
  //   }
  // }

  deletegroup(type) {
    const array = [];
    for (const item of this.coefDoor.DoorWrapper.children) {
      if (item.attrs.params) {
        if (item.attrs.params.remove) {
          if (item.attrs.params.type == type) {
            array.unshift(this.coefDoor.DoorWrapper.children.indexOf(item));
          }
        }
      }
    }
    for (const del of array) {
      this.coefDoor.DoorWrapper.children.splice(del, 1);
    }
  }

  findGroup(type, array) {
    for (const elem of array.children) {
      if (elem.nodeType === 'Group' && elem.attrs.params && elem.attrs.params.type && elem.attrs.params.type === type) {
        return elem;
      }
    }
  }

  isOnWhat(pos, array) {
    for (const elem of array.children) {
      if (elem.attrs.x <= pos.x && (elem.attrs.x + elem.attrs.width) >= pos.x) {
        if (elem.attrs.y <= pos.y && (elem.attrs.y + (elem.attrs.params.height || elem.attrs.height)) >= pos.y) {
          return elem;
        }
      }
    }
    return false;
  }

  con(obj) {
    return obj / this.coef.global_coef;
  }

  getnNormalTypeDoor(type) {
    switch (type) {
      case 8:
        return false;
      case  9:
        return false;
      case 11:
        return false;
      case 12:
        return false;
      default:
        return true;
    }
  }

  eventManual;

cahngeManualtrevel(){
  const nuarray = []
 

  for(const item of this.coefDoor.DoorWrapper.children){
    if(item.attrs && item.attrs.params && item.attrs.params.part){
      nuarray.push({x:item.attrs.x,y: item.attrs.y , id :item._id})
    }
  }
  nuarray.sort((a, b) => {
    // Спершу сортуємо за x
    if (a.x < b.x) return -1;
    if (a.x > b.x) return 1;
    
    // Якщо x однакові, сортуємо за y
    if (a.y < b.y) return -1;
    if (a.y > b.y) return 1;
    
    return 0; // Об'єкти однакові за обидвіма координатами
});

  if(!this.coefDoor.manualTravelElement){
    if(nuarray[0]){
      this.coefDoor.manualTravelElement = this.findItemById(nuarray[0].id) 
      this.coefDoor.manualTravelElement.attrs.fill = 'green';
      this.coefDoor.manualTravelElement.attrs.stroke = 'green';
      this.coefDoor.DoorWrapper.batchDraw();
    }
   
  }else{
   const index = nuarray.findIndex((elem)=>{return elem.id ==  this.coefDoor.manualTravelElement._id})
    if(nuarray[index + 1]){
      if( this.coefDoor.manualTravelElement &&  this.coefDoor.manualTravelElement.attrs){
        this.coefDoor.manualTravelElement.attrs.fill = this.coefDoor.colorSystem;
        this.coefDoor.manualTravelElement.attrs.stroke = 'black';
      }
      this.coefDoor.manualTravelElement = this.findItemById(nuarray[index + 1].id)
      this.coefDoor.manualTravelElement.attrs.fill = 'green';
      this.coefDoor.manualTravelElement.attrs.stroke = 'green';
      this.coefDoor.DoorWrapper.batchDraw();
    }else{
      if(nuarray[0]){
        if( this.coefDoor.manualTravelElement &&  this.coefDoor.manualTravelElement.attrs){
          this.coefDoor.manualTravelElement.attrs.fill = this.coefDoor.colorSystem;
        }
        this.coefDoor.manualTravelElement = this.findItemById(nuarray[0].id)
        this.coefDoor.manualTravelElement.attrs.fill = 'green';
        this.coefDoor.manualTravelElement.attrs.stroke = 'green';
        this.coefDoor.DoorWrapper.batchDraw();
      }
    }
  }
}
findItemById (id){
  
  for(const item of this.coefDoor.DoorWrapper.children){
      if(item._id == id){
        return item
      }
  }
}


changeManualTravel(direction) {
  const nuarray = [];

  for (const item of this.coefDoor.DoorWrapper.children) {
    if (item.attrs && item.attrs.params && item.attrs.params.part) {
      nuarray.push({ x: item.attrs.x, y: item.attrs.y, id: item._id });
    }
  }
console.log(nuarray , 'nuarray')
  // Сортування у зростаючому порядку за x і y
  nuarray.sort((a, b) => {
    if (a.x < b.x) return -1;
    if (a.x > b.x) return 1;
    if (a.y < b.y) return -1;
    if (a.y > b.y) return 1;
    return 0;
  });

  if (!this.coefDoor.manualTravelElement) {
    if (nuarray[0]) {
      this.coefDoor.manualTravelElement = this.findItemById(nuarray[0].id);
      this.coefDoor.manualTravelElement.attrs.fill = 'green';
      this.coefDoor.manualTravelElement.attrs.stroke = 'green';
      this.coefDoor.DoorWrapper.batchDraw();
    }
  } else {
    const currentIndex = nuarray.findIndex((elem) => elem.id == this.coefDoor.manualTravelElement._id);
    if (currentIndex !== -1) {
      if (direction === 'forward') {
        const nextIndex = currentIndex + 1;
        if (nuarray[nextIndex]) {
          if (this.coefDoor.manualTravelElement && this.coefDoor.manualTravelElement.attrs) {
            this.coefDoor.manualTravelElement.attrs.fill = this.coefDoor.colorSystem;
            this.coefDoor.manualTravelElement.attrs.stroke = 'black';
          }
          this.coefDoor.manualTravelElement = this.findItemById(nuarray[nextIndex].id);
          this.coefDoor.manualTravelElement.attrs.fill = 'green';
          this.coefDoor.manualTravelElement.attrs.stroke = 'green';
          this.coefDoor.DoorWrapper.batchDraw();
        }else{
         if(nuarray[0]){
          if (this.coefDoor.manualTravelElement && this.coefDoor.manualTravelElement.attrs) {
            this.coefDoor.manualTravelElement.attrs.fill = this.coefDoor.colorSystem;
            this.coefDoor.manualTravelElement.attrs.stroke = 'black';
          }
          this.coefDoor.manualTravelElement = this.findItemById(nuarray[0].id);
          this.coefDoor.manualTravelElement.attrs.fill = 'green';
          this.coefDoor.manualTravelElement.attrs.stroke = 'green';
          this.coefDoor.DoorWrapper.batchDraw();
         } 
        }
      } else if (direction === 'backward') {
        const prevIndex = currentIndex - 1;
        if (nuarray[prevIndex]) {
          if (this.coefDoor.manualTravelElement && this.coefDoor.manualTravelElement.attrs) {
            this.coefDoor.manualTravelElement.attrs.fill = this.coefDoor.colorSystem;
            this.coefDoor.manualTravelElement.attrs.stroke = 'black';
          }
          this.coefDoor.manualTravelElement = this.findItemById(nuarray[prevIndex].id);
          this.coefDoor.manualTravelElement.attrs.fill = 'green';
          this.coefDoor.manualTravelElement.attrs.stroke = 'green';
          this.coefDoor.DoorWrapper.batchDraw();
        }else{
          if(nuarray[nuarray.length-1]){
           if (this.coefDoor.manualTravelElement && this.coefDoor.manualTravelElement.attrs) {
             this.coefDoor.manualTravelElement.attrs.fill = this.coefDoor.colorSystem;
             this.coefDoor.manualTravelElement.attrs.stroke = 'black';
           }
           this.coefDoor.manualTravelElement = this.findItemById(nuarray[nuarray.length-1].id);
           this.coefDoor.manualTravelElement.attrs.fill = 'green';
           this.coefDoor.manualTravelElement.attrs.stroke = 'green';
           this.coefDoor.DoorWrapper.batchDraw();
          } 
         }
      }
    }
  }
}

  ManualTravel() {
    this.eventManual = (event) => {
      console.log(event,'1111asdasdasdas')
      if(event.keyCode == 32){
        // event.preventDefault();
       this.changeManualTravel('forward')
      }
      if(this.coefDoor.manualTravelElement && this.coefDoor.manualTravelElement.attrs &&  this.coefDoor.manualTravelElement.attrs.params){
        switch (event.keyCode) {
          case 38:
            if (this.coefDoor.manualTravelElement.attrs.params.horizon ) {
              event.preventDefault();
              this.goTo('y', this.coefDoor.manualTravelElement, -1);
              if (!this.stopDrag(this.coefDoor.manualTravelElement)) {
                this.goTo('y', this.coefDoor.manualTravelElement, 1);
              }
            }
            break;
          case 40:
            if (this.coefDoor.manualTravelElement.attrs.params.horizon) {
              event.preventDefault();
              this.goTo('y', this.coefDoor.manualTravelElement, 1);
              if (!this.stopDrag(this.coefDoor.manualTravelElement)) {
                this.goTo('y', this.coefDoor.manualTravelElement, -1);
              }
            }
            break;
            case 39:
            if (!this.coefDoor.manualTravelElement.attrs.params.horizon) {
              event.preventDefault();
              this.goTo('x', this.coefDoor.manualTravelElement, 1);
              if (!this.stopDragV(this.coefDoor.manualTravelElement)) {
                this.goTo('x', this.coefDoor.manualTravelElement, -1);
              }
            }
            break;
            case 37:
            if (!this.coefDoor.manualTravelElement.attrs.params.horizon) {
              event.preventDefault();
              this.goTo('x', this.coefDoor.manualTravelElement, -1);
              if (!this.stopDragV(this.coefDoor.manualTravelElement)) {
                this.goTo('x', this.coefDoor.manualTravelElement, 1);
              }
            }
            break;
          default:
        }
      }
      
    };
    document.addEventListener('keydown', this.eventManual);
  }

  stopDrag(Part) {
      const height = this.coef.convertCoef(this.coefDoor.glob_settings.min_h_section_height)
      const group = this.findGroup(Part.attrs.params.type, this.coefDoor.DoorWrapper);
      let top = this.drag.findPart(Part.y(), Part.x(), name);
      let bottom = this.drag.findPartbottom(Part.y(), Part.x(), name);
      let newHeightTop = height; 
      let newHeightBottom = height;
      if(Part.attrs.params.topElem && Part.attrs.params.topElem.length > 1){
        newHeightTop = this.coef.convertCoef(this.coefDoor.glob_settings.min_nested_v_section_height) 
      }
      if(Part.attrs.params.bottomElem && Part.attrs.params.bottomElem.length > 1){
        newHeightBottom = this.coef.convertCoef(this.coefDoor.glob_settings.min_nested_v_section_height) 
      }
      if (!top ) {
        top = { attrs: { y: group.attrs.y, height:  this.coef.convertCoef(this.coefDoor.glob_settings.top_plant_height) } }
      }
      if (!bottom ) {
       
        bottom = { attrs: { y: group.attrs.y + this.coefDoor.heightDoor  - this.coef.convertCoef(this.coefDoor.glob_settings.bottom_plant_height)  , height: this.coef.convertCoef(this.coefDoor.glob_settings.bottom_plant_height)  } }
      }
        if (Part.attrs.y < top.attrs.y +  top.attrs.height + newHeightTop) {
          return false;
        }
       
        if (Part.attrs.y  > bottom.attrs.y - newHeightBottom - Part.attrs.height) {
          return false;
        }
     
    return true;
  }

  stopDragV(Part) {
    const height = this.coef.convertCoef(this.coefDoor.glob_settings.min_v_section_width)
    const group = this.findGroup(Part.attrs.params.type, this.coefDoor.DoorWrapper);
    let left = this.drag.findPartLeft(Part.y(), Part.x(), name,group.attrs.params.type);
    let right = this.drag.findPartRight(Part.y(), Part.x(), name , group.attrs.params.type);
    let newWidthLeft = height; 
    let newWidthRight = height;
    if(Part.attrs.params.rightElem && Part.attrs.params.rightElem.length > 1){
      newWidthRight = this.coef.convertCoef(this.coefDoor.glob_settings.min_nested_h_section_width) 
    }
    if(Part.attrs.params.leftElem && Part.attrs.params.leftElem.length > 1){
      newWidthLeft = this.coef.convertCoef(this.coefDoor.glob_settings.min_nested_h_section_width) 
    }
    
      if (!left || left.attrs.params.id_parent != group._id) {
        left = { attrs: { x: group.attrs.x, width:  this.coef.convertCoef(this.coefDoor.glob_settings.left_hang_width) } }
      }
      if (!right || right.attrs.params.id_parent != group._id) {
        right = { attrs: { x: group.attrs.x + group.attrs.width - this.coef.convertCoef(this.coefDoor.glob_settings.right_hang_width)  , width: this.coef.convertCoef(this.coefDoor.glob_settings.right_hang_width)  } }
      }
      if (Part.attrs.x < left.attrs.x + left.attrs.width + newWidthLeft) {
        return false;
      }
     
      if (Part.attrs.x  >  right.attrs.x - newWidthRight -Part.attrs.width) {
        return false;
      }
  return true;
}

  goTo(x, manualTravelElement, number) {
    if (manualTravelElement) {
      const drag = new Event('dragmove');
      console.log(manualTravelElement[x](),manualTravelElement[x]() + number / this.coef.global_coef , 'asdadasd')
      manualTravelElement[x](manualTravelElement[x]() + number / this.coef.global_coef);
      // this.minSize.minSizeManualTravel(this.coef.shapesLayerw, {target: manualTravelElement}, true);
      manualTravelElement.dispatchEvent(drag);
      this.coefDoor.DoorWrapper.batchDraw();
      this.price.sendObjectPrice();
    }
  }

}
