import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-color-system',
  templateUrl: './color-system.component.html',
  styleUrls: ['./color-system.component.scss']
})
export class ColorSystemComponent implements OnInit {
  @Input() door_structure;
  configs: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 5,
    pagination: '.swiper-pagination',
    paginationClickable: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
  };
  constructor() {
  }

  ngOnInit() {
  }

}
