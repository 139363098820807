import * as Konva from '../../assets/konva';
import {Sizes} from './sizes/sizes';

export class Prihoza {
  group;
  coef;
  create;
  active;

  constructor(coef, create, active) {
    this.coef = coef;
    this.create = create;
    this.active = active;
  }

  createPrihoza(width, height, pos, sizeNeed?) {
    this.group = new Konva.Group({
      x: pos.x,
      y: pos.y,
      name: 'element66' + this.coef.countNameElement,
      width: width / this.coef.global_coef,
      draggable: false,
      params: {
        removable: false,
        type: this.coef.id_element.open_door,
        height: height / this.coef.global_coef,
      },
      bind: [],
    });

    const fon = new Konva.Rect({
      x: 0,
      y: 0,
      width: width / this.coef.global_coef,
      height: height / this.coef.global_coef,
      name: 'width',
      fill: 'white',
    });
    this.group.add(fon);
    let count = 3;
    if (this.coef.spec_values.tree_panel_max_width < width) {
      count = 4;
    }
    const lamelWidth = Math.floor((width - ((count - 1) * this.coef.spec_values.back_panel_step)) / count) / this.coef.global_coef;
    let array = [0, this.coef.spec_values.back_panel_step / this.coef.global_coef + lamelWidth, (this.coef.spec_values.back_panel_step / this.coef.global_coef + lamelWidth) * 2];
    if (this.coef.spec_values.tree_panel_max_width < width) {
      array = [0, this.coef.spec_values.back_panel_step / this.coef.global_coef + lamelWidth, (this.coef.spec_values.back_panel_step / this.coef.global_coef + lamelWidth) * 2, (this.coef.spec_values.back_panel_step / this.coef.global_coef + lamelWidth) * 3];
    }
    for (const item of array) {
      const lamel = new Konva.Rect({
        x: item,
        y: 0,
        width: lamelWidth,
        height: height / this.coef.global_coef - this.coef.spec_values.bottom_anteroom_section_height / this.coef.global_coef,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      const rect = new Konva.Rect({
        x: item + lamelWidth / 2 - 2.5,
        y: this.coef.spec_values.top_anteroom_section_height / this.coef.global_coef - this.coef.global_size_flat + 20,
        width: 5,
        height: 10,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      this.group.add(lamel, rect);
    }
    const shell = new Konva.Rect({
      x: 0,
      y: this.coef.spec_values.top_anteroom_section_height / this.coef.global_coef - this.coef.global_size_flat,
      width: width / this.coef.global_coef,
      height: this.coef.global_size_flat,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: this.coef.blackColor,
    });
    const bottomShel = new Konva.Rect({
      x: 0,
      y: (height - this.coef.spec_values.bottom_anteroom_section_height) / this.coef.global_coef,
      width: width / this.coef.global_coef,
      height: this.coef.global_size_flat,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: this.coef.blackColor,
    });
console.warn(this.coef.colorFacad , 'this.coef.colorFacad')
    const drawer = new Konva.Rect({
      x: 0,
      y: (height - this.coef.spec_values.bottom_anteroom_section_height) / this.coef.global_coef + this.coef.global_size_flat,
      width: width / this.coef.global_coef,
      height: this.coef.spec_values.bottom_anteroom_section_height / this.coef.global_coef - this.coef.global_size_flat,
      name: 'width',
      fill: this.coef.colorFacad,
      strokeWidth: 1,
      stroke: this.coef.blackColor,
      params: {
        facad: true
      }
    });
    const drawerRect = new Konva.Rect({
      x: width / this.coef.global_coef / 2 - 10,
      y: (height - this.coef.spec_values.bottom_anteroom_section_height) / this.coef.global_coef + this.coef.global_size_flat + 20,
      width: 20,
      height: 7,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: this.coef.blackColor,
    });
    const sizes = new Sizes(this.coef, this.create).createOneSizeVertical(false, drawer, false, false, true);
    const size = new Sizes(this.coef, this.create).createOneSizeVertical(false, this.group.children[5], false, false, true);
    this.group.add(shell, bottomShel, drawer, drawerRect, sizes, size);
    if (sizeNeed) {
      const sizess = new Sizes(this.coef, this.create).createOneSizePanal({
        x: drawer['attrs'].x,
        y: drawer['attrs'].y + drawer['attrs'].height - 10,
        width: drawer['attrs'].width
      });
      this.group.add(sizess);
    }
    return this.group;
  }
}
