import {RouterModule, Routes} from '@angular/router';
import {PAGE, PAGE_PATH} from './router.path';
import {LoginComponent} from './login/login.component';
import {OrderTypeComponent} from './order-type/order-type.component';
import {MechanicianComponent} from './mechanician/mechanician.component';
import {AuthorizationComponent} from './authorization/authorization.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {AuthGuard} from './auth.guard';
import {OrderComponent} from './pages/order/order.component';
import {HistoryComponent} from './pages/history/history.component';
import {Page404Component} from './pages/page404/page404.component';
import {CustomOrderComponent} from './pages/custom-order/custom-order.component';


const APP_ROUTES: Routes = [
  {path: PAGE.LOGIN, component: LoginComponent, pathMatch: 'full'},
  {
    path: PAGE.PROFILE, component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: PAGE.ORDERTYPE, component: OrderTypeComponent,
    canActivate: [AuthGuard]
  }, {
    path: PAGE.ORDERTYPEFree, component: OrderTypeComponent,
  },
  {
    path: PAGE.MechanicianComponentFree, component: MechanicianComponent,
  },
  {
    path: PAGE.MechanicianComponent, component: MechanicianComponent,
    canActivate: [AuthGuard]
  },
  {
    path: PAGE.Authorization, component: AuthorizationComponent
  },
  {
    path: PAGE.ORDER, component: OrderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: PAGE.CUSTOM, component: CustomOrderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: PAGE.CUSTOMFree, component: CustomOrderComponent,

  },
  {
    path: PAGE.ORDER_HISTORY, component: HistoryComponent,
    canActivate: [AuthGuard]
  },
  {path: '**', component: Page404Component}
];

export const APP_ROUTES_CONST = RouterModule.forRoot(APP_ROUTES, {useHash: true});
