import {Component, OnInit} from '@angular/core';
import {InitRenderService} from '../../service/renderingService/init-render.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {baseUrl} from '../../router.path';
import {CoefService} from '../../service/coef.service';

@Component({
  selector: 'app-button-table',
  templateUrl: './button-table.component.html',
  styleUrls: ['./button-table.component.scss']
})
export class ButtonTableComponent implements OnInit {
  params;
  disabled;

  constructor(private http: HttpClient,
              private router: Router,
              private render: InitRenderService,
              private coef: CoefService) {
  }

  agInit(params: any): void {
    this.disabled = params.data.isButtonDisabled;
    this.params = params;
    console.warn(this.params, 'this.params');
  }

  ngOnInit() {

  }

  informOrder() {
    this.coef.typeOrderId = this.params.data.orderTypeId;
    this.coef.chengPopapOrder(this.params.value);
  }

  customRender() {
    this.router.navigate(['custom'], {queryParams: {id: this.params.data.orderTypeId, render: this.params.data.id}});
  }

  renders() {
    this.http.get(baseUrl.api + `get-order-content/${this.params.value}`).subscribe((res) => {
      const renderobject = res['payload'];
      if(res['payload'].facade_object){
        renderobject.stoper = res['payload'].facade_object.stoper;
        renderobject.puller = res['payload'].facade_object.puller;
        renderobject.facade_object = res['payload'].facade_object.door_count;
      }
      this.render.object = renderobject;
      this.render.rerender = true;
      this.router.navigate(['designer'], {queryParams: {id: res['payload']['order_type_id']}});
    }, (error) => {
      this.coef.preloaderUpdate(false);
      if (error.status === 401) {
        this.coef.preloaderUpdate(false);
        this.router.navigate(['']);
      }
    });
  }
}
