import { Component, OnInit } from '@angular/core';
import {CoefService} from '../../service/coef.service';

@Component({
  selector: 'app-two-door-clock-d',
  templateUrl: './two-door-clock-d.component.html',
  styleUrls: ['./two-door-clock-d.component.scss']
})
export class TwoDoorClockDComponent implements OnInit {

  constructor(public coef: CoefService) { }

  ngOnInit() {
  }

}
