import {Create} from './create';
import * as Konva from '../../assets/konva';
import {Sizes} from './sizes/sizes';

export class Partition extends Create {
  group;
  coef;
  create;

  constructor(coef, create, active) {
    super(coef);
    this.coef = coef;
    this.create = create;
    this.createPartition(active);
  }

  createPartition(active) {
    this.group = new Konva.Rect({
      x: 10,
      y: -500,
      width: 8,
      height: 75,
      name: 'height',
      fill: this.coef.color,
      draggable: active,
      params: {
        removable: false,
        type: this.coef.id_element.partition,
        distance: {min: 250}
      }
    });
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.createFromParitation(pos, xy);
    });
  }

  createFromParitation(pos, xy, older?, neo? , deletes?) {
    let id = false;
    if (neo) {
      id = true;
    }
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.limitAddElement(xy, this.coef.id_element.partition, contours, id) &&
      this.topBottomTangent(contours) && this.paritationInShelflimitCreate(contours, id) &&
      this.partitionBlockLevel({x: pos.evt.layerX}, contours)) {
      let olderObject = null;
      if (older && !older.height) {
        olderObject = {
          width: this.coef.global_size_flat * this.coef.global_coef,
          height: (contours.bottom.attrs.y - contours.top.attrs.y - this.coef.global_size_flat) * this.coef.global_coef,
          depth: parseInt(this.coef.setingsWardrobe.depth) - (this.coef.spec_values['inner_depth_diff'] ? this.coef.spec_values['inner_depth_diff'] : 0)
        };
      }
      if (older && older.height) {
        olderObject = older;
      }
      // let remove = true;
      //       // if (neo) {
      //       //   remove = false;
      //       // }
      let removable;
      if (neo && !deletes) {
        removable = false;
      } else {
        removable = true;
      }
      const obj = {
        leftPosition: xy.x,
        topPosition: contours.top.attrs.y + this.coef.global_size_flat,
        width: this.coef.global_size_flat,
        height: contours.bottom.attrs.y - contours.top.attrs.y - this.coef.global_size_flat,
        type: 'vertical',
        contours: contours,
        wrapper: false,
        layer: this.coef.shapesLayerw,
        params: {
          removable: removable,
          type: this.coef.id_element.partition,
          distance: {min: 250},
          older: olderObject,
          size: [],
          contours: contours,
          neo: neo ? neo : false,
          deletes: deletes ? deletes : false
        }
      };
      if (neo) {
        obj.wrapper = true;
      }
      const object = this.create.createElement(obj);
      if (this.coef.pramsId != 4) {
        new Sizes(this.coef, this.create).NewHorizont(contours, object);
      }
    } else {
      this.coef.errorTrigger('Неможливо розташувати елемент у даній секції.');
    }
    this.coef.shapesLayerw.batchDraw();
    this.create.priceSend();
  }
}
