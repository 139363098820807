import {Create} from './create';
import * as Konva from '../../assets/konva';


export class BackWall extends Create {
  group;
  coef;
  create;

  constructor(coef, create, active) {
    super(coef);
    this.coef = coef;
    this.create = create;
    this.createBackWall(active);
  }

  createBackWall(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 800,
      y: -500,
      width: 50,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.back_wall,
        subId: false,
      },
      bind: [],
    });
    const WallRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 50,
      height: 50,
      name: 'width',
      fill: this.coef.color,
    });
    this.group.add(WallRect);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.createBackWallApi(pos);
    });
  }

  createBackWallApi(pos) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours && this.topBottomTangent(contours) && this.backWallHeightBlock(contours) && this.createMaxWidthHeight(contours)) {
      const groupWall = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + this.coef.global_size_flat,
        draggable: false,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        name: 'element' + this.coef.countNameElement,
        params: {
          removable: true,
          type: this.coef.id_element.back_wall,
          subId: false,
          isUnderWhat: null,
          top: contours['top'].attrs.params.type,
          height: contours['bottom'].attrs.y - contours['top'].attrs.y - (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
          contours: contours
        },
        bind: [],
      });
      const wall = this.retreatElement(contours, {
        x: 0,
        y: 0,
        width: groupWall['attrs'].width,
        height: groupWall['attrs'].params.height,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      const wallRect = new Konva.Rect(wall);
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        groupWall,
        'horizon',
      );
      groupWall.add(wallRect);
      this.coef.shapesLayerw.add(groupWall);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    } else {
      this.coef.errorTrigger('Неможливо розмістити елемент у даній секції.');
    }
  }
}
