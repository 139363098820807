import {Component, OnInit} from '@angular/core';
import {CoefService} from '../../service/coef.service';

@Component({
  selector: 'app-drawer-siple-specD',
  templateUrl: './drawer-siple-specD.component.html',
  styleUrls: ['./drawer-siple-specD.component.scss']
})
export class DrawerSipleSpecDComponent implements OnInit {

  constructor(public  coef: CoefService) {
  }

  ngOnInit() {
  }

}
