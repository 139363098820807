import {BlockCreate} from '../block-create';
import * as Konva from '../../../assets/konva';
import {Sizes} from '../sizes/sizes';
import {Depth} from '../depth';

export class Stone extends BlockCreate {
  public startPosition;
  public coef;
  public create;
  comod_group;
  arrayStone = {
    1: 'first',
    2: 'second',
    3: 'third',
    4: 'fourth',
  };
  stage;
  layer;

  constructor(coef, create, stage, layer) {
    super(coef, create);
    this.coef = coef;
    this.create = create;
    this.stage = stage;
    this.layer = layer;
  }

  createStone(setings, size) {
    const id = this.findType(size.init_types);
    if (id) {
      switch (id) {
        case 1: {
          this.createStoneType(setings, size, id, 1);
          break;
        }
        case 2: {
          this.createStoneType(setings, size, id, 2);
          break;
        }
        case 3: {
          this.createStoneType(setings, size, id, 2);
          break;
        }
        case 4: {
          this.createStoneType(setings, size, id, 2);
          break;
        }
      }
      const depth = new Depth(this.coef);
      depth.createDepth(setings.height, this.layer, this.stage);
    }
  }


  createStoneType(setings, size, id, count) {
    let visual_height = 0;
    if (id == 4) {
      visual_height = this.cof(setings.falshpanel_visual_height);
    }
    const centerPosition = (this.coef.widthWrapper - this.cof(size.width)) / 2;
    this.coef.countNameElement++;
    let height_one_draw = this.cof(setings[this.arrayStone[id] + '_scheme_facade_height']);
    let cockols_height = setings['cockols_height'];
    this.comod_group = new Konva.Group({
      x: centerPosition,
      y: this.coef.topPositaionWrapper,
      width: this.cof(size.width),
      draggable: false,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: false,
      },
      bind: [],
    });

    const bottom = new Konva.Rect({
      x: 0,
      y: this.cof(size.height) - this.cof(cockols_height),
      width: this.cof(size.width),
      height: this.cof(cockols_height),
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    const left = new Konva.Rect({
      x: 0,
      y: this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: this.cof(size.height) - (height_one_draw * count) - this.cof(cockols_height),
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    const fon = new Konva.Rect({
      x: this.coef.global_size_flat,
      y: this.coef.global_size_flat,
      width: this.cof(size.width) - this.coef.global_size_flat * 2,
      height: this.cof(size.height) - (height_one_draw * count) - this.cof(cockols_height) - this.coef.global_size_flat,
      name: 'width',
      fill: this.coef.addOpacytiRGBA(this.coef.color, 0.5),
      params: {
        opacity: true
      },
      strokeWidth: 1,
      stroke: 'black',
    });
    const right = new Konva.Rect({
      x: this.cof(size.width) - this.coef.global_size_flat,
      y: this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: this.cof(size.height) - (height_one_draw * count) - this.cof(cockols_height),
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    const top = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.cof(size.width),
      height: this.coef.global_size_flat,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    // falshpanel_height
    this.comod_group.add(bottom, left, right, fon, top);
    for (let i = 0; i < count; i++) {
      if (id == 4) {
        const planca = new Konva.Rect({
          x: 0,
          y: this.cof(size.height) - (height_one_draw * (i + 1)) - this.cof(cockols_height) - this.cof(setings.falshpanel_height),
          width: this.cof(size.width),
          height: this.cof(setings.falshpanel_height),
          name: 'width',
          fill: this.coef.color,
          strokeWidth: 1,
          stroke: 'black',
        });
        this.comod_group.add(planca);
      }

      const draw = new Konva.Rect({
        x: 0,
        y: this.cof(size.height) - (height_one_draw * (i + 1)) - this.cof(cockols_height) - visual_height * i,
        width: this.cof(size.width),
        height: height_one_draw,
        name: 'width',
        fill: this.coef.colorFacad,
        params: {
          facad: true
        },
        strokeWidth: 1,
        stroke: 'black',
      });
      this.comod_group.add(draw);
      if (id != 4) {
        const center = new Konva.Rect({
          x: this.cof(size.width) / 2 - 10,
          y: (this.cof(size.height) - (height_one_draw * (i + 1)) - this.cof(cockols_height) + height_one_draw / 2),
          width: 20,
          height: 7,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
        });
        this.comod_group.add(center);
      }
    }
    if (id != 4) {
      const sizeDrawer = new Sizes(this.coef, this.create).createOneSizeVertical(false, this.comod_group.children[this.comod_group.children.length - 2], false, false, true);
      this.comod_group.add(sizeDrawer);
    } else {
      const sizeDrawer = new Sizes(this.coef, this.create).createOneSizeVertical(false, this.comod_group.children[this.comod_group.children.length - 1], false, false, true);
      this.comod_group.add(sizeDrawer);
    }

    if (id == 1) {
      const sizebottom = new Sizes(this.coef, this.create).createOneSizeVertical(false, this.comod_group.children[3], false, false, true);
      this.comod_group.add(sizebottom);
    }
    this.layer.add(this.comod_group);
    this.stage.add(this.layer);
    new Sizes(this.coef, this.create).globalSize(
      {
        top: this.coef.topPositaionWrapper,
        left: centerPosition,
        width: this.cof(size.width)
      }, {
        top: this.coef.topPositaionWrapper,
        right: centerPosition + this.cof(size.width) - 110,
        height: this.cof(size.height)
      });
  }


}

