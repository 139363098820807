import {TwoDoorComponent} from './svg/two-door/two-door.component';
import {TwoDoorComponentD} from './svg/two-door-d/two-door-d.component';
import {FourPipeComponent} from './svg/pipe/four-pipe/four-pipe.component';
import {DrawerSipleComponent} from './svg/drawer-siple/drawer-siple.component';
import {ThreePipeComponent} from './svg/pipe/three-pipe/three-pipe.component';
import {PantographComponent} from './svg/pantograph/pantograph.component';
import {TwoDoorClockComponent} from './svg/two-door-clock/two-door-clock.component';
import {TwoDoorClockDComponent} from './svg/two-door-clock-d/two-door-clock-d.component';
import {DoorRightComponent} from './svg/door-right/door-right.component';
import {DoorRightDComponent} from './svg/door-right-d/door-right-d.component';
import {DoorLeftLockComponent} from './svg/door-left-lock/door-left-lock.component';
import {DoorLeftLockDComponent} from './svg/door-left-lock-d/door-left-lock-d.component';
import {DrawerComponent} from './svg/drawer/drawer.component';
import {OnePipeComponent} from './svg/pipe/one-pipe/one-pipe.component';
import {FiveHookComponent} from './svg/hook/five-hook/five-hook.component';
import {DrawerLockComponent} from './svg/drawer-lock/drawer-lock.component';
import {DoorRightLockComponent} from './svg/door-right-lock/door-right-lock.component';
import {DoorRightLockDComponent} from './svg/door-right-lock-d/door-right-lock-d.component';
import {ParitationComponent} from './svg/paritation/paritation.component';
import {CornerEndingFComponent} from './svg/corner-ending-f/corner-ending-f.component';
import {TwoPipeComponent} from './svg/pipe/two-pipe/two-pipe.component';
import {HookOneComponent} from './svg/hook/hook-one/hook-one.component';
import {ThreeHookComponent} from './svg/hook/three-hook/three-hook.component';
import {ShelfIsRemovableComponent} from './svg/shelf-is-removable/shelf-is-removable.component';
import {CornerEndingComponent} from './svg/corner-ending/corner-ending.component';
import {ShellComponent} from './svg/shell/shell.component';
import {DoorleftComponent} from './svg/doorleft/doorleft.component';
import {DoorleftDComponent} from './svg/door-left-d/door-left-d.component';

import {DrawerSipleLockComponent} from './svg/drawer-siple-lock/drawer-siple-lock.component';
import {DrawerSipleSpecComponent} from './svg/drawer-siple-spec/drawer-siple-spec.component';
import {DrawerSipleSpecDComponent} from './svg/drawer-siple-spec-d/drawer-siple-specD.component';
import {TrumpetComponent} from './svg/trumpet/trumpet.component';
import {TrumpetComponent2} from './svg/trumpet2/trumpet2.component';
import {FivePipeComponent} from './svg/pipe/five-pipe/five-pipe.component';

export const svgComponent = [
  CornerEndingComponent,
  DrawerComponent,
  DrawerLockComponent,
  DrawerSipleComponent,
  DrawerSipleLockComponent,
  DrawerSipleSpecComponent,
  DrawerSipleSpecDComponent,
  ShellComponent,
  ShelfIsRemovableComponent,
  PantographComponent,
  TrumpetComponent,
  TrumpetComponent2,
  DoorleftComponent,
  DoorRightComponent,
  DoorRightLockDComponent,
  DoorRightDComponent,
  TwoDoorComponent,
  TwoDoorComponentD,
  TwoDoorClockComponent,
  TwoDoorClockDComponent,
  DoorLeftLockComponent,
  DoorLeftLockDComponent,
  DoorleftDComponent,
  DoorRightLockComponent,
  CornerEndingFComponent,
  ParitationComponent,
  HookOneComponent,
  ThreeHookComponent,
  FiveHookComponent,
  OnePipeComponent,
  TwoPipeComponent,
  ThreePipeComponent,
  FourPipeComponent,
  FivePipeComponent];
