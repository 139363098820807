import {Component, HostListener, OnInit} from '@angular/core';
import {GridOptions} from 'ag-grid-community';
import {ImgTableComponent} from '../../components/img-table/img-table.component';
import {baseUrl} from '../../router.path';
import {TextTableComponent} from '../../components/text-table/text-table.component';
import {ButtonTableComponent} from '../../components/button-table/button-table.component';
import {HttpClient} from '@angular/common/http';
import {DataTableComponent} from '../../components/data-table/data-table.component';
import {PriceTableComponent} from '../../components/price-table/price-table.component';
import {CoefService} from '../../service/coef.service';
import {Router} from '@angular/router';
import {ButtonTable2Component} from '../../components/button-table2/button-table2.component';
import {API} from '../../common/api';
import {NotificationService} from '../../service/notification.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  img_hover_src;
  img_hover;
  localeText = {
    // for filter panel
    page: 'сторінка',
    more: 'daMore',
    to: '-',
    of: 'всього',
    next: 'daNexten',
    last: 'daLasten',
    first: 'daFirsten',
    previous: 'daPreviousen',
    loadingOoo: 'daLoading...',

    // for set filter
    selectAll: 'daSelect Allen',
    searchOoo: 'daSearch...',
    blanks: 'daBlanc',

    // for number filter and text filter
    filterOoo: 'daFilter...',
    applyFilter: 'daApplyFilter...',
    equals: 'daEquals',
    notEquals: 'daNotEqual',

    // for number filter
    lessThan: 'daLessThan',
    greaterThan: 'daGreaterThan',
    lessThanOrEqual: 'daLessThanOrEqual',
    greaterThanOrEqual: 'daGreaterThanOrEqual',
    inRange: 'daInRange',

    // for text filter
    contains: 'daContains',
    notContains: 'daNotContains',
    startsWith: 'daStarts dawith',
    endsWith: 'daEnds dawith',

    // the header of the default group column
    group: 'laGroup',

    // tool panel
    columns: 'laColumns',
    filters: 'laFilters',
    rowGroupColumns: 'laPivot Cols',
    rowGroupColumnsEmptyMessage: 'la drag cols to group',
    valueColumns: 'laValue Cols',
    pivotMode: 'laPivot-Mode',
    groups: 'laGroups',
    values: 'laValues',
    pivots: 'laPivots',
    valueColumnsEmptyMessage: 'la drag cols to aggregate',
    pivotColumnsEmptyMessage: 'la drag here to pivot',
    toolPanelButton: 'la tool panel',

    // other
    noRowsToShow: 'Записи відсутні',

    // enterprise menu
    pinColumn: 'laPin Column',
    valueAggregation: 'laValue Agg',
    autosizeThiscolumn: 'laAutosize Diz',
    autosizeAllColumns: 'laAutsoie em All',
    groupBy: 'laGroup by',
    ungroupBy: 'laUnGroup by',
    resetColumns: 'laReset Those Cols',
    expandAll: 'laOpen-em-up',
    collapseAll: 'laClose-em-up',
    toolPanel: 'laTool Panelo',
    export: 'laExporto',
    csvExport: 'la CSV Exportp',
    excelExport: 'la Excel Exporto',

    // enterprise menu pinning
    pinLeft: 'laPin <<',
    pinRight: 'laPin >>',
    noPin: 'laDontPin <>',

    // enterprise menu aggregation and status bar
    sum: 'laSum',
    min: 'laMin',
    max: 'laMax',
    none: 'laNone',
    count: 'laCount',
    average: 'laAverage',

    // standard menu
    copy: 'laCopy',
    copyWithHeaders: 'laCopy Wit hHeaders',
    ctrlC: 'ctrl n C',
    paste: 'laPaste',
    ctrlV: 'ctrl n V'
  }
  constructor(private http: HttpClient,
              public coef: CoefService,
              private router: Router,
              private notification: NotificationService,) {
    this.coef.preloaderUpdate(true);
  }
  private getUser(): void {
    this.http.get(baseUrl.api + API.GET_USER).subscribe(user => {
      this.coef.manager = user['payload'].manager;
    }, err => {
      this.notification.triggerNotification('Невідома помилка. Спробуйте пізніше.', true);
    });
  }
  filter = '';
  columnDefs = [
    {headerName: 'id', field: 'id', cellRendererFramework: TextTableComponent , getQuickFilterText: function(params) {
        return params.value.name;
      }},
    {headerName: 'Наповнення', field: 'contentUrl', cellRendererFramework: ImgTableComponent , filter: 'agTextColumnFilter',
      filterParams: {apply: true, newRowsAction: 'keep'}
    },
    {headerName: 'Двері', field: 'facadeUrl', cellRendererFramework: ImgTableComponent},
    {headerName: 'Ціна', field: 'orderPrice', cellRendererFramework: PriceTableComponent},
    {headerName: 'Тип', field: 'orderType', cellRendererFramework: TextTableComponent},
    {headerName: 'Статус', field: 'orderStatus', cellRendererFramework: TextTableComponent},
    {headerName: 'Статус оплати', field: 'paymentStatusText', cellRendererFramework: TextTableComponent},
    {headerName: 'Дата замовлення', field: 'startDate', cellRendererFramework: DataTableComponent},
    {headerName: 'Дата завершення', field: 'doneDate', cellRendererFramework: DataTableComponent},
    {headerName: '', field: 'id', cellRendererFramework: ButtonTable2Component}
  ];
  rowData = [];
  gridOptions: GridOptions = {
    columnDefs: this.columnDefs,
    rowData: this.rowData,
    enableSorting: true,
    pagination: true,
    paginationPageSize: 25,
    domLayout: 'autoHeight',

  };

  onFilterTextBoxChanged(e){
    this.gridOptions.api.setQuickFilter(this.filter);
  }
  ngOnInit() {
    this.http.get(baseUrl.api + `user-history-table`)
      .subscribe((data) => {
        this.rowData = data['payload'];
        this.gridOptions.api.sizeColumnsToFit();
        this.coef.preloaderUpdate(false);
      }, (error) => {
        this.coef.preloaderUpdate(false);
        if (error.status === 401) {
          this.coef.preloaderUpdate(false);
          this.router.navigate(['']);
        }
      });
  }


}
