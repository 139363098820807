import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '../../../service/notification.service';
import {FormBuilder, Validators} from '@angular/forms';
import {baseUrl} from '../../../router.path';
import {API} from '../../../common/api';

@Component({
  selector: 'app-update-company',
  templateUrl: './update-company.component.html',
  styleUrls: ['./update-company.component.css']
})
export class UpdateCompanyComponent implements OnInit {

  companyForm: any;

  public company: string;

  @Input() phone: string;
  @Input() defaultValue: string;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient,
              private notification: NotificationService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.companyForm = this.formBuilder.group(
      {
        'company': [this.defaultValue || null, Validators.required],
      });
  }

  onSubmit() {
    this.http.post(baseUrl.api + API.CHANGE_INFO, {param_name: 'company', value: this.companyForm.controls.company.value}).subscribe(res => {
      this.notification.triggerNotification(res['payload']);
      this.modalClosed.emit();
    }, error => {
      this.notification.triggerNotification(error['error']['error'], true);
    });
  }

}
