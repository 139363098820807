import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, Validators} from '@angular/forms';
import {CookieService} from 'angular2-cookie/core';
import {Router} from '@angular/router';
import {baseUrl, PAGE} from '../router.path';
import {Meta, Title} from '@angular/platform-browser';
import {FADE_IN_OUT} from '../common/animations';
import {NotificationService} from '../service/notification.service';
import {CoefService} from '../service/coef.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [],
  animations: [FADE_IN_OUT]
})
export class LoginComponent implements OnInit {
  Login;

  public modalOpened = false;

  constructor(
    private notification: NotificationService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private _cookieService: CookieService,
    private router: Router,
    private meta: Meta,
    private title: Title,
    private coef: CoefService) {
    this.title.setTitle('SVlux');
    this.Login = this.formBuilder.group(
      {
        'email': ['', [Validators.required, Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]],
        'password': ['', Validators.required],
      });
  }

  ngOnInit() {
    this.checkAuthorization();
    this.coef.preloaderUpdate(false);
  }

  submitUser(e) {
    this.http.post(baseUrl.local + 'sign-in', e.value).subscribe((res) => {
        const token = res['payload'];
        this._cookieService.put('jwt', token);
        localStorage.setItem('jwt', token);
        this.router.navigate([PAGE.ORDER]);
      },
      error => {
        this.notification.triggerNotification(error['error']['error'], true);
      });
  }

  private checkAuthorization(): void {
    if (localStorage.getItem('jwt')) this.router.navigate([PAGE.ORDER]);
  }

  deleteToken() {
    this._cookieService.remove('jwt');
  }

  goToRegistration() {
    this.router.navigate([PAGE.Authorization]);
  }

  forgotPassword() {
    this.modalOpened = true;
  }

}
