import * as Konva from '../../assets/konva';
import {BlockCreate} from './block-create';


export class Create extends BlockCreate {
  public startPosition;
  public coef;
  public create;
  remove;

  constructor(coef, create?, remove?) {
    super(coef, create, remove);
    this.coef = coef;
    this.create = create;
    this.remove = remove;
  }

  dragEnd(fillingElement, product, functionCalbak?) {
    fillingElement.on('dragend', (e) => {
      const x = Math.round(fillingElement.attrs.x * this.coef.global_coef) / this.coef.global_coef;
      const y = Math.round(fillingElement.attrs.y * this.coef.global_coef) / this.coef.global_coef;
      functionCalbak({evt: {layerX: x, layerY: y}}, {
        // x: fillingElement.attrs.x ,
        // y: fillingElement.attrs.y ,
        x: x,
        y: y
      });
      for (const element of this.coef.startPosition['children']) {
        if (fillingElement.attrs.params.type === element.attrs.params.type) {
          if (element.attrs.params.subId) {
            if (element.attrs.params.subId === fillingElement.attrs.params.subId) {
              fillingElement.x(element.attrs.x);
              fillingElement.y(element.attrs.y);
              product.batchDraw();
            }
          } else {
            fillingElement.x(element.attrs.x);
            fillingElement.y(element.attrs.y);
            product.batchDraw();
          }
        }
      }
      // functionCalbak(e, {x: e.evt.layerX, y: e.evt.layerY});
      this.shellToSimple(this.remove);
      this.create.priceSend();
    });
  }


  shellToSimple(remove) {
    for (const item of this.coef.shapesLayerw.children) {
      if (item.attrs.params.type === 7) {
        if (item.attrs.params.subId === 21) {
          if (item.attrs.bind.length > 0) {
            // if (!this.create.blockSelf(false, {y: item.attrs.y, height: item.attrs.height}, item)) {
            //   this.create.removeElement(item.attrs.name);
            // } else {
            item.attrs.dash = [];
            item.attrs.params.subId = 20;
            // }
          }
        }
      }
    }
  }

  createLockDesigne() {
    const drawerLockCreate = new Konva.Circle({
      x: 10,
      y: 10,
      radius: 6,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: 'black',
    });
    const drawerkeyCreate = new Konva.Rect({
      x: 9,
      y: 7,
      width: 2,
      height: 6,
      name: 'width',
      fill: 'black',
    });

    return [drawerLockCreate, drawerkeyCreate];
  }

  retreatElement(conturs, element) {
    if (this.coef.id_element.top_wall === conturs.top.attrs.params.type) {
      element.y = this.coef.look2dPosition;
      element.height = element.height - this.coef.look2dPosition;
    }
    if (this.coef.id_element.bottom_wall === conturs.bottom.attrs.params.type) {
      element.height = element.height - this.coef.look2dPosition;
    }
    if (this.coef.id_element.lateral_wall === conturs.left.attrs.params.type) {
      element.x = this.coef.look2dPosition;
      element.width = element.width - this.coef.look2dPosition;
    }
    if (this.coef.id_element.lateral_wall === conturs.right.attrs.params.type) {
      element.width = element.width - this.coef.look2dPosition;
    }
    return element;
  }

  isOnWhat(pos, element, array) {
    for (const elem of array.children) {
      if (elem.attrs.x <= pos.evt.layerX && (elem.attrs.x + elem.attrs.width) >= pos.evt.layerX) {
        if (elem.attrs.y <= pos.evt.layerY && (elem.attrs.y + (elem.attrs.params.height || elem.attrs.height)) >= pos.evt.layerY) {
          return elem;
        }
      }
    }
    return false;
  }

  addElemetToParent(parent, element) {
    if (element.attrs.params === this.coef.id_element.trumpet) {
      parent['top'].attrs.bind.push(element);
      parent['bottom'].attrs.bind.push(element);
    }
  }

  limitAddElement(pos, type, contours, id?) {
    if (this.coef.pramsId == 4 && id) {
      return true;
    }
    const distance = this.getDistancToContours(pos, contours);
    if (this.coef.pramsId == 7 && id) {
      return true;
    }
    if (this.coef.pramsId == 7 && !id) {
      const centerPosition = ((this.coef.widthWrapper - this.coef.setingsWardrobe.width / this.coef.global_coef) / 2) + this.coef.global_size_flat;
      const left = centerPosition + this.coef.global_size_flat + (+this.coef.widthSep) / this.coef.global_coef;
      const right = centerPosition + this.coef.setingsWardrobe.width / this.coef.global_coef - (+this.coef.widthSep) / this.coef.global_coef - this.coef.global_size_flat * 3;
      if (pos.x > left && pos.x < right) {
        this.coef.errorTrigger('Додати елементи до центральної секції даного замовлення заборонено.');
        return false;
      }
    }
    for (const types of this.coef.arrayOfsset) {
      if (type === types.id) {
        if (distance.width < types.min_width / this.coef.global_coef) {
          this.coef.errorTrigger(`Мінімальна ширина для встановлення елементу - ${types.min_width}мм`);
          return false;
        }
        if (distance.width > types.max_width / this.coef.global_coef) {
          this.coef.errorTrigger(`Максимальна ширина для встановлення елементу -${types.max_width}мм`);
          return false;
        }
        for (const offset of types.offsets) {
          if (contours['top'].attrs.params.type === offset.target_id) {
            if (distance.top <= (offset.top_left / this.coef.global_coef)) {
              this.coef.errorTrigger(`Мінімально-допустимий відступ до сусіднього верхнього елементу становить ${offset.top_left}мм.`);
              return false;
            }
          }
          if (contours['bottom'].attrs.params.type === offset.target_id) {
            if (distance.bottom <= (offset.bottom_right / this.coef.global_coef)) {
              this.coef.errorTrigger(`Мінімально-допустимий відступ до сусіднього нижнього елементу становить ${offset.bottom_right}мм`);
              return false;
            }
          }
          if (contours['right'].attrs.params.type === offset.target_id) {
            if (distance.right <= (offset.bottom_right / this.coef.global_coef)) {
              this.coef.errorTrigger(`Мінімально-допустимий відступ до сусіднього правого елементу становить ${offset.bottom_right}мм`);
              return false;
            }
          }
          if (contours['left'].attrs.params.type === offset.target_id) {
            if (distance.left <= (offset.bottom_right / this.coef.global_coef)) {
              this.coef.errorTrigger(`Мінімально-допустимий відступ до сусіднього лівого елементу становить ${offset.bottom_right}мм`);
              return false;
            }
          }
        }
      }
    }

    return true;
  }

  getDistancToContours(pos, contours) {
    const distance = {
      left: contours['right'].attrs.x - pos.x,
      right: pos.x - contours['left'].attrs.x,
      bottom: contours['bottom'].attrs.y - pos.y,
      top: pos.y - contours['top'].attrs.y,
      width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
      height: contours['bottom'].attrs.y - contours['top'].attrs.y - contours['top'].attrs.height
    };
    return distance;
  }

  getContourShalf(pos, drawer_group) {
    const contoursShalf = this.create.getTangentElement(pos, drawer_group, this.coef.shapesLayerw, true);
    const topShalf = this.create.getTangentElement({
      evt: {
        layerX: drawer_group.attrs.x,
        layerY: contoursShalf['top'].attrs.y
      }
    }, contoursShalf['top'], this.coef.shapesLayerw);
    return {contoursShalf: contoursShalf, topShalf: topShalf};
  }

  resize(top, height, on , dif ) {
    on.y(0);
    on.attrs.params.height = height;
    on.children[0].height((on.attrs.height ? on.attrs.height : on.attrs.params.height));
    on.children[1].y((on.attrs.height ? on.attrs.height : on.attrs.params.height) / 2 + 14);
    on.children[1].text(JSON.stringify((on.attrs.height ? on.attrs.height : on.attrs.params.height) * this.coef.global_coef - (dif * this.coef.global_coef)));
    on.children[3].attrs.points = [
      -3, on['attrs'].params.height - 10,
      -0, on['attrs'].params.height - 0,
      3, on['attrs'].params.height - 10,
      -3, on['attrs'].params.height - 10
    ];
    this.coef.Size.batchDraw();
  }

  blockCreateOnElement(pos) {

    for (const items of this.coef.shapesLayerw.children) {
      if (pos.x > items.attrs.x && pos.x < items.attrs.x + items.attrs.width && pos.y > items.attrs.y && pos.y < items.attrs.y + (items.attrs.height ?
        items.attrs.height : items.attrs.params.height)) {
        this.coef.errorTrigger('Неможливо створити елемент поверх встановленого елементу наповнення.');
        return false;
      }
    }
    return true;
  }

  trumpetBlockStop(pos, type, contours) {
    const distance = this.getDistancToContours(pos, contours);
    let stop = this.coef.heightTrumpet;

    if (type === this.coef.id_element.pantograph) {
      stop = this.coef.pantographHeight;
    }
    let good = true;
    for (const types of this.coef.arrayOfsset) {
      if (type === types.id) {
        for (const offset of types.offsets) {
          if (contours['top'].attrs.params.type === offset.target_id) {
            stop = stop + offset.top_left / this.coef.global_coef;
          }
          if (contours['bottom'].attrs.params.type === offset.target_id) {
            stop = stop + offset.bottom_right / this.coef.global_coef;
          }
        }
      }
    }
    if (distance.height < stop) {
      this.coef.errorTrigger(`Неможливо створити елемент висота секції повина бути мін - ${stop * this.coef.global_coef}мм`);
      good = false;
    }
    return good;
  }

  isInDrawer(drawer_group) {
    for (const drawer of this.coef.shapesLayerw.children) {
      if (drawer.attrs.name == drawer_group.attrs.name) {
        return true;
      }
    }
    return false;
  }

  resizeDrawerAll(pos, drawer_group, top, count, bottomShalf) {
    if (this.isInDrawer(drawer_group)) {
      let sideSpace =  this.coef.drawer.sideSpace;
     
      if(drawer_group.attrs.params.subId == 26 || drawer_group.attrs.params.subId == 27) {
        sideSpace = this.coef.drawer.sideSpaceSpec
      }
     
      let countDraw;
      countDraw = top.attrs.params.drawerArray.length;
      let bottomSectionDiff = 0;
      if (bottomShalf.attrs.params.type === this.coef.id_element.bottom_wall) {
        bottomSectionDiff = this.coef.drawer.bottomSectionDiff;
      }
      if (bottomShalf.attrs.params.type === 7) {
        bottomSectionDiff = this.coef.drawer.sideSpace;
        bottomShalf.attrs.params.stopDrags = true;
      }
      let heightWindow = bottomShalf.attrs.y - top.attrs.y - top.attrs.height;
      heightWindow = heightWindow - bottomSectionDiff;
      const heightDrawer = Math.floor(((heightWindow / countDraw) * this.coef.global_coef)) / this.coef.global_coef;
    
      if (top.attrs.params.drawerArray) {
        this.ResizArray(top.attrs.params.drawerArray, top, heightDrawer, bottomShalf , sideSpace);
      }
      if (heightDrawer >= this.coef.drawer.minSize) {
        const botoomY = bottomShalf.attrs.y - this.coef.global_size_flat;
        let topMinY = botoomY - (countDraw * this.coef.drawer.maxSize) - bottomSectionDiff - (countDraw * sideSpace);
        const bottomMinY = botoomY - (countDraw * this.coef.drawer.minSize) - (bottomSectionDiff) - (countDraw * sideSpace);
        const topStopDrag = (countDraw * this.coef.drawer.minSize) + (bottomSectionDiff) + (countDraw * sideSpace);
        const topStopMove = (this.coef.topPositaionWrapper + (this.coef.heightWardrobe / this.coef.global_coef)) - this.coef.drawer.maxTopPos - this.coef.global_size_flat;
        if (topStopMove >= topMinY) {
          topMinY = topStopMove;
        }
      
        top.attrs.params.drawer = {
          topMinY: topMinY,
          bottomMinY: bottomMinY,
          topStopDrag: topStopDrag,
          elementBottom: bottomShalf
        };
      }
    }
  }

  lastBottomDrag(array = [], element , top , height , sideSpace) {
    element.y(array[array.length - 1].attrs.y + array[array.length - 1].attrs.params.height + sideSpace);
  }

  ResizArray(array = [], top, height, bottomShalf , sideSpace) {
    for (let i = 0; i < array.length; i++) {
      array[i].attrs.params.touches = 'Touched';
      array[i].attrs.params.height = height;
      array[i].children[0].attrs.height = height;
      if (array[i].children[array[i].children.length - 1].nodeType === 'Group') {
        this.resize(true, height, array[i].children[array[i].children.length - 1] , sideSpace);
      }
      if (i === 0) {
        array[i].y(top.attrs.y + top.attrs.height);
      } else {
        array[i].y(array[i - 1].y() + array[i - 1].attrs.params.height);
      }
    }
    if (bottomShalf.attrs.params.type === this.coef.id_element.shelf) {
      this.lastBottomDrag(array, bottomShalf , top , height , this.coef.drawer.sideSpace);
    }
    this.coef.shapesLayerw.batchDraw();
  }

  countDrawerFromX(topWall, x) {
    let count = 0;
    for (const element of this.coef.shapesLayerw.children) {
      if (element.attrs.params.type === this.coef.id_element.drawer && element.attrs.x === x && element.attrs.y > topWall.attrs.y) {
        count++;
      }
    }
    return count;
  }

  drawerAdd(pos, drawer_group, bottomShalf, top?) {
    let sideSpace =  this.coef.drawer.sideSpace;
    let drawHeight  = this.coef.drawer.drawHeight
    if(drawer_group.attrs.params.subId == 26 || drawer_group.attrs.params.subId == 27) {
      sideSpace = this.coef.drawer.sideSpaceSpec;
      drawHeight = this.coef.drawer.drawHeightSpec;
    }

    const countDrawerNow = top.attrs.params.drawerArray.length;
    const allContour = this.getContourShalf(pos, drawer_group);
    let bottomSectionDiff = sideSpace;
    if (bottomShalf.attrs.params.type === 7) {
      bottomSectionDiff = this.coef.drawer.sideSpace;
    }
    if (bottomShalf.attrs.params.type === this.coef.id_element.bottom_wall) {
      bottomSectionDiff = this.coef.drawer.bottomSectionDiff + this.coef.drawer.sideSpace;
    }
    let heightWindow = bottomShalf.attrs.y - top.attrs.y - top.attrs.height;
    heightWindow = heightWindow - bottomSectionDiff;
    const countDrawer = Math.floor(heightWindow /drawHeight);
    if (countDrawer <= countDrawerNow) {
      const heightDrawer = Math.floor(((heightWindow / countDrawerNow) * this.coef.global_coef)) / this.coef.global_coef;
      
      if (countDrawerNow === 1 && heightWindow < this.coef.drawer.minSize * 2) {
        bottomShalf.attrs.bind.push(...top.attrs.params.drawerArray);
        bottomShalf.attrs.params.stopDrags = true;
        this.ResizArray(top.attrs.params.drawerArray, top, heightDrawer, bottomShalf , sideSpace);
      } else {
        if (countDrawerNow === 1 && heightWindow > this.coef.drawer.maxSize) {
        } else {
          if (countDrawerNow > 1 && heightDrawer > this.coef.drawer.minSize && heightDrawer <drawHeight) {
            bottomShalf.attrs.bind.push(...top.attrs.params.drawerArray);
            console.log( bottomShalf.attrs.bind , 'bottomShalf.attrs.bind')
            bottomShalf.attrs.params.stopDrags = true;
            this.ResizArray(top.attrs.params.drawerArray, top, heightDrawer, bottomShalf , sideSpace);
          } else {
            if (heightDrawer > this.coef.drawer.maxSize || heightDrawer <= this.coef.drawer.minSize) {
              if ((drawHeight) * countDrawerNow > heightWindow) {
                this.deleteElement(drawer_group.attrs.name, top);
              }
            }
          }
        }
      }
    }
    const elementBottom = top['attrs'].y + (heightWindow - top.attrs.params.drawerArray.length * (drawHeight - sideSpace) - sideSpace * countDrawer);
    const countDraw = top.attrs.params.drawerArray.length;
    const topStopDrag = (countDraw * (drawHeight - sideSpace)) + (bottomSectionDiff) + (countDraw * sideSpace);
    const topStopMove = (this.coef.topPositaionWrapper + (this.coef.heightWardrobe / this.coef.global_coef)) - this.coef.drawer.maxTopPos - this.coef.global_size_flat;
    top['attrs'].params.drawer = {
      topMinY: topStopMove,
      bottomMinY: elementBottom,
      topStopDrag: topStopDrag,
      elementBottom: allContour.contoursShalf['bottom'],
    };
  }

  countTopDrawer(pos, drawer_group, count) {
    const topDrawer = this.create.getTangentElement(pos, drawer_group, this.coef.shapesLayerw);
    if (topDrawer['top'].attrs.params.type === this.coef.id_element.drawer) {
      count.count++;
      this.countTopDrawer({evt: {layerX: topDrawer['top'].attrs.x, layerY: topDrawer['top'].attrs.y}}, topDrawer['top'], count);
    }
  }

  getlastdrawer(element, last) {

    if (element.attrs.params.type === 9) {
      const allContour = this.getContourShalf({evt: {layerX: element.attrs.x, layerY: element.attrs.y}}, element);
      return allContour.contoursShalf['top'].attrs.params['drawerArray'][allContour.contoursShalf['top'].attrs.params['drawerArray'].length - 1];
    } else {
      return false;
    }
  }

  parseRgba(string, opacity) {
    let respons = string.split(',');
    respons.splice(respons.length - 1, 1);
    respons.toString();
    respons = respons + ',' + opacity + ')';
    return respons;
  }
}
