import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Notification} from '../../../interfaces/notification.interface';

@Component({
  selector: 'app-single-notification',
  templateUrl: './single-notification.component.html',
  styleUrls: ['./single-notification.component.css']
})
export class SingleNotificationComponent implements OnInit {
  @Input() notification: Notification;
  @Output() notificationClosed: EventEmitter<any> = new EventEmitter();

  private timer: any;

  constructor() {
  }

  ngOnInit() {
    this.startTimer(4000 + this.notification.content.length * 10);
  }

  startTimer(ms: number) {
    this.timer = setTimeout(() => {
      this.notificationClosed.emit();
    }, ms);
  }

  public onMouseEnter(): void {
    clearTimeout(this.timer);
  }

  onMouseLeave() {
    this.startTimer(500);
  }


}
