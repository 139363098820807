import {Injectable} from '@angular/core';
import {CoefService} from './coef.service';
import {CreateService} from './create.service';
import {MinSizeService} from './min-size.service';
import {RemoveService} from './remove.service';
import {Drawer} from '../class/drawer';
import {OpenDoor} from '../class/open-door';
import {BackWall} from '../class/back-wall';
import {RetractablePipe} from '../class/retractable-pipe';
import {Hooks} from '../class/hooks';
import {Pantograph} from '../class/pantograph';
import {Trumpet} from '../class/trumpet';
import {Partition} from '../class/partition';
import {Shelf} from '../class/shelf';
import {CornerEnding} from '../class/cornerEnding';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ElementsService {
  public widthTrumpetBefore = 2;
  public pantographWidthLand = 4;
  public startPosition = {};

  constructor(private coef: CoefService,
              private create: CreateService,
              private minSize: MinSizeService,
              private remove: RemoveService) {
  }


  chanchOpacity(string, opacity) {
    let respons = string.split(',');
    let opac = respons[respons.length - 1].split(')')[0];
    respons.splice(respons.length - 1, 1);
    respons.toString();
    respons = respons + ',' + opacity + ')';
    return respons;
  }

  recolor(e, parent, look2d) {
    this.coef.color = e;
    for (const color of look2d.children) {
      const opacity = color.fill().split(')')[0].split(',')[3];
      color.fill(this.chanchOpacity(this.coef.color, opacity));
    }
    if (parent && parent.children) {
      for (const color of parent.children) {
        if (color.nodeType === 'Group') {
          for (const item of color.children) {
            if (typeof (item.fill) === 'function' && item.fill() !== this.coef.silverColor && item.fill() !== '#6c6c6c'  && item.fill() !== this.coef.blackColor && item.fill() !== 'black') {
              item.fill(this.coef.color);
              if (item.attrs && item.attrs.params && item.attrs.params.facad) {
                item.fill(this.coef.colorFacad);
              }
              if (item.attrs && item.attrs.params && item.attrs.params.opacity) {
                item.fill(this.coef.addOpacytiRGBA(this.coef.color, 0.5));
              }
            }
            if (item.nodeType === 'Group') {
              for (const items of item.children) {
                if (typeof (items.fill) === 'function' && items.fill() !== this.coef.silverColor && items.fill() !== '#6c6c6c'  && items.fill() !== this.coef.blackColor && items.fill() !== 'white' && items.fill() !== 'black') {
                  items.fill(this.coef.color);
                  if (items.attrs && items.attrs.params && items.attrs.params.facad ) {
                    items.fill(this.coef.colorFacad);
                  }
                  if (items.attrs && items.attrs.params && items.attrs.params.opacity) {
                    items.fill(this.coef.addOpacytiRGBA(this.coef.color, 0.5));
                  }
                }
              }
            }
          }
        } else {
          if (color.fill() !== 'black') {
            color.fill(this.coef.color);
          }
        }
      }
    }


    for (const color of this.coef.product.children) {
      if (color.nodeType === 'Group') {
        for (const item of color.children) {
          if (item.fill() !== this.coef.silverColor && item.fill() !== this.coef.blackColor && item.fill() !== 'black') {
            item.fill(this.coef.color);
          }
        }
      } else {
        if (color.fill() !== 'black') {
          color.fill(this.coef.color);
        }
      }
    }
    look2d.batchDraw();
    if (parent) {
      parent.batchDraw();
    }

    this.coef.product.batchDraw();
  }

  initShelf(product, parent, stage) {
    this.minSize.minsize(this.coef.shapesLayerw);
    for (const element of this.coef.arrayOfsset) {
      this.createElementFilling(product, parent, stage, element.id, element);
    }
    this.cursorePointer(product, parent);
    stage.add(parent);
    stage.add(product);
    this.coef.startPosition = {};
    this.startPosition = {};
    this.coef.startPosition = _.cloneDeep(product);
    this.startPosition = _.cloneDeep(product);
  }

  createElementFilling(product, parent, stage, id_element, active) {
    switch (id_element) {
      case 1: {
        this.createOpenDoorOne(product, active);
      }
        break;
      case 2: {
        this.createRetractablePipe(product, active);
      }
        break;
      case 3: {
        this.createHooks(product, active);
      }
        break;
      case 4: {
        this.createBackWall(product, active);

      }
        break;
      case 5: {
        this.createPantograph(product, parent, active);
      }
        break;
      case 6: {
        this.createPartition(product, parent, active);
      }
        break;
      case 7: {
        this.createShelf(product, parent, active);
      }
        break;
      case 8: {
        this.createTrumpet(product, parent, active);
      }
        break;
      case 9: {
        this.createDrawer(product, parent, active);
      }
        break;
      case 15: {
        this.createCornerEnding(product, parent, active);
      }
        break;
    }
  }

  dragEnd(fillingElement, product, test?) {
    fillingElement.on('dragend', (e) => {
      for (const element of this.startPosition['children']) {
        if (fillingElement.attrs.params.type === element.attrs.params.type) {
          if (element.attrs.params.subId) {
            if (element.attrs.params.subId === fillingElement.attrs.params.subId) {
              fillingElement.x(element.attrs.x);
              fillingElement.y(element.attrs.y);
              product.batchDraw();
            }
          } else {
            fillingElement.x(element.attrs.x);
            fillingElement.y(element.attrs.y);
            product.batchDraw();
          }
        }
      }
      test(e, {x: e.evt.layerX, y: e.evt.layerY});
    });
  }

  createShelf(product, parent, active) {
    product.add(new Shelf(this.coef, this.create, active.active, 21).group);
    product.add(new Shelf(this.coef, this.create, active.active, 20).group);
  }


  createCornerEnding(product, parent, active) {
    product.add(new CornerEnding(this.coef, this.create, active.active).group);
    product.add(new CornerEnding(this.coef, this.create, active.active, true).group);
  }

  createTrumpet(product, parent, active) {
    product.add(new Trumpet(this.coef, this.create, 24, active.active).group);
    product.add(new Trumpet(this.coef, this.create,25, active.active).group);
  }

  createPartition(product, parent, active) {
    product.add(new Partition(this.coef, this.create, active.active).group);
  }

  createPantograph(product, parent, active) {
    product.add(new Pantograph(this.coef, this.create, active.active).group);
  }

  createDrawer(product, parent, active) {
    this.coef.countNameElement++;
    for (const items of active.sub_items) {
      product.add(new Drawer(items.id, this.coef, this.create, items.active, this.minSize).drawer_group);
    }

  }

  createOpenDoorOne(product, active) {
    this.coef.countNameElement++;
    for (const item of active.sub_items) {
      if (item.id !== 19) {
        product.add(new OpenDoor(item.id, this.coef, this.create, item.active).group);
      }
    }
  }

  createRetractablePipe(product, active) {
    this.coef.countNameElement++;
    if (active.sub_items.length) {
      for (let i = 0; i < active.sub_items.length; i++) {
        product.add(new RetractablePipe(this.coef, this.create, i + 1, active.sub_items[i].id, active.sub_items[i].active).group);
      }
    }
  }

  createBackWall(product, active) {
    product.add(new BackWall(this.coef, this.create, active.active).group);
  }

  createHooks(product, active) {
    for (const item of active.sub_items) {
      product.add(new Hooks(this.coef, this.create, item.id, item.active).group);
    }
  }

  cursorePointer(product, parent) {
    product.on('dragstart', (pos) => {
      if (this.coef.startRemove) {
        this.remove.startElement(parent);
      }
    });
    parent.on('mouseover', (element) => {
      if (this.coef.startRemove) {
        if (element.target.parent.nodeType === 'Group') {
          if (element.target.parent.attrs.params.removable) {
            for (const elements of  element.target.parent.children) {
              if (elements.nodeType !== 'Group') {
                elements.fill(this.chanchOpacity(elements.fill(), 0.1));
              }
            }
            this.remove.mousOnColor(element.target.parent.attrs.bind, true);
            document.body.style.cursor = 'pointer';
            parent.batchDraw();
          }
        } else {
          if (element.target.attrs.params.removable) {
            element.target.fill(this.chanchOpacity(element.target.fill(), 0.1));
            this.remove.mousOnColor(element.target.attrs.bind, true);
            document.body.style.cursor = 'pointer';
            parent.batchDraw();
          }
        }
      } else {
        document.body.style.cursor = 'pointer';
      }
    });
    parent.on('mouseout', (element) => {
      if (this.coef.startRemove) {
        if (element.target.parent.nodeType === 'Group') {
          if (element.target.parent.attrs.params.removable) {
            for (const elements of  element.target.parent.children) {
              if (elements.nodeType !== 'Group') {
                elements.fill(this.chanchOpacity(elements.fill(), 1));
              }
            }
            this.remove.mousOnColor(element.target.parent.attrs.bind, true);
            document.body.style.cursor = 'pointer';
            parent.batchDraw();
          }
        } else {
          if (element.target.attrs.params.removable) {
            element.target.fill(this.chanchOpacity(element.target.fill(), 1));
            this.remove.mousOnColor(element.target.attrs.bind, true);
            document.body.style.cursor = 'pointer';
            parent.batchDraw();
          }
          if (element.target.attrs.params.removable) {
            element.target.fill(this.chanchOpacity(element.target.fill(), 1));
            this.remove.mousOnColor(element.target.attrs.bind, false);
            parent.batchDraw();
          }
        }
      }
    });
    this.coef.product.on('mouseover', function (evt) {
      document.body.style.cursor = 'pointer';
    });
    parent.on('click', (evt) => {
      if (this.coef.startRemove) {
        this.deleteElement(evt.target, parent);
      }

    });
    parent.on('mouseout', function (evt) {
      document.body.style.cursor = 'default';
    });
    product.on('mouseout', function (evt) {
      document.body.style.cursor = 'default';
    });
  }

  deleteElement(element, parent) {
    if (element.parent.nodeType === 'Group') {
      console.log(_.cloneDeep(element.parent), 'remove delete');
      this.remove.deleteElements(element.parent.attrs.bind, parent);
      if (element.parent.attrs.params.type === this.coef.id_element.drawer) {
        this.remove.deleteElements(element.parent.attrs.params.bind, parent, true);
      }
      if (element.parent.attrs.params.type === 15) {
        this.remove.deleteElements(element.parent.attrs.bind, parent, false, true);
      }
      this.remove.deleteElement(element.parent.attrs.name, parent);
    } else {
      this.remove.deleteElements(element.attrs.bind, parent);
      this.remove.deleteElement(element.attrs.name, parent);
    }
  }
}
