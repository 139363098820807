import {Component, OnInit} from '@angular/core';
import {CoefService} from '../../service/coef.service';

@Component({
  selector: 'app-drawer-lock',
  templateUrl: './drawer-lock.component.html',
  styleUrls: ['./drawer-lock.component.scss']
})
export class DrawerLockComponent implements OnInit {

  constructor(public coef: CoefService) {
  }

  ngOnInit() {
  }

}
