import {Injectable} from '@angular/core';
import * as Konva from '../../../assets/konva';
import {CoefService} from '../coef.service';
import {InitRenderService} from '../renderingService/init-render.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CoefDoorService {
  doorStag = {stage: null};
  DoorWrapper;
  manualTravelElement;
  door_structure = [];
  name = 1;
  heightDoor = 2000;
  glob_settings;
  color = 'rgba(100, 66, 66 , 1)';
  facade_materials = [];
  print_allowed:any = [];
  title = '';
  printAllowed = {};
  colorSystem = 'rgba(192,192,192,1)';
  colorSystemArray = [];
  colorSystemId = 1;
  reDesign;
  parsMatPhoto;
  parsMatPhoto2;
  stoperDoor:any = 'none';
  puller:any = false;
  has_top_stopper;
  has_bottom_stopper;
  has_puller;
  rollerDoor = false;
  door_max_separation = [];
  door_max_separation_v = []
  sepArray = [];
  heightSeparator;
  glob_settings_old;
  systemId;
  max_h_parts_qty;
  facade_materials_system;
  min_h_section_height;
  constructor(private coef: CoefService, private render: InitRenderService) {
    this.DoorWrapper = new Konva.Layer();
  }

  getStage(stag) {
    this.doorStag = stag;
  }

  parsApiSettings(facade_materials , sand_prints) {
    this.facade_materials = facade_materials;
    this.print_allowed = sand_prints;
    for(const item of facade_materials){
      this.printAllowed[item.id] = item.print_allowed;
    }
    this.title = sand_prints.name;
  console.log(this.printAllowed,'this.printAllowed')
    this.parsMatPhoto = this.parsMatPhotoAll(this.facade_materials, 8);
    this.parsMatPhoto2 = this.parsMatPhotoAllCustom(sand_prints);
  }
  parceSystemColor(colorSystem){
    this.colorSystemArray = colorSystem
    this.makeColor(this.colorSystemArray);
  }
  parsApiSettingsSystem(settings, facade_materials){
    this.glob_settings = settings;
    this.facade_materials_system = facade_materials;
    this.glob_settings_old = _.cloneDeep(settings);
    this.heightSeparator = this.glob_settings.separator_height ;
    console.log(this.coef.global_coef , 'this.coef.global_coef')
    this.door_max_separation = this.pars_separation(this.glob_settings.max_h_parts_qty);
    this.door_max_separation_v = this.pars_separation(this.glob_settings.max_v_parts_qty);
  }

 

  makeColor(color) {
    this.colorSystem = this.isDefaultTest(color).rgb;
    this.colorSystemId = this.isDefaultTest(color).id;
  }
  isDefault(array){
    for (const item of array) {
      if (item.is_default) {
        return item;
      }
    }
    return false;
  }
  isDefaultTest(array){
    for (const item of array) {
      if (item.is_default) {
        return item;
      }
    }
    return array[0];
  }
  findtoID(array, id) {
    for (const item of array) {
      if (item.id === id) {
        return item;
      }
    }
    return false;
  }

  pars_separation(max) {
    const array = [];
    for (let i = 1; i <= max; i++) {
      array.push(i);
    }
    return array;
  }

  test(i) {

  }

  parsRenderDoor() {
    this.colorSystem = this.render.object.systemColorRgb;
    this.colorSystemId = this.render.object.system_color_id;
  }


  parsMatPhotoAllCustom(mat) {
 
        for (let options of mat.options) {
          options.order_types = mat.order_types;
          options.names = options.name;
          options.name = mat.name;
        }
        return mat.options;
    
  }

  parsMatPhotoAll(mat, id) {
    const img = [];
    for (const material of mat) {
      if (material.id === id) {
        for (let options of material.options) {
          options.order_types = material.order_types;
          options.names = options.name;
          options.name = material.name;
          // for (let i = 0; i < options.images.length; i++) {
          //   const objct = {
          //     active: options.active,
          //     id: options.id,
          //     is_default: options.is_default,
          //     name: material.name,
          //     order_types: material.order_types,
          //     rgb: options.rgb,
          //     subId: i,
          //     src: options.images[i].path
          //   };
          //   img.push(objct);
          // }
        }
        return material.options;
      }
    }
    // return img;
  }
}
