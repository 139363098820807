import {Create} from './create';
import * as Konva from '../../assets/konva';
import {Sizes} from './sizes/sizes';
import * as _ from 'lodash';

export class CornerEnding extends Create {
  subId;
  group;
  coef;
  create;
  positionCentr = [];
  positionY = [];
  width;

  constructor(coef, create, active, fig?) {
    super(coef, create);
    // this.subId = subId;
    this.coef = coef;
    this.create = create;
    this.width = this.coef.ending.min_width_riall;
    if (fig) {
      this.createSpecCornerEnding(active, true);
    } else {
      this.createSpecCornerEnding(active);
    }

  }

  createSpecCornerEnding(active, fig?) {
    this.coef.countNameElement++;
    let subIds = 22;
    if (fig) {
      subIds = 23;
    }
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 20,
      y: -500,
      draggable: active,
      width: 30,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.corner_ending,
        position: 'left',
        subId: subIds
      },
      bind: [],
    });

    const bg = new Konva.Rect({
      x: 0,
      y: 0,
      width: 30,
      height: 100,
      name: 'width',
      fill: this.coef.color,
    });
    const topShelf = new Konva.Rect({
      x: 0,
      y: 0,
      width: 30,
      height: 6,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    const Shelf = new Konva.Rect({
      x: 0,
      y: 20,
      width: 30,
      height: 6,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    const Shelf2 = new Konva.Rect({
      x: 0,
      y: 40,
      width: 30,
      height: 6,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    const Shelf3 = new Konva.Rect({
      x: 0,
      y: 60,
      width: 30,
      height: 6,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    const Shelf4 = new Konva.Rect({
      x: 0,
      y: 80,
      width: 30,
      height: 6,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    const Shelf5 = new Konva.Rect({
      x: 0,
      y: 0,
      width: 6,
      height: 100,
      name: 'height',
      stroke: 'black',
      fill: this.coef.color,
      strokeWidth: 1,
    });

    this.group.add(bg, Shelf, Shelf2, Shelf3, Shelf4, Shelf5, topShelf);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.createSpecCornerEndingApi(pos, fig);
    });
  }

  createSpecCornerEndingApi(pos, fig?, width?) {
    if (width) {
      this.width = width;
    }
    this.coef.countNameElement++;
    let x;
    let subIds = 22;
    if (fig) {
      subIds = 23;
    }
    const initLeftRight = this.findPositionLeftRight(pos);
    if (initLeftRight === 'left') {
      x = this.positionCentr[1].x;
      if (!this.blockEnding('left')) {
        return;
      }
    } else {
      x = this.positionCentr[0].x + this.coef.global_size_flat;
      if (!this.blockEnding('right')) {
        return;
      }
    }
    const position = this.findPositionLeftRight(pos);
    let yTop = this.positionCentr[0].y - this.coef.global_size_flat;
    if (this.coef.pramsId == 11) {
      yTop = this.positionCentr[0].y;
    }
    this.coef.countNameElement++;
    const group_create = new Konva.Group({
      x: x,
      y: yTop,
      name: 'element' + this.coef.countNameElement,
      width: this.width,
      params: {
        removable: true,
        type: this.coef.id_element.corner_ending,
        height: parseInt(this.coef.setingsWardrobe.height) / this.coef.global_coef,
        position: position,
        subId: subIds,
        shelfPositions: []
      },
      bind: []
    });
    let leftRight;
    let fon;
    let start;
    let poly;
    const This = this;
    if (position === 'left') {
      leftRight = new Konva.Rect({
        x: -(this.width),
        y: 0,
        name: 'element' + this.coef.countNameElement,
        width: this.width,
        height: this.coef.global_size_flat,
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      fon = new Konva.Rect({
        x: -(this.width),
        y: 0,
        width: this.width,
        height: group_create['attrs'].params.height,
        name: 'element' + this.coef.countNameElement,
        fill: this.parseRgba(this.coef.color, '0.5'),
        params: {
          opacity: true
        },
        strokeWidth: 1,
        stroke: 'black',
      });
      start = new Konva.Rect({
        x: -this.coef.global_size_flat,
        y: 0,
        name: 'element' + this.coef.countNameElement,
        width: this.coef.global_size_flat,
        height: group_create['attrs'].params.height,
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      group_create.add(start);
      group_create.add(fon);

      if (fig) {
        const text = new Konva.Text({
          x: fon.attrs.x,
          y: (fon.attrs.y + fon.attrs.height) + 5,
          text: 'Ф',
          fontSize: 25,
          fontFamily: 'Calibri',
          fill: 'black'
        });
        group_create.add(text);
      }
      this.findYForElement(4, group_create['attrs'].params.height - this.coef.global_size_flat, this.coef.global_size_flat);
      for (let item of this.positionY) {
        group_create.add(new Konva.Rect({
          x: -(this.width),
          y: item,
          name: 'element' + this.coef.countNameElement,
          width: this.width,
          height: this.coef.global_size_flat,
          fill: this.coef.color,
          strokeWidth: 1,
          stroke: 'black',
        }));
        group_create['attrs'].params.shelfPositions.push(item);
      }
      group_create.add(leftRight);
      const centrY = (group_create['attrs'].params.height / 2) + this.positionCentr[0].y;
      const centX = x - this.width;
      poly = new Konva.Line({
        points: [centX - 20, centrY - 20, centX - 20, centrY + 20, centX - 40, centrY],
        fill: 'rgba(0,0,0,0.1)',
        name: `lines${this.coef.countNameElement}`,
        closed: true,
        draggable: true,
        bind: [],
        params: {
          removable: false,
          type: 250,
          bind: group_create
        },
        dragBoundFunc: function (pos) {
          const min_right = This.width - This.coef.ending.min_width_riall;
          const max_right = -(This.coef.ending.max_width - This.width);
          if (pos.x > min_right) {
            This.resizWidth(-This.coef.ending.min_width_riall, group_create);
            group_create.width(This.coef.ending.min_width_riall);
            return {
              x: min_right,
              y: this.getAbsolutePosition().y
            };
          }
          if (pos.x < max_right) {
            This.resizWidth(-This.coef.ending.max_width, group_create);
            group_create.width(This.coef.ending.max_width);
            return {
              x: max_right,
              y: this.getAbsolutePosition().y
            };
          }
          This.resizWidth(pos.x - This.width, group_create);
          group_create.width(pos.x + This.width);
          return {
            x: pos.x,
            y: this.getAbsolutePosition().y
          };
        }
      });
      new Sizes(this.coef, this.create).cornerEndingSizes(group_create, poly, true);
    } else {
      leftRight = new Konva.Rect({
        x: 0,
        y: 0,
        name: 'element' + this.coef.countNameElement,
        width: this.width,
        height: this.coef.global_size_flat,
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      fon = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.width,
        height: group_create['attrs'].params.height,
        name: 'element' + this.coef.countNameElement,
        fill: this.parseRgba(this.coef.color, '0.5'),
        params: {
          opacity: true
        },
        strokeWidth: 1,
        stroke: 'black',
      });
      start = new Konva.Rect({
        x: 0,
        y: 0,
        name: 'element' + this.coef.countNameElement,
        width: this.coef.global_size_flat,
        height: group_create['attrs'].params.height,
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      group_create.add(start);
      group_create.add(leftRight);
      group_create.add(fon);
      if (fig) {
        const text = new Konva.Text({
          x: fon.attrs.x + 20,
          y: (fon.attrs.y + fon.attrs.height) + 5,
          text: 'Ф',
          fontSize: 25,
          fontFamily: 'Calibri',
          fill: 'black'
        });
        group_create.add(text);
      }
      this.findYForElement(4, group_create['attrs'].params.height - this.coef.global_size_flat, this.coef.global_size_flat);
      group_create['attrs'].params.shelfPositions = [];
      for (let item of this.positionY) {
        group_create.add(new Konva.Rect({
          x: 0,
          y: item,
          name: 'element' + this.coef.countNameElement,
          width: this.width,
          height: this.coef.global_size_flat,
          fill: this.coef.color,
          strokeWidth: 1,
          stroke: 'black',
        }));
        this.positionY = [];
        group_create['attrs'].params.shelfPositions.push(item);
      }
      const centrY = (group_create['attrs'].params.height / 2) + this.positionCentr[0].y;
      const centX = x + this.width + this.coef.global_size_flat;
      this.coef.countNameElement++;
      const This = this;
      poly = new Konva.Line({
        points: [centX + 20, centrY - 20, centX + 20, centrY + 20, centX + 40, centrY],
        fill: 'rgba(0,0,0,0.2)',
        name: `lines${this.coef.countNameElement}`,
        closed: true,
        draggable: true,
        bind: [],
        params: {
          removable: false,
          type: 250,
          bind: group_create
        },
        dragBoundFunc: function (pos) {
          const min_right = -(This.width - This.coef.ending.min_width_riall);
          const max_right = This.coef.ending.max_width - This.width;
          if (pos.x < min_right) {
            This.resizWidth(min_right + This.width, group_create);
            group_create.width(min_right + This.width);
            return {
              x: min_right,
              y: this.getAbsolutePosition().y
            };
          }
          if (pos.x > max_right) {
            This.resizWidth(max_right + This.width, group_create);
            group_create.width(max_right + This.width);
            return {
              x: max_right,
              y: this.getAbsolutePosition().y
            };
          }
          This.resizWidth(pos.x + This.width, group_create);
          group_create.width(pos.x + This.width);
          return {
            x: pos.x,
            y: this.getAbsolutePosition().y
          };
        }
      });
      new Sizes(this.coef, this.create).cornerEndingSizes(group_create, poly);
    }
    this.coef.shapesLayerw.add(group_create);
    this.coef.shapesLayerw.add(poly);
    group_create['attrs'].bind.push(poly);
    this.coef.shapesLayerw.batchDraw();
    const height = group_create['children'][5].attrs.y - group_create['children'][4].attrs.y;
    console.log(group_create);
    // const height = 20;
    if (initLeftRight === 'left') {
      new Sizes(this.coef, this.create).createOneSizeTop(height - this.coef.global_size_flat, this.coef.global_size_flat, -15, group_create);
    } else {
      new Sizes(this.coef, this.create).createOneSizeTop(height - this.coef.global_size_flat, this.coef.global_size_flat, +15, group_create);
    }
  }

  findYForElement(count, hight, topForShelf) {
    const onheight = Math.floor((hight * this.coef.global_coef - (this.coef.global_size_flat * this.coef.global_coef * count)) / (count + 1)) / this.coef.global_coef;
    for (let i = 1; i <= count; i++) {
      if (i === 1) {
        this.positionY.push(topForShelf + onheight * i);
      } else {
        this.positionY.push(topForShelf + (this.coef.global_size_flat * (i - 1)) + onheight * i);
      }
    }
    return this.positionY;
  }

  resizWidth(width, group) {
    for (let i = 1; i < group.children.length; i++) {
      if (group.children[i].className !== 'Text') {
        group.children[i].width(width);
        group.children[i].x(0);
      }
    }
    if (width < 0) {
      group.children[group.children.length - 1].x(-15);
    } else {
      group.children[group.children.length - 1].x(width - 15);
    }
  }

  // resizX(group, poli) {
  //   group.x(poli['attrs'].points[0] + poli.attrs.x + 20 + group.width());
  // }

  findPositionLeftRight(pos) {
    if (this.coef.pramsId != 4) {
      for (const item of this.coef.shapesLayerw.children) {
        if (item.attrs.params.type === this.coef.id_element.lateral_wall) {
          this.positionCentr.push(item.attrs);
        }
      }
      if (pos.evt.layerX > (this.positionCentr[1].x - this.positionCentr[0].x) / 2 + this.positionCentr[0].x) {
        return 'right';
      }
      return 'left';
    } else {
      const centerPosition = (this.coef.widthWrapper - this.coef.setingsWardrobe.width / this.coef.global_coef) / 2;
      for (const item of this.coef.shapesLayerw.children) {
        if (item.attrs.params.type === this.coef.id_element.lateral_wall) {
          const attrs = {
            x: centerPosition + (this.coef.setingsWardrobe.width / this.coef.global_coef) - this.coef.global_size_flat,
            y: this.coef.topPositaionWrapper + this.coef.global_size_flat
          };
          this.positionCentr.push(attrs);
        }
        if (item.attrs.params.type === 16) {
          const attrs = {x: centerPosition, y: this.coef.topPositaionWrapper + this.coef.global_size_flat};
          this.positionCentr.push(attrs);
        }
      }
      if (pos.evt.layerX > (this.coef.widthWrapper / 2)) {
        return 'right';
      }
      return 'left';
    }
  }
}
