import {Injectable} from '@angular/core';
import {CoefService} from './coef.service';
import {SocketPriceService} from './socket-price.service';
import {DoorOptions} from '../interfaces/door-options.interface';
import {CookieService} from 'angular2-cookie/core';
import {CoefDoorService} from './door service/coef-door.service';
import * as _ from 'lodash';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {InitRenderService} from '../service/renderingService/init-render.service';

@Injectable({
  providedIn: 'root'
})
export class PriceService {
  object = {
    user_id: null,
    order_type_id: 12,
    width: 2000,
    height: 2400,
    depth: 600,
    comment: '',
    colorID: null,
    system_color_id: null,
    facade_color_id: null,
    scheme_id: null,
    lamp_id: null,
    init_option_ids: [],
    content: [],
    deleted_older_content_square: 0,
    global_coef: 4,
    deleted_older_content_elements: {
      locks: 0,
      pantographs: 0,
      vv: 0,
      shuttles_small: 0,
      shuttles_big: 0,
      slow_closers: 0,
      corner_ends: {
        little_default: 0,
        little_figured: 0,
        big_default: 0,
        big_figured: 0,
      },
      tubes: 0,
      hooks: 0
    },
    door_count: [
      /*{width: 980, door_type_id: 2, sep_count: 5, material_ids: []},
      {width: 980, door_type_id: 4, sep_count: 0, material_ids: []}*/
    ],
  };
  test = new Subject();
  result = this.test.pipe(debounceTime(1000));

  constructor(private coef: CoefService,
              private socket: SocketPriceService,
              private _cookieService: CookieService,
              private render: InitRenderService,
              private coefDoor: CoefDoorService) {

    this.result.subscribe((x) => {
        this.sendObjectToBack();
      }
    );

  }

  startCountPrice() {
    this.coef.shapesLayerw.on('dragend', () => {
      this.sendObjectPrice();
    });
  }


  _setMainParams() {
    const paramNamesArray = ['width', 'height', 'depth', 'order_type_id'];
    for (let i = 0; i < paramNamesArray.length; i++) {
      this.object[paramNamesArray[i]] = +this.coef.setingsWardrobe[paramNamesArray[i]];
    }
  }

  _setContentItems() {
    this.object.content = [];

    const content = this.coef.shapesLayerw.children.map(children => {
      const itemSend = _.cloneDeep(children.attrs);
      if (children.attrs.params && children.attrs.params.type === 15) {
        if (children.attrs.params.position == 'left') {
          if (children.children[1].attrs.width < 0) {
            itemSend.width = children.children[1].attrs.width * -1;
          }
        }
      }
      return itemSend;
    });

    for (let i = 0; i < content.length; i++) {
      for (const obj in content[i].params) {
        if (_.isObject(content[i].params[obj])) {
          if (obj === 'shelfPositions' || obj === 'older') {

          } else {
            delete content[i].params[obj];
          }
        }
      }
      if (content[i].bind) {
        for (let j = 0; j < content[i].bind.length; j++) {
          for (const obj in content[i].bind[j].attrs.params) {
            if (_.isObject(content[i].bind[j].attrs.params[obj])) {
              if (obj !== 'shelfPositions') {
                delete content[i].bind[j].attrs.params[obj];
              }
            }
          }
          content[i].bind[j] = content[i].bind[j].attrs;
          if (content[i].bind[j].bind) {
            delete content[i].bind[j].bind;
          }
        }
      }
    }
    this.object.content = content;
  }

  private setInitOptions(): void {
    this.object['scheme_id'] = this.coef.scheme_id;
    this.object['lamp_id'] = this.coef.lamp_id;
    this.object['init_option_ids'] = this.coef.init_option_ids;
  }

  setDoor(Layerw) {
    
    const content = this.copyIntStringParams(Layerw.attrs);
    content['params'] = this.copyIntStringParams(Layerw.attrs.params);
    return content;
  }

  copyIntStringParams(source) {
    const result = {};

    for (const key in source) {
        if (source.hasOwnProperty(key)) {
            const value = source[key];
            if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                result[key] = value;
            }
        }
    }

    return result;
}
  // getmeatirials(id) {
  //   for (const obj of this.coefDoor.facade_materials) {
  //     if (obj.id === id) {
  //       return obj;
  //     }
  //   }
  // }

  public setDoorOptions(doorOptions: DoorOptions[] , height?): void {
    this.object['door_count'] = [...this.coef.doorsArray];
    this.object['system_id'] = this.coefDoor.systemId;
    this.object['stoper'] = this.coefDoor.stoperDoor;
    this.object['puller'] = this.coefDoor.puller;
    
    let index = 0;
    for (const door of this.object['door_count']) {
      index = index + 1;
      door.materials = [];
      door.content = [];
      door.system_id = this.coef.doorSystemsId;
      door.y = this.coef.topPositaionWrapper;
      door.separators = [];
      console.log(this.coefDoor.DoorWrapper.children , 'this.coefDoor.DoorWrapper.children')
      for (const elem of this.coefDoor.DoorWrapper.children) {
        if (elem.attrs.params && elem.attrs.params.type === index && elem.attrs.params.price) {
          door.door_type_id = elem.attrs.params.typeDoor;
          door.materials.push(elem.attrs.params.id);
          door.content.push(this.setDoor(elem));
        }
        if (elem.attrs.params && elem.attrs.params.type === index && elem.attrs.params.part ) {
          door.separators.push(this.setDoor(elem));
        }
      }

      if (this.coefDoor.sepArray.includes(door.door_type_id)) {
        door.sep_count = door.materials.length - 1;
      } else {
        door.sep_count = 0;
      }
    }
    
    if (this.coefDoor.DoorWrapper.children.length == 0 && this.coefDoor.facade_materials_system) {
      if(!this.render.rerender){
        const mat = this.findMat(2,1 , this.coefDoor.facade_materials_system)
        const matDef = this.findMat(mat.id,1 , this.coefDoor.facade_materials)
  
        if (this.object['door_count']) {
          for (let i = 0; i < this.object['door_count'].length; i++) {
            const index = i;
            const door = this.object['door_count'][i];
          
              try{
                door.materials.push(2);
                door.sep_count = 0;
                // const item = this.findItem(2);
                console.log(height , 'height')
                const customHeightWardrobe = height|| this.coef.setingsWardrobe.height
                let customHeight = (customHeightWardrobe / this.coef.global_coef) - this.coef.height_legs - this.coef.global_size_flat * 2 - (this.coefDoor.glob_settings.system_thickness / this.coef.global_coef);
                if (this.coef.pramsId == 13) {
                  customHeight = (customHeightWardrobe / this.coef.global_coef) - (this.coefDoor.glob_settings.system_thickness / this.coef.global_coef);
                }
                if (this.coef.pramsId == 11) {
                  //need chack
                  customHeight = (customHeightWardrobe / this.coef.global_coef) - this.coefDoor.glob_settings.system_thickness / this.coef.global_coef;
                }
                console.log(customHeight , customHeightWardrobe ,'customHeightWardrobe')
                const width_hang = this.coef.convertCoef(this.coefDoor.glob_settings.right_hang_width) + this.coef.convertCoef(this.coefDoor.glob_settings.left_hang_width);
                const width = (this.coef.doorsArray[i]['width'] / this.coef.global_coef) - width_hang ;
                console.log(width  * 4 ,this.coef.doorsArray[i]['width'],width_hang, 'width')
                door.content.push({
                  width: width,
                  height: customHeight - this.coef.convertCoef(this.coefDoor.glob_settings.top_plant_height) - this.coef.convertCoef(this.coefDoor.glob_settings.bottom_plant_height),
                  params: {
                    id: matDef.default_option.id,
                    typeDoor: matDef.default_option.id,
                    type: i +1,
                    index: 1,
                    subId: mat.id,
                    remove: true,
                    numberItem: 1,
                    position: index + 1,
                    top:  'fence', left:  'fence', right: 'fence', bottom: 'fence'
                  },
                });
  
              }catch(e){
                console.warn(e)
              }
            
         
          }
        }
      }else{
        if (this.render.object.facade_object) {
          this.object['door_count'] = _.cloneDeep(this.render.object.facade_object)
        }
      }
      
    }

  }

  findMat(id , secondId , array){
    for(const item of array){
      if(item.id == id){
          return item
      } 
    }
    for(const item of array){
      if(item.id == secondId){
        return item;
      }
    }
    
    return this.coefDoor.facade_materials[0]
    }

  findItem(id) {
   
        return {id:null , content:null};

  }

  private updateColor(): void {
    this.object.colorID = this.coef.colorID;
    this.object.system_color_id = this.coef.system_color_id;
    this.object.facade_color_id = this.coef.facade_color_id;
    if (this.coef.arrayNotFacadColor.includes(this.coef.pramsId)) {
      this.object.system_color_id = null;
    }
  }


  sendDoor() {
    this.object.system_color_id = this.coefDoor.colorSystemId;
    if (this.coef.arrayNotSystemColor.includes(+this.coef.pramsId)) {
      this.object.system_color_id = null;
    }
    this.object['door_count'] = [...this.coef.doorsArray];
    this.object['system_id'] = this.coefDoor.systemId;
    this.object['stoper'] = this.coefDoor.stoperDoor;
    this.object['puller'] = this.coefDoor.puller;
    let index = 0;
    console.log(this.coefDoor.DoorWrapper.children , 'this.coefDoor.DoorWrapper.children2')
    for (const door of this.object['door_count']) {
      index = index + 1;
      door.materials = [];
      door.content = [];
      door.y = this.coef.topPositaionWrapper;
      door.separators = [];
      door.system_id = this.coef.doorSystemsId;
      door.systemId = this.coefDoor.systemId;
      for (const elem of this.coefDoor.DoorWrapper.children) {
        if (elem.attrs.params && elem.attrs.params.type === index && elem.attrs.params.price) {
          door.door_type_id = elem.attrs.params.typeDoor;
          door.materials.push(elem.attrs.params.id);
          door.content.push(this.setDoor(elem));
        }
        if (elem.attrs.params && elem.attrs.params.type === index && elem.attrs.params.part ) {
          door.separators.push(this.setDoor(elem));
        }
      }
      if (this.coefDoor.sepArray.includes(door.door_type_id)) {
        door.sep_count = door.materials.length - 1;
      } else {
        door.sep_count = 0;
      }
    }
  }


  sendObjectToBack() {
    
    
    this.object.user_id = this._cookieService.get('jwt') ? this._cookieService.get('jwt') : 'false';
    this.object.global_coef = this.coef.global_coef;
    this.updateColor();
    this.setInitOptions();
    this._setMainParams();
    this._setContentItems();
    console.warn(this.coefDoor.doorStag.stage, 'this.coefDoor.doorStag.stage');
    if (this.coefDoor.doorStag.stage &&  this.coefDoor.DoorWrapper &&  this.coefDoor.DoorWrapper.children &&  this.coefDoor.DoorWrapper.children.length !=0) {
      this.sendDoor();
      // const content = this.coefDoor.doorStag.stage.children[0].children.map(children => {
      //   const itemSend = _.cloneDeep(children.attrs);
      //   return itemSend;
      // });
      // this.object['door_content'] = content;
      this.object['stoper'] = this.coefDoor.stoperDoor;
      this.object['puller'] = this.coefDoor.puller;
      
    }
    this.object.deleted_older_content_square = this.coef.deleted_older_content_square;
    this.object.deleted_older_content_elements = this.coef.deleted_older_content_elements;
    // console.log(this.object, 'price');
    if (this.coef.pramsId == 2 || this.coef.pramsId == 15) {
      const objects = {
        leftCorner: this.coef.setingsWardrobe.content_object['leftCorner'].subId == 'null' ? null : this.coef.setingsWardrobe.content_object['leftCorner'],
        rightCorner: this.coef.setingsWardrobe.content_object['rightCorner'].subId == 'null' ? null : this.coef.setingsWardrobe.content_object['rightCorner'],
      };
      this.object.content = [objects];
    }
    console.warn(this.object);
    this.socket.sendMessage(this.object);
  }

  sendObjectPrice() {
    this.test.next();
  }
}

