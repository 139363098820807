import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  openClose = false;
  errorText = '';

  constructor() {
  }

  openPopap(text) {
    this.errorText = text;
    this.openClose = true;
    setTimeout(() => {
      this.openClose = false;
    }, 1500);
  }
}
