import {Injectable} from '@angular/core';
import * as Konva from '../../assets/konva';
import {ErrorService} from './error.service';
import {MainSetting} from '../parserClass/main-setting';
import {InitOptions} from '../interfaces/init-options.interface';
import {Subject} from 'rxjs';
import {InitRenderService} from './renderingService/init-render.service';
import {NotificationService} from './notification.service';
import * as _ from 'lodash';
import {baseUrl} from '../router.path';
import {API} from '../common/api';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class CoefService {
  stop = true;
  pramsId = 12;
  facade_option_list = {
    box_color_select: true,
    facade_color_select: true
  };
  public user;
  public end_client;
  clients = [];
  selectDrawer;
  widthSep;
  systemsDoor = [];
  preloader = new Subject();
  innfoStatus = new Subject();
  info;
  doorSystemsId;
  Size = new Konva.Layer();
  GlobalSize = new Konva.Layer();
  spec_values = {};
  LuxPosition = 'L';
  two_in_row_diapason;
  setingsWardrobe;
  heightWardrobe = 2000;
  too_handles = 600;
  door = {
    mincountDoor: null
  };
  facade_color_id;
  public colorFacad = 'rgba(135 , 66 , 66 , 1)';
  shellOfsetFormBottom = 62.5;
  max_hanging_height = 250;
  back_wall_min_height = 650;
  back_wall_max_height = 1000;
  back_wall_max_width = 1000;
  deleted_older_content_square = 0;
  public mainSetting;
  public widthWrapper = 1000;
  public heightWrapper = 500;
  public topPositaionWrapper = 60;
  look2dPosition;
  public countNameElement = 0;
  public stage;
  public color = 'rgba(135 , 66 , 66 , 1)';
  public colorInput = 'rgba(135 , 66 , 66 , 1)';
  public system_color_id: number = null;
  public colorID: number = null;
  public shapesLayerw;
  public global_coef = 4;
  public size_flat = 18;
  public global_size_flat = this.size_flat / this.global_coef;
  public height_legs = 100 / this.global_coef;
  public depthCoef = 8;
  public min_width_for_partition = 1600;
  public look2d;
  public legs;
  public product;
  public lamps;
  public min_double_split_width;
  public edgeRetreat = 50 / this.global_coef;
  public sizeLegs = 40 / this.global_coef;
  public edgeLegs = 500 / this.global_coef;
  public legs_count;
  public legs_step;
  public global_size_retreat = 200 / this.global_coef;
  public pantographHeight = 850 / this.global_coef;
  public startRemove = false;
  public top_trumpet = 50 / this.global_coef;
  public startPosition;
  public settingsColor = [];
  public drawHeight = 200 / this.global_coef;
  public scheme_id = null;
  public lamp_id = null;
  public init_option_ids = [];
  public comodCountDrawer;
  public idHistory;
  public idOrder;
  public typeOrderId;
  public historyPopap = false;
  public orderPopap = false;
  arrayNotSystemColor = [1, 3, 5, 8, 9, 10, 14, 15];
  arrayNoneDoor = [11, 12, 13, 14];
  arrayNotFacadColor = [2, 4, 6, 7, 11, 12, 13, 14, 15];
  literalComod = {14: 2, 15: 3, 16: 4, 17: 5 , 34: 3 , 35: 4};
  doorIs = [11, 12, 13,  7 ,4,6,2];
  public drawer = {
    drawHeight: 203 / this.global_coef,
    drawHeightSpec: 227 / this.global_coef,
    minSize: 150 / this.global_coef,
    maxSize: 350 / this.global_coef,
    maxWidth: 850 / this.global_coef,
    minWidth: 350 / this.global_coef,
    maxTopPos: 1400,
    bottomSectionDiff: 30 / this.global_coef,
    sideSpace: 3 / this.global_coef,
    sideSpaceSpec:27 / this.global_coef,
    secondSeparattor: 1106,
  };
  public hook = {
    hookWidth: 14 / this.global_coef,
    hookHeight: 50 / this.global_coef,
    side_space_with: 100 / this.global_coef,
    step: 150 / this.global_coef,
    vertical_step: 100 / this.global_coef,
    top_wall: 50 / this.global_coef,
    top_shell: 50 / this.global_coef,
    min_height: 650 / this.global_coef
  };
  public arrayOfsset;
  public widthTrumpetBefore = 2;
  public pantographWidthLand = 4;
  public pantographMinTop = 1900 / this.global_coef;
  public openDoor = {
    bottom_section_diff: 25,
    max_section_width_for_one_door: 600,
    max_section_width_for_two_doors: 1200,
    min_section_width_for_two_doors: 600,
    shelf_diff: 40,
    top_flat_height: 40,
    max_height: 1000
  };
  silverColor = 'rgba(192,192,192,1)';
  blackColor = 'rgba(0,0,0,1)';
  trumpetActive = true;
  public ending = {
    max_width: 400,
    min_width: 150,
    min_width_riall: 150,
  };
  public id_element: any = {
    open_door: 1,
    retractable_pipe: 2,
    hooks: 3,
    back_wall: 4,
    pantograph: 5,
    partition: 6,
    shelf: 7,
    trumpet: 8,
    drawer: 9,
    top_wall: 10,
    bottom_wall: 11,
    lateral_wall: 12,
    double_partition: 13,
    corner_ending: 15
  };
  public element_Sub_id = {
    singleDoorRight: 1,
    singleDoorLeft: 2,
    singleDoorRightLock: 3,
    singleDoorLeftLock: 4,
    twoDoor: 5,
    twoDoorLock: 6,
    singleDoorRightD: 28,
    singleDoorLeftD: 29,
    singleDoorRightLockD: 31,
    singleDoorLeftLockD:  32,
    twoDoorD: 30,
    twoDoorLockD: 33,
    drawerSimple: 7,
    drawerWithLength: 8,
    drawerSimpleLock: 9,
    drawerSimpleSpec: 26,
    drawerSimpleSpecD: 27,
    drawerWithLengthLock: 10,
    one_retractable_pipe: 11,
    two_retractable_pipe: 12,
    three_retractable_pipe: 13,
    four_retractable_pipe: 14,
    five_retractable_pipe: 15,
    two_hooks: 16,
    three_hooks: 17,
    five_hooks: 18,
    simpleEnding: 22,
    ending: 23

  };
  steppipe = 500 / 4;
  ofset_trumpet = {
    top: 12.5,
    bottom: 162.5,
  };
  ofset_pantograph = {
    top: 12.5,
    bottom: 12.5,
  };
  ofset_retractable_pipe = {
    top: 162.5,
    bottom: 162.5,
  };
  manualTravelElement;
  public deleted_older_content_elements = {
    locks: 0,
    pantographs: 0,
    vv: 0,
    shuttles_small: 0,
    shuttles_big: 0,
    slow_closers: 0,
    corner_ends: {
      little_default: 0,
      little_figured: 0,
      big_default: 0,
      big_figured: 0,
    },
    tubes: 0,
    hooks: 0
  };
  public heightTrumpet = this.convertCoef(30);
  public comment: String = '';
  public doorsArray: Array<Object> = [];
  public contentImage: any = 'asdasd';
  public facadeImage: any = 'asdas';
  public disabledItemMap: Object = {};
  manager = false;

  constructor(private error: ErrorService, private render: InitRenderService,
              private notification: NotificationService,
              private http: HttpClient,) {
    this.shapesLayerw = new Konva.Layer();
    this.look2d = new Konva.Layer();
    this.product = new Konva.Layer();
  }

  errorTrigger(text) {
    this.notification.triggerNotification(text, true);

  }

  chengPopap(id) {
    this.idHistory = id;
    this.historyPopap = true;
  }

  chengPopapOrder(id) {
    this.idOrder = id;
    this.orderPopap = true;
  }

  convertCoef(params) {
    return params / this.global_coef;
  }

  eventChangeInfo() {
    this.innfoStatus.next(this.info);
  }

  _createDisabledMaps(item): void {
    const itemInfo = {active: item.active, name: item.name};
    if (item.sub_items.length > 0) {
      itemInfo['subItems'] = {};
      for (const subItem of item.sub_items) {
        itemInfo['subItems'][subItem['id']] = {active: subItem['active'], name: subItem['name']};
      }
    }
    this.disabledItemMap[item.id] = itemInfo;
  }

  apiParsCof(cof) {
    this.disabledItemMap = {};
    this.arrayOfsset = cof;
    for (const items of cof) {

      if (items.id === this.id_element.retractable_pipe) {
        const ofset = this.getOfset(items.offsets, this.id_element.shelf);
        this.steppipe = this.convertCoef(items.spec_values.step);
        // this.ofset_trumpet.top = this.convertCoef(ofset.top_left);
        this.ofset_retractable_pipe.bottom = this.convertCoef(ofset.bottom_right);
        if (this.setingsWardrobe.depth > items.max_depth || this.setingsWardrobe.depth < items.min_depth) {
          for (const sub_items of items.sub_items) {
            sub_items.active = false;
          }
        }
        this.arrayOfsset = cof;
      }

      if (items.id === this.id_element.shelf) {
        for (const item of items.offsets) {
          if (item.target_id === 11) {
            this.shellOfsetFormBottom = item.bottom_right / this.global_coef;
          }
        }
      }
      if (items.id === this.id_element.partition) {
        this.max_hanging_height = this.convertCoef(items.spec_values.max_hanging_height);
        const object = {...items};
        object.id = 13;
        this.arrayOfsset.push(object);
      }
      if (items.id === this.id_element.pantograph) {
        this.pantographHeight = items.height / this.global_coef;
        this.pantographMinTop = items.spec_values.min_top_pos / this.global_coef;
        const ofset = this.getOfset(items.offsets, this.id_element.shelf);
        this.ofset_pantograph.top = this.convertCoef(ofset.top_left);
        this.ofset_pantograph.bottom = this.convertCoef(ofset.bottom_right);
        if (this.setingsWardrobe.depth < items.min_depth) {
          this.trumpetActive = false;
          items.active = false;
        } else {
          this.trumpetActive = true;
        }
      }
      if (items.id === this.id_element.trumpet) {
        const ofset = this.getOfset(items.offsets, this.id_element.shelf);
        this.ofset_trumpet.top = this.convertCoef(ofset.top_left);
        this.ofset_trumpet.bottom = this.convertCoef(ofset.bottom_right);
        if (this.setingsWardrobe.depth < items.min_depth) {
          items.active = false;
        }
      }
      if (items.id === this.id_element.drawer) {
        // this.drawHeight = items.height / this.global_coef;
        this.drawer.maxSize = items.max_height / this.global_coef;
        this.drawer.minSize = items.min_height / this.global_coef;
        this.drawer.minWidth = items.min_width / this.global_coef;
        this.drawer.maxWidth = items.max_width / this.global_coef;
        this.drawer.drawHeight = items.height / this.global_coef + items.spec_values.side_space / this.global_coef;
        this.drawer.drawHeightSpec = items.height / this.global_coef + items.spec_values.top_space_diff_for_zh_option / this.global_coef;
        this.drawer.sideSpaceSpec = items.spec_values.top_space_diff_for_zh_option / this.global_coef;
        this.drawer.maxTopPos = items.spec_values.max_top_pos / this.global_coef;
        this.drawer.bottomSectionDiff = items.spec_values.bottom_section_diff / this.global_coef;
        this.drawer.sideSpace = items.spec_values.side_space / this.global_coef;
        this.drawer.secondSeparattor = items.spec_values.second_handler_separator_width + items.spec_values.side_space * 2;
      }
      if (items.id === this.id_element.hooks) {
        this.hook.hookHeight = items.height / this.global_coef;
        this.hook.hookWidth = items.width / this.global_coef * 2;
        this.hook.step = items.spec_values.step / this.global_coef;
        this.hook.vertical_step = items.spec_values.vertical_step / this.global_coef;
        this.hook.side_space_with = items.spec_values.side_space_with / this.global_coef;
        items.min_width = items.spec_values.side_space_with * 2 + items.spec_values.step * 2 + items.width * 3;
        for (const top of items.offsets) {
          if (top.target_id === 7) {
            this.hook.top_shell = top.top_left / this.global_coef;
          }
          if (top.target_id === 10) {
            this.hook.top_wall = top.top_left / this.global_coef;
          }
        }
      }
      if (items.id === this.id_element.shelf) {
        this.min_width_for_partition = items.spec_values.min_width_for_partition / this.global_coef;
        this.two_in_row_diapason = items.spec_values.two_in_row_diapason / this.global_coef;
      }
      if (items.id === this.id_element.corner_ending) {
        this.ending.max_width = this.convertCoef((items.max_width));
        this.ending.min_width = this.convertCoef((items.max_width));
        this.ending.min_width_riall = this.convertCoef((items.min_width));
      }

      if (items.id === this.id_element.back_wall) {
        this.back_wall_min_height = this.convertCoef(items.min_height);
        this.back_wall_max_height = this.convertCoef(items.max_height);
        this.back_wall_max_width = this.convertCoef(items.max_width);
      }


      if (items.id === this.id_element.open_door) {
        this.openDoor.max_height = this.convertCoef(items.max_height);
        this.openDoor.bottom_section_diff = this.convertCoef(items.spec_values.bottom_section_diff);
        this.openDoor.max_section_width_for_one_door = this.convertCoef(items.spec_values.max_section_width_for_one_door);
        this.openDoor.max_section_width_for_two_doors = this.convertCoef(items.spec_values.max_section_width_for_two_doors);
        this.openDoor.min_section_width_for_two_doors = this.convertCoef(items.spec_values.min_section_width_for_two_doors);
        this.openDoor.shelf_diff = this.convertCoef(items.spec_values.shelf_diff);
        this.openDoor.top_flat_height = this.convertCoef(items.spec_values.top_flat_height);
      }
     
      this._createDisabledMaps(items);
    }
    this.blockDrawerCentr();
  }

  getOfset(offsets, id) {
    for (const offset of offsets) {
      if (offset.target_id === id) {
        return offset;
      }
    }
  }

  parsGlobalSetting(object?) {
    if (object) {
      this.mainSetting = new MainSetting(object);
    }
    this.size_flat = this.mainSetting.carcass_thickness;
    this.global_size_flat = this.convertCoef(this.size_flat);
    this.height_legs = this.convertCoef(this.mainSetting.legs_height);
  }

  public parsFacadeMaterials(facadeMaterials: Array<Object>): void {
    facadeMaterials.forEach(material => {
      if (material['id'] === 2) {
        this.colorFacad = this.addOpacyti(material['default_option']['rgb']);
        this.color = this.addOpacyti(material['default_option']['rgb']);
        this.colorInput = this.addOpacyti(material['default_option']['rgb']);
        this.colorID = material['default_option']['id'];
        this.facade_color_id = material['default_option']['id'];
      }
      if (material['id'] === 9) {
        this.system_color_id = material['default_option']['id'];
      }
    });
  }

  addOpacyti(rgb) {
    const start = rgb.split('(')[0];
    const mid = rgb.split('(')[1].split(')');
    const rgba = start + 'a(' + mid + '1)';
    return rgba;
  }

  addOpacytiRGBA(rgba, cof) {
    const start = rgba.split(',');
    start.splice(3, 1);
    start.join(',');
    const rgbaNew = start + `,${cof})`;
    return rgbaNew;
  }

  parsrgbatoRgb(rgba) {
    const array = rgba.split(',');
    array.splice(array.length - 1, 1);
    return array.join();
  }

  limitLinFromDoor = {
    left_left: 0,
    left_right: 0,
    right_left: 0,
    right_right: 0
  };

// testin = 'testingssss';
  blockDrawerCentr(pos?, countors?) {
    if (this.pramsId == 7) {
      return true;
    }
    console.log(pos, countors, 'pos?, countors?')
    const centerPosition = (this.widthWrapper - this.setingsWardrobe.width / this.global_coef) / 2;
    const centerPositionRightElement = centerPosition + this.setingsWardrobe.width / this.global_coef;
    const widthOneDoor = this.getOneDoorWidth(this.door.mincountDoor);
    const leftLine = centerPosition + widthOneDoor;
    const rightLine = centerPositionRightElement - widthOneDoor;
    const door_diff = this.mainSetting.door_diff / this.global_coef;
    console.log(door_diff , 'door_diff')
    this.limitLinFromDoor.left_left = leftLine - door_diff + this.global_size_flat;
    this.limitLinFromDoor.left_right = leftLine + (+this.door.mincountDoor === 2 ? (door_diff - this.global_size_flat) : (door_diff - this.global_size_flat));
    this.limitLinFromDoor.right_left = rightLine - (+this.door.mincountDoor === 2 ? (door_diff + this.global_size_flat) : (door_diff - this.global_size_flat));
    this.limitLinFromDoor.right_right = rightLine + door_diff - this.global_size_flat;

    if (pos) {
      const widthDrawer = countors['right'].attrs.x - countors['left'].attrs.x - countors['left'].attrs.width;
      const drawerX = countors['left'].attrs.x + this.global_size_flat;
      console.log(drawerX , widthDrawer , 'door_diff_test')
      const errorText = 'Неможливо встановити елемент у даній секції, оскільки розташування відкривних дверей перешкоджатиме коректній роботі даного елементу.';
      if (drawerX <= this.limitLinFromDoor.left_left && this.limitLinFromDoor.left_left <= drawerX + widthDrawer) {
        this.errorTrigger(errorText);
        return false;
      }
      if (drawerX < this.limitLinFromDoor.left_right && this.limitLinFromDoor.left_right < drawerX + widthDrawer) {
        this.errorTrigger(errorText);
        return false;
      }
      if (drawerX < this.limitLinFromDoor.right_right && this.limitLinFromDoor.right_right < drawerX + widthDrawer) {
        this.errorTrigger(errorText);
        return false;
      }
      if (drawerX < this.limitLinFromDoor.right_left && this.limitLinFromDoor.right_left < drawerX + widthDrawer) {
        this.errorTrigger(errorText);
        return false;
      }
      return true;
    }
  }

  public getOneDoorWidth(doorAmount: number, wardrobeWidth: number = this.setingsWardrobe.width, wardrobeDepth: number = this.setingsWardrobe.height): number {
    const widthTop = wardrobeWidth;
    if (this.pramsId == 13) {
      wardrobeWidth = wardrobeWidth + this.global_size_flat * 2 * this.global_coef;
    }
    if (this.pramsId == 2 || this.pramsId == 15) {
      const width = wardrobeWidth - wardrobeDepth - this.global_size_flat * this.global_coef;
      wardrobeWidth = Math.ceil(((width ** 2) * 2) ** 0.5);
    }
    const door_addition_size = this.typCountDoor().width;
    console.log(door_addition_size , 'door_addition_size')
    if (this.pramsId == 7) {
      return Math.floor(this.widthSep + this.mainSetting.neo_prohoja_door_width_diff) / this.global_coef;
    } else if (this.pramsId == 6) {
      return Math.floor((wardrobeWidth - (this.global_size_flat * this.global_coef * 4)) / 3 + this.mainSetting.neo_prohoja_door_width_diff) / this.global_coef;
    } else if (this.pramsId == 4) {
      return Math.floor((wardrobeWidth - (this.global_size_flat * this.global_coef * 3)) / 2 + this.mainSetting.neo_prohoja_door_width_diff) / this.global_coef;
    } else if (this.pramsId == 2) {
      return Math.floor(((wardrobeWidth + this.spec_values['doors_hole_width_diff']) / doorAmount)) / this.global_coef;
    } else {
      console.log(Math.floor(((wardrobeWidth - door_addition_size) / doorAmount)) / this.global_coef);
      if(this.pramsId == 13 && this.door.mincountDoor * 1 == 1){
       return widthTop / this.global_coef
      }
      return Math.floor(((wardrobeWidth - door_addition_size) / doorAmount)) / this.global_coef;
    }
  }

  typCountDoor() {
    // const door_addition_size = this.mainSetting.door_addition_size / this.global_coef;
    switch (this.door.mincountDoor * 1) {
      case 2: {
        return {width: this.mainSetting.door_additional_size_two, margin: this.mainSetting.door_additional_size_two / 2};
      }
      case 3: {
        return {width: -this.mainSetting.door_additional_size_three, margin: this.mainSetting.door_additional_size_three / 2};
      }
      case 4: {
        return {width: -this.mainSetting.door_additional_size_four, margin: this.mainSetting.door_additional_size_four / 2};
      }
      case 5: {
        return {width: -this.mainSetting.door_additional_size_five, margin: this.mainSetting.door_additional_size_five / 2};
      }
      default: {
        return {width: 0, margin: 0};
      }
    }
  }

  parsRenderCoef() {
    this.colorID = this.render.object.color_id;
    if (this.render.object.facade_color_id) {
      this.facade_color_id = this.render.object.facade_color_id;
    }
    this.system_color_id = this.render.object.system_color_id;
    this.comment = this.render.object.admin_comment;
    this.end_client = Number(this.render.object.user_id);

    this.deleted_older_content_square = this.render.object.deleted_older_content_square;
    this.deleted_older_content_elements = this.render.object.deleted_older_content_elements;
    try {
      this.color = this.addOpacyti(this.render.object.colorRgb);
      this.colorInput = this.addOpacyti(this.render.object.colorRgb);
      if (this.render.object.facadeColorRgb) {
        this.colorFacad = this.addOpacyti(this.render.object.facadeColorRgb);
      }
    } catch (e) {
      console.log(e);
    }
  }

  storeInitParams(initOptions: InitOptions) {
    this.scheme_id = initOptions.scheme_id || null;
    this.lamp_id = initOptions.lamp_id || null;
    this.init_option_ids = initOptions.init_option_ids;
  }

  preloaderUpdate(type) {
    this.preloader.next(type);
  }

  getTangentElement(pos, mainElement?, parents?, drawer?) {
    let elemLeft;
    let elemRight;
    let elemTop;
    let elemBottom;
    let left = 0;
    let top = 0;
    let right = 99999;
    let bottom = 99999;
    const positionElementX = pos.evt.layerX;
    const positionElementY = pos.evt.layerY;
    for (const element of parents.children) {
      if (this.checkHorizontalVertical(element)) {
        if (positionElementY >= element.attrs.y && positionElementY < (element.attrs.y + (element.attrs.height ? element.attrs.height : element.attrs.params.height))) {
          if (element.attrs.params.type === 13) {
            // console.log(element);
          }
          if (positionElementX > element.attrs.x && element.attrs.x > left) {
            left = element.attrs.x;
            elemLeft = element;
          }
          if (positionElementX < element.attrs.x && element.attrs.x < right) {
            right = element.attrs.x;
            elemRight = element;
          }
        }
      } else {
        if (element.attrs.name !== mainElement.attrs.name) {
          if (positionElementX >= element.attrs.x && positionElementX < (element.attrs.x + element.attrs.width)) {
            if (positionElementY > element.attrs.y && element.attrs.y > top) {
              if (drawer) {
                if (element.attrs.params.type === this.id_element.shelf || element.attrs.params.type === this.id_element.top_wall) {
                  top = element.attrs.y;
                  elemTop = element;
                }
              } else {
                top = element.attrs.y;
                elemTop = element;
              }


            }
            if (positionElementY < element.attrs.y && element.attrs.y < bottom) {
              if (drawer) {
                if (element.attrs.params.type === this.id_element.shelf || element.attrs.params.type === this.id_element.bottom_wall) {
                  bottom = element.attrs.y;
                  elemBottom = element;
                }
              } else {
                bottom = element.attrs.y;
                elemBottom = element;
              }
            }
          }
        }
      }
    }
    if (this.pramsId != 4) {
      if (elemLeft && elemRight && elemTop && elemBottom) {
        return {top: elemTop, right: elemRight, bottom: elemBottom, left: elemLeft};
      } else {
        return false;
      }
    } else {
      return {top: elemTop, right: elemRight, bottom: elemBottom, left: elemLeft};
    }

  }

  checkHorizontalVertical(element) {
    switch (element.attrs.params.type) {
      case 1:
        return false;
      case 3:
        return false;
      case 4:
        return false;
      case this.id_element.pantograph:
        return false;
      case this.id_element.partition:
        return true;
      case this.id_element.shelf:
        return false;
      case this.id_element.trumpet:
        return false;
      case this.id_element.lateral_wall:
        return true;
      case this.id_element.top_wall:
        return false;
      case this.id_element.bottom_wall:
        return false;
      case this.id_element.double_partition:
        return true;
      default:
        return false;
    }
  }
}
