import {Create} from './create';
import * as Konva from '../../assets/konva';

export class Pantograph extends Create {
  group;
  coef;
  create;


  constructor(coef, create, active) {
    super(coef);
    this.coef = coef;
    this.create = create;
    this.createPantograph(active);
  }

  createPantograph(active) {

    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 300,
      y: -500,
      draggable: active,
      width: 87,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.pantograph,
      },
      bind: [],
    });
    const pantographCenter = new Konva.Rect({
      x: this.coef.widthTrumpetBefore * 5,
      y: 0,
      width: 75 - this.coef.pantographWidthLand * 2,
      height: this.coef.heightTrumpet,
      name: 'width',
      fill: this.coef.silverColor,
    });
    const pantographCenter1 = new Konva.Rect({
      x: 42,
      y: 0,
      width: 4,
      height: 60,
      name: 'width',
      fill: this.coef.silverColor,
    });
    const pantographLeft = new Konva.Rect({
      x: 0,
      y: (this.coef.heightTrumpet * 5),
      width: this.coef.widthTrumpetBefore * 5,
      height: (this.coef.heightTrumpet * 3),
      name: 'width',
      fill: this.coef.blackColor,
    });
    const pantographLeft1 = new Konva.Rect({
      x: this.coef.widthTrumpetBefore * 5 - this.coef.pantographWidthLand,
      y: 0,
      width: this.coef.pantographWidthLand,
      height: (this.coef.heightTrumpet * 5),
      name: 'width',
      fill: this.coef.silverColor,
    });
    const pantographRight = new Konva.Rect({
      x: 75 + this.coef.widthTrumpetBefore,
      y: this.coef.heightTrumpet * 5,
      width: this.coef.widthTrumpetBefore * 5,
      height: (this.coef.heightTrumpet * 3),
      name: 'width',
      fill: this.coef.blackColor,
    });
    const pantographRight1 = new Konva.Rect({
      x: 75 + this.coef.widthTrumpetBefore,
      y: 0,
      width: this.coef.pantographWidthLand,
      height: (this.coef.heightTrumpet * 5),
      name: 'width',
      fill: this.coef.silverColor,
    });
    this.group.add(pantographLeft);
    this.group.add(pantographLeft1);
    this.group.add(pantographCenter);
    this.group.add(pantographCenter1);
    this.group.add(pantographRight);
    this.group.add(pantographRight1);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.createPantographBack(pos, xy);
    });
  }

  createPantographBack(pos, xy) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.limitAddElement(xy, this.coef.id_element.pantograph, contours) &&
      this.mintTopLimit(contours['top']) && this.coef.blockDrawerCentr(pos, contours) &&
      this.trumpetBlockStop(xy, this.coef.id_element.pantograph, contours) &&
      this.blockCreateOnElement(xy)) {
      const top = (this.coef.top_trumpet * 4) / this.coef.global_coef;
      const pantograph_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + top + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        draggable: false,
        name: 'element' + this.coef.countNameElement,
        params: {
          removable: true,
          type: this.coef.id_element.pantograph,
          height: this.coef.pantographHeight
        },
        bind: [],
        dragBoundFunc: function (position) {
          return {
            x: this.getAbsolutePosition().x,
            y: position.y
          };
        }
      });
      const pantographCenterCreate = new Konva.Rect({
        x: this.coef.widthTrumpetBefore * 5,
        y: 0,
        width: pantograph_group_create['attrs'].width - this.coef.widthTrumpetBefore * 10,
        height: this.coef.heightTrumpet,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const pantographCenter1Create = new Konva.Rect({
        x: pantograph_group_create['attrs'].width / 2 - this.coef.pantographWidthLand,
        y: 0,
        width: this.coef.pantographWidthLand,
        height: this.coef.pantographHeight * 0.8,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const pantographLeftCreate = new Konva.Rect({
        x: 0,
        y: this.coef.pantographHeight * 0.8,
        width: this.coef.widthTrumpetBefore * 5,
        height: this.coef.pantographHeight * 0.2,
        name: 'width',
        fill: this.coef.blackColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const pantographLeft1Create = new Konva.Rect({
        x: this.coef.widthTrumpetBefore * 5 - this.coef.pantographWidthLand,
        y: 0,
        width: this.coef.pantographWidthLand,
        height: this.coef.pantographHeight * 0.8,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const pantographRightCreate = new Konva.Rect({
        x: pantograph_group_create['attrs'].width - this.coef.widthTrumpetBefore * 5,
        y: this.coef.pantographHeight * 0.8,
        width: this.coef.widthTrumpetBefore * 5,
        height: this.coef.pantographHeight * 0.2,
        name: 'width',
        fill: this.coef.blackColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const pantographRight1Create = new Konva.Rect({
        x: pantograph_group_create['attrs'].width - this.coef.widthTrumpetBefore * 5,
        y: 0,
        width: this.coef.widthTrumpetBefore * 2,
        height: this.coef.pantographHeight * 0.8,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      this.create.bindingTocounters(
        contours.left,
        contours.right,
        contours.top,
        contours.bottom,
        pantograph_group_create,
        'horizon',
      );
      // this.addElemetToParent(contours, pantograph_group_create);
      pantograph_group_create.add(pantographCenter1Create);
      pantograph_group_create.add(pantographCenterCreate);
      pantograph_group_create.add(pantographRightCreate);
      pantograph_group_create.add(pantographRight1Create);
      pantograph_group_create.add(pantographLeftCreate);
      pantograph_group_create.add(pantographLeft1Create);
      this.coef.shapesLayerw.add(pantograph_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    } else {
      // this.coef.errorTrigger('Ширина або відстані між елементами не підходять');
    }
  }
}
