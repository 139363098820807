import {Component, OnInit} from '@angular/core';
import {CoefService} from '../../service/coef.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {

  constructor(public coef: CoefService) {
  }

  ngOnInit() {
  }

}
