import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SocketPriceService} from '../../service/socket-price.service';
import {CoefService} from '../../service/coef.service';
import {CoefDoorService} from '../../service/door service/coef-door.service';
import {ParserService} from '../../service/parser.service';
import {InitRenderService} from '../../service/renderingService/init-render.service';
import {PriceService} from '../../service/price.service';
import {isArray, tryCatch} from 'rxjs/internal-compatibility';
import {baseUrl} from '../../router.path';
import {API} from '../../common/api';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '../../service/notification.service';

@Component({
  selector: 'app-third-stage',
  templateUrl: './third-stage.component.html',
  styleUrls: ['./third-stage.component.css']
})
export class ThirdStageComponent implements OnInit {
  imgContent;
  imgDoor;
  price;
  door;
  facad;
  inform = false;
  demoVersion = false;
  constructor(private socket: SocketPriceService,
              public coef: CoefService,
              public coefDoor: CoefDoorService,
              public parserService: ParserService,
              public render: InitRenderService,
              private http: HttpClient,
              private notification: NotificationService,
              public priceService: PriceService) {
    this.socket.price.subscribe((data) => {
      this.price = data ;
    });
  }

  ngOnInit() {
    this.inform = false;
    this.demoVersion = window.location.href.indexOf('calculator?') === -1;
  }

  getparams() {
    this.inform = !this.inform;
  }

  getNameOption(id) {
    if (id) {
      for (const options of this.coef.setingsWardrobe.init_types) {
        for (const option of options.options) {
          if (option.id === id) {
            return `${options.name}: ${option.name}`;
          }
        }
      }
    }
  }

  getname(optionid) {
    if (optionid) {
      if (isArray(optionid)) {
        return this.getNameOption(optionid[0]);
      } else {
        return this.getNameOption(optionid);
      }
    }
  }

  getColor(id) {
    if (id) {
      for (const color of this.coef.settingsColor) {
        if (id == color.id) {
          return `Колір плити корпусу: ${color.name}`;
        }
      }
    }
  }

  getColorFacad(id) {
    if (id) {
      if (!this.coef.arrayNotFacadColor.includes(+this.coef.pramsId)) {
        for (const color of this.coef.settingsColor) {
          if (id == color.id) {
            return `Колір плити фасаду: ${color.name}`;
          }
        }
      }
    }

  }
  getNameSystem(){
    for(const item of this.coef.systemsDoor){
      if(item.id == this.coefDoor.systemId){
        return item.name;
      }
    }
  }
  getColorSystem(id) {
    if (id) {
      if (!this.coef.arrayNotSystemColor.includes(+this.coef.pramsId)) {
        if (this.coefDoor.colorSystemArray ) {
          for (const color of this.coefDoor.colorSystemArray) {
            if (id == color.id) {
              return `${this.getNameSystem()} - ${color.name}`;
            }
          }
        }
      }
    }
  }

  getClient() {
    try {
      if (this.coef.end_client) {
        if (this.coef.user.manager) {
          const item = this.coef.clients.find((items) => {
            return Number(items.value) === Number(this.coef.end_client);
          });
          if (item) {
            return 'Клієнт: ' + item.label.last_name + ' ' + item.label.name + ' ' + item.label.surname;
          }
        }
      }
    } catch (e) {
      return '';
    }
  }

  send() {
    this.coef.preloaderUpdate(true);
    setTimeout(() => {
      this.parserService.sendInfo();
    });
  }

  sendToo() {
    this.coef.preloaderUpdate(true);
    setTimeout(() => {
      this.parserService.reSendInfo();
    });

  }

  deleteObject() {
    this.coef.preloaderUpdate(true);
    setTimeout(() => {
      this.parserService.deleteObject();
    });
  }
}
