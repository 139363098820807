import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pantograph',
  templateUrl: './pantograph.component.html',
  styleUrls: ['./pantograph.component.scss']
})
export class PantographComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
