import {Injectable} from '@angular/core';
import {CoefService} from './coef.service';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutStepService {
  routObject = new Subject();
  nextTab = new Subject();
  firstTab = new Subject();
  validForm = {size: false, door: false, option: false};
  _routOrder;
  setting = {
    12: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'двері', index: 2},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    11: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'двері', index: 2},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    7: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'двері', index: 2},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    13: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'двері', index: 2},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    14: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    1: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    2: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'двері', index: 2},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    15: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    9: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    8: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    10: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    3: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    5: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    6: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'двері', index: 2},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ],
    4: [
      {active: true, settings: true, text: 'габарити', index: 0},
      {active: false, settings: true, text: 'наповнення', index: 1},
      {active: false, settings: true, text: 'двері', index: 2},
      {active: false, settings: true, text: 'підсумок', index: 3}
    ]

  };

  checkvalidForm(sizi) {

  }

  set setOrder(value: any) {
    this.routObject.next(value);
    this._routOrder = value;
  }

  constructor(private coef: CoefService) {

  }

  goNextStep(index) {
    this.nextTab.next(index);
  }

  firstTabSelectform() {
    this.firstTab.next();
  }

}

