import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-three-pipe',
  templateUrl: './three-pipe.component.html',
  styleUrls: ['./three-pipe.component.scss']
})
export class ThreePipeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
