import { Component, OnInit } from '@angular/core';
import {CoefService} from '../../service/coef.service';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit {

  constructor(public coef: CoefService) { }

  ngOnInit() {
  }

}
