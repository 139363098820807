import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
  params;

  constructor() {
  }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
  }

}
