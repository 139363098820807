import { Component, OnInit } from '@angular/core';
import {CoefService} from '../../service/coef.service';

@Component({
  selector: 'app-doorleft',
  templateUrl: './doorleft.component.html',
  styleUrls: ['./doorleft.component.scss']
})
export class DoorleftComponent implements OnInit {

  constructor(public coef: CoefService) { }

  ngOnInit() {
  }

}
