import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-five-hook',
  templateUrl: './five-hook.component.html',
  styleUrls: ['./five-hook.component.scss']
})
export class FiveHookComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
