import {Create} from './create';
import * as Konva from '../../assets/konva';

export class Trumpet extends Create {
  group;
  coef;
  create;
  subId;

  constructor(coef, create, sub_id,active) {
    super(coef);
    this.coef = coef;
    this.create = create;
    this.subId = sub_id;
    this.createTrumpet(active);
  }

  createTrumpet(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 200,
      y: -500,
      draggable: active,
      width: 79,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.trumpet,
        subId:this.subId
      },
      bind: [],
    });
    const trumpetCenter = new Konva.Rect({
      x: this.coef.widthTrumpetBefore,
      y: ((this.coef.heightTrumpet * 3) / 2) - (this.coef.heightTrumpet / 2),
      width: 75,
      height: this.coef.heightTrumpet,
      name: 'width',
      fill: this.coef.silverColor,
    });
    let center:any;
    if(this.subId == 25){
      center = new Konva.Rect({
        x: this.coef.widthTrumpetBefore,
        y: ((this.coef.heightTrumpet * 3) / 2) - (this.coef.heightTrumpet / 2),
        width: 75,
        height: this.coef.heightTrumpet,
        name: 'width',
        fill: 'white',
      });
    }
    
    const trumpetLeft = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.coef.widthTrumpetBefore,
      height: (this.coef.heightTrumpet * 3),
      name: 'width',
      fill: this.coef.silverColor,
    });
    const trumpetRight = new Konva.Rect({
      x: 75 + this.coef.widthTrumpetBefore,
      y: 0,
      width: this.coef.widthTrumpetBefore,
      height: (this.coef.heightTrumpet * 3),
      name: 'width',
      fill: this.coef.silverColor,
    });
    this.group.add(trumpetLeft);
    this.group.add(trumpetCenter);
    if(this.subId == 25){
      this.group.add(center); 
    }
    this.group.add(trumpetRight);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.createTrumpetlocal(pos, xy);
    });
  }

  createTrumpetlocal(pos, xy, older?) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours && this.coef.trumpetActive && this.limitAddElement(xy, this.coef.id_element.trumpet, contours) &&
      this.trumpetBlockStop(xy, this.coef.id_element.trumpet, contours) &&
      this.blockCreateOnElement(xy)
    ) {
      let topY = contours['top'].attrs.y + this.coef.ofset_trumpet.top + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height);
      if(contours['top'].attrs.params.type == 8) {
        topY = xy.y;
      }
      const trumpet_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: topY,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        name: 'element' + this.coef.countNameElement,
        draggable: true,
        params: {
          removable: true,
          type: this.coef.id_element.trumpet,
          subId:this.subId,
          height: this.coef.heightTrumpet,
          older: older ? older : null
        },
        bind: [],
        dragBoundFunc: function (position) {
          return {
            x: this.getAbsolutePosition().x,
            y: position.y
          };
        }
      });
      const trumpetCenterCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: trumpet_group_create['attrs'].width,
        height: this.coef.heightTrumpet,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      let CenterCreate
      if(this.subId == 25){
         CenterCreate = new Konva.Rect({
          x: 0,
          y: 3,
          width: trumpet_group_create['attrs'].width,
          height: 2,
          name: 'width',
          fill: 'white',
        });
      }
      const trumpetLeftCreate = new Konva.Rect({
        x: 0,
        y: 0 - (this.coef.heightTrumpet * 3) / 2 + this.coef.heightTrumpet / 2,
        width: this.coef.widthTrumpetBefore,
        height: (this.coef.heightTrumpet * 3),
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const trumpetRightCreate = new Konva.Rect({
        x: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width - this.coef.widthTrumpetBefore,
        y: 0 - (this.coef.heightTrumpet * 3) / 2 + this.coef.heightTrumpet / 2,
        width: this.coef.widthTrumpetBefore,
        height: (this.coef.heightTrumpet * 3),
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      this.create.bindingTocounters(
        contours.left,
        contours.right,
        contours.top,
        contours.bottom,
        trumpet_group_create,
        'horizon',
      );
      this.addElemetToParent(contours, trumpet_group_create);
      trumpet_group_create.add(trumpetCenterCreate);
      if(this.subId == 25){
        trumpet_group_create.add(CenterCreate);
      }
      trumpet_group_create.add(trumpetLeftCreate);
      trumpet_group_create.add(trumpetRightCreate);
      this.coef.shapesLayerw.add(trumpet_group_create);
      this.coef.shapesLayerw.batchDraw();
      setTimeout(()=> {
        this.coef.shapesLayerw.fire('dragmove', trumpetCenterCreate , false);
        this.coef.shapesLayerw.fire('click', trumpetCenterCreate , true);
        this.coef.shapesLayerw.draw();
      } , 500)

      this.create.priceSend();
    } else {
      this.coef.errorTrigger('Неможливо розмістити елемент у даній секції (розміри проєму або відстані до сусідніх елементів не відповідають вимогам).');
    }
  }
}
