import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {InitRenderService} from '../../service/renderingService/init-render.service';
import {CoefService} from '../../service/coef.service';
import {baseUrl} from '../../router.path';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-button-table2',
  templateUrl: './button-table2.component.html',
  styleUrls: ['./button-table2.component.scss']
})
export class ButtonTable2Component implements OnInit {
  params;
  disabled;

  constructor(private http: HttpClient,
              private router: Router,
              private render: InitRenderService,
              private coef: CoefService,
              private cdr: ChangeDetectorRef) {
  }


  agInit(params: any): void {
    this.disabled = params.data.isButtonDisabled;
    this.params = params;
  }

  ngOnInit() {

  }

  inform() {
    this.coef.typeOrderId = this.params.data.orderTypeId;
    this.coef.chengPopap(this.params.value);
  }

  renders() {
    if (this.params.data.orderTypeId != 16) {
      this.http.get(baseUrl.api + `get-order-content/${this.params.value}`).subscribe((res) => {
        const renderobject = res['payload'];
        renderobject.stoper = res['payload'].facade_object.stoper;
        renderobject.puller = res['payload'].facade_object.puller;
        renderobject.facade_object = res['payload'].facade_object.door_count;
        console.log(renderobject , 'renderobject')
        this.render.object = renderobject;
        this.render.rerender = true;
        this.router.navigate(['designer'], {queryParams: {id: res['payload']['order_type_id']}});
      }, (error) => {
        this.coef.preloaderUpdate(false);
        if (error.status === 401) {
          this.coef.preloaderUpdate(false);
          this.router.navigate(['']);
        }
      });
    } else {
      this.router.navigate(['custom'], {queryParams: {id: this.params.data.orderTypeId, render: this.params.data.id , shab: true}});
    }

  }
}
