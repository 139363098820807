import * as Konva from '../../assets/konva';

export class Depth {
  coef;

  constructor(coef) {
    this.coef = coef;
  }

  createDepth(height?, layer?, stage?) {
    const centerPosition = (this.coef.widthWrapper - this.coef.setingsWardrobe.width / this.coef.global_coef) / 2;
    let y = this.coef.heightWrapper - 20;
    if (height) {
      y = height / this.coef.global_coef + 5 + this.coef.topPositaionWrapper;
    }
    const TextL = new Konva.Text({
      bind: [],
      x: centerPosition + this.coef.setingsWardrobe.width / this.coef.global_coef / 2 - 20,
      y: y,
      text: `Глибина-${this.coef.setingsWardrobe.depth}`,
      fontSize: 15,
      params: {},
      fontFamily: 'Calibri',
      fill: 'black'
    });
    if (layer) {
      layer.add(TextL);
      layer.batchDraw();
      stage.add(layer);
    } else {
      this.coef.shapesLayerw.add(TextL);
      this.coef.shapesLayerw.batchDraw;
    }

  }
}
