import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TabsetComponent} from 'ngx-bootstrap';
import {SecondStageComponent} from './second-stage/second-stage.component';
import {CreateService} from '../service/create.service';
import {InitializationService} from '../service/initialization.service';
import {ElementsService} from '../service/elements.service';
import {CoefService} from '../service/coef.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../router.path';
import {PriceService} from '../service/price.service';
import {DoorStageComponent} from './door-stag/door-stage.component';
import {InitDoorService} from '../service/door service/init-door.service';
import {CoefDoorService} from '../service/door service/coef-door.service';
import {ThirdStageComponent} from './third-stage/third-stage.component';
import {ParserService} from '../service/parser.service';
import {InitRenderService} from '../service/renderingService/init-render.service';
import {Classic} from '../class/BoundingUnitElements/classic';
import {MinSizeService} from '../service/min-size.service';
import {RoutStepService} from '../service/rout-step.service';
import {FirstStageComponent} from './first-stage/first-stage.component';
import {Comod} from '../class/order/comod';
import {CornerСabinet} from '../class/order/cornerсabinet';
import {ComodFoot} from '../class/order/comod-foot';
import {Superstructure} from '../class/order/superstructure';
import {Stone} from '../class/order/stone';
import {Penal} from '../class/order/penal';
import * as _ from 'lodash';
import {Depth} from '../class/depth';
import {API} from '../common/api';
import {tryCatch} from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-mechanician',
  templateUrl: './mechanician.component.html',
  styleUrls: ['./mechanician.component.css']
})
export class MechanicianComponent implements OnInit , OnDestroy {
  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  @ViewChild('secondStage') secondStage: SecondStageComponent;
  @ViewChild('firstStage') firstStage: FirstStageComponent;
  @ViewChild('door') door: DoorStageComponent;
  @ViewChild('finish') finish: ThirdStageComponent;
  prams_id;
  preloader = true;
  public allWardrobe = {
    width: 2000,
    height: 2000,
    order_type_id: 12,
    depth: null,
    lamps: 1,
    bolt_id: 1,
    content_object: {},
    facade_object: {},
    price: 400,
    color_id: null,
    f_color_id: null,
    legs_count: null,
    legs_step: 500,
    doors_typ_id: 1,
    init_types: null,
    name: ''
  };
  settings;
  firstStart = true;
  params;
  renderInput;
  comodFoot = {18: 4, 5: 2, 6: 3};

  constructor(private create: CreateService,
              private init: InitializationService,
              private elements: ElementsService,
              private coef: CoefService,
              private route: ActivatedRoute,
              private router: Router,
              private http: HttpClient,
              private price: PriceService,
              private coefDoor: CoefDoorService,
              private initDoor: InitDoorService,
              private pars: ParserService,
              private render: InitRenderService,
              private minSize: MinSizeService,
              private rout: RoutStepService,
              private doorCoef: CoefDoorService
  ) {
    this.rout.nextTab.subscribe((index) => {
      this.goNextTab(index);
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.coef.preloaderUpdate(false);
    }, 1000);
    this.getUser();
    this.preloader = true;
    this.route.queryParams.subscribe(params => {
      this.params = params;
      this.prams_id = params.id;
      this.coef.pramsId = params.id;
      this.rout.setOrder = this.prams_id;
      this.http.get(baseUrl.api + 'get-ot-info/' + params.id).subscribe((res) => {
        console.warn(res['payload']);
        if (!res['payload']['active']) {
          this.coef.errorTrigger('Можливість створювати даний тип замовлення тимчасово призупинена.');
          this.router.navigate(['order']);
        }
        this.http.get(baseUrl.api + '/get-globals').subscribe((respons) => {
          if (respons['success']) {
            this.coef.parsGlobalSetting(respons['payload'].main_setting);
            this.coefDoor.parsApiSettings( respons['payload'].facade_materials , respons['payload'].sand_prints);
            this.coef.parsFacadeMaterials(respons['payload'].facade_materials);
            this.renderInput = true;
            this.preloader = false;
          }
        }, (error) => {
          if (error.status === 401) {
            this.router.navigate(['']);
          }
        });
        this.allWardrobe.name = res['payload']['name'];
        this.coefDoor.door_structure = res['payload']['door_types'];
        if(res['payload']['facade_option_list']){
          this.coef.facade_option_list = res['payload']['facade_option_list'];
        }
        this.allWardrobe.init_types = res['payload']['init_types'];
        this.allWardrobe['spec_values'] = res['payload']['spec_values'];
        if (res['payload']['spec_values']) {
          this.coef.spec_values = res['payload']['spec_values'];
          this.coef.min_double_split_width = res['payload']['spec_values']['min_double_split_width'];
        } else {
          this.coef.spec_values = {};
        }

        this.settings = res['payload'];
        this.coef.settingsColor = res['payload']['colors'];
        this.secondStage.colorAraay = this.secondStage.generate(this.coef.settingsColor);


      }, (error) => {
        if (error.status === 401) {
          this.router.navigate(['']);
        }
      });
      this.http.get(baseUrl.api + '/order-type/' + params.id +'/'+'systems').subscribe((res) => {
        console.warn(res['payload'] , 'asdasdasdas');
        this.coef.systemsDoor = res['payload'];
      }, (error) => {
        if (error.status === 401) {
          this.router.navigate(['']);
        }
      });
    });
    this.staticTabs.tabs[1].disabled = true;
    this.staticTabs.tabs[2].disabled = false;
  }
  private getUser(): void {
    try {
      this.http.get(baseUrl.api + API.GET_USER).subscribe(user => {
        this.coef.user = user['payload'];
      }, err => {
        // this.notification.triggerNotification('Невідома помилка. Спробуйте пізніше.', true);
      });
    } catch (e) {
      console.log(e);
    }
  }

  selectTab(e) {
    this.preloader = true;
    this.http.get(baseUrl.api + 'get-ot-items/' + this.params.id).subscribe((res) => {
      this.coef.setingsWardrobe = e;
      this.coef.setingsWardrobe.order_type_id = +this.prams_id;
      if (e.width > 2700) {
        this.coef.global_coef = 5;
        this.coef.global_size_flat = this.coef.convertCoef(this.coef.size_flat);
      } else if (e.height <= 2700 && e.width <= 2700) {
        this.coef.global_coef = 4;
        this.coef.global_size_flat = this.coef.convertCoef(this.coef.size_flat);
      }
      if (e.height > 2700) {
        this.coef.global_coef = 5;
        this.coef.global_size_flat = this.coef.convertCoef(this.coef.size_flat);
      } else if (e.height <= 2700 && e.width <= 2700) {
        this.coef.global_coef = 4;
        this.coef.global_size_flat = this.coef.convertCoef(this.coef.size_flat);
      }
      this.coef.parsGlobalSetting();
      this.coef.apiParsCof(res['payload']['content_items']);
      this.initAllWardrop(e);
      this.rout.firstTabSelectform();
      this.preloader = false;
    }, (error) => {
      if (error.status === 401) {
        this.router.navigate(['']);
      }
    });
  }

  createImg() {
    this.pars.createImg();
  }

  doorInit() {
    this.door.initDoor(this.coefDoor.DoorWrapper);
  }

  initAllWardrop(e) {
    this.staticTabs.tabs[1].disabled = false;
    if (this.render.rerender) {
      this.coef.parsRenderCoef();
      this.coefDoor.parsRenderDoor();
    } else {
      this.coef.deleted_older_content_square = 0;
      for (const item in this.coef.deleted_older_content_elements) {
        if (item == 'corner_ends') {
          for (const items in this.coef.deleted_older_content_elements[item]) {
            this.coef.deleted_older_content_elements[item][items] = 0;
          }
        } else {
          this.coef.deleted_older_content_elements[item] = 0;
        }
      }
    }
    this.allWardrobe.lamps = e.lamps;
    if (this.firstStart) {
      this.allWardrobe = _.cloneDeep(e);
      if (this.allWardrobe && this.allWardrobe.content_object && this.allWardrobe.content_object['leftCorner'] && this.allWardrobe.content_object['leftCorner'] && this.allWardrobe.content_object['leftCorner'].subId != 'null') {
        this.allWardrobe.content_object['leftCorner'].subId = +this.allWardrobe.content_object['leftCorner'].subId;
      }
      if (this.allWardrobe && this.allWardrobe.content_object && this.allWardrobe.content_object['rightCorner'] && this.allWardrobe.content_object['rightCorner'] && this.allWardrobe.content_object['rightCorner'].subId != 'null') {
        this.allWardrobe.content_object['rightCorner'].subId = +this.allWardrobe.content_object['rightCorner'].subId;
      }
      this.coef.heightWardrobe = this.allWardrobe.height;
      this.coef.setingsWardrobe = this.allWardrobe;
      if (this.allWardrobe.width > 2700) {
        this.coef.global_coef = 5;
        this.coef.global_size_flat = this.coef.convertCoef(this.coef.size_flat);
      } else if (this.allWardrobe.height <= 2700 && this.allWardrobe.width <= 2700) {
        this.coef.global_coef = 4;
        this.coef.global_size_flat = this.coef.convertCoef(this.coef.size_flat);
      }
      if (this.allWardrobe.height > 2700) {
        this.coef.global_coef = 5;
        this.coef.global_size_flat = this.coef.convertCoef(this.coef.size_flat);
      } else if (this.allWardrobe.height <= 2700 && this.allWardrobe.width <= 2700) {
        this.coef.global_coef = 4;
        this.coef.global_size_flat = this.coef.convertCoef(this.coef.size_flat);
      }
      this.coef.widthWrapper = (this.allWardrobe.width + 400 * 2) / this.coef.global_coef + 200;
      this.secondStage.stage.attrs.width = this.coef.widthWrapper;
      this.coef.heightWrapper = (this.allWardrobe.height) / this.coef.global_coef + this.coef.topPositaionWrapper * 2;
      this.secondStage.stage.attrs.height = this.coef.heightWrapper;
      this.secondStage.stage.batchDraw();
      this.typeOrder(e);
      if (this.render.rerender) {
        new Classic(this.coef, this.create, this.minSize, this.render).reinit(this.render.object.content_object);
      }
      this.create.ManualTravel();
    }
    this.price.startCountPrice();
    this.price.sendObjectPrice();
    this.firstStart = false;
    if (this.prams_id == 13) {
      this.staticTabs.tabs[1].active = true;
      setTimeout(() => {
        this.staticTabs.tabs[2].active = true;
      });
    } else {
      this.staticTabs.tabs[1].active = true;
    }
  }

  goNextTab(e) {
    this.pars.createImg();
    if (e.index === 1 && !e.dir) {
    } else {
      if (this.staticTabs.tabs[e.index]) {
        this.staticTabs.tabs[e.index].active = true;
      }
    }
  }

  deleteOld() {
    _.remove(this.coef.Size['children']);
    _.remove(this.coef.shapesLayerw['children']);
    _.remove(this.coef.look2d['children']);
    if (this.coef.stage) {
      for (let i = this.coef.look2d.children.length - 1; i >= 0; i--) {
        this.coef.look2d.children[i].destroy();
      }
      for (let i = this.coef.GlobalSize['children'].length - 1; i >= 0; i--) {
        this.coef.GlobalSize['children'][i].destroy();
      }
      for (let i = this.coef.stage.stage.children.length - 1; i >= 0; i--) {
        this.coef.stage.stage.children[i].destroy();
      }
      for (let i = this.coef.shapesLayerw.children.length - 1; i >= 0; i--) {
        this.coef.shapesLayerw.children[i].destroy();
      }

    }
    if (this.doorCoef.doorStag.stage) {
      for (let i = this.doorCoef.doorStag.stage.children.length - 1; i >= 0; i--) {
        this.doorCoef.doorStag.stage.children[i].destroy();
      }
    }
  }

  typeOrder(e) {
    this.finish.facad = true;
    this.finish.door = true;
    if (this.create.EventTravel) {
      document.removeEventListener('keydown', this.create.EventTravel);
      document.removeEventListener('keydown', this.initDoor.eventManual);
    }
    if (!this.render.rerender) {
      this.coef.comment = '';
    }
    this.coef.height_legs = this.coef.mainSetting.legs_height / this.coef.global_coef;
    try{
      this.coefDoor.glob_settings.right_hang_width = this.coefDoor.glob_settings_old.right_hang_width;
      this.coefDoor.glob_settings.top_plant_height = this.coefDoor.glob_settings_old.top_plant_height;
      this.coefDoor.glob_settings.bottom_plant_height = this.coefDoor.glob_settings_old.bottom_plant_height;
    }catch{}
    
    this.deleteOld();
    this.deleteOld();
    this.deleteOld();
    switch (+this.coef.pramsId) {
      case 1: {
        this.finish.door = false;
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        new Comod(this.coef, this.create).createComod(this.allWardrobe.width / this.coef.global_coef, this.allWardrobe.height / this.coef.global_coef, this.allWardrobe.depth / this.coef.global_coef, this.coef.literalComod[this.coef.comodCountDrawer], this.secondStage.stage, this.coef.shapesLayerw, e);
        break;
      }
      case 12: {
        this.create.createLamps(e, this.coef.lamps, this.secondStage.stage);
        this.create.createLegs(e, this.secondStage.stage);
        this.init.initLook2d(e, this.coef.look2d, this.secondStage.stage);
        this.elements.initShelf(this.coef.product, this.coef.shapesLayerw, this.secondStage.stage);
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        if (this.coef.min_double_split_width >= e.width) {
          this.init.init(e, this.coef.shapesLayerw);
        } else {
          this.init.initBigCabinet(e, this.coef.shapesLayerw);
        }
        const depth = new Depth(this.coef);
        depth.createDepth();
        break;
      }
      case 14: {
        this.finish.door = false;
        this.create.createLamps(e, this.coef.lamps, this.secondStage.stage);
        this.create.createLegs(e, this.secondStage.stage);
        this.init.initLook2d(e, this.coef.look2d, this.secondStage.stage);
        this.elements.initShelf(this.coef.product, this.coef.shapesLayerw, this.secondStage.stage);
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        if (this.coef.min_double_split_width >= e.width) {
          this.init.init(e, this.coef.shapesLayerw);
        } else {
          this.init.initBigCabinet(e, this.coef.shapesLayerw);
        }
        const depth = new Depth(this.coef);
        depth.createDepth();
        break;
      }
      case 13: {
        this.finish.facad = false;
        this.create.createLamps(e, this.coef.lamps, this.secondStage.stage);
        this.create.createLegs(e, this.secondStage.stage);
        this.init.initLook2d(e, this.coef.look2d, this.secondStage.stage);
        this.elements.initShelf(this.coef.product, this.coef.shapesLayerw, this.secondStage.stage);
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        if (this.coef.min_double_split_width >= e.width) {
          this.init.init(e, this.coef.shapesLayerw);
        } else {
          this.init.initBigCabinet(e, this.coef.shapesLayerw);
        }
        break;
      }
      case 2: {
        console.warn(_.cloneDeep(e), 'ee');
        this.coef.heightWrapper = 700;
        this.coef.widthWrapper = 900;
        this.secondStage.stage.attrs.height = this.coef.heightWrapper;
        this.secondStage.stage.attrs.width = this.coef.widthWrapper;
        this.secondStage.stage.batchDraw();
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);

        const bodyApi = {
          orderTypeId: +this.coef.pramsId,
          width: +e.width,
          height: +e.height,
          depth: +e.depth,
          leftCorner: this.coef.setingsWardrobe.content_object['leftCorner'].subId == 'null' ? null : this.coef.setingsWardrobe.content_object['leftCorner'],
          rightCorner: this.coef.setingsWardrobe.content_object['rightCorner'].subId == 'null' ? null : this.coef.setingsWardrobe.content_object['rightCorner'],
        };
        this.http.post(baseUrl.api + '/get-corner-sizes', bodyApi).subscribe((response) => {
          new CornerСabinet(this.coef, this.create, bodyApi).сornerСabinetCreate(this.secondStage.stage, this.coef.shapesLayerw, response['payload']);
        });
        break;
      }
      case 15: {
        this.finish.door = false;
        this.coef.heightWrapper = 700;
        this.coef.widthWrapper = 900;
        this.secondStage.stage.attrs.height = this.coef.heightWrapper;
        this.secondStage.stage.attrs.width = this.coef.widthWrapper;
        this.secondStage.stage.batchDraw();
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        const bodyApi = {
          orderTypeId: +this.coef.pramsId,
          width: +e.width,
          height: +e.height,
          depth: +e.depth,
          leftCorner: this.coef.setingsWardrobe.content_object['leftCorner'].subId == 'null' ? null : this.coef.setingsWardrobe.content_object['leftCorner'],
          rightCorner: this.coef.setingsWardrobe.content_object['rightCorner'].subId == 'null' ? null : this.coef.setingsWardrobe.content_object['rightCorner'],
        };
        this.http.post(baseUrl.api + '/get-corner-sizes', bodyApi).subscribe((response) => {
          new CornerСabinet(this.coef, this.create, bodyApi).сornerСabinetCreateSmall(this.secondStage.stage, this.coef.shapesLayerw, response['payload']);
        });
        break;
      }
      case 9: {
        this.finish.door = false;
        this.coef.heightWrapper = 650;
        this.secondStage.stage.attrs.height = 650;
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        new ComodFoot(this.coef, this.create).createComod(this.allWardrobe.width / this.coef.global_coef, this.allWardrobe.height / this.coef.global_coef, this.allWardrobe.depth / this.coef.global_coef, this.comodFoot[e.schem_id], this.secondStage.stage, this.coef.shapesLayerw, e);

        break;
      }
      case 8: {
        this.finish.door = false;
        this.coef.heightWrapper = 600;
        this.secondStage.stage.attrs.height = 600;
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        new Superstructure(this.coef, this.create).createSuper(this.allWardrobe.width / this.coef.global_coef, this.allWardrobe.height / this.coef.global_coef, this.allWardrobe.depth / this.coef.global_coef, this.secondStage.stage, this.coef.shapesLayerw);
        break;
      }
      case 10: {
        this.finish.door = false;
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        if (this.coef.scheme_id && this.coef.scheme_id == 4) {
          const id = this.findType(e.init_types);
          const index = this.coef.init_option_ids.indexOf(id);
          this.coef.init_option_ids.splice(index, 1);
        }
        new Stone(this.coef, this.create, this.secondStage.stage, this.coef.shapesLayerw).createStone(this.coef.spec_values, e);
        break;
      }
      case 3: {
        this.finish.door = false;
        this.coef.widthWrapper = ((this.allWardrobe.width + 400 * 2) / this.coef.global_coef + 200) * 1.6;
        this.secondStage.stage.attrs.width = this.coef.widthWrapper;
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        this.init.initLook2d(e, this.coef.look2d, this.secondStage.stage);
        new Penal(this.coef, this.create, this.secondStage.stage, this.coef.shapesLayerw, e).createPenal(this.coef.spec_values, e);

        break;
      }
      case 11: {
        this.coefDoor.glob_settings.right_hang_width = this.coefDoor.glob_settings_old.right_hang_width;
        this.coefDoor.glob_settings.top_plant_height = this.coefDoor.glob_settings_old.top_plant_height;
        this.coefDoor.glob_settings.bottom_plant_height = this.coefDoor.glob_settings_old.bottom_plant_height;
        this.coef.height_legs = this.coef.spec_values['cockols_height'] / this.coef.global_coef;
        this.create.createLamps(e, this.coef.lamps, this.secondStage.stage);
        this.create.createLegs(e, this.secondStage.stage);
        this.init.initLook2d(e, this.coef.look2d, this.secondStage.stage , this.coef.spec_values['top_vertical_offset']);
        this.elements.initShelf(this.coef.product, this.coef.shapesLayerw, this.secondStage.stage);
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        if (this.coef.min_double_split_width >= e.width) {
          this.init.initTop(e, this.coef.shapesLayerw);
        } else {
          this.init.initBigCabinetTop(e, this.coef.shapesLayerw);
        }
        const depth = new Depth(this.coef);
        depth.createDepth();
        break;
      }
      case 7: {
        this.create.createLamps(e, this.coef.lamps, this.secondStage.stage);
        this.create.createLegs(e, this.secondStage.stage);
        this.init.initLook2d(e, this.coef.look2d, this.secondStage.stage);
        this.elements.initShelf(this.coef.product, this.coef.shapesLayerw, this.secondStage.stage);
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        this.init.initNeo(e, this.coef.shapesLayerw);
        const depth = new Depth(this.coef);
        depth.createDepth();
        // this.coef.widthSep = '';
        break;
      }
      case 5: {
        this.finish.door = false;
        this.create.createLegs(e, this.secondStage.stage);
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        this.init.initLamel(e, this.coef.shapesLayerw, this.secondStage.stage);
        const depth = new Depth(this.coef);
        depth.createDepth();
        break;
      }
      case 6: {
        this.create.createLegs(e, this.secondStage.stage);
        this.init.initLook2d(e, this.coef.look2d, this.secondStage.stage);
        this.elements.initShelf(this.coef.product, this.coef.shapesLayerw, this.secondStage.stage);
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        this.init.initLamelSonat(e, this.coef.shapesLayerw, this.secondStage.stage);
        const depth = new Depth(this.coef);
        depth.createDepth();
        break;
      }
      case 4: {
        this.create.createLegs(e, this.secondStage.stage);
        this.init.initLook2d(e, this.coef.look2d, this.secondStage.stage);
        this.elements.initShelf(this.coef.product, this.coef.shapesLayerw, this.secondStage.stage);
        this.secondStage.stage.add(this.coef.Size);
        this.secondStage.stage.add(this.coef.GlobalSize);
        this.init.initLamelLux(e, this.coef.shapesLayerw, this.secondStage.stage);
        const depth = new Depth(this.coef);
        depth.createDepth();
        break;
      }
    }
  }

  findType(array) {
    for (const item of array) {
      if (item.id == 3) {
        return item.value;
      }
    }
    return false;
  }
  ngOnDestroy () {
    this.coef.end_client = '';
  }
}
