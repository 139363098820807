import {ChangeDetectorRef, Component} from '@angular/core';
import {baseUrl} from './router.path';
import {HttpClient} from '@angular/common/http';
import {CoefService} from './service/coef.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  dateValue: any;
  preloader = true;

  constructor(private http: HttpClient, private coef: CoefService,
              private ref: ChangeDetectorRef ,
              private router: Router) {
    // window.console.log = function () {}
    this.coef.preloader.subscribe((res: boolean) => {
      if (this.preloader !== res) {
        this.preloader = res;
      }
    });
    this.http.get(baseUrl.local + `get-help-info`)
      .subscribe((data) => {
        this.coef.info = data['payload'];
        this.coef.eventChangeInfo();
      } , (error) => {
        if (error.status === 401) {
          // this.router.navigate(['']);
        }
      });
  }
}
