import {Create} from './create';
import * as Konva from '../../assets/konva';


export class OpenDoor extends Create {
  subId;
  group;
  coef;
  create;
  active;

  constructor(subId, coef, create, active) {
    super(coef);
    this.subId = subId;
    this.coef = coef;
    this.create = create;
    switch (this.subId) {
      case this.coef.element_Sub_id.singleDoorRight: {
        this.singleDoorRight(active);
      }
        break;
      case this.coef.element_Sub_id.twoDoor: {
        this.twoDoor(active);
      }
        break;
      case this.coef.element_Sub_id.singleDoorRightLock: {
        this.singleDoorRightLock(active);
      }
        break;
      case this.coef.element_Sub_id.twoDoorLock: {
        this.twoDoorLock(active);
      }
        break;
      case this.coef.element_Sub_id.singleDoorLeft: {
        this.singleDoorLeft(active);
      }
        break;
      case this.coef.element_Sub_id.singleDoorLeftLock: {
        this.singleDoorLeftLock(active);
      }
        break;
        case this.coef.element_Sub_id.singleDoorRightD: {
          this.singleDoorRightD(active);
        }
          break;
          case this.coef.element_Sub_id.singleDoorLeftD: {
            this.singleDoorLeftD(active);
          }
            break;
            case this.coef.element_Sub_id.singleDoorRightLockD: {
              this.singleDoorRightLockD(active);
            }
              break;

              case this.coef.element_Sub_id.singleDoorLeftLockD: {
                this.singleDoorLeftLockD(active);
              }
                break;
                case this.coef.element_Sub_id.twoDoorD: {
                  this.twoDoorD(active);
                }
                  break;
                  case this.coef.element_Sub_id.twoDoorLockD: {
                    this.twoDoorLockD(active);
                  }
                    break;
      case 24: {
        this.singleDoorLeftLock(active);
      }
        break;
      default:
        console.log(this.subId);
        break;
    }
  }

  singleDoorRight(active) {

    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 500,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.open_door,
        subId: this.subId,
      },
      bind: [],
    });
    const OpenDoorOneRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 80,
      name: 'width',
      fill: this.coef.color,

    });
    const OpenDoorOneRight = new Konva.Rect({
      x: 75 - 17,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    this.group.add(OpenDoorOneRect);
    this.group.add(OpenDoorOneRight);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.singleDoorRightApi(pos);
    });
  }
  singleDoorRightD(active) {

    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 500,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.open_door,
        subId: this.subId,
      },
      bind: [],
    });
    const OpenDoorOneRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 80,
      name: 'width',
      fill: this.coef.color,

    });
    const OpenDoorOneRight = new Konva.Rect({
      x: 75 - 17,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    this.group.add(OpenDoorOneRect);
    this.group.add(OpenDoorOneRight);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.singleDoorRightApiD(pos);
    });
  }
  singleDoorRightApiD(pos){
    console.warn('singleDoorRightApi');
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.topBottomTangent(contours) &&
      this.coef.blockDrawerCentr(pos, contours) &&
      this.oneOpenDoorlimitCrate(contours) &&
      this.openDoorMaxHeight(contours) && this.stopCreate(contours)) {
      const OpenDoorOne_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        draggable: false,
        params: {
          removable: true,
          type: this.coef.id_element.open_door,
          height: contours['bottom'].attrs.y - contours['top'].attrs.y - (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
          subId: this.subId,
          position: this.setPosition(contours),
          contours: contours
        },
        bind: [],
      });
      const OpenDoorOneRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: OpenDoorOne_group_create['attrs'].width,
        height: OpenDoorOne_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      const OpenDoorOneRightCreate = new Konva.Rect({
        x: OpenDoorOneRectCreate['attrs'].width - 17,
        y: OpenDoorOneRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      const drawerText= new Konva.Text({
        x: (contours['right'].attrs.x - contours['left'].attrs.x ) - 20,
        y: 10,
        text: 'Д',
        fontSize: 15,
        fontFamily: 'Calibri',
        fill: 'black'
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        OpenDoorOne_group_create,
        'horizon',
      );
      OpenDoorOne_group_create.add(OpenDoorOneRectCreate);
      OpenDoorOne_group_create.add(OpenDoorOneRightCreate);
      OpenDoorOne_group_create.add(drawerText);
      this.coef.shapesLayerw.add(OpenDoorOne_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    }
  }
  stopCreate(contours) {
    if (this.setPosition(contours) == 'top') {
      if (this.coef.pramsId == 11) {
        this.coef.errorTrigger('Неможливо розмістити елемент під кришкою.');
        return false;
      }
    }
    return true;
  }
  
  singleDoorRightApi(pos) {
    console.warn('singleDoorRightApi');
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.topBottomTangent(contours) &&
      this.coef.blockDrawerCentr(pos, contours) &&
      this.oneOpenDoorlimitCrate(contours) &&
      this.openDoorMaxHeight(contours) && this.stopCreate(contours)) {
      const OpenDoorOne_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        draggable: false,
        params: {
          removable: true,
          type: this.coef.id_element.open_door,
          height: contours['bottom'].attrs.y - contours['top'].attrs.y - (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
          subId: this.subId,
          position: this.setPosition(contours),
          contours: contours
        },
        bind: [],
      });
      const OpenDoorOneRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: OpenDoorOne_group_create['attrs'].width,
        height: OpenDoorOne_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      const OpenDoorOneRightCreate = new Konva.Rect({
        x: OpenDoorOneRectCreate['attrs'].width - 17,
        y: OpenDoorOneRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        OpenDoorOne_group_create,
        'horizon',
      );
      OpenDoorOne_group_create.add(OpenDoorOneRectCreate);
      OpenDoorOne_group_create.add(OpenDoorOneRightCreate);
      this.coef.shapesLayerw.add(OpenDoorOne_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    }
  }
  singleDoorLeftD(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 720,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.open_door,
        subId: this.subId
      },
      bind: [],
    });
    const OpenDoorOneRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 80,
      name: 'width',
      fill: this.coef.color,
    });
    const OpenDoorOneRight = new Konva.Rect({
      x: 17,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    this.group.add(OpenDoorOneRect);
    this.group.add(OpenDoorOneRight);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.singleDoorLeftApiD(pos);
    });
  }
  singleDoorLeftApiD(pos) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.topBottomTangent(contours) &&
      this.coef.blockDrawerCentr(pos, contours) &&
      this.oneOpenDoorlimitCrate(contours) &&
      this.openDoorMaxHeight(contours) && this.stopCreate(contours)) {
      const OpenDoorOne_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        draggable: false,
        params: {
          removable: true,
          type: this.coef.id_element.open_door,
          height: contours['bottom'].attrs.y - contours['top'].attrs.y - (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
          subId: this.subId,
          position: this.setPosition(contours),
          contours: contours
        },
        bind: [],
      });
      const OpenDoorOneRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: OpenDoorOne_group_create['attrs'].width,
        height: OpenDoorOne_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      const OpenDoorOneRightCreate = new Konva.Rect({
        x: 17,
        y: OpenDoorOneRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      const drawerText= new Konva.Text({
        x: (contours['right'].attrs.x - contours['left'].attrs.x ) - 20,
        y: 10,
        text: 'Д',
        fontSize: 15,
        fontFamily: 'Calibri',
        fill: 'black'
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        OpenDoorOne_group_create,
        'horizon',
      );
      OpenDoorOne_group_create.add(OpenDoorOneRectCreate);
      OpenDoorOne_group_create.add(OpenDoorOneRightCreate);
      OpenDoorOne_group_create.add(drawerText);
      this.coef.shapesLayerw.add(OpenDoorOne_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    }
  }
  singleDoorLeft(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 720,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.open_door,
        subId: this.subId
      },
      bind: [],
    });
    const OpenDoorOneRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 80,
      name: 'width',
      fill: this.coef.color,
    });
    const OpenDoorOneRight = new Konva.Rect({
      x: 17,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    this.group.add(OpenDoorOneRect);
    this.group.add(OpenDoorOneRight);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.singleDoorLeftApi(pos);
    });
  }

  singleDoorLeftApi(pos) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.topBottomTangent(contours) &&
      this.coef.blockDrawerCentr(pos, contours) &&
      this.oneOpenDoorlimitCrate(contours) &&
      this.openDoorMaxHeight(contours) && this.stopCreate(contours)) {
      const OpenDoorOne_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        draggable: false,
        params: {
          removable: true,
          type: this.coef.id_element.open_door,
          height: contours['bottom'].attrs.y - contours['top'].attrs.y - (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
          subId: this.subId,
          position: this.setPosition(contours),
          contours: contours
        },
        bind: [],
      });
      const OpenDoorOneRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: OpenDoorOne_group_create['attrs'].width,
        height: OpenDoorOne_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      const OpenDoorOneRightCreate = new Konva.Rect({
        x: 17,
        y: OpenDoorOneRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        OpenDoorOne_group_create,
        'horizon',
      );
      OpenDoorOne_group_create.add(OpenDoorOneRectCreate);
      OpenDoorOne_group_create.add(OpenDoorOneRightCreate);
      this.coef.shapesLayerw.add(OpenDoorOne_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    }
  }
  singleDoorLeftLockD(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 720,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.open_door,
        subId: this.subId
      },
      bind: [],
    });
    const OpenDoorOneRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 80,
      name: 'width',
      fill: this.coef.color,

    });
    const OpenDoorOneRight = new Konva.Rect({
      x: 17,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,

    });
    const lock = this.createLockDesigne();
    this.group.add(OpenDoorOneRect);
    this.group.add(OpenDoorOneRight);
    this.group.add(lock[0]);
    this.group.add(lock[1]);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.singleDoorLeftLockApiD(pos);
    });
  }
  singleDoorLeftLockApiD(pos) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.topBottomTangent(contours)
      && this.coef.blockDrawerCentr(pos, contours)
      && this.oneOpenDoorlimitCrate(contours) &&
      this.openDoorMaxHeight(contours) && this.stopCreate(contours)) {
      const OpenDoorOne_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        draggable: false,
        params: {
          removable: true,
          type: this.coef.id_element.open_door,
          height: contours['bottom'].attrs.y - contours['top'].attrs.y - (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
          subId: this.subId,
          position: this.setPosition(contours),
          contours: contours
        },
        bind: [],
      });
      const OpenDoorOneRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: OpenDoorOne_group_create['attrs'].width,
        height: OpenDoorOne_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      const OpenDoorOneRightCreate = new Konva.Rect({
        x: 17,
        y: OpenDoorOneRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const drawerText= new Konva.Text({
        x: (contours['right'].attrs.x - contours['left'].attrs.x ) - 20,
        y: 10,
        text: 'Д',
        fontSize: 15,
        fontFamily: 'Calibri',
        fill: 'black'
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        OpenDoorOne_group_create,
        'horizon',
      );
      const lock = this.createLockDesigne();
      OpenDoorOne_group_create.add(OpenDoorOneRectCreate);
      OpenDoorOne_group_create.add(OpenDoorOneRightCreate);
      OpenDoorOne_group_create.add(lock[0]);
      OpenDoorOne_group_create.add(lock[1]);
      OpenDoorOne_group_create.add(drawerText);
      this.coef.shapesLayerw.add(OpenDoorOne_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    }
  }
  singleDoorLeftLock(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 720,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.open_door,
        subId: this.subId
      },
      bind: [],
    });
    const OpenDoorOneRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 80,
      name: 'width',
      fill: this.coef.color,

    });
    const OpenDoorOneRight = new Konva.Rect({
      x: 17,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,

    });
    const lock = this.createLockDesigne();
    this.group.add(OpenDoorOneRect);
    this.group.add(OpenDoorOneRight);
    this.group.add(lock[0]);
    this.group.add(lock[1]);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.singleDoorLeftLockApi(pos);
    });
  }

  singleDoorLeftLockApi(pos) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.topBottomTangent(contours)
      && this.coef.blockDrawerCentr(pos, contours)
      && this.oneOpenDoorlimitCrate(contours) &&
      this.openDoorMaxHeight(contours) && this.stopCreate(contours)) {
      const OpenDoorOne_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        draggable: false,
        params: {
          removable: true,
          type: this.coef.id_element.open_door,
          height: contours['bottom'].attrs.y - contours['top'].attrs.y - (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
          subId: this.subId,
          position: this.setPosition(contours),
          contours: contours
        },
        bind: [],
      });
      const OpenDoorOneRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: OpenDoorOne_group_create['attrs'].width,
        height: OpenDoorOne_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      const OpenDoorOneRightCreate = new Konva.Rect({
        x: 17,
        y: OpenDoorOneRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        OpenDoorOne_group_create,
        'horizon',
      );
      const lock = this.createLockDesigne();
      OpenDoorOne_group_create.add(OpenDoorOneRectCreate);
      OpenDoorOne_group_create.add(OpenDoorOneRightCreate);
      OpenDoorOne_group_create.add(lock[0]);
      OpenDoorOne_group_create.add(lock[1]);
      this.coef.shapesLayerw.add(OpenDoorOne_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    }
  }
  singleDoorRightLockD(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 500,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.open_door,
        subId: this.subId
      },
      bind: [],
    });
    const OpenDoorOneRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 80,
      name: 'width',
      fill: this.coef.color,
    });
    const OpenDoorOneRight = new Konva.Rect({
      x: 75 - 17,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    const lock = this.createLockDesigne();
    this.group.add(OpenDoorOneRect);
    this.group.add(OpenDoorOneRight);
    this.group.add(lock[0]);
    this.group.add(lock[1]);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.singleDoorRightLockApiD(pos);
    });
  }
  singleDoorRightLockApiD(pos) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.topBottomTangent(contours) &&
      this.coef.blockDrawerCentr(pos, contours) &&
      this.oneOpenDoorlimitCrate(contours) &&
      this.openDoorMaxHeight(contours) && this.stopCreate(contours)) {
      const OpenDoorOne_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        draggable: false,
        params: {
          removable: true,
          type: this.coef.id_element.open_door,
          height: contours['bottom'].attrs.y - contours['top'].attrs.y - (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
          subId: this.subId,
          position: this.setPosition(contours),
          contours: contours
        },
        bind: [],
      });
      const OpenDoorOneRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: OpenDoorOne_group_create['attrs'].width,
        height: OpenDoorOne_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      const drawerText= new Konva.Text({
        x: (contours['right'].attrs.x - contours['left'].attrs.x ) - 20,
        y: 10,
        text: 'Д',
        fontSize: 15,
        fontFamily: 'Calibri',
        fill: 'black'
      });
      const OpenDoorOneRightCreate = new Konva.Rect({
        x: OpenDoorOneRectCreate['attrs'].width - 17,
        y: OpenDoorOneRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        OpenDoorOne_group_create,
        'horizon',
      );
      const lock = this.createLockDesigne();
      OpenDoorOne_group_create.add(OpenDoorOneRectCreate);
      OpenDoorOne_group_create.add(OpenDoorOneRightCreate);
      OpenDoorOne_group_create.add(lock[0]);
      OpenDoorOne_group_create.add(lock[1]);
      OpenDoorOne_group_create.add(drawerText);
      this.coef.shapesLayerw.add(OpenDoorOne_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    }
  }
  singleDoorRightLock(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 500,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.open_door,
        subId: this.subId
      },
      bind: [],
    });
    const OpenDoorOneRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 80,
      name: 'width',
      fill: this.coef.color,
    });
    const OpenDoorOneRight = new Konva.Rect({
      x: 75 - 17,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    const lock = this.createLockDesigne();
    this.group.add(OpenDoorOneRect);
    this.group.add(OpenDoorOneRight);
    this.group.add(lock[0]);
    this.group.add(lock[1]);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.singleDoorRightLockApi(pos);
    });
  }

  singleDoorRightLockApi(pos) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.topBottomTangent(contours) &&
      this.coef.blockDrawerCentr(pos, contours) &&
      this.oneOpenDoorlimitCrate(contours) &&
      this.openDoorMaxHeight(contours) && this.stopCreate(contours)) {
      const OpenDoorOne_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        draggable: false,
        params: {
          removable: true,
          type: this.coef.id_element.open_door,
          height: contours['bottom'].attrs.y - contours['top'].attrs.y - (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
          subId: this.subId,
          position: this.setPosition(contours),
          contours: contours
        },
        bind: [],
      });
      const OpenDoorOneRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: OpenDoorOne_group_create['attrs'].width,
        height: OpenDoorOne_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      const OpenDoorOneRightCreate = new Konva.Rect({
        x: OpenDoorOneRectCreate['attrs'].width - 17,
        y: OpenDoorOneRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        OpenDoorOne_group_create,
        'horizon',
      );
      const lock = this.createLockDesigne();
      OpenDoorOne_group_create.add(OpenDoorOneRectCreate);
      OpenDoorOne_group_create.add(OpenDoorOneRightCreate);
      OpenDoorOne_group_create.add(lock[0]);
      OpenDoorOne_group_create.add(lock[1]);
      this.coef.shapesLayerw.add(OpenDoorOne_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    }
  }
  twoDoorD(active){
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 600,
      y: -500,
      width: 100,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.open_door,
        subId: this.subId,
      },
      bind: [],
    });
    const OpenDoorDoubleRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 100,
      height: 80,
      name: 'width',
      fill: this.coef.color,
    });
    const OpenDoorDoubleRight = new Konva.Rect({
      x: 50 - 9,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    const OpenDoorDoubleLeft = new Konva.Rect({
      x: 50 + 4,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    const OpenDoorDoubleCenter = new Konva.Rect({
      x: 49,
      y: 0,
      width: 2,
      height: 80,
      name: 'width',
      fill: this.coef.blackColor,
    });
    this.group.add(OpenDoorDoubleRect);
    this.group.add(OpenDoorDoubleRight);
    this.group.add(OpenDoorDoubleLeft);
    this.group.add(OpenDoorDoubleCenter);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.twoDoorApiD(pos);
    });
  }
  twoDoorApiD(pos, neo?){
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.topBottomTangent(contours) &&
      this.twoOpenDoorlimitCreate(contours) &&
      this.coef.blockDrawerCentr(pos, contours) &&
      this.openDoorMaxHeight(contours) &&
      this.stopCreate(contours) &&
      this.openDoorMaxHeight(contours)) {
      let removable = true;
      if (neo) {
        removable = false;
      }
      const OpenDoorDouble_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        draggable: false,
        params: {
          removable: removable,
          type: this.coef.id_element.open_door,
          height: contours['bottom'].attrs.y - contours['top'].attrs.y - (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
          subId: this.subId,
          position: this.setPosition(contours),
          contours: contours,
          neo: neo ? neo : false,
          innerSection: neo ? neo : false
        },
        bind: [],
      });
      const OpenDoorDoubleRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: OpenDoorDouble_group_create['attrs'].width,
        height: OpenDoorDouble_group_create['attrs'].params.height,
        name: 'width',
        fill: neo ? this.coef.colorFacad : this.coef.color,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
        params: {
          facad: neo ? neo : false,
        }
      });
      const OpenDoorDoubleRightCreate = new Konva.Rect({
        x: OpenDoorDoubleRectCreate['attrs'].width / 2 - 15,
        y: OpenDoorDoubleRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      const OpenDoorDoubleLeftCreate = new Konva.Rect({
        x: OpenDoorDoubleRectCreate['attrs'].width / 2 + 10,
        y: OpenDoorDoubleRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      const drawerText= new Konva.Text({
        x: (contours['right'].attrs.x - contours['left'].attrs.x ) - 20,
        y: 10,
        text: 'Д',
        fontSize: 15,
        fontFamily: 'Calibri',
        fill: 'black'
      });
      const OpenDoorDoubleCenterCreate = new Konva.Rect({
        x: OpenDoorDoubleRectCreate['attrs'].width / 2 - 1,
        y: 0,
        width: 2,
        height: OpenDoorDouble_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.blackColor,
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        OpenDoorDouble_group_create,
        'horizon',
      );
      OpenDoorDouble_group_create.add(OpenDoorDoubleRectCreate);
      OpenDoorDouble_group_create.add(OpenDoorDoubleRightCreate);
      OpenDoorDouble_group_create.add(OpenDoorDoubleLeftCreate);
      OpenDoorDouble_group_create.add(OpenDoorDoubleCenterCreate);
      OpenDoorDouble_group_create.add(drawerText);
      this.coef.shapesLayerw.add(OpenDoorDouble_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    }
  }

  twoDoor(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 600,
      y: -500,
      width: 100,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.open_door,
        subId: this.subId,
      },
      bind: [],
    });
    const OpenDoorDoubleRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 100,
      height: 80,
      name: 'width',
      fill: this.coef.color,
    });
    const OpenDoorDoubleRight = new Konva.Rect({
      x: 50 - 9,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    const OpenDoorDoubleLeft = new Konva.Rect({
      x: 50 + 4,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    const OpenDoorDoubleCenter = new Konva.Rect({
      x: 49,
      y: 0,
      width: 2,
      height: 80,
      name: 'width',
      fill: this.coef.blackColor,
    });
    this.group.add(OpenDoorDoubleRect);
    this.group.add(OpenDoorDoubleRight);
    this.group.add(OpenDoorDoubleLeft);
    this.group.add(OpenDoorDoubleCenter);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.twoDoorApi(pos);
    });
  }

  twoDoorApi(pos, neo?) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.topBottomTangent(contours) &&
      this.twoOpenDoorlimitCreate(contours) &&
      this.coef.blockDrawerCentr(pos, contours) &&
      this.openDoorMaxHeight(contours) &&
      this.stopCreate(contours) &&
      this.openDoorMaxHeight(contours)) {
      let removable = true;
      if (neo) {
        removable = false;
      }
      const OpenDoorDouble_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        draggable: false,
        params: {
          removable: removable,
          type: this.coef.id_element.open_door,
          height: contours['bottom'].attrs.y - contours['top'].attrs.y - (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
          subId: this.subId,
          position: this.setPosition(contours),
          contours: contours,
          neo: neo ? neo : false,
          innerSection: neo ? neo : false
        },
        bind: [],
      });
      const OpenDoorDoubleRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: OpenDoorDouble_group_create['attrs'].width,
        height: OpenDoorDouble_group_create['attrs'].params.height,
        name: 'width',
        fill: neo ? this.coef.colorFacad : this.coef.color,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
        params: {
          facad: neo ? neo : false,
        }
      });
      const OpenDoorDoubleRightCreate = new Konva.Rect({
        x: OpenDoorDoubleRectCreate['attrs'].width / 2 - 15,
        y: OpenDoorDoubleRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      const OpenDoorDoubleLeftCreate = new Konva.Rect({
        x: OpenDoorDoubleRectCreate['attrs'].width / 2 + 10,
        y: OpenDoorDoubleRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: this.coef.blackColor,
      });
      const OpenDoorDoubleCenterCreate = new Konva.Rect({
        x: OpenDoorDoubleRectCreate['attrs'].width / 2 - 1,
        y: 0,
        width: 2,
        height: OpenDoorDouble_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.blackColor,
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        OpenDoorDouble_group_create,
        'horizon',
      );
      OpenDoorDouble_group_create.add(OpenDoorDoubleRectCreate);
      OpenDoorDouble_group_create.add(OpenDoorDoubleRightCreate);
      OpenDoorDouble_group_create.add(OpenDoorDoubleLeftCreate);
      OpenDoorDouble_group_create.add(OpenDoorDoubleCenterCreate);
      this.coef.shapesLayerw.add(OpenDoorDouble_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    }
  }

  twoDoorLock(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 600,
      y: -500,
      width: 100,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.open_door,
        subId: this.subId,
      },
      bind: [],
    });
    const OpenDoorDoubleRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 100,
      height: 80,
      name: 'width',
      fill: this.coef.color,
    });
    const OpenDoorDoubleRight = new Konva.Rect({
      x: 50 - 9,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    const OpenDoorDoubleLeft = new Konva.Rect({
      x: 50 + 4,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    const OpenDoorDoubleCenter = new Konva.Rect({
      x: 49,
      y: 0,
      width: 2,
      height: 80,
      name: 'width',
      fill: this.coef.blackColor,
    });
    const lock = this.createLockDesigne();
    this.group.add(OpenDoorDoubleRect);
    this.group.add(OpenDoorDoubleRight);
    this.group.add(OpenDoorDoubleLeft);
    this.group.add(OpenDoorDoubleCenter);
    this.group.add(lock[0]);
    this.group.add(lock[1]);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.twoDoorLockApi(pos);
    });
  }

  twoDoorLockApi(pos) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.topBottomTangent(contours) &&
      this.twoOpenDoorlimitCreate(contours) &&
      this.coef.blockDrawerCentr(pos, contours) &&
      this.openDoorMaxHeight(contours) && this.stopCreate(contours)) {
      const OpenDoorDouble_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        draggable: false,
        params: {
          removable: true,
          type: this.coef.id_element.open_door,
          height: contours['bottom'].attrs.y - contours['top'].attrs.y - (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
          subId: this.subId,
          position: this.setPosition(contours),
          contours: contours
        },
        bind: [],
      });
      const OpenDoorDoubleRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: OpenDoorDouble_group_create['attrs'].width,
        height: OpenDoorDouble_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      const OpenDoorDoubleRightCreate = new Konva.Rect({
        x: OpenDoorDoubleRectCreate['attrs'].width / 2 - 15,
        y: OpenDoorDoubleRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const OpenDoorDoubleLeftCreate = new Konva.Rect({
        x: OpenDoorDoubleRectCreate['attrs'].width / 2 + 10,
        y: OpenDoorDoubleRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const OpenDoorDoubleCenterCreate = new Konva.Rect({
        x: OpenDoorDoubleRectCreate['attrs'].width / 2 - 1,
        y: 0,
        width: 2,
        height: OpenDoorDouble_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.blackColor,
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        OpenDoorDouble_group_create,
        'horizon',
      );
      OpenDoorDouble_group_create.add(OpenDoorDoubleRectCreate);
      OpenDoorDouble_group_create.add(OpenDoorDoubleRightCreate);
      OpenDoorDouble_group_create.add(OpenDoorDoubleLeftCreate);
      OpenDoorDouble_group_create.add(OpenDoorDoubleCenterCreate);
      const lock = this.createLockDesigne();
      OpenDoorDouble_group_create.add(lock[0]);
      OpenDoorDouble_group_create.add(lock[1]);
      this.coef.shapesLayerw.add(OpenDoorDouble_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    }
  }
  twoDoorLockD(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 600,
      y: -500,
      width: 100,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.open_door,
        subId: this.subId,
      },
      bind: [],
    });
    const OpenDoorDoubleRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 100,
      height: 80,
      name: 'width',
      fill: this.coef.color,
    });
    const OpenDoorDoubleRight = new Konva.Rect({
      x: 50 - 9,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    const OpenDoorDoubleLeft = new Konva.Rect({
      x: 50 + 4,
      y: 80 / 2 - 16 / 2,
      width: 5,
      height: 16,
      name: 'width',
      fill: this.coef.silverColor,
    });
    const OpenDoorDoubleCenter = new Konva.Rect({
      x: 49,
      y: 0,
      width: 2,
      height: 80,
      name: 'width',
      fill: this.coef.blackColor,
    });
    const lock = this.createLockDesigne();
    this.group.add(OpenDoorDoubleRect);
    this.group.add(OpenDoorDoubleRight);
    this.group.add(OpenDoorDoubleLeft);
    this.group.add(OpenDoorDoubleCenter);
    this.group.add(lock[0]);
    this.group.add(lock[1]);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.twoDoorLockApiD(pos);
    });
  }

  twoDoorLockApiD(pos) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours &&
      this.topBottomTangent(contours) &&
      this.twoOpenDoorlimitCreate(contours) &&
      this.coef.blockDrawerCentr(pos, contours) &&
      this.openDoorMaxHeight(contours) && this.stopCreate(contours)) {
      const OpenDoorDouble_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        draggable: false,
        params: {
          removable: true,
          type: this.coef.id_element.open_door,
          height: contours['bottom'].attrs.y - contours['top'].attrs.y - (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
          subId: this.subId,
          position: this.setPosition(contours),
          contours: contours
        },
        bind: [],
      });
      const OpenDoorDoubleRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: OpenDoorDouble_group_create['attrs'].width,
        height: OpenDoorDouble_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      const OpenDoorDoubleRightCreate = new Konva.Rect({
        x: OpenDoorDoubleRectCreate['attrs'].width / 2 - 15,
        y: OpenDoorDoubleRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const OpenDoorDoubleLeftCreate = new Konva.Rect({
        x: OpenDoorDoubleRectCreate['attrs'].width / 2 + 10,
        y: OpenDoorDoubleRectCreate['attrs'].height / 2 - 16 / 2,
        width: 5,
        height: 16,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const OpenDoorDoubleCenterCreate = new Konva.Rect({
        x: OpenDoorDoubleRectCreate['attrs'].width / 2 - 1,
        y: 0,
        width: 2,
        height: OpenDoorDouble_group_create['attrs'].params.height,
        name: 'width',
        fill: this.coef.blackColor,
      });
      const drawerText= new Konva.Text({
        x: (contours['right'].attrs.x - contours['left'].attrs.x ) - 20,
        y: 10,
        text: 'Д',
        fontSize: 15,
        fontFamily: 'Calibri',
        fill: 'black'
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        OpenDoorDouble_group_create,
        'horizon',
      );
      OpenDoorDouble_group_create.add(OpenDoorDoubleRectCreate);
      OpenDoorDouble_group_create.add(OpenDoorDoubleRightCreate);
      OpenDoorDouble_group_create.add(OpenDoorDoubleLeftCreate);
      OpenDoorDouble_group_create.add(OpenDoorDoubleCenterCreate);
      const lock = this.createLockDesigne();
      OpenDoorDouble_group_create.add(lock[0]);
      OpenDoorDouble_group_create.add(lock[1]);
      OpenDoorDouble_group_create.add(drawerText);
      this.coef.shapesLayerw.add(OpenDoorDouble_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    }
  }
  setPosition(parent) {
    if (parent['top'].attrs.params.type === 10) {
      return 'top';
    }
    if (parent['bottom'].attrs.params.type === 11) {
      return 'bottom';
    }
    return null;
  }
}
