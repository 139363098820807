import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-text-table',
  templateUrl: './text-table.component.html',
  styleUrls: ['./text-table.component.scss']
})
export class TextTableComponent implements OnInit {
  params;
  colorSchema = {
    0: 'rgb(30, 90, 163)',
    1: 'rgb(202, 138, 13)',
    2: 'rgb(0, 128, 0)',
    3: 'rgb(139, 0, 0)',
    4: 'rgb(139, 0, 0)',
    6: 'rgb(141, 140, 138)',
    7: 'rgb(141, 140, 138)',
  };
  colorSchemaOrder = {
    true: 'rgb(0, 128, 0)',
    false: 'gb(139, 0, 0)',
  };

  constructor() {
  }

  ngOnInit() {
  }

  agInit(params: any): void {
    console.warn(params, 'color');
    this.params = params;
  }
}
