import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../../router.path';
import {GridOptions} from 'ag-grid-community';
import {ImgTableComponent} from '../../components/img-table/img-table.component';
import {ButtonTableComponent} from '../../components/button-table/button-table.component';
import {TextTableComponent} from '../../components/text-table/text-table.component';
import {PriceTableComponent} from '../../components/price-table/price-table.component';
import {CoefService} from '../../service/coef.service';
import {Router} from '@angular/router';
import {CoefDoorService} from '../../service/door service/coef-door.service';
import {API} from '../../common/api';
import {NotificationService} from '../../service/notification.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  img_hover_src;
  img_hover;
  localeText = {
    // for filter panel
    page: 'сторінка',
    more: 'daMore',
    to: '-',
    of: 'всього',
    next: 'daNexten',
    last: 'daLasten',
    first: 'daFirsten',
    previous: 'daPreviousen',
    loadingOoo: 'daLoading...',

    // for set filter
    selectAll: 'daSelect Allen',
    searchOoo: 'daSearch...',
    blanks: 'daBlanc',

    // for number filter and text filter
    filterOoo: 'daFilter...',
    applyFilter: 'daApplyFilter...',
    equals: 'daEquals',
    notEquals: 'daNotEqual',

    // for number filter
    lessThan: 'daLessThan',
    greaterThan: 'daGreaterThan',
    lessThanOrEqual: 'daLessThanOrEqual',
    greaterThanOrEqual: 'daGreaterThanOrEqual',
    inRange: 'daInRange',

    // for text filter
    contains: 'daContains',
    notContains: 'daNotContains',
    startsWith: 'daStarts dawith',
    endsWith: 'daEnds dawith',

    // the header of the default group column
    group: 'laGroup',

    // tool panel
    columns: 'laColumns',
    filters: 'laFilters',
    rowGroupColumns: 'laPivot Cols',
    rowGroupColumnsEmptyMessage: 'la drag cols to group',
    valueColumns: 'laValue Cols',
    pivotMode: 'laPivot-Mode',
    groups: 'laGroups',
    values: 'laValues',
    pivots: 'laPivots',
    valueColumnsEmptyMessage: 'la drag cols to aggregate',
    pivotColumnsEmptyMessage: 'la drag here to pivot',
    toolPanelButton: 'la tool panel',

    // other
    noRowsToShow: 'Записи відсутні',

    // enterprise menu
    pinColumn: 'laPin Column',
    valueAggregation: 'laValue Agg',
    autosizeThiscolumn: 'laAutosize Diz',
    autosizeAllColumns: 'laAutsoie em All',
    groupBy: 'laGroup by',
    ungroupBy: 'laUnGroup by',
    resetColumns: 'laReset Those Cols',
    expandAll: 'laOpen-em-up',
    collapseAll: 'laClose-em-up',
    toolPanel: 'laTool Panelo',
    export: 'laExporto',
    csvExport: 'la CSV Exportp',
    excelExport: 'la Excel Exporto',

    // enterprise menu pinning
    pinLeft: 'laPin <<',
    pinRight: 'laPin >>',
    noPin: 'laDontPin <>',

    // enterprise menu aggregation and status bar
    sum: 'laSum',
    min: 'laMin',
    max: 'laMax',
    none: 'laNone',
    count: 'laCount',
    average: 'laAverage',

    // standard menu
    copy: 'laCopy',
    copyWithHeaders: 'laCopy Wit hHeaders',
    ctrlC: 'ctrl n C',
    paste: 'laPaste',
    ctrlV: 'ctrl n V'
  }
  filter = '';
  constructor(private http: HttpClient,
              public coef: CoefService,
              private router: Router,
              private doorCoef: CoefDoorService,
              private notification: NotificationService,
  ) {
    this.coef.preloaderUpdate(true);
    this.getUser();
  }
  columnDefs = [
    {headerName: 'id', field: 'id', cellRendererFramework: TextTableComponent ,filter: 'agTextColumnFilter',
  filterParams: {apply: true, newRowsAction: 'keep'}},
    {headerName: 'Наповнення', field: 'contentUrl', cellRendererFramework: ImgTableComponent},
    {headerName: 'Двері', field: 'facadeUrl', cellRendererFramework: ImgTableComponent},
    {headerName: 'Ціна', field: 'orderPrice', cellRendererFramework: PriceTableComponent},
    {headerName: 'Тип', field: 'orderType', cellRendererFramework: TextTableComponent},
    {headerName: 'Час ред (год)', field: 'timeLeft', cellRendererFramework: TextTableComponent},
    {headerName: '', field: 'id', cellRendererFramework: ButtonTableComponent}
  ];
  rowData = [];
  gridOptions: GridOptions = {
    columnDefs: this.columnDefs,
    rowData: this.rowData,
    enableSorting: true,
    pagination: true,
    paginationPageSize: 25,
    domLayout: 'autoHeight',
  };
  onFilterTextBoxChanged(e){
    this.gridOptions.api.setQuickFilter(this.filter);
  }
  private getUser(): void {
    this.http.get(baseUrl.api + API.GET_USER).subscribe(user => {
      this.coef.manager = user['payload'].manager;
    }, err => {
      this.notification.triggerNotification('Невідома помилка. Спробуйте пізніше.', true);
    });
  }
  ngOnInit() {
    if (this.coef.stage) {
      for (let i = this.coef.look2d.children.length - 1; i >= 0; i--) {
        this.coef.look2d.children[i].destroy();
      }
      for (let i = this.coef.GlobalSize['children'].length - 1; i >= 0; i--) {
        this.coef.GlobalSize['children'][i].destroy();
      }
      for (const item of this.coef.stage.stage.children) {
        item.destroy();
      }
    }
    if (this.doorCoef.doorStag.stage) {
      for (let i = this.doorCoef.doorStag.stage.children.length - 1; i >= 0; i--) {
        this.doorCoef.doorStag.stage.children[i].destroy();
      }
    }
    this.http.get(baseUrl.api + `user-active-table`)
      .subscribe((data) => {
        this.rowData = data['payload'];
        try {
          this.gridOptions.api.sizeColumnsToFit();
        } catch (e) {
          console.log(e);
        }
        this.coef.preloaderUpdate(false);
      }, (error) => {
        this.coef.preloaderUpdate(false);
        if (error.status === 401) {
          this.coef.preloaderUpdate(false);
          this.router.navigate(['']);
        }
      });
  }


}
