import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import 'rxjs-compat/add/operator/merge';
import 'rxjs-compat/add/operator/map';

@Directive({
  selector: '[appDrag]'
})
export class DragDirective implements OnInit {
  @Input() appDrag = null;
  @Output() dragMove = new EventEmitter();
  @Output() dragStart = new EventEmitter();
  @Output() dragEnd = new EventEmitter();

  private dragging = false;
  private img;
  element;

  @HostListener('pointerdown', ['$event']) onPointerDown(event): void {
    if (this.element && this.element.active) {
      this.dragging = true;
      this.dragStart.emit(event);
      this.createImg(event.target.currentSrc, {top: event.clientY, left: event.clientX});
    }
  }

  @HostListener('document:pointermove', ['$event']) onPointerMove(event): void {
    if (!this.dragging) {
      return;
    }
    this.dragMove.emit(event);
    this.img.style.top = `${event.clientY - 50}px`;
    this.img.style.left = `${event.clientX - 30}px`;
  }

  @HostListener('document:pointerup', ['$event']) onPointerUp(event): void {
    if (!this.dragging) {
      return;
    }
    this.dragging = false;
    this.dragEnd.emit({event: event, item: this.element});
    this.deleteImg();
  }

  constructor() {
  }

  ngOnInit() {
    this.element = this.appDrag;
  }

  createImg(src, position) {
    const img = document.createElement('IMG');
    img.setAttribute('src', src);
    img.setAttribute('width', '60');
    img.setAttribute('height', '100');
    img.setAttribute('id', 'imgDragable');
    img.setAttribute('alt', 'The Pulpit Rock');
    img.setAttribute('style', `position:fixed; top:${position.top - 50}px;left:${position.left - 30}px; z-index:1000;`);
    document.body.appendChild(img);
    this.img = document.getElementById('imgDragable');
  }

  deleteImg() {
    document.getElementById('imgDragable').remove();
  }
}
