import * as Konva from '../../assets/konva';
import {Create} from './create';
import {Sizes} from './sizes/sizes';


export class Drawer extends Create {
  subId;
  drawer_group;
  coef;
  create;
  minSize;

  constructor(subId, coef, create, active, minSize) {
    super(coef, create);
    this.subId = subId;
    this.coef = coef;
    this.create = create;
    this.minSize = minSize;
    
    switch (this.subId) {
      case this.coef.element_Sub_id.drawerSimple: {
        this.createSimple(active);
      }
        break;
      case this.coef.element_Sub_id.drawerWithLength: {
        this.createWithLength(active);
      }
        break;
      case this.coef.element_Sub_id.drawerWithLengthLock: {
        this.createWithLengthLock(active);
      }
        break;
      case this.coef.element_Sub_id.drawerSimpleLock: {
        this.createSimpleLock(active);
      }
        break;
      case this.coef.element_Sub_id.drawerSimpleSpec: {
        this.createSimpleSpec(active);
      }
        break;
      case this.coef.element_Sub_id.drawerSimpleSpecD: {
          this.createSimpleSpecD(active);
        }
          break;
    }
  }
  createSimpleSpecD(active) {
    this.coef.countNameElement++;
    this.drawer_group = new Konva.Group({
      x: 400,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.drawer,
        subId: this.subId
      },
      bind: [],
    });

    const drawerRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 35,
      name: 'width',
      fill: this.coef.color,
    });
 
    this.drawer_group.add(drawerRect);

    this.dragEnd(this.drawer_group, this.coef.product, (pos, xy) => {
      this.createSimpleApiSpecD(pos);
    });
  }
  createSimpleApiSpecD(pos, heightDrawer?, neo?) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.drawer_group, this.coef.shapesLayerw);
    if (contours) {
      const isOnWhat = this.isOnWhat(pos, this.drawer_group, this.coef.shapesLayerw);
      const lastDrawer = {bottom: null};
      if (isOnWhat) {
        if (this.getlastdrawer(isOnWhat, lastDrawer)) {
          contours['top'] = this.getlastdrawer(isOnWhat, lastDrawer);
        }
      }
      let height = this.coef.drawer.drawHeightSpec;
      if (heightDrawer) {
        height = heightDrawer;
      }
      const globX = contours['left'].attrs.x + contours['left'].attrs.width;
      const globY = contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height);
      let removable = true;
      if (neo) {
        removable = false;
      }
      if (this.blockDrawerCreate(contours, pos, neo)) {
        const width = contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width;
        const drawer_group_create = new Konva.Group({
          x: globX,
          y: globY,
          name: 'element' + this.coef.countNameElement,
          width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
          params: {
            removable: removable,
            type: this.coef.id_element.drawer,
            height: height,
            subId: this.subId,
            touches: 'noTouched',
            neo: neo ? neo : false,
            innerSection: neo ? neo : false
          },
          bind: [],
        });
        const drawerRectCreate = new Konva.Rect({
          x: 0,
          y: 0,
          width: drawer_group_create['attrs'].width,
          height: height,
          name: 'width',
          fill: neo ? this.coef.colorFacad : this.coef.color,
          strokeWidth: 1,
          stroke: 'black',
          params: {
            facad: neo ? neo : false,
          },
          dash: []
        });
        const drawerCenterCreate = new Konva.Rect({
          x: 0,
          y: 0,
          width:drawer_group_create['attrs'].width,
          height: 5,
          name: 'width',
          fill: '#6c6c6c',
          strokeWidth: 1,
          stroke: 'black',
        });
        const drawerText= new Konva.Text({
          x: (contours['right'].attrs.x - contours['left'].attrs.x ) - 20,
          y: 10,
          text: 'Д',
          fontSize: 15,
          fontFamily: 'Calibri',
          fill: 'black'
        });
        this.create.bindingTocounters(
          contours['left'],
          contours['right'],
          contours['top'],
          contours['bottom'],
          drawer_group_create,
          'horizon',
        );
        drawer_group_create.add(drawerRectCreate);
        drawer_group_create.add(drawerCenterCreate)
        drawer_group_create.add(drawerText)
        this.coef.shapesLayerw.add(drawer_group_create);
        this.coef.shapesLayerw.batchDraw();
        this.create.priceSend();
        if (heightDrawer) {
          drawer_group_create['attrs'].params.touches = 'Touched';
        }
        this.resizeDraw(drawer_group_create, contours, pos);
      }
    }
  }
  createSimpleSpec(active) {
    this.coef.countNameElement++;
    this.drawer_group = new Konva.Group({
      x: 400,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.drawer,
        subId: this.subId
      },
      bind: [],
    });

    const drawerRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 35,
      name: 'width',
      fill: this.coef.color,
    });
 
    this.drawer_group.add(drawerRect);

    this.dragEnd(this.drawer_group, this.coef.product, (pos, xy) => {
      this.createSimpleApiSpec(pos);
    });
  }
  createSimpleApiSpec(pos, heightDrawer?, neo?) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.drawer_group, this.coef.shapesLayerw);
    if (contours) {
      const isOnWhat = this.isOnWhat(pos, this.drawer_group, this.coef.shapesLayerw);
      const lastDrawer = {bottom: null};
      if (isOnWhat) {
        if (this.getlastdrawer(isOnWhat, lastDrawer)) {
          contours['top'] = this.getlastdrawer(isOnWhat, lastDrawer);
        }
      }
      let height = this.coef.drawer.drawHeightSpec;
      if (heightDrawer) {
        height = heightDrawer;
      }
      const globX = contours['left'].attrs.x + contours['left'].attrs.width;
      const globY = contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height);
      let removable = true;
      if (neo) {
        removable = false;
      }
      if (this.blockDrawerCreate(contours, pos, neo)) {
        const width = contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width;
        const drawer_group_create = new Konva.Group({
          x: globX,
          y: globY,
          name: 'element' + this.coef.countNameElement,
          width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
          params: {
            removable: removable,
            type: this.coef.id_element.drawer,
            height: height,
            subId: this.subId,
            touches: 'noTouched',
            neo: neo ? neo : false,
            innerSection: neo ? neo : false
          },
          bind: [],
        });
        const drawerRectCreate = new Konva.Rect({
          x: 0,
          y: 0,
          width: drawer_group_create['attrs'].width,
          height: height,
          name: 'width',
          fill: neo ? this.coef.colorFacad : this.coef.color,
          strokeWidth: 1,
          stroke: 'black',
          params: {
            facad: neo ? neo : false,
          },
          dash: []
        });
        const drawerCenterCreate = new Konva.Rect({
          x: 0,
          y: 0,
          width:drawer_group_create['attrs'].width,
          height: 5,
          name: 'width',
          fill: '#6c6c6c',
          strokeWidth: 1,
          stroke: 'black',
        });

        this.create.bindingTocounters(
          contours['left'],
          contours['right'],
          contours['top'],
          contours['bottom'],
          drawer_group_create,
          'horizon',
        );
        drawer_group_create.add(drawerRectCreate);
        drawer_group_create.add(drawerCenterCreate)
        this.coef.shapesLayerw.add(drawer_group_create);
        this.coef.shapesLayerw.batchDraw();
        this.create.priceSend();
        if (heightDrawer) {
          drawer_group_create['attrs'].params.touches = 'Touched';
        }
        this.resizeDraw(drawer_group_create, contours, pos);
      }
    }
  }
  createSimple(active) {
    this.coef.countNameElement++;
    this.drawer_group = new Konva.Group({
      x: 400,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.drawer,
        subId: this.subId
      },
      bind: [],
    });

    const drawerRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 35,
      name: 'width',
      fill: this.coef.color,
    });
    const drawerCenter = new Konva.Rect({
      x: (75 / 2) - 16 / 2,
      y: (35 / 2) - 5 / 2,
      width: 16,
      height: 5,
      name: 'width',
      fill: this.coef.silverColor,
    });
    this.drawer_group.add(drawerRect);
    this.drawer_group.add(drawerCenter);
    this.dragEnd(this.drawer_group, this.coef.product, (pos, xy) => {
      this.createSimpleApi(pos);
    });
  }

  createSimpleApi(pos, heightDrawer?, neo?) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.drawer_group, this.coef.shapesLayerw);
    if (contours) {
      const isOnWhat = this.isOnWhat(pos, this.drawer_group, this.coef.shapesLayerw);
      const lastDrawer = {bottom: null};
      if (isOnWhat) {
        if (this.getlastdrawer(isOnWhat, lastDrawer)) {
          contours['top'] = this.getlastdrawer(isOnWhat, lastDrawer);
        }
      }
      let height = this.coef.drawer.drawHeight;
      if (heightDrawer) {
        height = heightDrawer;
      }
      const globX = contours['left'].attrs.x + contours['left'].attrs.width;
      const globY = contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height);
      let removable = true;
      if (neo) {
        removable = false;
      }
      if (this.blockDrawerCreate(contours, pos, neo)) {
        const width = contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width;
        const drawer_group_create = new Konva.Group({
          x: globX,
          y: globY,
          name: 'element' + this.coef.countNameElement,
          width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
          params: {
            removable: removable,
            type: this.coef.id_element.drawer,
            height: height,
            subId: this.subId,
            touches: 'noTouched',
            neo: neo ? neo : false,
            innerSection: neo ? neo : false
          },
          bind: [],
        });

        const drawerRectCreate = new Konva.Rect({
          x: 0,
          y: 0,
          width: drawer_group_create['attrs'].width,
          height: height,
          name: 'width',
          fill: neo ? this.coef.colorFacad : this.coef.color,
          strokeWidth: 1,
          stroke: 'black',
          params: {
            facad: neo ? neo : false,
          },
          dash: []
        });
        const drawerCenterCreate = new Konva.Rect({
          x: (contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 2 - 16 / 2,
          y: (height / 2) - 5 / 2,
          width: 16,
          height: 5,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
        });
        const drawerCenterCreate3 = new Konva.Rect({
          x: (((contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 3) * 2) - 16 / 2,
          y: (height / 2) - 5 / 2,
          width: 16,
          height: 5,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
        });
        const drawerCenterCreate4 = new Konva.Rect({
          x: (((contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 3) * 2) - 16 / 2,
          y: (height / 2) - 5 / 2,
          width: 16,
          height: 5,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
          visible: false,
        });
        const drawerCenterCreate2 = new Konva.Rect({
          x: (contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 3 - 16 / 2,
          y: (height / 2) - 5 / 2,
          width: 16,
          height: 5,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
        });

        this.create.bindingTocounters(
          contours['left'],
          contours['right'],
          contours['top'],
          contours['bottom'],
          drawer_group_create,
          'horizon',
        );
        drawer_group_create.add(drawerRectCreate);
        if (width * this.coef.global_coef >= this.coef.drawer.secondSeparattor) {
          drawer_group_create.add(drawerCenterCreate2);
          drawer_group_create.add(drawerCenterCreate3);
        } else {
          drawer_group_create.add(drawerCenterCreate);
          drawer_group_create.add(drawerCenterCreate4);
        }
        this.coef.shapesLayerw.add(drawer_group_create);
        this.coef.shapesLayerw.batchDraw();
        this.create.priceSend();
        if (heightDrawer) {
          drawer_group_create['attrs'].params.touches = 'Touched';
        }
        this.resizeDraw(drawer_group_create, contours, pos);
      }
    }
  }

  resizeDraw(drawer_group_create, contours, pos) {
    let sideSpace =  this.coef.drawer.sideSpace;
    if(drawer_group_create.attrs.params.subId == 26 || drawer_group_create.attrs.params.subId == 27) {
      sideSpace = this.coef.drawer.sideSpaceSpec
    }
    const globX = contours['left'].attrs.x + contours['left'].attrs.width;
    const globY = contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height);
    let size;
    if (contours['top'].attrs.params.type === this.coef.id_element.shelf) {
      size = new Sizes(this.coef, this.create).createOneSizeVertical(contours, drawer_group_create, true);
      drawer_group_create.add(size);
    }
    const allContour = this.getContourShalf({evt: {layerX: globX, layerY: globY}}, drawer_group_create);
    if (!allContour.contoursShalf['top'].attrs.params['drawerArray']) {
      allContour.contoursShalf['top'].attrs.params['drawerArray'] = [];
    }
    allContour.contoursShalf['top'].attrs.params['drawerArray'].push(drawer_group_create);
    this.drawerAdd({
      evt: {
        layerX: globX,
        layerY: globY
      }
    }, drawer_group_create, allContour.contoursShalf['bottom'], allContour.contoursShalf['top']);
    const countDrawerNow = {count: 1};
    this.countTopDrawer(pos, drawer_group_create, countDrawerNow);
    countDrawerNow.count = this.countDrawerFromX(allContour.contoursShalf['top'], drawer_group_create['attrs'].x);
    if (contours['top'].attrs.params.type === this.coef.id_element.shelf) {
      contours['top'].on('dragmove', () => {
        this.resize(contours['top'], drawer_group_create['attrs'].params.height, size , sideSpace);
      });
    }
    if (drawer_group_create['attrs'].params.touches === 'Touched') {
      allContour.contoursShalf['top'].on('dragmove', () => {
        if (allContour.contoursShalf['top'].attrs.params.drawer) {
        
          this.resizeDrawerAll({
            evt: {
              layerX: globX,
              layerY: globY
            }
          }, drawer_group_create, allContour.contoursShalf['top'], countDrawerNow.count, allContour.contoursShalf['bottom']);
        }
      });
    }
  }

  createSimpleLock(active) {
    this.coef.countNameElement++;
    this.drawer_group = new Konva.Group({
      x: 400,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.drawer,
        subId: this.subId
      },
      bind: [],
    });
   
    const drawerRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 35,
      name: 'width',
      fill: this.coef.color,
    });
    const drawerCenter = new Konva.Rect({
      x: (75 / 2) - 16 / 2,
      y: (35 / 2) - 5 / 2,
      width: 16,
      height: 5,
      name: 'width',
      fill: this.coef.silverColor,
    });
    const lock = this.createLockDesigne();
    this.drawer_group.add(drawerRect);
    this.drawer_group.add(drawerCenter);
    this.drawer_group.add(lock[0]);
    this.drawer_group.add(lock[1]);
    this.dragEnd(this.drawer_group, this.coef.product, (pos, xy) => {
      this.createSimpleLockApi(pos);
    });
  }

  createSimpleLockApi(pos, heightDrawer?) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.drawer_group, this.coef.shapesLayerw);
    const isOnWhat = this.isOnWhat(pos, this.drawer_group, this.coef.shapesLayerw);
    const lastDrawer = {bottom: null};
    if (isOnWhat) {
      if (this.getlastdrawer(isOnWhat, lastDrawer)) {
        contours['top'] = this.getlastdrawer(isOnWhat, lastDrawer);
      }
    }
    let height = this.coef.drawer.drawHeight;
    if (heightDrawer) {
      height = heightDrawer;
    }
    if (this.blockDrawerCreate(contours, pos)) {
      const width = contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width;
      const drawer_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        params: {
          removable: true,
          type: this.coef.id_element.drawer,
          height: height,
          subId: this.subId
        },
        bind: [],
      });
      const drawerRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: drawer_group_create['attrs'].width,
        height: height,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      const drawerCenterCreate = new Konva.Rect({
        x: (contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 2 - 16 / 2,
        y: (height / 2) - 5 / 2,
        width: 16,
        height: 5,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const drawerCenterCreate3 = new Konva.Rect({
        x: (((contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 3) * 2) - 16 / 2,
        y: (height / 2) - 5 / 2,
        width: 16,
        height: 5,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const drawerCenterCreate4 = new Konva.Rect({
        x: (((contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 3) * 2) - 16 / 2,
        y: (height / 2) - 5 / 2,
        width: 16,
        height: 5,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
        visible: false,
      });

      const drawerCenterCreate2 = new Konva.Rect({
        x: (contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 3 - 16 / 2,
        y: (height / 2) - 5 / 2,
        width: 16,
        height: 5,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        drawer_group_create,
        'horizon',
      );
      let lock = this.createLockDesigne();
      drawer_group_create.add(drawerRectCreate);
      if (width * this.coef.global_coef >= this.coef.drawer.secondSeparattor) {
        drawer_group_create.add(drawerCenterCreate2);
        drawer_group_create.add(drawerCenterCreate3);
      } else {
        drawer_group_create.add(drawerCenterCreate);
        drawer_group_create.add(drawerCenterCreate4);
      }
      drawer_group_create.add(lock[0]);
      drawer_group_create.add(lock[1]);
      this.coef.shapesLayerw.add(drawer_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
      if (heightDrawer) {
        drawer_group_create['attrs'].params.touches = 'Touched';
      }
      this.resizeDraw(drawer_group_create, contours, pos);
    }
  }

  createWithLength(active) {
    this.coef.countNameElement++;
    this.drawer_group = new Konva.Group({
      x: 400,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.drawer,
        subId: this.subId
      },
      bind: [],
    });
    const drawerRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 35,
      name: 'width',
      fill: this.coef.color,
    });
    const drawerCenter = new Konva.Rect({
      x: (75 / 2) - 16 / 2,
      y: (35 / 2) - 5 / 2,
      width: 16,
      height: 5,
      name: 'width',
      fill: this.coef.silverColor,
    });
    this.drawer_group.add(drawerRect);
    this.drawer_group.add(drawerCenter);

    this.dragEnd(this.drawer_group, this.coef.product, (pos) => {
      this.createWithLengthApi(pos);
    });
  }

  createWithLengthApi(pos, heightDrawer? , neo?) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.drawer_group, this.coef.shapesLayerw);
    const isOnWhat = this.isOnWhat(pos, this.drawer_group, this.coef.shapesLayerw);
    const lastDrawer = {bottom: null};
    if (isOnWhat) {
      if (this.getlastdrawer(isOnWhat, lastDrawer)) {
        contours['top'] = this.getlastdrawer(isOnWhat, lastDrawer);
      }
    }
    let height = this.coef.drawer.drawHeight;
    if (heightDrawer) {
      height = heightDrawer;
    }
    let removable = true;
      if (neo) {
        removable = false;
      }
    if (this.blockDrawerCreate(contours, pos , neo)) {
      const width = contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width;
      const drawer_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        params: {
          removable: removable,
          type: this.coef.id_element.drawer,
          height: height,
          subId: this.subId,
          touches: 'noTouched',
          neo: neo ? neo : false,
          innerSection: neo ? neo : false
        },
        bind: [],
      });
      const drawerRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: drawer_group_create['attrs'].width,
        height: height,
        params: {
          facad: neo ? neo : false,
        },
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
       const drawerCenterCreate = new Konva.Rect({
          x: (contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 2 - 16 / 2,
          y: (height / 2) - 5 / 2,
          width: 16,
          height: 5,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
        });
        const drawerCenterCreate3 = new Konva.Rect({
          x: (((contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 3) * 2) - 16 / 2,
          y: (height / 2) - 5 / 2,
          width: 16,
          height: 5,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
        });
        const drawerCenterCreate4 = new Konva.Rect({
          x: (((contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 3) * 2) - 16 / 2,
          y: (height / 2) - 5 / 2,
          width: 16,
          height: 5,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
          visible: false,
        });
        const drawerCenterCreate2 = new Konva.Rect({
          x: (contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 3 - 16 / 2,
          y: (height / 2) - 5 / 2,
          width: 16,
          height: 5,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
        });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        drawer_group_create,
        'horizon',
      );
      const drawerText= new Konva.Text({
        x: (contours['right'].attrs.x - contours['left'].attrs.x ) - 20,
        y: 10,
        text: 'Д',
        fontSize: 15,
        fontFamily: 'Calibri',
        fill: 'black'
      });
      drawer_group_create.add(drawerRectCreate);
      if (width * this.coef.global_coef >= this.coef.drawer.secondSeparattor) {
        drawer_group_create.add(drawerCenterCreate2);
        drawer_group_create.add(drawerCenterCreate3);
      } else {
        drawer_group_create.add(drawerCenterCreate);
        drawer_group_create.add(drawerCenterCreate4);
      }
      drawer_group_create.add(drawerText)
      this.coef.shapesLayerw.add(drawer_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
      if (heightDrawer) {
        drawer_group_create['attrs'].params.touches = 'Touched';
      }
      this.resizeDraw(drawer_group_create, contours, pos);
    }
  }

  createWithLengthLock(active) {
    this.coef.countNameElement++;
    this.drawer_group = new Konva.Group({
      x: 400,
      y: -500,
      width: 75,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.drawer,
        subId: this.subId
      },
      bind: [],
    });
    const drawerRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 75,
      height: 35,
      name: 'width',
      fill: this.coef.color,
    });
    const lock = this.createLockDesigne();
    this.drawer_group.add(drawerRect);
    this.drawer_group.add(lock[0]);
    this.drawer_group.add(lock[1]);
    this.dragEnd(this.drawer_group, this.coef.product, (pos, xy) => {
      this.createWithLengthLockApi(pos);
    });
  }

  createWithLengthLockApi(pos, heightDrawer?) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.drawer_group, this.coef.shapesLayerw);
    const isOnWhat = this.isOnWhat(pos, this.drawer_group, this.coef.shapesLayerw);
    const lastDrawer = {bottom: null};
    if (isOnWhat) {
      if (this.getlastdrawer(isOnWhat, lastDrawer)) {
        contours['top'] = this.getlastdrawer(isOnWhat, lastDrawer);
      }
    }
    let height = this.coef.drawer.drawHeight;
    if (heightDrawer) {
      height = heightDrawer;
    }
    if (this.blockDrawerCreate(contours, pos)) {
      const width = contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width;
      const drawer_group_create = new Konva.Group({
        x: contours['left'].attrs.x + contours['left'].attrs.width,
        y: contours['top'].attrs.y + (contours['top'].attrs.height ? contours['top'].attrs.height : contours['top'].attrs.params.height),
        name: 'element' + this.coef.countNameElement,
        width: contours['right'].attrs.x - contours['left'].attrs.x - contours['left'].attrs.width,
        params: {
          removable: true,
          type: this.coef.id_element.drawer,
          height: height,
          subId: this.subId
        },
        bind: [],
      });
      const drawerRectCreate = new Konva.Rect({
        x: 0,
        y: 0,
        width: drawer_group_create['attrs'].width,
        height: height,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      const drawerCenterCreate = new Konva.Rect({
        x: (contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 2 - 16 / 2,
        y: (height / 2) - 5 / 2,
        width: 16,
        height: 5,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const drawerCenterCreate3 = new Konva.Rect({
        x: (((contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 3) * 2) - 16 / 2,
        y: (height / 2) - 5 / 2,
        width: 16,
        height: 5,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      const drawerCenterCreate4 = new Konva.Rect({
        x: (((contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 3) * 2) - 16 / 2,
        y: (height / 2) - 5 / 2,
        width: 16,
        height: 5,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
        visible: false,
      });
      const drawerCenterCreate2 = new Konva.Rect({
        x: (contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / 3 - 16 / 2,
        y: (height / 2) - 5 / 2,
        width: 16,
        height: 5,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        contours['top'],
        contours['bottom'],
        drawer_group_create,
        'horizon',
      );
      const drawerText= new Konva.Text({
        x: (contours['right'].attrs.x - contours['left'].attrs.x ) - 20,
        y: 10,
        text: 'Д',
        fontSize: 15,
        fontFamily: 'Calibri',
        fill: 'black'
      });
      let lock = this.createLockDesigne();
      drawer_group_create.add(drawerRectCreate);
      if (width * this.coef.global_coef >= this.coef.drawer.secondSeparattor) {
        drawer_group_create.add(drawerCenterCreate2);
        drawer_group_create.add(drawerCenterCreate3);
      } else {
        drawer_group_create.add(drawerCenterCreate);
        drawer_group_create.add(drawerCenterCreate4);
      }
      drawer_group_create.add(drawerText)
      drawer_group_create.add(lock[0]);
      drawer_group_create.add(lock[1]);
      this.coef.shapesLayerw.add(drawer_group_create);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
      if (heightDrawer) {
        drawer_group_create['attrs'].params.touches = 'Touched';
      }
      this.resizeDraw(drawer_group_create, contours, pos);
    }
  }
 

  blockDrawerCreate(contours, pos, id?) {
    console.log(contours , 'contours')
    return contours &&
      this.topBottomTangent(contours, true) &&
      this.blockDifDawer(contours['top'] ,this.subId)&&
      this.minTopLimitDrawer(contours['top']) &&
      this.chachWidthDrawer(contours, pos, id) &&
      this.chechLeftRight(contours) && this.stopCreateOnLevel(contours) &&
      this.coef.blockDrawerCentr(pos, contours);
  }
}
