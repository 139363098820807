import {BlockCreate} from '../block-create';
import * as Konva from '../../../assets/konva';
import {Sizes} from '../sizes/sizes';
import {Depth} from '../depth';

export class Penal extends BlockCreate {
  public startPosition;
  public coef;
  public create;
  comod_group;
  stage;
  layer;
  e;
  constructor(coef, create, stage, layer ,e) {
    super(coef, create);
    this.e = e;
    this.coef = coef;
    this.create = create;
    this.stage = stage;
    this.layer = layer;
  }

  createPenal(setings, size) {
    const id = this.findType(size.init_types);
    if (id) {
      switch (id) {
        case 7: {
          const center = (this.coef.widthWrapper - this.cof(size.width)) / 2;
          this.createPenalTypeFirst(size, center);
          break;
        }
        case 8: {
          let center = (this.coef.widthWrapper - this.cof(size.width)) / 2;
          this.createPenalTypeFirst(size, center);
          center = (this.coef.widthWrapper - this.cof(size.width)) / 2 - this.cof(size.width) - 100;
          this.createPenalTypeFirstDoor(size, center);
          break;
        }
        case 9: {
          let center = (this.coef.widthWrapper - this.cof(size.width)) / 2;
          this.createPenalTypeFirst(size, center);
          center = (this.coef.widthWrapper - this.cof(size.width)) / 2 - this.cof(size.width) - 100;
          this.createPenalTypeFirstDoorDubl(size, center);

          break;
        }
        case 10: {
          let center = (this.coef.widthWrapper - this.cof(size.width)) / 2;
          this.createPenalTypeFirst(size, center);
          this.createPenalTypeFirstDoorBot(size, center);
          break;
        }
        case 11: {
          let center = (this.coef.widthWrapper - this.cof(size.width)) / 2;
          this.createPenalTypeFirst(size, center);
          center = (this.coef.widthWrapper - this.cof(size.width)) / 2 - this.cof(size.width) - 100;
          this.createPenalTypeFirstDoorBot(size, center);
          this.createPenalTypeFirstDooTop(size, center);
          break;
        }
      }
      const depth = new Depth(this.coef);
      depth.createDepth(setings.height, this.layer, this.stage);
    }
  }


  position(height) {
    const size = height - (this.coef.global_size_flat * this.coef.global_coef * 4);
    const onePosition = Math.floor(size / 5);
    return onePosition / this.coef.global_coef;
  }
  isBase(array) {
    for (const item of array) {
      if (item.id === 6 && item.value === 33) {
        return true;
      }
    }
    return false;
  }
  createPenalTypeFirst(size, center) {
    const heightOneShelf = this.position((this.cof(size.height) - this.coef.height_legs - this.coef.global_size_flat * 2) * this.coef.global_coef);
    const centerPosition = center;
    const base = this.isBase(this.e.init_types);
    this.coef.countNameElement++;

    this.comod_group = new Konva.Group({
      x: centerPosition,
      y: this.coef.topPositaionWrapper,
      width: this.cof(size.width),
      draggable: false,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: false,
      },
      bind: [],
    });
    const top = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.cof(size.width),
      height: this.coef.global_size_flat,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    let heightLeftRight = this.cof(size.height) - this.coef.global_size_flat * 2 - this.coef.height_legs
    let widthBase = this.cof(size.width);
    let xBottom = 0;
    if (base) {
      widthBase = this.cof(size.width) - this.coef.global_size_flat * 2;
      xBottom = this.coef.global_size_flat;
      heightLeftRight = this.cof(size.height) - this.coef.global_size_flat;
    }
    const bottom = new Konva.Rect({
      x: xBottom,
      y: this.cof(size.height) - this.coef.global_size_flat - this.coef.height_legs,
      width: widthBase,
      height: this.coef.global_size_flat,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    const left = new Konva.Rect({
      x: 0,
      y: this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: heightLeftRight,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    const right = new Konva.Rect({
      x: this.cof(size.width) - this.coef.global_size_flat,
      y: this.coef.global_size_flat,
      width: this.coef.global_size_flat,
      height: heightLeftRight,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    const cocol = new Konva.Rect({
      x: xBottom,
      y: this.cof(size.height) - this.coef.height_legs,
      width: widthBase,
      height: this.coef.height_legs,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    this.comod_group.add(top, bottom, left, right, cocol);
    let firstY = this.coef.global_size_flat;
    for (let i = 1; i < 5; i++) {
      let dash = [10, 5];
      if (i == 3) {
        dash = [];
      }
      const rect = new Konva.Rect({
        x: this.coef.global_size_flat,
        y: firstY + heightOneShelf * i + (this.coef.global_size_flat * (i - 1)),
        width: this.cof(size.width) - this.coef.global_size_flat * 2,
        height: this.coef.global_size_flat,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        dash: dash,
        stroke: 'black',
      });
      this.comod_group.add(rect);
    }
    const sizes = new Sizes(this.coef, this.create).createOneSizeVerticalPanal({
      top: this.comod_group.children[this.comod_group.children.length - 1],
      bottom: bottom
    }, bottom);
    const sizesss = new Sizes(this.coef, this.create).createOneSizeVerticalPanal({
      top: this.comod_group.children[this.comod_group.children.length - 2],
      bottom: this.comod_group.children[this.comod_group.children.length - 1]
    }, bottom);
    const sizess = new Sizes(this.coef, this.create).createOneSizeVerticalPanal({
      top: this.comod_group.children[this.comod_group.children.length - 3],
      bottom: this.comod_group.children[this.comod_group.children.length - 2]
    }, bottom);
    const sizessss = new Sizes(this.coef, this.create).createOneSizeVerticalPanal({
      top: this.comod_group.children[this.comod_group.children.length - 4],
      bottom: this.comod_group.children[this.comod_group.children.length - 3]
    }, bottom);
    const sizesssss = new Sizes(this.coef, this.create).createOneSizeVerticalPanal({
      top: top,
      bottom: this.comod_group.children[this.comod_group.children.length - 4]
    }, bottom);
    this.comod_group.add(sizesssss);
    this.comod_group.add(sizessss);
    this.comod_group.add(sizesss);
    this.comod_group.add(sizess);
    this.comod_group.add(sizes);
    this.layer.add(this.comod_group);
    this.stage.add(this.layer);
    console.log(this.cof(size.width), centerPosition, centerPosition, 'centerPosition');
    new Sizes(this.coef, this.create).globalSize(
      {
        top: this.coef.topPositaionWrapper,
        left: centerPosition,
        width: this.cof(size.width)
      }, {
        top: this.coef.topPositaionWrapper,
        right: centerPosition + this.cof(size.width) - 100,
        height: this.cof(size.height)
      });
  }

  createPenalTypeFirstDoor(size, center) {
    const heightOneShelf = this.position((this.cof(size.height) - this.coef.height_legs - this.coef.global_size_flat * 2) * this.coef.global_coef);
    const centerPosition = center;
    this.coef.countNameElement++;
    this.comod_group = new Konva.Group({
      x: centerPosition,
      y: this.coef.topPositaionWrapper,
      width: this.cof(size.width),
      draggable: false,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: false,
      },
      bind: [],
    });
    const door = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.cof(size.width),
      height: this.cof(size.height) - this.coef.height_legs,
      name: 'width',
      fill: this.coef.colorFacad,
      params: {
        facad: true
      },
      strokeWidth: 1,
      stroke: 'black',
    });
    const handle = new Konva.Rect({
      x: this.cof(size.width) - 16,
      y: this.cof(size.height) - this.cof(size.height) / 2 - 10,
      width: 10,
      height: 20,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: 'black',
    });
    const cocol = new Konva.Rect({
      x: 0,
      y: this.cof(size.height) - this.coef.height_legs,
      width: this.cof(size.width),
      height: this.coef.height_legs,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    this.comod_group.add(door, cocol, handle);
    this.layer.add(this.comod_group);
    this.stage.add(this.layer);
  }

  createPenalTypeFirstDoorDubl(size, center) {
    const centerPosition = center;
    this.coef.countNameElement++;
    this.comod_group = new Konva.Group({
      x: centerPosition,
      y: this.coef.topPositaionWrapper,
      width: this.cof(size.width),
      draggable: false,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: false,
      },
      bind: [],
    });
    const door = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.cof(size.width) / 2,
      height: this.cof(size.height) - this.coef.height_legs,
      name: 'width',
      fill: this.coef.colorFacad,
      params: {
        facad: true
      },
      strokeWidth: 1,
      stroke: 'black',
    });
    const handle = new Konva.Rect({
      x: this.cof(size.width) / 2 - 16,
      y: this.cof(size.height) - this.cof(size.height) / 2 - 10,
      width: 10,
      height: 20,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: 'black',
    });
    const door2 = new Konva.Rect({
      x: this.cof(size.width) / 2,
      y: 0,
      width: this.cof(size.width) / 2,
      height: this.cof(size.height) - this.coef.height_legs,
      name: 'width',
      fill: this.coef.colorFacad,
      params: {
        facad: true
      },
      strokeWidth: 1,
      stroke: 'black',
    });
    const handle2 = new Konva.Rect({
      x: this.cof(size.width) / 2 + 4,
      y: this.cof(size.height) - this.cof(size.height) / 2 - 10,
      width: 10,
      height: 20,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: 'black',
    });
    const cocol = new Konva.Rect({
      x: 0,
      y: this.cof(size.height) - this.coef.height_legs,
      width: this.cof(size.width),
      height: this.coef.height_legs,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    this.comod_group.add(door, cocol, handle, door2, handle2);
    this.layer.add(this.comod_group);
    this.stage.add(this.layer);
  }

  createPenalTypeFirstDoorBot(size, center) {
    const heightOneShelf = this.position((this.cof(size.height) - this.coef.height_legs - this.coef.global_size_flat * 2) * this.coef.global_coef);
    const centerPosition = center;
    this.coef.countNameElement++;
    this.comod_group = new Konva.Group({
      x: centerPosition,
      y: this.coef.topPositaionWrapper,
      width: this.cof(size.width),
      draggable: false,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: false,
      },
      bind: [],
    });
    const door = new Konva.Rect({
      x: 0,
      y: this.cof(size.height) - this.coef.height_legs - heightOneShelf * 2 - this.coef.global_size_flat * 2.5,
      width: this.cof(size.width),
      height: heightOneShelf * 2 + this.coef.global_size_flat * 2.5,
      name: 'width',
      fill: this.coef.colorFacad,
      params: {
        facad: true
      },
      strokeWidth: 1,
      stroke: 'black',
    });
    const handle = new Konva.Rect({
      x: this.cof(size.width) - 16,
      y: (this.cof(size.height) - this.coef.height_legs - heightOneShelf * 2 - this.coef.global_size_flat * 2.5) + (heightOneShelf * 2 + this.coef.global_size_flat * 2.5) / 2 - 10,
      width: 10,
      height: 20,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: 'black',
    });
    // const cocol = new Konva.Rect({
    //   x: 0,
    //   y: this.cof(size.height) - this.coef.height_legs,
    //   width: this.cof(size.width),
    //   height: this.coef.height_legs,
    //   name: 'width',
    //   fill: this.coef.color,
    //   strokeWidth: 1,
    //   stroke: 'black',
    // });
    this.comod_group.add(door, handle);
    this.layer.add(this.comod_group);
    this.stage.add(this.layer);
  }

  createPenalTypeFirstDooTop(size, center) {
    const heightOneShelf = this.position((this.cof(size.height) - this.coef.height_legs - this.coef.global_size_flat * 2) * this.coef.global_coef);
    const centerPosition = center;
    this.coef.countNameElement++;
    this.comod_group = new Konva.Group({
      x: centerPosition,
      y: this.coef.topPositaionWrapper,
      width: this.cof(size.width),
      draggable: false,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: false,
      },
      bind: [],
    });
    const door = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.cof(size.width),
      height: heightOneShelf * 3 + this.coef.global_size_flat * 3.5,
      name: 'width',
      fill: this.coef.colorFacad,
      params: {
        facad: true
      },
      strokeWidth: 1,
      stroke: 'black',
    });
    const handle = new Konva.Rect({
      x: this.cof(size.width) - 16,
      y: (heightOneShelf * 3 + this.coef.global_size_flat * 3.5) / 2 - 10,
      width: 10,
      height: 20,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: 'black',
    });
    const cocol = new Konva.Rect({
      x: 0,
      y: this.cof(size.height) - this.coef.height_legs,
      width: this.cof(size.width),
      height: this.coef.height_legs,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    this.comod_group.add(door, cocol, handle);
    this.layer.add(this.comod_group);
    this.stage.add(this.layer);
  }
}
