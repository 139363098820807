import { Component, OnInit } from '@angular/core';
import {CoefService} from '../../service/coef.service';

@Component({
  selector: 'app-door-right-lock-d',
  templateUrl: './door-right-lock-d.component.html',
  styleUrls: ['./door-right-lock-d.component.scss']
})
export class DoorRightLockDComponent implements OnInit {

  constructor(public coef: CoefService) { }

  ngOnInit() {
  }

}
