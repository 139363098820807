export const API = {

  //RESTORE PASS
  RESTORE_PASS: 'pass-restore',

  //GET USER
  GET_USER: 'get-user',

  CHANGE_INFO: 'change-info',

  CHANGE_PASSWORD: 'change-password'

};

