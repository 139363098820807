import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoefService } from '../../service/coef.service';
import { Subscription } from 'rxjs';
import { baseUrl, PAGE } from '../../router.path';
import { HttpClient } from '@angular/common/http';
import { cloneDeep } from 'lodash';
import { API } from '../../common/api';
import { NotificationService } from '../../service/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-custom-order',
  templateUrl: './custom-order.component.html',
  styleUrls: ['./custom-order.component.scss']
})
export class CustomOrderComponent implements OnInit {
  registerForm: FormGroup;
  baseUrl = baseUrl;
  border = '0.1rem solid black';
  bigBorder = '0.5rem solid black';
  midBorder = '0.3rem solid black';
  order = [];
  materialArray = [];
  img = '';
  client;
  price;
  comment = '';
  customer = '';
  edits = {
    type: false,
    index: 0
  };
  color = [];
  defColor = null;
  user = [];
  material = [];
  partsSelector = [];
  flat = [];
  settings;
  disabled = true;
  manager = false;
  subscription = new Subscription();
  editType = false;
  oldPhoto = true;
  paramsRender;
  createShab = false;
  error = '';
  isClientPage = false;
  type_parts = '';


  sendObject = {
    user: null,
    title: null,
    carcassColorId: null,
    percentages: {
      creator: 0,
      frame: 0,
      door: 0,
      company: 0,
    },
    systemColorId: null,
    image: '',
    facadeParts: [],
    parts: [],
    comment: ''

  };

  materialObject = {
    door: null,
    section: null,
    height: null,
    width: null,
    type: null,
    material: null,
    facade_part: true,
    facade_path: null,
    custom_path:null,
    imageIndex: null,
    printOption:null,
    name: '',
    count: 1,
    edit: null
  };
  facade_parts = {};
  colorSystem = [];
  materialTypes = [];
  materials = {};
  validFirstStep = true;
  validFirstStepCustom: any = {
    frame: false,
    door: false,
    company: false,
    creator: false,
  }
  openMat = false;
  openMatCustom = false;
  typeNames = {};
  materialNames = {};
  nameCustom = '';
  customNames = {}
  pen = [];
  typePrint = {}
  part = {
    id: null,
    width: null,
    height: null,
    quantity: null,
    сrCode: null,
    count: 1,
    color: null,
    handlerId: null,
    facade: null
  };
  handlerSelectors = [];

  constructor(private formBuilder: FormBuilder,
    private coef: CoefService,
    private http: HttpClient,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private router: Router) {
    const isClientPage = this.router.url.indexOf('calculator?') !== -1;
    if (!isClientPage) {
      this.getUser();
    }
    this.http.get(baseUrl.api + 'get-ot-info/16').subscribe((res: any) => {
      if (res.success) {
        this.settings = res.payload;
        this.sendObject.percentages.frame = this.settings.frame_work_percentage;
        this.sendObject.percentages.company = this.settings.company_percentage;
        this.sendObject.percentages.creator = this.settings.spec_values.tech_min_limit;
        this.sendObject.percentages.door = this.settings.door_work_percentage;
        console.warn(this.settings);
        if (res.payload.colors) {
          this.color = this.creatArrayColor(res.payload.colors);
          const item = res.payload.colors.find(i => i.is_default)
          if (item) {
            this.defColor = String(item.id)
            console.warn(this.defColor, 'defColor');
            this.sendObject.carcassColorId = this.defColor;
          }
        }
        if (this.registerForm) {
          this.addValid();
        }
      }
    });
    this.http.get(baseUrl.api + 'handler-selector').subscribe((res: any) => {
      console.warn(res, 'handler-selector');
      if (res.success) {
        if (res.payload) {
          for (const item of res.payload) {
            const newItem = item;
            newItem.label = item.name;
            newItem.value = item.id;
            this.handlerSelectors.push(item);
          }
        }
      }
    });

    this.http.get(baseUrl.api + 'material-selector').subscribe((res: any) => {

      if (res.success) {
        if (res.payload) {
          this.material = res.payload;
          for (const item of res.payload) {
            if (item.active && item.id != 9) {
              item.label = item.name;
              item.value = +item.id;
              if(item.id  != 5){
                this.materialTypes.push(item);
              }
              
              if (item.option && item.option.name && item.option.id) {
                item.option.label = item.option.name;
                item.option.value = item.option.id;

              }
              this.materials[item.id] = item.options;
              this.typeNames[item.id] = item.name;
              this.typePrint[item.id] = item.print_allowed;
              for (const itemOpt of item.options) {
                this.materialNames[itemOpt.id] = itemOpt.name;
              }
            }
            if(item.id == 5){
              this.nameCustom = item.name;
            }
            if (item.id == 9) {
              this.colorSystem = item.options;
              this.colorSystem.map(color => {
                color.label = { name: color.name, rgb: color.rgb };
                color.value = color.id;
                if (color.is_default) {
                  this.sendObject.systemColorId = color.id;
                }
                return color;
              });

            }
          }
          this.sendObject.carcassColorId = this.defColor;
          console.warn(this.materialTypes, this.materials, this.colorSystem);
        }
      }

    });
    this.http.get(baseUrl.api + 'parts-selector').subscribe((res: any) => {
      if (res.success) {
        this.flat = this.creatArrayFlat(res.payload);
        this.partsSelector = res.payload;
        for (const part of res.payload) {
          this.facade_parts[part.id] = part.facade_part;
        }
        console.warn(this.material);
      }
    });

    this.http.get(baseUrl.api + 'user-selector').subscribe((res: any) => {
      if (res.success) {
        this.user = this.creatArrayUser(res.payload);
        console.warn(this.user);
      }
    });

    this.oldObject();
  }


  addValidePercentage() {
    this.validFirstStep = validFirstStage(this.sendObject, this.settings);
    this.validFirstStepCustom = validFirstStageCustom(this.sendObject, this.settings)
    console.warn(this.validFirstStep, this.validFirstStepCustom, 'this.validFirstStep');
  }

  changeType() {
    this.materialObject.material = null;
    this.materialObject.facade_path = null;
    this.materialObject.custom_path = null;
    this.materialObject.printOption = null;
    // this.materialObject.typeName = ;
  }

  handleMat(id, path, rgb, name, img_spec_id = null) {
    this.materialObject.material = id;
    this.materialObject.name = name;
    this.materialObject.imageIndex = img_spec_id;
    if (path) {
      this.materialObject.facade_path = path;
    } else {
      this.materialObject.facade_path = rgb;
    }
    this.openMat = false;
  }
  handleMatCustom(id, path, rgb, name, img_spec_id = null){
    this.openMatCustom = false;
    this.materialObject.imageIndex = img_spec_id;
    this.materialObject.custom_path = path;
    this.materialObject.printOption = id;
  }

  openModalMat() {
    this.openMat = true;
  }
  openModalMatCustom() {
    this.openMatCustom = true;
  }

  validFirstTab() {

  }

  closeEdit(value) {
    this.part = {
      id: null,
      width: null,
      height: null,
      quantity: null,
      сrCode: null,
      color: null,
      count: 1,
      handlerId: null,
      facade: null
    };
    if (value) {
      this.registerForm.reset();
    }

    this.registerForm.controls.count.setValue(1);

    this.edits.type = false;
    this.edits.index = -1;
  }

  addPart() {
    if (this.edits.type) {
      this.order[this.edits.index] = {
        ...this.part,
        id: this.registerForm.controls.name.value,
        facade_part: this.facade_parts[this.registerForm.controls.name.value]
      };
      this.part = {
        id: null,
        width: null,
        height: null,
        quantity: null,
        сrCode: null,
        color: null,
        count: 1,
        handlerId: null,
        facade: null
      };
    } else {
      this.order.unshift({
        ...this.part,
        id: this.registerForm.controls.name.value,
        facade_part: this.facade_parts[this.registerForm.controls.name.value]
      });
      this.part = {
        id: null,
        width: null,
        height: null,
        quantity: null,
        сrCode: null,
        color: null,
        count: 1,
        handlerId: null,
        facade: null
      };
    }
    this.edits.type = false;
    this.edits.index = -1;
    this.getPrice();
    this.registerForm.reset();
    this.findType(this.registerForm.controls.name.value);
  }

  onCheckboxChange(){
      this.registerForm.controls['l'].setValue(true)
      this.registerForm.controls['b'].setValue(true)
      this.registerForm.controls['r'].setValue(true)
      this.registerForm.controls['t'].setValue(true)
    
  }
  addDoor() {
    if (this.materialObject.edit || this.materialObject.edit === 0) {
      this.materialArray[this.materialObject.edit] = this.materialObject;
      this.materialArray[this.materialObject.edit].edit = null;
    } else {
      this.materialArray.unshift(this.materialObject);
    }
    this.materialObject = {
      door: null,
      section: null,
      height: null,
      width: null,
      type: null,
      material: null,
      facade_part: true,
      facade_path: null,
      custom_path:null,
      printOption:null,
      imageIndex: null,
      name: '',
      count: 1,
      edit: null
    };
    this.getPrice();
  }

  repars(item) {
    const obj = { l: false, r: false, b: false, t: false };
    for (const it of item.cr_code) {
      if (it == 'l') {
        obj['l'] = true;
      }
      if (it == 't') {
        obj['t'] = true;
      }
      if (it == 'r') {
        obj['r'] = true;
      }
      if (it == 'b') {
        obj['b'] = true;
      }
    }
    return obj;
  }

  async oldObject() {
    const params = await this.route.queryParams.pipe(first()).toPromise();
    if (params.render) {
      if (params.shab) {
        this.createShab = true;
      }
      this.http.get(baseUrl.api + `/n-order/info/${params.render}`).subscribe(res => {
        this.validFirstStep = false;
        const obj = res['payload'].grouped_items;
        for (const item of obj) {
          if (this.findTypeReturn(item.part_item_id) == 'flat') {
            this.order.push({
              ...this.repars(item),
              name: item.part_item_id,
              width: item.width,
              height: item.height,
              color: item.color_id,
              count: item.quantity
            });
          } else {
            this.order.push({
              id: item.part_item_id,
              width: item.width,
              height: item.height,
              quantity: null,
              сrCode: null,
              color: null,
              count: item.quantity,
              handlerId: item.handler_id,
              facade: item.facade_view
            });
          }

        }
        this.getPrice();
        // this.sendObject.percentages.company = res['payload'].content_image_url;
        // this.sendObject.percentages.company = res['payload'].content_image_url;
        // this.sendObject.percentages.company = res['payload'].content_image_url;
        // this.sendObject.percentages.company = res['payload'].content_image_url;
        this.paramsRender = params.render;
        this.sendObject.user = res['payload'].owner.id;
        this.img = baseUrl.photo + res['payload'].content_image_url;
        this.oldPhoto = res['payload'].content_image_url;
        this.sendObject.comment = res['payload'].admin_comment;
        this.sendObject.systemColorId = res['payload'].constructor_object.system_color_id;
        this.sendObject.title = res['payload'].title;
        this.sendObject.carcassColorId = res['payload'].constructor_object.color_id;
        this.sendObject.percentages.company = JSON.parse(res['payload'].calculator_values.content_object).company;
        this.sendObject.percentages.creator = JSON.parse(res['payload'].calculator_values.content_object).creator;
        this.sendObject.percentages.frame = JSON.parse(res['payload'].calculator_values.content_object).frame;
        this.sendObject.percentages.door = JSON.parse(res['payload'].calculator_values.content_object).door;

        const facade = res['payload'].parsed_facade_items;
        for (const item of facade) {
          this.materialArray.push({
            door: item.door,
            section: item.section,
            height: item.height,
            width: item.width,
            type: item.facade_material_id,
            material: item.facade_material_option_id,
            imageIndex: item.group_image_index,
            facade_part: true,
            printOption:item.print_material_option_id,
            facade_path: this.getPath(item.facade_material_id,item.facade_material_option_id,item.print_material_option_id ? null : item.group_image_index),
            custom_path: this.getPath(5,item.print_material_option_id,item.group_image_index),
            name: '',
            count: 1,
            edit: null
          });
        }
        this.getPrice();
        const mats = res['payload'].grouped_items;
        this.editType = true;
        this.edits.type = false;
        this.edits.index = -1;
      }, err => {
        this.notification.triggerNotification('Невідома помилка. Спробуйте пізніше.', true);
      });
    }
  }

  getPath(type,material,imageIndex){
    console.log(type , material , imageIndex ,  this.material , ' this.material')
      for(const types of this.material){
        if(types.id == type){
          for(const item of types.options){
            if(material == item.id){
              if(imageIndex){
                return item.images[imageIndex].path
              }else{
                console.log(item)
                return item.rgb
              }
            }
          }
        }
      }
      return null;
  }
  private getUser(): void {
    this.http.get(baseUrl.api + API.GET_USER).subscribe(user => {
      this.manager = user['payload'].manager;
    }, err => {
      this.notification.triggerNotification('Невідома помилка. Спробуйте пізніше.', true);
    });
  }


  addValid() {
    this.registerForm.controls.width.setValidators([Validators.min(this.settings.min_width),
    Validators.max(this.settings.max_width), Validators.required]);
    this.registerForm.controls.height.setValidators([Validators.required, Validators.min(this.settings.min_height),
    Validators.max(this.settings.max_height)]);
    this.registerForm.controls.color.setValidators([Validators.required]);
  }

  ngOnInit() {
    const isClientPage = this.router.url.indexOf('calculator') !== -1;
    this.isClientPage = !isClientPage;
    this.registerForm = this.formBuilder.group({
      l: [false],
      b: [false],
      r: [false],
      t: [false],
      name: ['',],
      width: ['',],
      height: ['',],
      color: ['',],
      count: [1]
    });
    // if (this.settings) {
    //   this.addValid();
    // }
    this.registerForm.valueChanges.subscribe(val => {
      this.findType(this.registerForm.controls.name.value);
      const width = this.registerForm.controls.width.value;
      const height = this.registerForm.controls.height.value;
      const color = this.registerForm.controls.color.value;
      if (this.settings) {
        if (width > this.settings.spec_values.less_side_max_size && height > this.settings.spec_values.less_side_max_size && color) {
          this.disabled = true;
          this.error = `Недопустимі розміри деталі, максимальний розмір меншої сторони  - ${this.settings.spec_values.less_side_max_size}мм`;
        } else {
          this.error = '';
          this.disabled = false;
        }
      }
    });
    this.registerForm.controls['name'].valueChanges.subscribe(val => {
      console.warn(val, 'valqweqwe')
      if (this.edits.type) {
        this.closeEdit(val);
        // this.registerForm.controls.color.setValue(this.sendObject.carcassColorId);
      } else {
        if(!this.registerForm.controls.color.value){
          this.registerForm.controls.color.setValue(this.sendObject.carcassColorId);
        }
      }
    });
    this.coef.preloaderUpdate(false);
    this.getPrice();
  }

  findType(id) {
    const item = this.partsSelector.find(flat => flat.id == id);
    if (item) {
      this.type_parts = item.type;
    } else {
      this.type_parts = '';
    }
  }


  findTypeReturn(id) {
    const item = this.partsSelector.find(flat => flat.id == id);
    if (item) {
      return item.type;
    } else {
      return '';
    }
  }

  validSend() {
    let count = 0;
    for (const item of this.order) {
      if (Number(item.count) < 1) {
        return true;
      }
      count = count + Number(item.count);
    }
    if (this.manager && this.settings) {
      if (!this.client || count > this.settings.spec_values.number_details_max || count < this.settings.spec_values.number_details_min) {
        return true;
      }
    }
    if (!this.manager && this.settings) {
      if (count > this.settings.spec_values.number_details_max || count < this.settings.spec_values.number_details_min) {
        return true;
      }
    }
    return false;
  }

  getObjectprice() {
    const array = [];
    for (const item of this.order) {
      array.push({
        id: Number(item.name),
        width: item.width,
        height: item.height,
        color: +item.color,
        quantity: Number(item.count)
      });
    }
    return array;
  }

  getPrice() {
    // const obj = this.getObjectprice();
    // const req = {
    //   parts: obj, user: +this.client
    // };
    // console.warn(req, 'req');
    this.http.post(baseUrl.api + '/n-order/count-price', this.createObjectSend()).subscribe((res: any) => {
      console.warn(res);
      if (res.success) {
        this.price = res.payload;
      }
    });
  }

  getPenName(item) {
    const pen = this.handlerSelectors.find((e) => {
      return e.id == item;
    });
    if (pen && pen.name) {
      return pen.name;
    } else {
      return 'Ручка';

    }

  }

  getName(id) {
    const name = this.flat.find((e) => {
      return e.value == id;
    });
    if (name) {
      return name;
    } else {
      return { label: '' };
    }
  }

  getColor(id) {
    const color = this.color.find((e) => {
      return e.value == id;
    });
    if (color) {
      return color;
    } else {
      return { label: { rgb: 'white', name: '' } };
    }
  }

  creatArrayColor(array) {
    const newArray = [];
    for (const item of array) {
      newArray.push({ label: { rgb: item.rgb, name: item.name }, value: String(item.id) });
    }
    return newArray;
  }

  creatArrayUser(array) {
    const newArray = [];
    for (const item of array) {
      newArray.push({ label: { name: item.name, surname: item.surname, last_name: item.last_name }, value: String(item.id) });
    }
    return newArray;
  }

  creatArrayFlat(array) {
    const newArray = [];
    for (const item of array) {
      newArray.push({ label: item.name, value: String(item.id) });
    }
    return newArray;
  }

  getBase64ImageFromURL(target: any) {
    const file: File = target.files[0];
    const img = new FileReader();
    img.onloadend = () => {
      this.img = img.result;
      this.sendObject.image = img.result;
    };
    img.readAsDataURL(file);
  }

  change(event) {
    this.oldPhoto = false;
    this.getBase64ImageFromURL(event.target);
  }

  edit(index) {
    const item = { ...this.order[index] };
    console.warn(item , 'item.id')
    if (item.id) {
      this.edits.type = true;
      this.edits.index = index;
      this.part = item;
      this.registerForm.controls['name'].setValue(item.id);
      // setTimeout(() => {
      //   this.registerForm.controls['name'].setValue(item.id);
      //   this.registerForm.controls['width'].setValue('500');
      //   item.name = item.id.toString();
      //   delete item.id;
      //   // this.registerForm.setValue(item);
      //   this.edits.type = true;
      //   this.edits.index = index;
      // }, 500);
      // setTimeout(() => {
      //   this.edits.type = true;
      //   this.edits.index = index;
      // }, 600);
    } else {
      this.edits.type = true;
      this.edits.index = index;
      delete item.facade_part;
      this.registerForm.controls['name'].setValue(item.name);
      setTimeout(() => {
        this.registerForm.setValue(item);
        this.edits.type = true;
        this.edits.index = index;
      }, 500);
      setTimeout(() => {
        this.edits.type = true;
        this.edits.index = index;
      }, 600);

    }
  }

  editmatrial(index, item) {
    console.warn(item, 'item');
    this.materialObject = { ...item, edit: index };
    console.warn(this.materialArray, 'mat');
  }

  remove(index) {
    this.order.splice(index, 1);
    this.getPrice();
  }

  removeMaterial(index) {
    this.materialArray.splice(index, 1);
    this.getPrice();
  }

  copy(index) {
    this.order.push(cloneDeep(this.order[index]));
    this.getPrice();
  }

  onSubmit(event) {
    if (this.edits.type) {
      this.order[this.edits.index] = { ...this.registerForm.value, facade_part: this.facade_parts[this.registerForm.controls.name.value] };
      this.registerForm.reset();
      this.registerForm.controls.count.setValue(1);
      this.edits.type = false;
    } else {
      this.order.unshift({ ...this.registerForm.value, facade_part: this.facade_parts[this.registerForm.controls.name.value] });
      this.registerForm.reset();
      this.registerForm.controls.count.setValue(1);
    }
    this.edits.type = false;
    this.edits.index = -1;
    this.getPrice();
    this.findType(this.registerForm.controls.name.value);
  }

  creatCode(item) {
    let code = '';
    if (item.l) {
      code = code + 'l';
    }
    if (item.r) {
      code = code + 'r';
    }
    if (item.t) {
      code = code + 't';
    }
    if (item.b) {
      code = code + 'b';
    }
    return code;
  }

  createObjectStore() {
    const object = {
      parts: [],
      image: null,
      user: null,
      comment: null
    };
    for (const item of this.order) {
      object.parts.push({
        id: +item.name,
        width: item.width,
        crCode: this.creatCode(item),
        height: item.height,
        color: +item.color,
        quantity: Number(item.count)
      });
    }
    if (this.img) {
      object.image = this.img;
    }
    if (this.client) {
      object.user = +this.client;
    }
    if (this.comment) {
      object.comment = this.comment;
    }
    if (this.editType) {
      if (this.oldPhoto) {
        object.image = this.oldPhoto;
        object['existingImage'] = true;
      }
    }
    return object;
  }

  send() {
    const req = this.createObjectStore();
    if (this.editType) {
      if (this.createShab) {
        this.http.post(baseUrl.api + '/n-order/store', req).subscribe((res: any) => {
          console.warn(res);
          if (res.success) {
            this.router.navigate(['order']);
          }
        });
      } else {
        this.http.post(baseUrl.api + `n-order/edit/${this.paramsRender} `, req).subscribe((res: any) => {
          console.warn(res);
          if (res.success) {
            this.router.navigate(['order']);
          }
        });
      }

    } else {
      this.http.post(baseUrl.api + '/n-order/store', req).subscribe((res: any) => {
        console.warn(res);
        if (res.success) {
          this.router.navigate(['order']);
        }
      });
    }
  }

  async removeFromOrder() {
    const params = await this.route.queryParams.pipe(first()).toPromise();
    this.http.get(baseUrl.api + `cancel-order/${params.render}`).subscribe(res => {
      if (res['success']) {
        this.notification.triggerNotification(res['payload'], false);
        this.router.navigate([PAGE.ORDER]);
      }
    }, (error) => {
      if (error.status === 401) {
        this.router.navigate(['']);
      }
    });
  }

  getSendMatObject() {
    const newArray = [];
    for (const item of this.materialArray) {
      newArray.push({
        door: item.door,
        section: Number(item.section),
        width: item.width,
        height: item.height,
        materialId: item.type,
        materialOption: item.material,
        imageIndex: item.imageIndex,
        printOption: item.printOption
      });
    }
    return newArray;
  }

  createPartsArray() {
    const parts = [];
    for (const item of this.order) {
      if (this.findTypeReturn(+item.name) == 'flat') {
        parts.push({
          id: +item.name,
          width: item.width,
          crCode: this.creatCode(item),
          height: item.height,
          color: +item.color,
          quantity: Number(item.count),
          facade: item.facade_part,
        });
      } else {
        parts.push({
          id: +item.id,
          width: item.width,
          height: item.height,
          quantity: Number(item.count),
          handlerId: item.handlerId,
          facade: item.facade_part,
        });

      }

    }
    return parts;
  }


  createObjectSend() {
    const sendObject: any = {
      facadeParts: this.getSendMatObject(),
      percentages: {
        creator: this.sendObject.percentages.creator,
        frame: this.sendObject.percentages.frame,
        door: this.sendObject.percentages.door,
        company: this.sendObject.percentages.company,
      },
      parts: this.createPartsArray(),
      comment: this.sendObject.comment,
      image: this.sendObject.image,
      title: this.sendObject.title,
      user: Number(this.sendObject.user),
      systemColorId: Number(this.sendObject.systemColorId),
      carcassColorId: Number(this.sendObject.carcassColorId)
    };
    if (this.editType) {
      if (this.oldPhoto) {
        sendObject['image'] = this.oldPhoto;
        sendObject['existingImage'] = true;
      }
    }
    return sendObject;
  }

  sendOrder() {
    if (this.editType) {
      this.http.post(baseUrl.api + `n-order/edit/${this.paramsRender} `, this.createObjectSend()).subscribe((res: any) => {
        console.warn(res);
        if (res.success) {
          this.router.navigate(['order']);
        }
      });
    } else {
      this.http.post(baseUrl.api + '/n-order/store', this.createObjectSend()).subscribe((res: any) => {
        console.warn(res);
        if (res.success) {
          this.router.navigate(['order']);
        }
      });
    }
  }

  sendOrderShab() {
    if (this.editType) {
      this.http.post(baseUrl.api + '/n-order/store', this.createObjectSend()).subscribe((res: any) => {
        console.warn(res);
        if (res.success) {
          this.router.navigate(['order']);
        }
      });
    }
  }
}
function validFirstStageCustom(object, settings) {
  const proc = object.percentages;
  const validObject = {
    frame: false,
    door: false,
    company: false,
    creator: false,
  }
  if (proc.creator < settings.spec_values.tech_min_limit || proc.creator > settings.spec_values.tech_max_limit) {
    validObject.creator = true;
  }
  if (proc.frame < settings.spec_values.carcass_min_limit || proc.frame > settings.spec_values.carcass_max_limit) {
    validObject.frame = true;
  }
  if (proc.door < settings.spec_values.door_min_limit || proc.door > settings.spec_values.door_max_limit) {
    validObject.door = true;
  }
  if (proc.company < settings.spec_values.company_min_limit || proc.company > settings.spec_values.company_max_limit) {
    validObject.company = true;
  }
  return validObject;
}
function validFirstStage(object, settings) {

  const proc = object.percentages;
  console.warn(proc, settings)
  if (object.user && proc.creator && proc.frame && proc.door && proc.company && object.systemColorId) {
    if (proc.creator < settings.spec_values.tech_min_limit || proc.creator > settings.spec_values.tech_max_limit) {
      return true;
    }
    if (proc.frame < settings.spec_values.carcass_min_limit || proc.frame > settings.spec_values.carcass_max_limit) {
      return true;
    }
    if (proc.door < settings.spec_values.door_min_limit || proc.door > settings.spec_values.door_max_limit) {
      return true;
    }
    if (proc.company < settings.spec_values.company_min_limit || proc.company > settings.spec_values.company_max_limit) {
      return true;
    }
    return false;
  }
  return true;
}
