import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trumpet-2',
  templateUrl: './trumpet2.component.html',
  styleUrls: ['./trumpet2.component.scss']
})
export class TrumpetComponent2 implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
