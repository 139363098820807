export class MainSetting {
  carcass_thickness: null;
  door_addition_size: null;
  door_h_profile_thickness: null;
  door_max_separation: null;
  door_max_width: null;
  door_sealant_thickness: null;
  door_system_thickness: null;
  factory_power: null;
  hanging_door_max_width: null;
  legs_height: null;
  legs_step: null;
  min_door_size_four: null;
  min_door_size_three: null;

  constructor(array?: any) {
    try {
      if (array) {
        for (const value in array) {
          this[value] = array[value];
        }
      }
    } catch (e) {
    }
  }
}
