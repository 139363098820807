import {Create} from './create';
import * as Konva from '../../assets/konva';

export class Hooks extends Create {
  group;
  coef;
  create;
  top = 27;
  subId;

  constructor(coef, create, subId, active) {
    super(coef);
    this.coef = coef;
    this.create = create;
    this.subId = subId;
    if (subId === this.coef.element_Sub_id.three_hooks) {
      this.createHooks(false, active);
    } else if (subId === this.coef.element_Sub_id.five_hooks) {
      this.createHooks(true, active);
    } else if (subId === this.coef.element_Sub_id.two_hooks) {
      this.createTwoHooks(active);
    }

  }

  createHooks(count, active) {
    this.coef.countNameElement++;
    let y = 0;
    if (count) {
      y = 70;
    }
    this.group = new Konva.Group({
      x: 900,
      y: -500,
      width: 83,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.hooks,
        subId: this.subId,
      },
      bind: [],
    });

    const hook = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.coef.hook.hookWidth,
      height: this.coef.hook.hookHeight,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: 'black',
    });
    const hook2 = new Konva.Rect({
      x: this.coef.hook.hookWidth + 150 / this.coef.global_coef - this.coef.hook.hookWidth,
      y: 0,
      width: this.coef.hook.hookWidth,
      height: this.coef.hook.hookHeight,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: 'black',
    });
    const hook3 = new Konva.Rect({
      x: this.coef.hook.hookWidth * 2 + (150 / this.coef.global_coef) * 2 - this.coef.hook.hookWidth,
      y: 0,
      width: this.coef.hook.hookWidth,
      height: this.coef.hook.hookHeight,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: 'black',
    });
    let hook4;
    let hook5;
    if (count) {
      hook4 = new Konva.Rect({
        x: ((this.coef.hook.hookWidth * 2 + (150 / this.coef.global_coef) * 2 - this.coef.hook.hookWidth) / 3),
        y: this.coef.hook.hookHeight + 25,
        width: this.coef.hook.hookWidth,
        height: this.coef.hook.hookHeight,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      hook5 = new Konva.Rect({
        x: ((this.coef.hook.hookWidth * 2 + (150 / this.coef.global_coef) * 2 - this.coef.hook.hookWidth) / 3) + 25 + this.coef.hook.hookWidth,
        y: this.coef.hook.hookHeight + 25,
        width: this.coef.hook.hookWidth,
        height: this.coef.hook.hookHeight,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
    }
    this.group.add(hook);
    this.group.add(hook2);
    this.group.add(hook3);
    if (count) {
      this.group.add(hook4);
      this.group.add(hook5);
    }

    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.createHooksApi(pos, xy, count);
    });
  }

  createHooksApi(pos, xy, count) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    const isOnWhat = this.isOnWhat(pos, this.group, this.coef.shapesLayerw);
    this.createLockDesigne();
    if (contours && this.limitAddElement(xy, this.coef.id_element.hooks, contours) && isOnWhat && isOnWhat.attrs.bind.length === 0) {
      const hookContours = this.create.getTangentElement(pos, contours['top'], this.coef.shapesLayerw);
      let height = this.heightHook();
      if (count) height = this.heightHook(2);
      const groupHooks = new Konva.Group({
        x: contours['left'].attrs.x + this.coef.global_size_flat,
        y: contours['top'].attrs.y + this.coef.global_size_flat + 12.5,
        draggable: false,
        width: contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat,
        name: 'element' + this.coef.countNameElement,
        params: {
          removable: true,
          type: this.coef.id_element.hooks,
          subId: this.subId,
          onYou: isOnWhat,
          height: height
        },
        bind: [],
      });

      const createHook = new Konva.Rect({
        x: ((groupHooks['attrs'].width) / 4) - (this.coef.hook.hookWidth / 2),
        y: 0,
        width: this.coef.hook.hookWidth,
        height: this.coef.hook.hookHeight,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });

      const createHook2 = new Konva.Rect({
        x: ((groupHooks['attrs'].width) / 4) * 2 - (this.coef.hook.hookWidth / 2),
        y: 0,
        width: this.coef.hook.hookWidth,
        height: this.coef.hook.hookHeight,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });

      const createHook3 = new Konva.Rect({
        x: ((groupHooks['attrs'].width) / 4) * 3 - (this.coef.hook.hookWidth / 2),
        y: 0,
        width: this.coef.hook.hookWidth,
        height: this.coef.hook.hookHeight,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      let createHook4;
      let createHook5;
      if (count) {
        createHook4 = new Konva.Rect({
          x: ((groupHooks['attrs'].width) / 3) - (this.coef.hook.hookWidth / 2),
          y: this.coef.hook.hookHeight + 25,
          width: this.coef.hook.hookWidth,
          height: this.coef.hook.hookHeight,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
        });
        createHook5 = new Konva.Rect({
          x: ((groupHooks['attrs'].width) / 3) * 2 - (this.coef.hook.hookWidth / 2),
          y: this.coef.hook.hookHeight + 25,
          width: this.coef.hook.hookWidth,
          height: this.coef.hook.hookHeight,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
        });
      }

      if (contours['top'].attrs.params.type === this.coef.id_element.top_wall) {
        groupHooks.y(groupHooks.y() + Math.floor(this.coef.look2dPosition));
      }
      if (contours['right'] === this.coef.id_element.lateral_wall || contours['left'] === this.coef.id_element.lateral_wall) {
        groupHooks.scaleX(0.6);
      }
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        hookContours['top'],
        hookContours['bottom'],
        groupHooks,
        'horizon',
      );
      groupHooks.add(createHook);
      groupHooks.add(createHook2);
      groupHooks.add(createHook3);
      if (count) {
        groupHooks.add(createHook4);
        groupHooks.add(createHook5);
      }
      isOnWhat.attrs.bind.push(groupHooks);
      if (isOnWhat.attrs.params.top === this.coef.id_element.top_wall) {
        groupHooks.y(groupHooks.y() + Math.floor(this.coef.look2dPosition));
      }
      this.coef.shapesLayerw.add(groupHooks);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    } else {
      this.coef.errorTrigger('Неможливо розмістити елемент у даній секції.');
    }
  }

  createTwoHooks(active) {
    this.coef.countNameElement++;
    this.group = new Konva.Group({
      x: 900,
      y: -500,
      width: 42,
      draggable: active,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: true,
        type: this.coef.id_element.hooks,
        subId: this.subId,
      },
      bind: [],
    });
    const hook = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.coef.hook.hookWidth,
      height: this.coef.hook.hookHeight,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: 'black',
    });
    const hook2 = new Konva.Rect({
      x: this.coef.hook.hookWidth + 150 / this.coef.global_coef - this.coef.hook.hookWidth,
      y: 0,
      width: this.coef.hook.hookWidth,
      height: this.coef.hook.hookHeight,
      name: 'width',
      fill: this.coef.silverColor,
      strokeWidth: 1,
      stroke: 'black',
    });
    this.group.add(hook);
    this.group.add(hook2);
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.createTwoHooksApi(pos, xy);
    });
  }

  createTwoHooksApi(pos, xy) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    const hookContours = this.create.getTangentElement(pos, contours['top'], this.coef.shapesLayerw);
    const isOnWhat = this.isOnWhat(pos, this.group, this.coef.shapesLayerw);
    const height = this.heightHook();
    if (contours && this.limitAddElement(xy, this.coef.id_element.hooks, contours) && isOnWhat && isOnWhat.attrs.bind.length === 0) {
      const groupHooks = new Konva.Group({
        x: contours['left'].attrs.x + this.coef.global_size_flat,
        y: contours['top'].attrs.y + this.coef.global_size_flat + 12.5,
        draggable: false,
        width: contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat,
        name: 'element' + this.coef.countNameElement,
        params: {
          removable: true,
          type: this.coef.id_element.hooks,
          subId: this.subId,
          onYou: isOnWhat,
          height: height
        },
        bind: [],
      });
      const createHook = new Konva.Rect({
        x: (groupHooks['attrs'].width) / 3 - (this.coef.hook.hookWidth / 2),
        y: 0,
        width: this.coef.hook.hookWidth,
        height: this.coef.hook.hookHeight,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });

      const createHook2 = new Konva.Rect({
        x: ((groupHooks['attrs'].width) / 3) * 2 - (this.coef.hook.hookWidth / 2),
        y: 0,
        width: this.coef.hook.hookWidth,
        height: this.coef.hook.hookHeight,
        name: 'width',
        fill: this.coef.silverColor,
        strokeWidth: 1,
        stroke: 'black',
      });
      groupHooks.add(createHook);
      groupHooks.add(createHook2);
      isOnWhat.attrs.bind.push(groupHooks);
      if (isOnWhat.attrs.params.top === this.coef.id_element.top_wall) {
        groupHooks.y(groupHooks.y() + Math.floor(this.coef.look2dPosition));
      }
      this.create.bindingTocounters(
        contours['left'],
        contours['right'],
        hookContours['top'],
        hookContours['bottom'],
        groupHooks,
        'horizon',
      );
      this.coef.shapesLayerw.add(groupHooks);
      this.coef.shapesLayerw.batchDraw();
      this.create.priceSend();
    } else {
      this.coef.errorTrigger('Неможливо розмістити елемент у даній секції.');
    }
  }

  heightHook(countLevel = 1) {
    const hook = this.coef.hook;
    if (countLevel === 1) return hook.top_wall + hook.hookHeight;
    return hook.top_wall + hook.hookHeight + hook.vertical_step;
  }
}
