import { Injectable } from '@angular/core';
import {Notification} from '../interfaces/notification.interface';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public notificationTriggered = new Subject<Notification>();

  constructor() { }

  public triggerNotification(content: string, isError: boolean = false): void {
    this.notificationTriggered.next({isError: isError, content: content});
  }

}
