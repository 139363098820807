import {Component, ElementRef, HostListener, OnInit, ViewChild,} from '@angular/core';
import {CoefService} from '../../service/coef.service';
import {FormBuilder} from '@angular/forms';
import {PriceService} from '../../service/price.service';
import {SwiperComponent} from 'angular2-useful-swiper';
import * as Konva from '../../../assets/konva';
import {CoefDoorService} from '../../service/door service/coef-door.service';
import {InitDoorService} from '../../service/door service/init-door.service';
import * as _ from 'lodash';
import {CreateMaterials} from '../../class/doorMaterials/create-materials';
import {DragService} from '../../service/door service/drag.service';
import {CreateService} from '../../service/create.service';
import {FADE_IN_OUT} from '../../common/animations';
import {SocketPriceService} from '../../service/socket-price.service';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../../router.path';
@Component({
  selector: 'app-door-stage',
  templateUrl: './door-stage.component.html',
  styleUrls: ['./door-stage.component.css'],
  animations: [FADE_IN_OUT]
})
export class DoorStageComponent implements OnInit {
  @ViewChild('myButton') myButton: ElementRef;
  @ViewChild('myButton2') myButton2: ElementRef;

  public typeMat;
  allMat = [];
  typeDoor;
  selectedValue;
  selectedValue2;
  colorSystem = [];
  textEroor = '';
  typeValue = '';
  img_hover;
  filterName = '';
  type;
  modalsError = false;
  popapCloseCustom = false;
  selectedCities;
  id;
  nameSection;
  nameSectionCustom = 'не вибрано';
  colorSystemModal = false;
  colorSystemName = '';
  saveSystemColorItem:any = false;
  typeSelect = 1;
  countSection ; 
  img_hover_src;
  separation = [];
  img_hover_class = false;
  objectSaveType:any = false;
  systemsDoorId ;
  popapClose = false;
  public doorStage = null;
  config: SwiperOptions = {
    slidesPerView: 2,
    spaceBetween: 5,
    pagination: '.swiper-pagination',
    paginationClickable: true,
    // simulateTouch: false,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
  };
  configs: SwiperOptions = {
    slidesPerView: 4,
    spaceBetween: 5,
    pagination: '.swiper-pagination',
    paginationClickable: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
  };
  configss: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 5,
    pagination: '.swiper-pagination',
    paginationClickable: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
  };
  element = true;
  price;
  door_structure = [];
  @ViewChild('usefulSwiper') usefulSwiper: SwiperComponent;
  @ViewChild('SwiperMat') SwiperMat: SwiperComponent;
  @ViewChild('select') select: ElementRef;

  @HostListener('document:pointermove', ['$event']) onPointerMove(event): void {
    if (!event.target.attributes['data-hover']) {
      this.img_hover_class = false;
      this.img_hover_src = null;
      this.img_hover_class = false;
      return;
    }
    if (event.target.attributes['data-hover'].value === 'width') {
      this.img_hover_src = event.srcElement.currentSrc;
      this.img_hover = true;
      this.img_hover_class = true;
    } else {
      this.img_hover_src = event.srcElement.currentSrc;
      this.img_hover = true;
      this.img_hover_class = false;
    }
  }

  constructor(private formBuilder: FormBuilder,
              public coef: CoefService,
              public coefDoor: CoefDoorService,
              private priceService: PriceService,
              private init_door: InitDoorService,
              private drag: DragService,
              private create: CreateService,
              private socket: SocketPriceService,
              private prices: PriceService,
              private http: HttpClient,
  ) {
    this.socket.price.subscribe((data) => {
      this.price = data ;
    });

  }

  initDoor(doorLayr) {
    if (!this.doorStage) {
      this.doorStage = new Konva.Stage({
        container: 'containers',
        width: this.coef.widthWrapper,
        height: this.coef.heightWrapper
      });
      this.doorStage.add(doorLayr);
      this.systemsDoorId = this.coefDoor.systemId;
      this.getColorSystem(this.coefDoor.colorSystemId)
      this.allMat = _.cloneDeep(this.coefDoor.facade_materials);
      this.generate(this.allMat);
   
      this.init_door.intDoor({stage: this.doorStage});
    }
    this.door_structure = this.coefDoor.door_structure;

  }

  getColorSystem(id) {
    if (id) {
      if (!this.coef.arrayNotSystemColor.includes(+this.coef.pramsId)) {
        if (this.coefDoor.colorSystemArray ) {
          for (const color of this.coefDoor.colorSystemArray) {
            if (id == color.id) {
              this.colorSystemName = `${color.name}`;
            }
          }
        }
      }
    }
  }
   removeItemsWithCommonSubId(items,  idDoor) {
    for (let i = items.length - 1; i >= 0; i--) {
      if (items[i].attrs && items[i].attrs.params  && items[i].attrs.params.idDoor === idDoor && !items[i].attrs.params.contours) {
       
        items[i].remove();
      }
    }
  }
  deleteAllDoor(){
    if(this.typeDoor){
    this.textEroor = 'Видалення дверей  спричинить ситуацію, при якій всі двері будуть скинуті до початкового стану';
    this.typeValue = 'deleteAllDoor';
    this.modalsError = true;
    }

  }
  onModalClosed(event){
    if(!event){
      this.modalsError = false;
      return;
    }
    switch (event){
      case 'deleteAllDoor':
        this.coef.doorsArray.forEach((door,index) => {
          this.deleteOneDoor(index+1)
          })
        break;
      case 'deleteOneDoor':
        this.deleteOneDoor(this.coefDoor.reDesign.attrs.params.idDoor)
        break;
      case 'changeSystem':
        this.changeAllSystem();
        break;
    }
    this.modalsError = false;
  }
  changeBlure(type){
    this.init_door.changeManualTravel(type);
    this.myButton.nativeElement.blur();
    this.myButton2.nativeElement.blur();
  }
  changeAllSystem(){
    this.coefDoor.DoorWrapper.destroyChildren()
    this.coefDoor.reDesign = false;
    this.coefDoor.manualTravelElement = false;
    this.coefDoor.DoorWrapper.batchDraw();

    this.http.get(baseUrl.api + 'door-systems/' + this.systemsDoorId ).subscribe((res: any) => {
      if (res.success) {
        this.coef.mainSetting.door_additional_size_two = res.payload.door_additional_size_coef_two;
        this.coef.mainSetting.door_additional_size_three = res.payload.door_additional_size_coef_three;
        this.coef.mainSetting.door_additional_size_four = res.payload.door_additional_size_coef_four;
        this.coef.mainSetting.door_additional_size_five = res.payload.door_additional_size_coef_five;
        const oldSystem = this.coefDoor.systemId;
        this.coef.doorSystemsId =  Number(this.systemsDoorId);
        this.coefDoor.systemId =  Number(this.systemsDoorId);
        this.coefDoor.has_top_stopper = res.payload.has_top_stopper;
        this.coefDoor.has_puller = res.payload.has_puller;
      
        
      this.coefDoor.has_bottom_stopper = res.payload.has_bottom_stopper;
      if(res.payload.has_top_stopper && res.payload.has_bottom_stopper){
        this.coefDoor.stoperDoor = 'bottom';
      }else if(res.payload.has_top_stopper && !res.payload.has_bottom_stopper){
        this.coefDoor.stoperDoor = 'top';
      }else if(!res.payload.has_top_stopper && res.payload.has_bottom_stopper){
        this.coefDoor.stoperDoor = 'bottom';
      }else{
        this.coefDoor.stoperDoor = 'none';
      }
      if(!res.payload.has_puller){
        this.coefDoor.puller = false;
      }
      
        this.coefDoor.parsApiSettingsSystem( res.payload , res.payload.facade_materials)
        this.coefDoor.parceSystemColor( res.payload.system_colors)
        this.getColorSystem(this.coefDoor.colorSystemId)
        const selectOpenDoor = this.coef.doorsArray.length;
        let doors = [];
        for (let i = 0; i < selectOpenDoor; i++) {
          if (this.coef.pramsId == 11) {
            doors.push({
              width: this.coef.getOneDoorWidth(selectOpenDoor, this.coef.setingsWardrobe.width, this.coef.setingsWardrobe.depth ) * this.coef.global_coef,
              door_type_id: 3,
              sep_count: 0,
              materials: [4]
            });
          } else {
            doors.push({
              width: this.coef.getOneDoorWidth(selectOpenDoor, this.coef.setingsWardrobe.width, this.coef.setingsWardrobe.depth ) * this.coef.global_coef,
              door_type_id: 2,
              sep_count: 0,
              materials: [2]
            });
          }
        }
        this.coef.doorsArray = doors;
        this.priceService.setDoorOptions(doors);
        let status = true;
        for(const item of this.coef.shapesLayerw.children){
          if( item.attrs&&item.attrs.params){
            if(item.attrs.params.type ==this.coef.id_element.drawer || item.attrs.params.type == this.coef.id_element.open_door || item.attrs.params.type == this.coef.id_element.pantograph){
              const a = this.coef.blockDrawerCentr(true,{left:{attrs:{x:item.attrs.x - this.coef.global_size_flat,width:this.coef.global_size_flat}} , right:{attrs:{x:item.attrs.x + item.attrs.width,}}});
              if(!a){
                status = false;
                break;
              }
            }
          }
        }
        if(!status){
          this.systemsDoorId = oldSystem;
          this.changeAllSystem();
        }
        this.init_door.intDoor({stage: this.doorStage} , true);
      }
    });

  }
  changeStoper(id){
    this.coefDoor.stoperDoor = id;
    this.prices.sendObjectPrice();
  }
  changehas_puller(value){
    this.coefDoor.puller = value;
    this.prices.sendObjectPrice();
  }
  changeSystem(){
    this.textEroor = 'це спричинить ситуацію, при якій всі двері будуть скинуті до початкового стану';
    this.typeValue = 'changeSystem';
    this.modalsError = true;
  }
  textureReversed(type){
if( this.coefDoor.reDesign.attrs.params.isTextureReversed != type){


    if(type ){

      if( this.coefDoor.reDesign.attrs.height * this.coef.global_coef < this.coef.mainSetting.texture_rotation_max_height){
        this.coefDoor.reDesign.attrs.params.isTextureReversed = type;
        if( this.coefDoor.reDesign.className == "Image"){
          this.rotateHtmlImageToBase64(this.coefDoor.reDesign.image(),90 ,(test)=>{
            const newImage = new Image();
            newImage.src = test;
            this.coefDoor.reDesign.image(newImage)
            this.coefDoor.DoorWrapper.batchDraw()
          })
          this.coefDoor.reDesign.attrs.params.texturedV.visible(false)
          this.coefDoor.reDesign.attrs.params.texturedH.visible(true)
        }else{
          this.coefDoor.reDesign.attrs.params.texturedV.visible(false)
          this.coefDoor.reDesign.attrs.params.texturedH.visible(true)
        }
       
      }
    }else{
      this.coefDoor.reDesign.attrs.params.isTextureReversed = type;
      if( this.coefDoor.reDesign.className == "Image"){
        this.rotateHtmlImageToBase64(this.coefDoor.reDesign.image(),90 ,(test)=>{
          const newImage = new Image();
          newImage.src = test;
          this.coefDoor.reDesign.image(newImage)
          this.coefDoor.DoorWrapper.batchDraw()
        })
        this.coefDoor.reDesign.attrs.params.texturedV.visible(true)
        this.coefDoor.reDesign.attrs.params.texturedH.visible(false)
      }else{
        this.coefDoor.reDesign.attrs.params.texturedV.visible(true)
        this.coefDoor.reDesign.attrs.params.texturedH.visible(false)
      }
     
    }
    this.coefDoor.DoorWrapper.batchDraw()
    this.prices.sendObjectPrice();
  }
  }

  rotateHtmlImageToBase64(htmlImage, degrees, callback) {
    // Створіть новий об'єкт зображення
    var img = new Image();
  
    // Встановіть обробник завантаження зображення
    img.onload = function () {
      // Створіть canvas для обернутого зображення
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
  
      // Оберніть зображення
      canvas.width = degrees % 180 === 0 ? img.width : img.height;
      canvas.height = degrees % 180 === 0 ? img.height : img.width;
  
      if (degrees === 90) {
        context.rotate(Math.PI / 2);
        context.drawImage(img, 0, -img.height);
      } else if (degrees === 180) {
        context.rotate(Math.PI);
        context.drawImage(img, -img.width, -img.height);
      } else if (degrees === 270) {
        context.rotate((3 * Math.PI) / 2);
        context.drawImage(img, -img.width, 0);
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);
      }
  
      // Отримайте base64 зображення з canvas
      var base64 = canvas.toDataURL('image/jpeg'); // Замініть на інший тип, якщо потрібно
  
      // Викличте зворотну функцію та передайте base64
      callback(base64);
    };
    img.crossOrigin = 'Anonymous';
    // Встановіть посилання на зображення об'єкта <img>
    img.src = htmlImage.src;

  }
  

  
  

  deleteOneDoorStart(){
    if(this.typeDoor){
      this.textEroor = 'це спричинить ситуацію, при якій  двері будуть скинуті до початкового стану';
      this.typeValue = 'deleteOneDoor';
      this.modalsError = true;
    }
   
  }
  deleteOneDoor(typeDoor){
    this.removeItemsWithCommonSubId(this.coefDoor.DoorWrapper.children,typeDoor);
    this.coefDoor.reDesign = false;

    this.typeDoor.content = [2];
    const group = this.init_door.findGroup(typeDoor, this.coefDoor.DoorWrapper);
    const mat = this.init_door.findMat(2,1 , this.coefDoor.facade_materials_system)
     const matDef = this.init_door.findMat(mat.id,1 , this.coefDoor.facade_materials)
    new CreateMaterials(this.coefDoor, this.coef, this.drag, this.create, this.prices).defaultMaterials({id:matDef.default_option.id,content:[mat.id]}, group, false);
    this.openModal(true)
    this.coefDoor.DoorWrapper.batchDraw();
  }
  update(id, color, active,name, parentId?) {
    
    this.closePpopap();
    this.objectSaveType = {id:id, color:color, active:active, parentId:parentId}
    this.nameSection = name;
    this.changeSection();
  }
  updateCustom(id, color, active,name, parentId?){
    this.nameSectionCustom = name;
    this.closePpopapCustom();
    this.init_door.changItemCustom(id, color,  parentId);
    this.closePpopapCustom();
  }

  changeSection(){
    if (this.objectSaveType.active) {
      this.init_door.changItem(this.objectSaveType.id, this.objectSaveType.color, this.typeMat, this.objectSaveType.parentId);
      this.nameSectionCustom = 'не вибрано';
      this.objectSaveType = false;
    }
  }
  openModalColor(){
   
    this.colorSystemModal = true;
  }
  test(i) {
    this.nameSection = 'не вибрано';
    this.filterName = '';
    this.objectSaveType = false;
  }
  findeName(e){
    for(const item of  this.coefDoor.facade_materials){
      if (item.id ==e.id){
        for(const sub of item.options){
          if(sub.id == e.obj.attrs.params.id){
            if(e.id == 8 || e.id == 5 ){
              this.nameSection = sub.names;
            }else{
              this.nameSection = sub.name;
            }
          }
        }
      }
    }
  }
  findeNameCustom(e){
  
      if(e.obj.attrs.params.printId){
        for(const sub of  this.coefDoor.print_allowed.options){
         
          if(sub.id == e.obj.attrs.params.printId){
              this.nameSectionCustom = sub.names;
          }
        }
      }else{
        this.nameSectionCustom = 'не вибрано';
      }
        
    
  }
  ngOnInit() {
    
    this.init_door.openSlid.subscribe((e) => {
      if(e){
        this.element = false;
        this.findeName(e);
        this.findeNameCustom(e)
        const type = e['id'];
        this.typeMat = type;
        this.popapClose = false;
        this.createSelect(e);
      }else{
        this.element = true;
      }
    });
 
  }
  openModalColaps(){
    this.filterName = '';
    this.popapClose = true;
    this.popapCloseCustom = false;
  }
  openModalColapsCustom(){
    this.filterName = '';
    this.popapClose = false;
    this.popapCloseCustom = true;
  }
  canAdd(){
    if(this.coefDoor.reDesign && this.coefDoor.reDesign.attrs && this.coefDoor.reDesign.attrs.params){
      if(this.coefDoor.reDesign.attrs.params.left === "part" || this.coefDoor.reDesign.attrs.params.right === "part"){
        return false;
      }
    }else{
      return false;
    }
    return true;
  }
  canAddH(){
    if(this.coefDoor.reDesign && this.coefDoor.reDesign.attrs && this.coefDoor.reDesign.attrs.params){
      if(this.coefDoor.reDesign.attrs.params.top === "part" || this.coefDoor.reDesign.attrs.params.bottom === "part"){
        return false;
      }
    }else{
      return false;
    }
    return true;
  }
  createCountSelect (typeSelect){
  
    if(typeSelect == 1 ){
      const min_h_section_height = this.coefDoor.glob_settings.min_h_section_height;
      const wrapperHeight = this.coefDoor.reDesign.attrs.height * this.coef.global_coef;
      const max_h_sep_qty = this.coefDoor.glob_settings.max_h_parts_qty;
      const height_one_separator =  this.coefDoor.heightSeparator  ;
      // Обчислюємо доступну висоту для секцій після врахування розділювачів
      const availableHeightForSections = wrapperHeight + height_one_separator;
    
      // Кількість секцій, що поміщаються в доступну висоту
      const sectionsInAvailableHeight = Math.floor(availableHeightForSections / (min_h_section_height + height_one_separator));
  
      // Обмежуємо кількість секцій максимальною кількістю допустимих розділювачів
      const maxSectionsInWrapperHeight = Math.min(sectionsInAvailableHeight, max_h_sep_qty);

      // Повертаємо результат
      const result = Array.from({ length: maxSectionsInWrapperHeight }, (_, index) => index + 1);
      this.separation = result;
      if( this.coefDoor.reDesign.attrs.params.left == 'part' || this.coefDoor.reDesign.attrs.params.right == 'part'){
        if(this.coefDoor.reDesign.attrs.width * this.coef.global_coef < this.coefDoor.glob_settings.min_nested_h_section_width){
          this.separation = []
        }
     }
     if( (this.coefDoor.reDesign.attrs.params.left == 'part' || this.coefDoor.reDesign.attrs.params.right == 'part') && ((this.coefDoor.reDesign.attrs.params.top == 'part'  && this.coefDoor.reDesign.attrs.width != this.coefDoor.reDesign.attrs.params.topElem.attrs.width ) || (this.coefDoor.reDesign.attrs.params.bottom == 'part' && this.coefDoor.reDesign.attrs.width != this.coefDoor.reDesign.attrs.params.bottomElem.attrs.width))){
        this.separation = []               
      }
      if( (this.coefDoor.reDesign.attrs.params.left == 'fence' && this.coefDoor.reDesign.attrs.params.right == 'fence')){
        let count=0;
        for ( const item of this.coefDoor.DoorWrapper.children){
          if(item.attrs && item.attrs.params && item.attrs.width == this.coefDoor.reDesign.attrs.width && this.coefDoor.reDesign.attrs.x == item.attrs.x  && item.attrs.params.part){
            count++;
          }
        }
         if(this.separation.length >= max_h_sep_qty - count ){
          this.separation =  Array.from({ length: max_h_sep_qty - count }, (_, index) => index + 1)
         }            
      }
      if( (this.coefDoor.reDesign.attrs.params.left == 'part' || this.coefDoor.reDesign.attrs.params.right == 'part') && ((this.coefDoor.reDesign.attrs.params.top == 'part'  && this.coefDoor.reDesign.attrs.width == this.coefDoor.reDesign.attrs.params.topElem.attrs.width ) || (this.coefDoor.reDesign.attrs.params.bottom == 'part' && this.coefDoor.reDesign.attrs.width == this.coefDoor.reDesign.attrs.params.bottomElem.attrs.width))){
       let count=0;
        for ( const item of this.coefDoor.DoorWrapper.children){
          if(item.attrs && item.attrs.params && item.attrs.width == this.coefDoor.reDesign.attrs.width && this.coefDoor.reDesign.attrs.x == item.attrs.x  && item.attrs.params.part){
            count++;
          }
        }
         if(this.separation.length >= max_h_sep_qty - count ){
          this.separation =  Array.from({ length: max_h_sep_qty - count }, (_, index) => index + 1)
         }
                   
      }
    
    }else{
    
      const wrapperWidth = this.coefDoor.reDesign.attrs.width * this.coef.global_coef;
      const max_v_parts_qty = this.coefDoor.glob_settings.max_v_parts_qty;
      const min_v_section_width = this.coefDoor.glob_settings.min_v_section_width;
      const height_one_separator =  this.coefDoor.heightSeparator  ;
      const availableHeightForSections = wrapperWidth + height_one_separator;
   
      // Кількість секцій, що поміщаються в доступну висоту
      const sectionsInAvailableHeight = Math.floor(availableHeightForSections / (min_v_section_width + height_one_separator));
     
      // Обмежуємо кількість секцій максимальною кількістю допустимих розділювачів
      const maxSectionsInWrapperHeight = Math.min(sectionsInAvailableHeight, max_v_parts_qty);

      // Повертаємо результат
      const result = Array.from({ length: maxSectionsInWrapperHeight }, (_, index) => index + 1);
      this.separation = result;
   
      if( this.coefDoor.reDesign.attrs.params.top == 'part' || this.coefDoor.reDesign.attrs.params.bottom == 'part'){
        if(this.coefDoor.reDesign.attrs.height * this.coef.global_coef < this.coefDoor.glob_settings.min_nested_v_section_height){
          this.separation = [];
        }
     }
     if( (this.coefDoor.reDesign.attrs.params.top == 'part' || this.coefDoor.reDesign.attrs.params.bottom == 'part') && ((this.coefDoor.reDesign.attrs.params.left == 'part'  && this.coefDoor.reDesign.attrs.height != this.coefDoor.reDesign.attrs.params.leftElem.attrs.height ) || (this.coefDoor.reDesign.attrs.params.right == 'part' && this.coefDoor.reDesign.attrs.height != this.coefDoor.reDesign.attrs.params.rightElem.attrs.height))){
      this.separation = []               
    }
    if( (this.coefDoor.reDesign.attrs.params.top == 'fence' && this.coefDoor.reDesign.attrs.params.bottom == 'fence')){
      let count=0;
      for ( const item of this.coefDoor.DoorWrapper.children){
        if(item.attrs && item.attrs.params && item.attrs.height == this.coefDoor.reDesign.attrs.height && this.coefDoor.reDesign.attrs.y == item.attrs.y  && item.attrs.params.part && item.attrs.params.type == this.coefDoor.reDesign.attrs.params.type){
          count++;
        }
      }
       if(this.separation.length >= max_v_parts_qty - count ){
        this.separation =  Array.from({ length: max_v_parts_qty - count }, (_, index) => index + 1)
       }            
    }
    if( (this.coefDoor.reDesign.attrs.params.top == 'part' || this.coefDoor.reDesign.attrs.params.bottom == 'part') && ((this.coefDoor.reDesign.attrs.params.left == 'part'  && this.coefDoor.reDesign.attrs.height == this.coefDoor.reDesign.attrs.params.leftElem.attrs.height ) || (this.coefDoor.reDesign.attrs.params.right == 'part' && this.coefDoor.reDesign.attrs.height == this.coefDoor.reDesign.attrs.params.rightElem.attrs.height))){
     let count=0;
      for ( const item of this.coefDoor.DoorWrapper.children){
        if(item.attrs && item.attrs.params && item.attrs.height == this.coefDoor.reDesign.attrs.height && this.coefDoor.reDesign.attrs.y == item.attrs.y  && item.attrs.params.part && item.attrs.params.type == this.coefDoor.reDesign.attrs.params.type){
          count++;
        }
      }
       if(this.separation.length >= max_v_parts_qty - count ){
        this.separation =  Array.from({ length: max_v_parts_qty - count }, (_, index) => index + 1)
       }
                 
    }
    }
  }
  createSelect(e) {
    const typeDoor = this.findDoorType(e.obj.attrs.params.typeDoor);
  
    
    this.createCountSelect(this.typeSelect)

    if (typeDoor) {
      this.typeDoor = _.cloneDeep(typeDoor);
      this.typeDoor.type = e.obj.attrs.params.type;
    } else {
      this.typeDoor = false;
    }
  }

  firsElement = false;

  count(count) {
  
    if(this.countSection != 1){
     this.openModal(true)
    if(this.typeSelect == 1){
      const arrayCount = this.pars_separation(this.countSection, 2);
      this.typeDoor.content = arrayCount;
      const group = this.init_door.findGroup(this.typeDoor.type, this.coefDoor.DoorWrapper);
      new CreateMaterials(this.coefDoor, this.coef, this.drag, this.create, this.prices).defaultMaterials(this.typeDoor, group, false);
      this.select.nativeElement.blur();
      if(count){
        count.selected = true;
      }
    }else{
   
      const group = this.init_door.findGroup(this.typeDoor.type, this.coefDoor.DoorWrapper);
   
      new CreateMaterials(this.coefDoor, this.coef, this.drag, this.create, this.prices).defaultMaterialsV(this.typeDoor, group,this.countSection, false);
      this.select.nativeElement.blur();
      if(count){
        count.selected = true;
      }
      
    }
   
    this.countSection = 1;
  }
  }

  count2() {
   
    this.createCountSelect(this.typeSelect)
    this.countSection = 1;
  }

  pars_separation(max, idMat) {
    const array = [];
    for (let i = 1; i <= max; i++) {
      array.push(idMat);
    }
    return array;
  }


  findDoorType(id) {
    return {id:31,content:[2]}
  }

  generate(array) {
    // for (let mat of array) {
    //   if (mat.id !== 8 && mat.id !== 5) {
    //     const newArray = [];
    //     let oneArray = [];
    //     for (let i = 0; i < mat.options.length; i++) {
    //       oneArray.push(mat.options[i]);
    //       if (i != 0) {
    //         if (((i + 1) % 6) === 0) {
    //           newArray.push(oneArray);
    //           oneArray = [];
    //         }
    //       }
    //     }
    //     if (oneArray.length > 0 && oneArray.length < 6) {
    //       newArray.push(oneArray);
    //       oneArray = [];
    //     }
    //     mat.options = newArray;
    //   }
    // }
  }


  openModal(element) {
    if(!this.coefDoor.reDesign && !element ){
      
    }else{
      this.element = element
    }
   
    this.getColorSystem(this.coefDoor.colorSystemId)
    this.saveSystemColorItem = false;
    this.systemsDoorId = this.coefDoor.systemId;
    if (this.usefulSwiper) {
      this.usefulSwiper.swiper.update();
    }
  }

  onDragStart(e) {

  }

  onDragMove(e) {

  }

 

  findtoID(array, id) {
    for (const item of array) {
      if (item.id === id) {
        return item;
      }
    }
    return false;
  }

  updateColorSystem() {
    if (this.saveSystemColorItem.active) {
      this.coefDoor.colorSystem = this.saveSystemColorItem.rgb;
      this.coefDoor.colorSystemId = this.saveSystemColorItem.id;
      for (const elem of this.coefDoor.DoorWrapper.children) {
        if (elem.nodeType === 'Group' && elem.attrs.params && elem.attrs.params.type && !elem.attrs.params.remove) {
          for (const element of elem.children) {
            element.fill(this.coefDoor.colorSystem);
          }
        }
        if (elem.attrs.params && elem.attrs.params.part) {
          elem.fill(this.coefDoor.colorSystem);
        }
      }
      this.coefDoor.DoorWrapper.batchDraw();
        this.prices.sendObjectPrice();

    }
  }
  sendPrice(){
    
    this.prices.sendObjectPrice();
  }
  saveColorSystem(id, rgb, active){
    this.saveSystemColorItem = {active:active,rgb:rgb,id:id};
    this.getColorSystem(id)
  
    this.colorSystemModal = false;
  }
  closePpopap() {
    this.popapClose = false;
  }
  closePpopapCustom() {
    this.popapClose = false;
    this.popapCloseCustom = false;
  }
}
