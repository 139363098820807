import { P } from '@angular/core/src/render3';
import * as Konva from '../../../assets/konva';
import { Sizes } from '../sizes/sizes';
import { ColorElement } from './color-element';

import * as _ from 'lodash';
export class CreateMaterials {
  coefDoor;
  coef;
  height = 4.5;
  width = 4.5;
  drag;
  create;
  price;
  init;
  render;

  constructor(coefDoor, coef, drag, create, price, init?, render?) {
    this.coefDoor = coefDoor;
    this.coef = coef;
    this.height = this.coefDoor.heightSeparator / this.coef.global_coef;
    this.width = this.coefDoor.heightSeparator / this.coef.global_coef;
    this.drag = drag;
    this.create = create;
    this.price = price;
    this.init = init;
    this.render = render;

  }
  async defaultMaterialsV(item, group, countSep, spec?, color?) {
   
    const reDesignX =  this.coefDoor.reDesign.attrs.x;
    const reDesignWidth = this.coefDoor.reDesign.attrs.width;
    const width = reDesignWidth - this.width * (countSep - 1);
    let oneX = Math.floor((width * this.coef.global_coef) / countSep) / this.coef.global_coef;
    //need check
    const bottomX = reDesignX + reDesignWidth  - this.width;
    let count = 1;
    
    removeItemsWithCommonSubId(this.coefDoor.DoorWrapper.children, this.coefDoor.reDesign.attrs.params.idForDelete, this.coefDoor.reDesign.attrs.params.idDoor)
    this.coefDoor.reDesign.remove();
    for (let i = 0; i < countSep; i++) {
      if (i === countSep - 1) {
          await this.createPartV(i, item, reDesignX + oneX * count + this.height * (count - 1), group, 2, oneX, false, false, bottomX - (reDesignX + oneX * (count - 1) + this.width * (count - 2)));
        count++;
      } else if (i === 0) {
          await this.createPartV(i, item, reDesignX + oneX, group, 2, oneX, true, true);
        count++;
      } else {
          await this.createPartV(i, item, reDesignX + oneX * count + this.width * (count - 1), group, 2, oneX, true);
        count++;
      }
    }
    const doorItems = this.coefDoor.DoorWrapper.children.filter((item) => {
      return (item.attrs && item.attrs.params && item.attrs.params.price && item.attrs.params.type === this.coefDoor.reDesign.attrs.params.type);
    })
    doorItems.sort((a, b) => {
      if (a.attrs.y === b.attrs.y) {
        return a.attrs.x - b.attrs.x; // Сортування за зростанням координати x при рівних значеннях y
      } else {
        return a.attrs.y - b.attrs.y; // Сортування за зростанням координати y
      }
    });
    for (let i = 1; i < doorItems.length + 1; i++) {
  
      doorItems[i - 1].attrs.params.numberItem = i;
      for (const itemText of this.coefDoor.DoorWrapper.children) {
        if (itemText.attrs && itemText.attrs.params && itemText.attrs.params.isText && itemText.attrs.params.idForDelete === doorItems[i - 1].attrs.params.idForDelete) {
          itemText.text(i);
        }
      }
    }
    this.coefDoor.reDesign = false;
  }
  async defaultMaterials(item, group, spec?, color?) {
   
   
    let height = this.coefDoor.heightDoor - this.coef.convertCoef(this.coefDoor.glob_settings.top_plant_height) - this.coef.convertCoef(this.coefDoor.glob_settings.bottom_plant_height) - this.height * (item.content.length - 1);
    let oneY = Math.floor((height * this.coef.global_coef) / item.content.length) / this.coef.global_coef;
    let bottomY = group.attrs.y + this.coefDoor.heightDoor - this.coef.convertCoef(this.coefDoor.glob_settings.bottom_plant_height) - this.height;
    let reDesignY;
    if (this.coefDoor.reDesign) {
      reDesignY =  this.coefDoor.reDesign.attrs.y;
      const reDesignHeight = this.coefDoor.reDesign.attrs.height;
      bottomY = reDesignHeight + reDesignY - this.height;
      height = reDesignHeight  - this.height * (item.content.length - 1);
      oneY = Math.floor((height * this.coef.global_coef) / item.content.length) / this.coef.global_coef;
    }
    let count = 1;
   
    if (this.coefDoor.reDesign) {
      removeItemsWithCommonSubId(this.coefDoor.DoorWrapper.children, this.coefDoor.reDesign.attrs.params.idForDelete, this.coefDoor.reDesign.attrs.params.idDoor)
      this.coefDoor.reDesign.remove()
    }
    for (let i = 0; i < item.content.length; i++) {
      let topStart = group.attrs.y + this.coef.convertCoef(this.coefDoor.glob_settings.top_plant_height);
      if (reDesignY) {
         topStart = reDesignY;
      }
      if (i === item.content.length - 1) {
          await this.createPart(i, item, topStart + oneY * count + this.height * (count - 1), group, item.content[i], oneY, false, false, bottomY - (topStart + oneY * (count - 1) + this.height * (count - 2)));
        count++;
      } else if (i === 0) {
          await this.createPart(i, item, topStart + oneY, group, item.content[i], oneY, true, true);
  
        count++;
      } else {
          await this.createPart(i, item, topStart + oneY * count + this.height * (count - 1), group, item.content[i], oneY, true);
    
        count++;
      }
    }
    let itemType = group.attrs.params.type
    if(this.coefDoor.reDesign){
      itemType = this.coefDoor.reDesign.attrs.params.type;
    }
    const doorItems = this.coefDoor.DoorWrapper.children.filter((item) => {
      return (item.attrs && item.attrs.params && item.attrs.params.price && item.attrs.params.type === itemType);
    })
    doorItems.sort((a, b) => {
      if (a.attrs.y === b.attrs.y) {
        return a.attrs.x - b.attrs.x; // Сортування за зростанням координати x при рівних значеннях y
      } else {
        return a.attrs.y - b.attrs.y; // Сортування за зростанням координати y
      }
    });
    for (let i = 1; i < doorItems.length + 1; i++) {
     
      doorItems[i - 1].attrs.params.numberItem = i;
      for (const itemText of this.coefDoor.DoorWrapper.children) {
        if (itemText.attrs && itemText.attrs.params && itemText.attrs.params.isText && itemText.attrs.params.idForDelete === doorItems[i - 1].attrs.params.idForDelete) {
          itemText.text(i);
        }
      }

    }
    this.coefDoor.reDesign = false;
  }
  
  findPath(subid,id,index){
   
    for(const item of this.coefDoor.facade_materials){
      if(item.id == subid){
        for(const sub of item.options){
          if(sub.id == id){
            if(sub.images &&  sub.images[index] &&  sub.images[index].path){
              return  sub.images[index].path;
            }
            }
        }
      }
    }
    return false;
  }
  findPathCustom(id,index){
   
        for(const sub of this.coefDoor.print_allowed.options){
          if(sub.id == id){
        
            return sub.images[index].path;
          }
        }
  }
  async createPartV(i, item, oneX, group, part, width, need, first?, bottomY?) {
    this.coefDoor.name++;
    const haightAllDoor = this.coefDoor.reDesign.attrs.height;
    const name = 'door' + this.coefDoor.name;
    const This = this;
    const yoOfObj = this.coefDoor.reDesign.attrs.y;
    const xOfObj = oneX - width;
    const bindTopElement = this.drag.findPart(yoOfObj, xOfObj, name);
    const bindBotElement = this.drag.findPartbottom(yoOfObj, xOfObj, name);
    
    const Part = new Konva.Rect({
      x: oneX,
      y: this.coefDoor.reDesign.attrs.y,
      width: this.height,
      height: haightAllDoor,
      name: name,
      params: {
        part: true,
        typeDoor: item.id,
        type: group.attrs.params.type,
        id_parent: group._id,
        idDoor: group.attrs.params.type,
        remove: true,
        position: i + 1,
        top:bindTopElement? 'part': 'fence',
        bottom:bindBotElement? 'part': 'fence'
      },
      draggable: true,
      dragBoundFunc: function (pos) {
        return {
          x: pos.x,
          y: this.getAbsolutePosition().y,
        };
      },
      fill: this.coefDoor.colorSystem,
      strokeWidth: 1,
      stroke: 'black'
    });

    const stopPosition = { left: null, right: null };
    if (!item.element_min_height) {
      stopPosition.left = item.flat_element_min_height / this.coef.global_coef;
      stopPosition.right = item.flat_element_max_height / this.coef.global_coef;
    }

    Part['attrs'].dragBoundFunc = function (pos) {
      let left = This.drag.findPartLeft(Part.y(), Part.x(), name,group.attrs.params.type);
      let right = This.drag.findPartRight(Part.y(), Part.x(), name , group.attrs.params.type);
      if (!left || left.attrs.params.id_parent != group._id) {
        left = { attrs: { x: group.attrs.x, width:  This.coef.convertCoef(This.coefDoor.glob_settings.left_hang_width) } }
      }
      if (!right || right.attrs.params.id_parent != group._id) {
        right = { attrs: { x: group.attrs.x + group.attrs.width - This.coef.convertCoef(This.coefDoor.glob_settings.right_hang_width)  , width: This.coef.convertCoef(This.coefDoor.glob_settings.right_hang_width)  } }
      }

   
      return {
        x: This.findStopPositionRight(pos, group, {
          left: left,
          right: right
        }, This.coef.convertCoef(This.coefDoor.glob_settings.min_v_section_width) , Part ),
        y: this.getAbsolutePosition().y
      };
    };
    let Yposition = oneX - width;
    if (first) {
      Yposition = oneX - width;
    }
    if (bottomY) {
      width = bottomY;
    }

    const id = Date.now().toString() + i.toString();
    var SOURCE = 'assets/img/texture/left-right.svg';
    const imageNode2 = await this.loadImage(SOURCE);
    const mat = this.findMat(item.content[0],1 , this.coefDoor.facade_materials_system)
    const matDef = this.findMat(mat,1 , this.coefDoor.facade_materials)
    const textured = matDef.default_option.textured
  
    imageNode2.setAttrs({
      width: width,
      height: 20,
      x: Yposition,
      y: this.coefDoor.reDesign.attrs.y ,
      visible:  textured
    });
    // @ts-ignore
    imageNode2.attrs.params = { idForDelete: id, type: group.attrs.params.type, idDoor: group.attrs.params.type, remove: true, isTexture:true }
  
  
    var SOURCE2 = 'assets/img/texture/sclo.jpg';
    if(this.coefDoor.reDesign){
      if(this.coefDoor.reDesign.attrs.params.printId){
        SOURCE2 = this.findPathCustom(this.coefDoor.reDesign.attrs.params.printId , this.coefDoor.reDesign.attrs.params.position)
      }
    }
    const imageNodeFilte = await this.loadImage(SOURCE2);


    
    var SOURCE21 = 'assets/img/texture/index.svg';
  
    const imageNode = await this.loadImage(SOURCE21);
    imageNode.setAttrs({
      width:  20,
      height: haightAllDoor,
      x: Yposition,
      y: this.coefDoor.reDesign.attrs.y,
      visible:  false 
    });
   // @ts-ignore
   imageNode.attrs.params = { idForDelete: id, type: group.attrs.params.type, idDoor: group.attrs.params.type, remove: true, isTexture:true }
   
    const simpleText = new Konva.Text({
      x: oneX - width + 4,
      y: this.coefDoor.reDesign.attrs.y + 10,
      text: `${id}`,
      fontSize: 20,
      params: { type: group.attrs.params.type, idForDelete: id, idDoor: group.attrs.params.type, remove: true, isText: true },
      fontFamily: 'Calibri',
      fill: 'black'
    });
    const bg = new Konva.Rect({
      x: oneX - width + 4,
      y: this.coefDoor.reDesign.attrs.y + 10,
      width: 12,
      height: 18,
      params: { type: group.attrs.params.type, idForDelete: id, idDoor: group.attrs.params.type, remove: true },
      fill: 'white'
    });
    let obj;
    if (need) {
      this.coefDoor.DoorWrapper.add(Part);
    }

    const Left = this.drag.findPartLeft(yoOfObj, xOfObj, Part.name , group.attrs.params.type);
    const Right = this.drag.findPartRight(yoOfObj, xOfObj, Part.name , group.attrs.params.type);
  
    let fill ;
    let redesignNotpath = true;
      if(this.coefDoor.reDesign){
        if(this.coefDoor.reDesign.attrs.params.subId ==8 ||  this.coefDoor.reDesign.attrs.params.subId ==5 ||  this.coefDoor.reDesign.attrs.params.subId ==11 || this.coefDoor.reDesign.attrs.params.subId ==2){
          const path = this.findPath(this.coefDoor.reDesign.attrs.params.subId, this.coefDoor.reDesign.attrs.params.id,this.coefDoor.reDesign.attrs.params.position);
         if(path){
          fill = path;
          
         } else{
          redesignNotpath = false;
          fill = this.coefDoor.reDesign.attrs.fill;
         }
         part = this.coefDoor.reDesign.attrs.params.subId;
        }else{
          part = this.coefDoor.reDesign.attrs.params.subId;
          fill = this.coefDoor.reDesign.attrs.fill;
        }
      
      }else{
        fill =false
      }
      const params = {item:item, idForDelete: id,text:simpleText, isTextureReversed:false,bgText:bg, subId: part, section: true,istextured:textured, idDoor: group.attrs.params.type, type: group.attrs.params.type, index: i + 1, top: bindTopElement ? 'part' : 'fence', left: Left ? 'part' : 'fence', right: Right ? 'part' : 'fence', bottom: bindBotElement ? 'part' : 'fence',topElem:bindTopElement ? bindTopElement : false , bottomElem: bindBotElement ? bindBotElement :false, leftElem: Left ? Left : false, rightElem: Right ? Right : false }
  
      if (part != 8 && part != 5 && part != 11 && part != 2) {
      obj = await new ColorElement(this.coefDoor, this.coef).createElmentColor(part, {
        width: width,
        y: this.coefDoor.reDesign.attrs.y,
        x: Yposition,
        height: haightAllDoor,
        item: item,
        isImage:false,
        index: i + 1
      }, params , fill);
    } else {
      obj = await new ColorElement(this.coefDoor, this.coef).createElmentColor(part, {
        width: width,
        y: this.coefDoor.reDesign.attrs.y,
        x: Yposition,
        height: haightAllDoor,
        item: item,
        isImage:redesignNotpath ?  true : false,
      }, { item:item,idForDelete: id,text:simpleText, bgText:bg, isTextureReversed:false,subId: part, section: true, istextured:textured,idDoor: group.attrs.params.type, type: group.attrs.params.type, index: i + 1, top: bindTopElement ? 'part' : 'fence', left: Left ? 'part' : 'fence', right: Right ? 'part' : 'fence', bottom: bindBotElement ? 'part' : 'fence',topElem:bindTopElement ? bindTopElement : false , bottomElem: bindBotElement ? bindBotElement :false , leftElem: Left ? Left : false, rightElem: Right ? Right : false} , fill);
    }
    if(this.coefDoor.reDesign){
      if(this.coefDoor.reDesign.attrs.params.subId == 8 || this.coefDoor.reDesign.attrs.params.subId == 5 || this.coefDoor.reDesign.attrs.params.subId == 11){
        obj.attrs.params.subId = this.coefDoor.reDesign.attrs.params.subId;
        obj.attrs.params.id = this.coefDoor.reDesign.attrs.params.id;
        obj.attrs.params.position = this.coefDoor.reDesign.attrs.params.position;
        if(obj.attrs.params.istextured ){
          if(obj.attrs.params.isTextureReversed){
            if( obj.className == "Image"){
              this.rotateHtmlImageToBase64(obj.image(),90 ,(test)=>{
                const newImage = new Image();
                newImage.src = test;
                obj.image(newImage)
                this.coefDoor.DoorWrapper.batchDraw()
              })
            }
            imageNode.visible(false)
            imageNode2.visible(true)
          }else{
            imageNode.visible(true)
            imageNode2.visible(false)
          }
        }
      }else{
        obj.attrs.params.subId = this.coefDoor.reDesign.attrs.params.subId;
        obj.attrs.params.id = this.coefDoor.reDesign.attrs.params.id;
        obj.attrs.params.istextured = this.coefDoor.reDesign.attrs.params.istextured;
        obj.attrs.params.isTextureReversed = this.coefDoor.reDesign.attrs.params.isTextureReversed;
        if(this.coefDoor.reDesign.attrs.params.printId){
          obj.attrs.params.printId = this.coefDoor.reDesign.attrs.params.printId;
          obj.attrs.params.position = this.coefDoor.reDesign.attrs.params.position;
        }
        if(obj.attrs.params.istextured ){
          if(obj.attrs.params.isTextureReversed){
            if( obj.className == "Image"){
              this.rotateHtmlImageToBase64(obj.image(),90 ,(test)=>{
                const newImage = new Image();
                newImage.src = test;
                obj.image(newImage)
                this.coefDoor.DoorWrapper.batchDraw()
              })
            }
            imageNode.visible(false)
            imageNode2.visible(true)
          }else{

            imageNode.visible(true)
            imageNode2.visible(false)
          }
        }
      }
    }



  
    imageNodeFilte.setAttrs({
      width: width,
      y: this.coefDoor.reDesign.attrs.y,
      x: Yposition,
      height: haightAllDoor,
      opacity : 0.5,
      visible:  false 
    });
   
    if(this.coefDoor.reDesign){
      if(this.coefDoor.reDesign.attrs.params.subId ==10 || this.coefDoor.reDesign.attrs.params.subId == 1 || this.coefDoor.reDesign.attrs.params.subId == 4){
        imageNodeFilte.visible(true)
      }
    }else{
      if(part ==10 || part == 1 || part == 4){
        imageNodeFilte.visible(true)
      }
    }
   
    if(this.coefDoor.reDesign){
      if(this.coefDoor.reDesign.attrs.params.printId){  
        imageNodeFilte.visible(true)
      }
    }
     // @ts-ignore
     imageNodeFilte.attrs.params = { idForDelete: id, type: group.attrs.params.type, idDoor: group.attrs.params.type, remove: true ,isFilter: true}
    obj.attrs.params.filter = imageNodeFilte;
    obj.attrs.params.texturedV = imageNode; 
    obj.attrs.params.texturedH = imageNode2;
    // @ts-ignore
    imageNodeFilte.attrs.params.objectSelect = obj;
    // @ts-ignore
   imageNode2.attrs.params.objectSelect = obj;
   // @ts-ignore
   imageNode.attrs.params.objectSelect = obj;
    if (need) {
      this.drag.bindElementV(bg, simpleText, obj, Part, Left, false);
    } else {
      const bottomY = this.coefDoor.reDesign.attrs.x + this.coefDoor.reDesign.attrs.width;
      if(Right){
        this.drag.bindElementV(bg, simpleText, obj, Right, Left, false);
      }else{
        this.drag.bindElementV(bg, simpleText, obj, false, Left, bottomY);
      }
    }

    if (bindTopElement) {
      if (!bindTopElement.attrs.params.bottomElem) bindTopElement.attrs.params.bottomElem = []
      bindTopElement.attrs.params.bottomElem.push(obj)
      bindTopElement.attrs.params.bottomElem.push(Part)

    }



    if (bindBotElement) {
      if (!bindBotElement.attrs.params.topElem) bindBotElement.attrs.params.topElem = []
      bindBotElement.attrs.params.topElem.push(obj)
      bindBotElement.attrs.params.topElem.push(Part)
    }

    this.coefDoor.DoorWrapper.add(obj);
    this.coefDoor.DoorWrapper.batchDraw();
    this.coefDoor.DoorWrapper.add(imageNode2 , imageNode , imageNodeFilte)
    this.coefDoor.DoorWrapper.add(bg, simpleText);
    this.coefDoor.DoorWrapper.batchDraw();

    
      const sizeV = new Sizes(this.coef, this.create , this.coefDoor).createOneSizeHorizontDoor({ left: Left ? Left : group }, obj,  {
        remove: true,
        type: group.attrs.params.type,
        idForDelete: id,
        idDoor: group.attrs.params.type
      } , 'h');
      sizeV.x(obj.x());
      sizeV.y(obj.y() + obj.height() - 14);
      this.coefDoor.DoorWrapper.add(sizeV);
      if (sizeV) {
          obj.attrs.params.sizeV = sizeV;
      }
  
    if (bindTopElement) {
      const size = new Sizes(this.coef, this.create).createOneSizeVerticalDoor({ top: bindTopElement }, obj, false, {
        remove: true,
        type: group.attrs.params.type,
        idForDelete: id,
        idDoor: group.attrs.params.type
      });
      size.x(size.x() + obj.attrs.width +10 );
      this.coefDoor.DoorWrapper.add(size);
      if (size) {
          obj.attrs.params.sizeH = size;
      }
    } else {
      const size = new Sizes(this.coef, this.create).createOneSizeVerticalDoor({ top: group }, obj, false, {
        remove: true,
        type: group.attrs.params.type,
        idForDelete: id,
        idDoor: group.attrs.params.type
      });
      size.y(group.attrs.y + this.coefDoor.glob_settings.top_plant_height / this.coef.global_coef);
      size.x(size.x() + obj.attrs.width + 10);
      this.coefDoor.DoorWrapper.add(size);
      if (size) {
        obj.attrs.params.sizeH = size;
      }
    }
    this.price.sendObjectPrice();
  }

  async createPart(i, item, oneY, group, part, height, need, first?, bottomY?) {
    this.coefDoor.name++;
    const right_width_hang = this.coef.convertCoef(this.coefDoor.glob_settings.right_hang_width);
    let widthAllDoor;
    let baseX;
    if (this.coefDoor.reDesign) {
      widthAllDoor = this.coefDoor.reDesign.attrs.width;
      baseX = this.coefDoor.reDesign.attrs.x;
    }
    const left_width_hang = this.coef.convertCoef(this.coefDoor.glob_settings.left_hang_width);
    const width = group.attrs.width - right_width_hang - left_width_hang;
    const name = 'door' + this.coefDoor.name;
    const This = this;
    const yoOfObj = oneY - height;
    const xOfObj = baseX || group.attrs.x + left_width_hang;
    const Left = this.drag.findPartLeft(yoOfObj, xOfObj, name , group.attrs.params.type);
    const Right = this.drag.findPartRight(yoOfObj, xOfObj,name, group.attrs.params.type);
    const Part = new Konva.Rect({
      x: baseX || group.attrs.x + left_width_hang,
      y: oneY,
      width: widthAllDoor ? widthAllDoor : width,
      height: this.height,
      name: name,
      params: {
        part: true,
        typeDoor: item.id,
        type: group.attrs.params.type,
        idDoor: group.attrs.params.type,
        remove: true,
        horizon:true,
        position: i + 1,
        left: Left ? 'part' : 'fence',
        right: Right ? 'part' : 'fence',
      },
      draggable: true,
      dragBoundFunc: function (pos) {
        return {
          x: this.getAbsolutePosition().x,
          y: pos.y,
        };
      },
      fill: this.coefDoor.colorSystem,
      strokeWidth: 1,
      stroke: 'black'
    });
    const stopPosition = { top: null, bottom: null };
    if (!item.element_min_height) {
      stopPosition.top = item.flat_element_min_height / this.coef.global_coef;
      stopPosition.bottom = item.flat_element_max_height / this.coef.global_coef;
    }
    Part['attrs'].dragBoundFunc = function (pos) {
      let top = This.drag.findPart(Part.y(), Part.x(), name);
      let bottom = This.drag.findPartbottom(Part.y(), Part.x(), name);

      if (!top ) {
        top = { attrs: { y: group.attrs.y, height:  This.coef.convertCoef(This.coefDoor.glob_settings.top_plant_height) } }
      }
      if (!bottom ) {
       
        bottom = { attrs: { y: group.attrs.y + This.coefDoor.heightDoor  - This.coef.convertCoef(This.coefDoor.glob_settings.bottom_plant_height)  , height: This.coef.convertCoef(This.coefDoor.glob_settings.bottom_plant_height)  } }
      }

      return {
        x: this.getAbsolutePosition().x,
        y: This.findStopPosition(pos, group, {
          top: top,
          bottom: bottom
        }, This.coef.convertCoef(This.coefDoor.glob_settings.min_h_section_height),Part)
      };
    };
    let Yposition = oneY - height;
    if (first) {
      Yposition = oneY - height;
    }
    if (bottomY) {
      height = bottomY;
    }
    // const id  = i + 1;
    const id = Date.now().toString() + i.toString();
    var SOURCE = 'assets/img/texture/left-right.svg';
    const imageNode2 = await this.loadImage(SOURCE);
  
    const mat = this.findMat(item.content[0],1 , this.coefDoor.facade_materials_system)
    const matDef = this.findMat(mat,1 , this.coefDoor.facade_materials)

    const textured = matDef.default_option.textured
   
    imageNode2.setAttrs({
      width: widthAllDoor || width,
      height: 20,
      x: baseX || group.attrs.x + left_width_hang,
      y: Yposition ,
      visible:  textured
    });
    // @ts-ignore
    imageNode2.attrs.params = { idForDelete: id, type: group.attrs.params.type, idDoor: group.attrs.params.type, remove: true , isTexture:true }
  
    var SOURCE22 = 'assets/img/texture/sclo.jpg';
    if(this.coefDoor.reDesign){
      if(this.coefDoor.reDesign.attrs.params.printId){
        SOURCE22 = this.findPathCustom(this.coefDoor.reDesign.attrs.params.printId , this.coefDoor.reDesign.attrs.params.position)
      }
    }
    const imageNodeFilte = await this.loadImage(SOURCE22);

    var SOURCE2 = 'assets/img/texture/index.svg';
    const imageNode = await this.loadImage(SOURCE2);
    imageNode.setAttrs({
      width:  20,
      height: height,
      x: baseX || group.attrs.x + left_width_hang,
      y: Yposition,
      visible:  false 
    });
     // @ts-ignore
    imageNode.attrs.params = { idForDelete: id, type: group.attrs.params.type, idDoor: group.attrs.params.type, remove: true, isTexture:true }
    const simpleText = new Konva.Text({
      x: baseX? baseX + 6 : group.attrs.x + left_width_hang + 15,
      y: Yposition + 10,
      text: `${id}`,
      fontSize: 20,
      params: { idForDelete: id, type: group.attrs.params.type, idDoor: group.attrs.params.type, remove: true, isText: true },
      fontFamily: 'Calibri',
      fill: 'black'
    });
    const bg = new Konva.Rect({
      x: baseX? baseX +6 : group.attrs.x + left_width_hang + 15,
      y: Yposition + 10,
      width: 12,
      height: 18,
      params: { idForDelete: id, type: group.attrs.params.type, idDoor: group.attrs.params.type, remove: true },
      fill: 'white'
    });
    let obj;
    if (need) {
      this.coefDoor.DoorWrapper.add(Part);
    }
  
    const bindTopElement = this.drag.findPart(yoOfObj, xOfObj, Part.name);
    const bindBotElement = this.drag.findPartbottom(yoOfObj, xOfObj, Part.name);
    let fill ;
    let redesignNotpath = true;
    if(this.coefDoor.reDesign){
      if(this.coefDoor.reDesign.attrs.params.subId ==8 ||  this.coefDoor.reDesign.attrs.params.subId ==5 ||  this.coefDoor.reDesign.attrs.params.subId ==11  || this.coefDoor.reDesign.attrs.params.subId ==2){
        const path = this.findPath(this.coefDoor.reDesign.attrs.params.subId, this.coefDoor.reDesign.attrs.params.id,this.coefDoor.reDesign.attrs.params.position);
        if(path){
          fill = path;
         } else{
          redesignNotpath = false;
          fill = this.coefDoor.reDesign.attrs.fill;
         }
        part = this.coefDoor.reDesign.attrs.params.subId;
      }else{
        part = this.coefDoor.reDesign.attrs.params.subId;
        fill = this.coefDoor.reDesign.attrs.fill;
      }
    
    }else{
      fill =false
    }

    if (part != 8 && part != 5 && part != 11 && part != 2) {
      obj = await new ColorElement(this.coefDoor, this.coef).createElmentColor(part, {
        width: widthAllDoor || width,
        y: Yposition,
        x: baseX || group.attrs.x + left_width_hang,
        height: height,
        item: item,
        isImage:false,
        index: i + 1
      }, { item:item,idForDelete: id, text:simpleText, bgText:bg, subId: part, section: true,isTextureReversed:false, istextured:textured, idDoor: group.attrs.params.type, type: group.attrs.params.type, index: i + 1, top: bindTopElement ? 'part' : 'fence', left: Left ? 'part' : 'fence', right: Right ? 'part' : 'fence', bottom: bindBotElement ? 'part' : 'fence' , topElem:bindTopElement ? bindTopElement : false , bottomElem: bindBotElement ? bindBotElement :false, leftElem: Left ? Left : false, rightElem: Right ? Right : false},fill);
    } else {
      obj = await new ColorElement(this.coefDoor, this.coef).createElmentColor(part, {
        width: widthAllDoor || width,
        y: Yposition,
        x: baseX || group.attrs.x + left_width_hang,
        height: height,
        isImage: redesignNotpath ?  true : false,
        item: item,
      }, {item:item, idForDelete: id,text:simpleText, bgText:bg,isTextureReversed:false, subId: part, section: true,istextured:textured, idDoor: group.attrs.params.type, type: group.attrs.params.type, index: i + 1, top: bindTopElement ? 'part' : 'fence', left: Left ? 'part' : 'fence', right: Right ? 'part' : 'fence', bottom: bindBotElement ? 'part' : 'fence'  , topElem:bindTopElement ? bindTopElement : false , bottomElem: bindBotElement ? bindBotElement :false, leftElem: Left ? Left : false, rightElem: Right ? Right : false } ,fill);
    }
    if(this.coefDoor.reDesign){
      if(this.coefDoor.reDesign.attrs.params.subId == 8 || this.coefDoor.reDesign.attrs.params.subId == 5 || this.coefDoor.reDesign.attrs.params.subId == 11){
        obj.attrs.params.subId = this.coefDoor.reDesign.attrs.params.subId;
        obj.attrs.params.id = this.coefDoor.reDesign.attrs.params.id;
        obj.attrs.params.position = this.coefDoor.reDesign.attrs.params.position;
        if(obj.attrs.params.istextured ){
          if(obj.attrs.params.isTextureReversed){
            if( obj.className == "Image"){
              this.rotateHtmlImageToBase64(obj.image(),90 ,(test)=>{
                const newImage = new Image();
                newImage.src = test;
                obj.image(newImage)
                this.coefDoor.DoorWrapper.batchDraw()
              })
            }
            imageNode.visible(false)
            imageNode2.visible(true)
          }else{
            imageNode.visible(true)
            imageNode2.visible(false)
          }
        }
      }else{
        obj.attrs.params.subId = this.coefDoor.reDesign.attrs.params.subId;
        obj.attrs.params.id = this.coefDoor.reDesign.attrs.params.id;
        obj.attrs.params.istextured = this.coefDoor.reDesign.attrs.params.istextured;
        obj.attrs.params.isTextureReversed = this.coefDoor.reDesign.attrs.params.isTextureReversed;
        if(this.coefDoor.reDesign.attrs.params.printId){
          obj.attrs.params.printId = this.coefDoor.reDesign.attrs.params.printId;
          obj.attrs.params.position = this.coefDoor.reDesign.attrs.params.position;
        }
        if(obj.attrs.params.istextured ){
          if(obj.attrs.params.isTextureReversed){
            if( obj.className == "Image"){
              this.rotateHtmlImageToBase64(obj.image(),90 ,(test)=>{
                const newImage = new Image();
                newImage.src = test;
                obj.image(newImage)
                this.coefDoor.DoorWrapper.batchDraw()
              })
            }
           
            imageNode.visible(false)
            imageNode2.visible(true)
          }else{
            imageNode.visible(true)
            imageNode2.visible(false)
          }
        }
      }
    }


  
    imageNodeFilte.setAttrs({
      width: widthAllDoor || width,
      y: Yposition,
      x: baseX || group.attrs.x + left_width_hang,
      height: height,
      opacity : 0.5,
      visible:  false 
    });
    if(this.coefDoor.reDesign){
      if(this.coefDoor.reDesign.attrs.params.subId ==10 || this.coefDoor.reDesign.attrs.params.subId == 1 || this.coefDoor.reDesign.attrs.params.subId == 4){
        imageNodeFilte.visible(true)
      }
    }else{
      if(part ==10 || part == 1  || part == 4){
        imageNodeFilte.visible(true)
      }
    }
    if(this.coefDoor.reDesign){
      if(this.coefDoor.reDesign.attrs.params.printId){  
        imageNodeFilte.visible(true)
      }
    }
     // @ts-ignore
     imageNodeFilte.attrs.params = { idForDelete: id, type: group.attrs.params.type, idDoor: group.attrs.params.type, remove: true , isFilter:true }

    obj.attrs.params.texturedV = imageNode;
    obj.attrs.params.texturedH = imageNode2;
    obj.attrs.params.filter = imageNodeFilte;
       // @ts-ignore
       imageNodeFilte.attrs.params.objectSelect = obj;
       // @ts-ignore
    imageNode2.attrs.params.objectSelect = obj;
    // @ts-ignore
    imageNode.attrs.params.objectSelect = obj;
    const TopBind = this.drag.findPart(obj.y(), obj.x(), Part.name);
    if (need) {
      this.drag.bindElement(bg, simpleText, obj, Part, TopBind, false);
    } else {
    
      if(this.coefDoor.reDesign){
        const bottomY = this.coefDoor.reDesign.attrs.y + this.coefDoor.reDesign.attrs.height;
        if(bindBotElement){
          this.drag.bindElement(bg, simpleText, obj, bindBotElement, TopBind, false);
        }else{
          this.drag.bindElement(bg, simpleText, obj, false, TopBind, bottomY);
        }
      }else{
        const bottomY = group.attrs.y + this.coefDoor.heightDoor - this.coef.convertCoef(this.coefDoor.glob_settings.bottom_plant_height);
        this.drag.bindElement(bg, simpleText, obj, false, TopBind, bottomY);
      }
  
    }

    const bindTopElementtest = this.drag.findPart(yoOfObj, xOfObj, 'Part.name');
    const bindBotElementtest = this.drag.findPartbottom(yoOfObj, xOfObj, 'Part.name');

    if (bindTopElementtest) {
      if (!bindTopElementtest.attrs.params.bottomElemTextured) bindTopElementtest.attrs.params.bottomElemTextured = []
      bindTopElementtest.attrs.params.bottomElemTextured.push(obj)
    }

    if (bindBotElementtest) {
      if (!bindBotElementtest.attrs.params.topElemTextured) bindBotElementtest.attrs.params.topElemTextured = []
      bindBotElementtest.attrs.params.topElemTextured.push(obj)
    }

    if (Left) {
      if (!Left.attrs.params.rightElem) Left.attrs.params.rightElem = []
      Left.attrs.params.rightElem.push(obj)
      Left.attrs.params.rightElem.push(Part)

    }

    if (Right) {
      if (!Right.attrs.params.leftElem) Right.attrs.params.leftElem = []
      Right.attrs.params.leftElem.push(obj)
      Right.attrs.params.leftElem.push(Part)
    }
    this.coefDoor.DoorWrapper.add(obj);
    this.coefDoor.DoorWrapper.batchDraw();
    this.coefDoor.DoorWrapper.add(imageNode, imageNode2,imageNodeFilte);
    this.coefDoor.DoorWrapper.add(bg, simpleText);
    this.coefDoor.DoorWrapper.batchDraw();
    const sizeV = new Sizes(this.coef, this.create, this.coefDoor).createOneSizeHorizontDoor({ left: Left ? Left : group }, obj,  {
      remove: true,
      type: group.attrs.params.type,
      idForDelete: id,
      idDoor: group.attrs.params.type
    } ,'v');
    sizeV.x(obj.x());
    sizeV.y(obj.y() + obj.height() - 14);
    this.coefDoor.DoorWrapper.add(sizeV);
    if (sizeV) {
        obj.attrs.params.sizeV = sizeV;
    }
    if (TopBind) {
      const size = new Sizes(this.coef, this.create).createOneSizeVerticalDoor({ top: TopBind }, obj, false, {
        remove: true,
        type: group.attrs.params.type,
        idForDelete: id,
        idDoor: group.attrs.params.type
      });
      size.x(size.x() + obj.attrs.width - 1);
      this.coefDoor.DoorWrapper.add(size);
      if (size) {
        obj.attrs.params.sizeH = size;
      }
      if (size) {
        if (need) {
          this.drag.bindElement(bg, simpleText, obj, Part, TopBind, false, size);
        } else {
       
          if(this.coefDoor.reDesign){
            const bottomY = this.coefDoor.reDesign.attrs.y + this.coefDoor.reDesign.attrs.height;
            if(bindBotElement){
              this.drag.bindElement(bg, simpleText, obj, bindBotElement, TopBind, false, size);
            }else{
              this.drag.bindElement(bg, simpleText, obj, false, TopBind, bottomY, size);
            }
          }else{
            const bottomY = group.attrs.y + this.coefDoor.heightDoor - this.coef.convertCoef(this.coefDoor.glob_settings.bottom_plant_height);
            this.drag.bindElement(bg, simpleText, obj, false, TopBind, bottomY, size);
          }
        }
      }
    } else {
      const size = new Sizes(this.coef, this.create).createOneSizeVerticalDoor({ top: group }, obj, false, {
        remove: true,
        type: group.attrs.params.type,
        idForDelete: id,
        idDoor: group.attrs.params.type
      });
      size.y(group.attrs.y + this.coefDoor.glob_settings.top_plant_height / this.coef.global_coef);
      size.x(size.x() + obj.attrs.width - 1);
      this.coefDoor.DoorWrapper.add(size);
      if (size) {
        obj.attrs.params.sizeH = size;
      }
      if (size) {
        if (need) {
          this.drag.bindElement(bg, simpleText, obj, Part, TopBind, false, size);
        } else {
          if(this.coefDoor.reDesign){
            const bottomY = this.coefDoor.reDesign.attrs.y + this.coefDoor.reDesign.attrs.height;
            if(bindBotElement){
              this.drag.bindElement(bg, simpleText, obj, bindBotElement, TopBind, false, size);
            }else{
              this.drag.bindElement(bg, simpleText, obj, false, TopBind, bottomY, size);
            }
          }else{
            const bottomY = group.attrs.y + this.coefDoor.heightDoor - this.coef.convertCoef(this.coefDoor.glob_settings.bottom_plant_height);
            this.drag.bindElement(bg, simpleText, obj, false, TopBind, bottomY, size);
          }
         
        }
      }
    }
    this.price.sendObjectPrice();
  }
  rotateHtmlImageToBase64(htmlImage, degrees, callback) {
    // Створіть новий об'єкт зображення
    var img = new Image();
  
    // Встановіть обробник завантаження зображення
    img.onload = function () {
      // Створіть canvas для обернутого зображення
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
  
      // Оберніть зображення
      canvas.width = degrees % 180 === 0 ? img.width : img.height;
      canvas.height = degrees % 180 === 0 ? img.height : img.width;
  
      if (degrees === 90) {
        context.rotate(Math.PI / 2);
        context.drawImage(img, 0, -img.height);
      } else if (degrees === 180) {
        context.rotate(Math.PI);
        context.drawImage(img, -img.width, -img.height);
      } else if (degrees === 270) {
        context.rotate((3 * Math.PI) / 2);
        context.drawImage(img, -img.width, 0);
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);
      }
  
      // Отримайте base64 зображення з canvas
      var base64 = canvas.toDataURL('image/jpeg'); // Замініть на інший тип, якщо потрібно
  
      // Викличте зворотну функцію та передайте base64
      callback(base64);
    };
    img.crossOrigin = 'Anonymous';
    // Встановіть посилання на зображення об'єкта <img>
    img.src = htmlImage.src;

  }
  findMat(id , secondId , array){
    for(const item of array){
      if(item.id == id){
          return item
      } 
    }
    for(const item of array){
      if(item.id == secondId){
        return item;
      }
    }
    
    return this.coefDoor.facade_materials[0]
    }
  loadImage(src: string): Promise<Konva.Image> {
    return new Promise((resolve, reject) => {
      Konva.Image.fromURL(src, (imageNode:any) => {
        resolve(imageNode);
      });
    });
  }
  findStopPosition(pos, group, parent, height, part) {
    let position = pos.y;
    let newHeightTop = height; 
    let newHeightBottom = height;
    const newHeightSeparator = this.coefDoor.heightSeparator / this.coef.global_coef;
   
    if(part.attrs.params.topElem && part.attrs.params.topElem.length > 1){
      newHeightTop = this.coef.convertCoef(this.coefDoor.glob_settings.min_nested_v_section_height) 
     
    }
    if(part.attrs.params.bottomElem && part.attrs.params.bottomElem.length > 1){
      newHeightBottom = this.coef.convertCoef(this.coefDoor.glob_settings.min_nested_v_section_height) 
    }
    
      if (pos.y < parent.top.attrs.y + parent.top.attrs.height + newHeightTop) {
        position = parent.top.attrs.y + parent.top.attrs.height + newHeightTop;
      } else if (pos.y > parent.bottom.attrs.y - newHeightBottom - newHeightSeparator) {
        position = parent.bottom.attrs.y - newHeightBottom - newHeightSeparator;
      } else {
        position = pos.y;
      }
      if(part.attrs.params.topElem){
        const isTextured  = this.findTextured(part.attrs.params.topElem);
        if(isTextured){
          this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height)
          if(pos.y > parent.top.attrs.y + parent.top.attrs.height + this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height)){
            if(position > parent.top.attrs.y +parent.top.attrs.height + this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height)){
              position = parent.top.attrs.y+parent.top.attrs.height + this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height);
            }
             
          }
        }
      }
      if(part.attrs.params.topElemTextured){
        const isTextured  = this.findTextured(part.attrs.params.topElemTextured);
        if(isTextured){
          this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height)
          if(pos.y > parent.top.attrs.y + parent.top.attrs.height + this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height)){
            if(position > parent.top.attrs.y +parent.top.attrs.height + this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height)){
              position = parent.top.attrs.y+parent.top.attrs.height + this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height);
            }
             
          }
        }
      }
      if(part.attrs.params.bottomElem){
        const isTextured  = this.findTextured(part.attrs.params.bottomElem);
        if(isTextured){
          this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height)
          if(pos.y < parent.bottom.attrs.y - newHeightSeparator - this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height)){
            if(position < parent.bottom.attrs.y -newHeightSeparator - this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height)){
              position = parent.bottom.attrs.y-newHeightSeparator - this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height);
            }
          }
        }
      }
      if(part.attrs.params.bottomElemTextured){
        const isTextured  = this.findTextured(part.attrs.params.bottomElemTextured);
        if(isTextured){
          this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height)
          if(pos.y < parent.bottom.attrs.y - newHeightSeparator - this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height)){
            if(position < parent.bottom.attrs.y -newHeightSeparator - this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height)){
              position = parent.bottom.attrs.y-newHeightSeparator - this.coef.convertCoef(this.coef.mainSetting.texture_rotation_max_height);
            }
          }
        }
      }
      
    
    return position;
  }
findTextured(array){
  for(const item of array){

    
    if(item.attrs && item.attrs.params&& item.attrs.params.isTextureReversed){
      return true;
    }
  }
return false;
}
  findStopPositionRight(pos, group, parent, width , part) {
    let position = pos.x;
    let newWidthRight = width; 
    let newWidthLeft = width;
   
    if(part.attrs.params.rightElem && part.attrs.params.rightElem.length > 1){
      newWidthRight = this.coef.convertCoef(this.coefDoor.glob_settings.min_nested_h_section_width) 
    }
    if(part.attrs.params.leftElem && part.attrs.params.leftElem.length > 1){
      newWidthLeft = this.coef.convertCoef(this.coefDoor.glob_settings.min_nested_h_section_width) 
    }
      if (pos.x < parent.left.attrs.x + parent.left.attrs.width + newWidthLeft ) {
        position = parent.left.attrs.x + parent.left.attrs.width + newWidthLeft ;
      } else if (pos.x > parent.right.attrs.x - newWidthRight - parent.right.attrs.width) {
        position = parent.right.attrs.x - newWidthRight -  (this.coefDoor.heightSeparator / this.coef.global_coef);
      } else {
        position = pos.x;
      }
      
   
    return position;
  }

}
function removeItemsWithCommonSubId(items, subId, idDoor) {
  for (let i = items.length - 1; i >= 0; i--) {
    if (items[i].attrs && items[i].attrs.params && items[i].attrs.params.id && items[i].attrs.params.idForDelete === subId && items[i].attrs.params.idDoor === idDoor) {
      items[i].remove();
    }
  }
}