import {Component, Input, OnInit} from '@angular/core';
import {RoutStepService} from '../../../service/rout-step.service';
import * as _ from 'lodash';
import {CoefService} from '../../../service/coef.service';
import {FADE_IN_OUT} from '../../../common/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {MechanicianComponent} from '../../../mechanician/mechanician.component';
import {CoefDoorService} from '../../../service/door service/coef-door.service';
import {InitRenderService} from '../../../service/renderingService/init-render.service';

@Component({
  selector: 'app-calculator-steps',
  templateUrl: './calculator-steps.component.html',
  styleUrls: ['./calculator-steps.component.css'],
  animations: [FADE_IN_OUT]
})
export class CalculatorStepsComponent implements OnInit {
  @Input() type = false;
  first = true;

  constructor(private routs: RoutStepService,
              private coef: CoefService,
              private router: Router,
              private doorCoef: CoefDoorService,
              private render: InitRenderService,
              private route: ActivatedRoute,) {
    this.routs.routObject.subscribe((id) => {
      this.init(id);
    });
    this.routs.firstTab.subscribe(() => {
      this.first = false;
      this.go(false, true);
    });
  }

  activeIndex = 0;
  array = [
    {active: false, settings: true, text: 'габарити'},
    {active: false, settings: true, text: 'наповнення'},
    {active: false, settings: true, text: 'двері'},
    {active: false, settings: true, text: 'підсумок'}
  ];
  modal = false;

  ngOnInit() {
    if (this.routs._routOrder) {
      this.init(this.routs._routOrder);
    }
  }

  init(id) {
    this.array = _.cloneDeep(this.routs.setting[id]);
  }

  go(dir, type?) {
    for (let i = 0; i < this.array.length; i++) {
      if (this.array[i].active) {
        this.activeIndex = i;
      }
    }
    this.direction(this.activeIndex, dir);
    if (!type) {
      this.rout(dir);
    }
  }

  clear() {
    if (this.coef.stage) {
      for (let i = this.coef.look2d.children.length - 1; i >= 0; i--) {
        this.coef.look2d.children[i].destroy();
      }
      for (let i = this.coef.GlobalSize['children'].length - 1; i >= 0; i--) {
        this.coef.GlobalSize['children'][i].destroy();
      }
      for (const item of this.coef.stage.stage.children) {
        item.destroy();
      }
    }
    if (this.doorCoef.doorStag.stage) {
      for (let i = this.doorCoef.doorStag.stage.children.length - 1; i >= 0; i--) {
        this.doorCoef.doorStag.stage.children[i].destroy();
      }
    }
    this.direction(1, true, true);
    this.rout(true);
    const isClientPage = this.router.url.indexOf('calculator') !== -1;
    if (isClientPage) {
      this.route.queryParams.subscribe(params => {
        this.router.navigateByUrl('order-type-calculator');
        setTimeout(() => {
          if (this.render.rerender) {
            this.render.rerender = false;
            this.render.changeSize = true;
          }
          this.router.navigateByUrl(`calculator?id=${params.id}`, {queryParams: {id: params.id}});
        });
        this.modal = false;
      });
    } else {
      this.route.queryParams.subscribe(params => {
        this.router.navigateByUrl('profile');
        setTimeout(() => {
          if (this.render.rerender) {
            this.render.rerender = false;
            this.render.changeSize = true;
          }
          this.router.navigateByUrl(`designer?id=${params.id}`, {queryParams: {id: params.id}});
        });
        this.modal = false;
      });
    }
  }

  direction(i, dir, type?) {
    if (dir) {
      if (i === 1) {
        this.modal = true;
        if (!type) {
          return;
        }
      }
      this.array[i - 1].active = true;
      this.array[i].active = false;
      return;
    }
    if (this.first) {
      return;
    }
    if (i === this.array.length - 1) {
      return;
    }
    this.array[i + 1].active = true;
    this.array[i].active = false;
  }

  rout(dir) {
    let index;
    for (const item of this.array) {
      if (item.active) {
        index = item['index'];
      }
    }
    this.routs.goNextStep({index: index, dir: dir});
  }
}
