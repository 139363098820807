import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {baseUrl} from '../../router.path';
import {HttpClient} from '@angular/common/http';
import {API} from '../../common/api';
import {ErrorService} from '../../service/error.service';
import {NotificationService} from '../../service/notification.service';

@Component({
  selector: 'app-forgot-pass-modal',
  templateUrl: './forgot-pass-modal.component.html',
  styleUrls: ['./forgot-pass-modal.component.css']
})
export class ForgotPassModalComponent {
  public email: string;

  @Output() modalClosed: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient,
              private notification: NotificationService) { }

  onSubmit() {
    this.http.post(baseUrl.local + API.RESTORE_PASS, {'email': this.email}).subscribe(res => {
      this.notification.triggerNotification(res['payload'], true);
    }, error => {
      this.notification.triggerNotification(error['error']['error'], true);
    });
    this.modalClosed.emit();
  }

}
