import {Component, OnInit} from '@angular/core';
import {CoefService} from '../../service/coef.service';

@Component({
  selector: 'app-shelf-is-removable',
  templateUrl: './shelf-is-removable.component.html',
  styleUrls: ['./shelf-is-removable.component.scss']
})
export class ShelfIsRemovableComponent implements OnInit {

  constructor(public coef: CoefService) {
  }

  ngOnInit() {
  }

}
