import {Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CoefService} from '../coef.service';
import {document} from 'ngx-bootstrap/utils/facade/browser';

// import _$ from 'jquery';

@Directive({
  selector: '[appDragContent]'
})
export class DragContentDirective implements OnInit, OnDestroy {
  @Input() appDragContent = null;
  @Output() dragMove = new EventEmitter();
  @Output() dragStart = new EventEmitter();
  @Output() dragEnd = new EventEmitter();

  private dragging = false;
  private img;
  params;
  element;
  index;
  div;
  stop;

  @HostListener('pointerdown', ['$event']) onPointerDown(event): void {
    if (this.params) {
      console.log(this.params);
      this.dragging = true;
      for (const item of  this.coef.product.children) {
        if (item.attrs.params.type === this.params.id) {
          if (this.params.subId) {
            if (this.params.subId === item.attrs.params.subId) {
              console.log(item.attrs ,'item.attrs.params.subId')
              this.element = item;
              this.index = this.coef.product.children.indexOf(item);
              const drag = new Event('dragstart');
              this.element.dispatchEvent(drag);
              if (this.isActive(this.params.id, this.params.subId)) {
                this.create(this.element, {top: event.clientY, left: event.clientX});
              } else {
                this.stop = true;
              }
            }
          } else {
            this.index = this.coef.product.children.indexOf(item);
            this.element = item;
            const drag = new Event('dragstart');
            this.element.dispatchEvent(drag);
            if (this.isActive(this.params.id)) {
              this.create(this.element, {top: event.clientY, left: event.clientX});
            } else {
              this.stop = true;
            }
          }
        }
      }
    }
  }

  isActive(id, subid?) {
    console.log(id, subid);
    for (const item of this.coef.arrayOfsset) {
      if (subid) {
        if (id === item.id) {
          for (const subItem of item.sub_items) {
            if (subItem.id === subid) {
              return subItem.active;
            }
          }
        }
      }
      if (id === item.id) {
        return item.active;
      }
    }
  }

  @HostListener('document:pointermove', ['$event']) onPointerMove(event): void {
    if (!this.dragging) {
      return;
    }
    this.coef.startRemove = false;
    if (!this.stop) {
      const heightHeader = document.getElementsByClassName('custom_header')[0].clientHeight;
      const heightHeaderTab = document.getElementsByClassName('nav-tabs')[0].clientHeight;
      const Y = event.clientY - heightHeader - heightHeaderTab + window.scrollY;
      this.element.y(Y);
      this.element.x(event.clientX);
      this.div.style.top = `${event.clientY}px`;
      this.div.style.left = `${event.clientX}px`;
      const drag = new Event('dragmove');
      this.element.dispatchEvent(drag);
      this.coef.product.batchDraw();
    }

  }

  @HostListener('document:pointerup', ['$event']) onPointerUp(event): void {
    if (!this.dragging) {
      return;
    }
    this.dragging = false;
    this.dragEnd.emit(this.params);
    if (!this.stop) {
      this.deleteImg();
    }
  }

  constructor(private coef: CoefService, private el: ElementRef) {
    el.nativeElement.style.backgroundColor = 'yellow';
    this.img = el;

  }

  ngOnInit() {
    this.params = this.appDragContent;
  }

  create(elem, position) {
    console.log(elem, position, 'testings create');
    const svg = this.img.nativeElement.children[0].innerHTML;
    this.div = document.createElement('div');
    this.div.innerHTML = svg;
    this.div.setAttribute('style', `position:fixed; top:${position.top}px;left:${position.left }px; width:${elem.attrs.width}px; height:${elem.attrs.height}; z-index:1000;`);
    this.div.id = 'blockDrag';
    this.div.className = 'blockDrag2';
    document.body.appendChild(this.div);
  }

  deleteImg() {
    const drag = new Event('dragend');
    const array = document.getElementsByClassName('blockDrag2');
    for (const item of array) {
      item.remove();
    }
    if (document.getElementById('blockDrag')) {
      document.getElementById('blockDrag').remove();
    }

    this.element.dispatchEvent(drag);
    this.coef.shapesLayerw.batchDraw();
  }

  ngOnDestroy() {
    this.onPointerDown = () => {
    };
    this.onPointerMove = () => {
    };
    this.onPointerUp = () => {
    };
  }
}
