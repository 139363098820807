import {Create} from './create';
import * as Konva from '../../assets/konva';
import {Rect} from '../../assets/konva';

export class RetractablePipe extends Create {
  group;
  coef;
  create;
  top = 27;
  subId;
  vvQuantityMap = {
    1: {subId: 11, width: 10},
    2: {subId: 12, width: 27},
    3: {subId: 13, width: 47},
    4: {subId: 14, width: 67},
    5: {subId: 15, width: 87},
  };

  constructor(coef, create, number, subId, active) {
    super(coef);
    this.coef = coef;
    this.create = create;
    this.subId = subId;
    this.createRetractablePipe(number, active);
  }

  createRetractablePipeApi(pos, number, xy) {
    this.coef.countNameElement++;
    const contours = this.create.getTangentElement(pos, this.group, this.coef.shapesLayerw);
    if (contours && this.topBottomTangent(contours) && this.limitAddElement(xy, this.coef.id_element.retractable_pipe, contours)) {
      const widthCounters = contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat;
      if ((widthCounters / this.coef.steppipe) >= number) {
        this.coef.countNameElement++;
        const groupPipe = new Konva.Group({
          x: contours['left'].attrs.x + this.coef.global_size_flat,
          y: contours['top'].attrs.y + this.coef.global_size_flat,
          draggable: false,
          name: 'element' + this.coef.countNameElement,
          width: contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat,
          params: {
            removable: true,
            type: this.coef.id_element.retractable_pipe,
            subId: this.vvQuantityMap[number].subId,
            height: 20
          },
          bind: [],
        });
        const width = (contours['right'].attrs.x - contours['left'].attrs.x - this.coef.global_size_flat) / (number + 1);
        for (let i = 1; i < number + 1; i++) {
          const pipe = new Rect({
            x: (width * i) - 6 / 2,
            y: 0,
            width: 6,
            height: 25,
            name: 'width',
            fill: this.coef.silverColor,
            strokeWidth: 1,
            stroke: 'black',
          });
          groupPipe.add(pipe);
        }
        this.create.bindingTocounters(
          contours['left'],
          contours['right'],
          contours['top'],
          contours['bottom'],
          groupPipe,
          'horizon',
        );
        this.coef.shapesLayerw.add(groupPipe);
        this.coef.shapesLayerw.batchDraw();
        this.create.priceSend();
      } else {
        this.coef.errorTrigger('Неможливо розташувати елемент у даній секції.');
      }
    } else {
      this.coef.errorTrigger('Неможливо розташувати елемент у даній секції.');
    }
  }

  createRetractablePipe(number, active) {
    this.coef.countNameElement++;
    this.group = new Konva.Group(
      {
        x: 800,
        y: -500,
        width: this.vvQuantityMap[number].width,
        draggable: active,
        name: 'element' + this.coef.countNameElement,
        params: {
          removable: true,
          type: this.coef.id_element.retractable_pipe,
          subId: this.vvQuantityMap[number].subId,
          height: 20
        },
        bind: [],
      }
    );
    let count = 0;
    for (let i = 0; i < number; i++) {
      const pipe = new Rect({
        x: count,
        y: 0,
        width: 6,
        height: 25,
        name: 'width',
        fill: this.coef.silverColor,
      });
      count = count + 20;
      this.group.add(pipe);
    }
    this.dragEnd(this.group, this.coef.product, (pos, xy) => {
      this.createRetractablePipeApi(pos, number, xy);
    });
  }
}
