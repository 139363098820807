import {Component, OnInit} from '@angular/core';
import {CoefService} from '../../service/coef.service';
import {PAGE} from '../../router.path';
import {Router} from '@angular/router';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})
export class Page404Component implements OnInit {

  constructor(
    private coef: CoefService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.coef.preloaderUpdate(false);
  }

  goToOrder() {
    this.router.navigate([(PAGE.ORDER)]);
  }

  goToType() {
    this.router.navigate([(PAGE.ORDERTYPE)]);
  }
}
