import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InitRenderService {
  object;
  rerender = false;
  changeSize = false;
  constructor() {
  }

}
