import {BlockCreate} from '../block-create';
import * as Konva from '../../../assets/konva';
import {Sizes} from '../sizes/sizes';
import {Depth} from '../depth';

export class Comod extends BlockCreate {
  public startPosition;
  public coef;
  public create;
  remove;
  comod_group;

  constructor(coef, create?, remove?) {
    super(coef, create, remove);
    this.coef = coef;
    this.create = create;
    this.remove = remove;
  }

  isBase(array) {
    for (const item of array) {
      if (item.id === 6 && item.value === 33) {
        return true;
      }
    }
    return false;
  }

  createComod(width, height, depth, count, stage, layer, e) {
    const centerPosition = (this.coef.widthWrapper - width) / 2;
    const base = this.isBase(e.init_types);
    let withoutHand = false;
    for (const item of e.init_types) {
      if (item.id == 4) {
        if (item.value == 35 || item.value == 34) {
          withoutHand = true;
        }
      }
    }
    this.coef.countNameElement++;
    let height_one_draw = (height - this.coef.height_legs - this.coef.global_size_flat) / count;
    height_one_draw = Math.floor(height_one_draw);
    this.comod_group = new Konva.Group({
      x: centerPosition,
      y: this.coef.topPositaionWrapper,
      width: 75,
      draggable: false,
      name: 'element' + this.coef.countNameElement,
      params: {
        removable: false,
      },
      bind: [],
    });
    const top = new Konva.Rect({
      x: 0,
      y: 0,
      width: width,
      height: this.coef.global_size_flat,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });
    let widthBase = width;
    let xBottom = 0;
    if (base) {
      widthBase = width - this.coef.global_size_flat * 2;
      xBottom = this.coef.global_size_flat;
    }

    const bottom = new Konva.Rect({
      x: xBottom,
      y: top['attrs'].height + height_one_draw * count,
      width: widthBase,
      height: this.coef.height_legs,
      name: 'width',
      fill: this.coef.color,
      strokeWidth: 1,
      stroke: 'black',
    });

    this.comod_group.add(top, bottom);
    let topWidth = 0;
    if (withoutHand) {
      topWidth = this.coef.spec_values['z_shellf_difference'] / this.coef.global_coef;
    }
    for (let i = 0; i < count; i++) {
      const draw = new Konva.Rect({
        x: 0,
        y: top['attrs'].height + (i * height_one_draw) + topWidth,
        width: width,
        height: height_one_draw - topWidth,
        name: 'width',
        fill: this.coef.colorFacad,
        params: {
          facad: true
        },
        strokeWidth: 1,
        stroke: 'black',
      });
      const drawTop = new Konva.Rect({
        x: 0,
        y: top['attrs'].height + (i * height_one_draw) ,
        width: width,
        height: topWidth,
        name: 'widthtop',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      let center;
      let center2;
      if (width * this.coef.global_coef >= this.coef.spec_values['inner_shuttle_second_handler_separator_width'] + this.coef.spec_values['inner_shuttle_side_space'] * 2) {
        center = new Konva.Rect({
          x: width / 3 - 10,
          y: (top['attrs'].y + (i * height_one_draw)) + (height_one_draw / 2),
          width: 20,
          height: 7,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
        });
        center2 = new Konva.Rect({
          x: (width / 3) * 2 - 10,
          y: (top['attrs'].y + (i * height_one_draw)) + (height_one_draw / 2),
          width: 20,
          height: 7,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
        });
      } else {
        center = new Konva.Rect({
          x: width / 2 - 10,
          y: (top['attrs'].y + (i * height_one_draw)) + (height_one_draw / 2),
          width: 20,
          height: 7,
          name: 'width',
          fill: this.coef.silverColor,
          strokeWidth: 1,
          stroke: 'black',
        });
      }


      if (width * this.coef.global_coef >= this.coef.spec_values['inner_shuttle_second_handler_separator_width'] + this.coef.spec_values['inner_shuttle_side_space'] * 2) {
        if (withoutHand) {
          this.comod_group.add(draw , drawTop);
        } else {
          this.comod_group.add(draw, center, center2 );
        }

      } else {

        if (withoutHand) {
          this.comod_group.add(draw , drawTop);
        } else {
          this.comod_group.add(draw, center );
        }
      }
    }
    if (base) {
      const left = new Konva.Rect({
        x: 0,
        y: top['attrs'].height + height_one_draw * count,
        width: this.coef.global_size_flat,
        height: this.coef.height_legs,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      const right = new Konva.Rect({
        x: xBottom + widthBase,
        y: top['attrs'].height + height_one_draw * count,
        width: this.coef.global_size_flat,
        height: this.coef.height_legs,
        name: 'width',
        fill: this.coef.color,
        strokeWidth: 1,
        stroke: 'black',
      });
      this.comod_group.add(left, right);
    }
    const size = new Sizes(this.coef, this.create).createOneSizeVertical(false, this.comod_group.children[2], false, false, true);
    this.comod_group.add(size);
    layer.add(this.comod_group);
    stage.add(layer);
    new Sizes(this.coef, this.create).globalSize(
      {
        top: this.coef.topPositaionWrapper,
        left: centerPosition,
        width: (width)
      }, {
        top: this.coef.topPositaionWrapper,
        right: centerPosition + width - 110,
        height: (height)
      });
    const depths = new Depth(this.coef);
    depths.createDepth(height * this.coef.global_coef, layer, stage);
    console.log(this.coef.stage.stage);
  }
}
