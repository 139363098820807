import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CoefService} from '../../service/coef.service';
import {baseUrl} from '../../router.path';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {FADE_IN_OUT} from '../../common/animations';

@Component({
  selector: 'app-popap',
  templateUrl: './popap.component.html',
  styleUrls: ['./popap.component.scss'],
  animations: [FADE_IN_OUT]
})
export class PopapComponent implements OnInit, OnChanges {
  @Input() id;
  @Input() displays = false;
  @Input() type;
  inform;
  informDoor = false;
  textinform = 'Матеріали дверей';
  baseUrl = baseUrl;
  order = [];
  materialArray = [];
  border = '0.1rem solid black';
  bigBorder = '0.5rem solid black';
  midBorder = '0.3rem solid black';
  partsSelector = [];
  color = [];
  flat = [];
  typeNames = {};
  handlerSelectors = [];
  materialNames = {};
  systemColorId = null;
  constructor(public coef: CoefService,
              private http: HttpClient,
              private router: Router) {
    this.http.get(baseUrl.api + 'parts-selector').subscribe((res: any) => {
      if (res.success) {
        this.partsSelector = res.payload;
        this.flat = this.creatArrayFlat(res.payload);
      }
    });
    this.http.get(baseUrl.api + 'get-ot-info/16').subscribe((res: any) => {
      if (res.payload.colors) {
        this.color = this.creatArrayColor(res.payload.colors);
      }
    });
    this.http.get(baseUrl.api + 'material-selector').subscribe((res: any) => {

      if (res.success) {
        if (res.payload) {
          for (const item of res.payload) {
            if (item.active && item.id != 9) {
              this.typeNames[item.id] = item.name;
              for (const itemOpt of item.options) {
                this.materialNames[itemOpt.id] = itemOpt.name;
              }
            }
          }
        }
      }

    });
    this.http.get(baseUrl.api + 'handler-selector').subscribe((res: any) => {
      console.warn(res, 'handler-selector');
      if (res.success) {
        if (res.payload) {
          for (const item of res.payload) {
            const newItem = item;
            newItem.label = item.name;
            newItem.value = item.id;
            this.handlerSelectors.push(item);
          }
        }
      }
    });

  }

  ngOnInit() {

  }

  creatArrayFlat(array) {
    const newArray = [];
    for (const item of array) {
      newArray.push({label: item.name, value: String(item.id)});
    }
    return newArray;
  }

  getName(id) {
    const name = this.flat.find((e) => {
      return e.value == id;
    });
    if (name) {
      return name;
    } else {
      return {label: ''};
    }
  }

  getPenName(item) {
    const pen = this.handlerSelectors.find((e) => {
      return e.id == item;
    });
    if (pen && pen.name) {
      return pen.name;
    } else {
      return 'Ручка';

    }

  }

  creatArrayColor(array) {
    const newArray = [];
    for (const item of array) {
      newArray.push({label: {rgb: item.rgb, name: item.name}, value: String(item.id)});
    }
    return newArray;
  }

  informs() {
    if (this.inform.facadeUrl) {
      this.informDoor = !this.informDoor;
      if (this.informDoor) {
        this.textinform = 'Схема корпусу';
      } else {
        this.textinform = 'Матеріали дверей';
      }
    }

  }

  repars(item) {
    const obj = {l: false, r: false, b: false, t: false};
    for (const it of item.cr_code) {
      if (it == 'l') {
        obj['l'] = true;
      }
      if (it == 't') {
        obj['t'] = true;
      }
      if (it == 'r') {
        obj['r'] = true;
      }
      if (it == 'b') {
        obj['b'] = true;
      }
    }
    return obj;
  }

  findTypeReturn(id) {
    const item = this.partsSelector.find(flat => flat.id == id);
    if (item) {
      return item.type;
    } else {
      return '';
    }
  }

  getColor(id) {
    const color = this.color.find((e) => {
      return e.value == id;
    });
    if (color) {
      return color;
    } else {
      return {label: {rgb: 'white', name: ''}};
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.displays && changes.displays.currentValue) {
      if (this.type != 16) {
        this.coef.preloaderUpdate(true);
        this.http.get(baseUrl.api + `get-order-row-info/${this.id}`).subscribe((res) => {
          this.textinform = 'Матеріали дверей';
          this.informDoor = false;
          this.inform = res['payload'];
          if (!this.inform.facadeUrl) {
            this.textinform = '';
          }
          if (!this.inform.contentUrl) {
            this.informDoor = true;
            this.textinform = '';
          }
          this.coef.preloaderUpdate(false);
        }, (error) => {
          this.coef.preloaderUpdate(false);
          if (error.status === 401) {
            this.coef.preloaderUpdate(false);
            this.router.navigate(['']);
          }
        });
      } else {
        this.http.get(baseUrl.api + `/n-order/info/${this.id}`).subscribe(res => {
          this.inform = res['payload'];
          const obj = res['payload'].grouped_items;
          this.order = [];
          this.materialArray = [];
          // for (const item of obj) {
          //   this.order.push({
          //     ...this.repars(item),
          //     name: item.part_name_info.name,
          //     width: item.width,
          //     height: item.height,
          //     colorName: item.part_color.name,
          //     color: item.part_color.rgb,
          //     count: item.quantity
          //   });
          // }

          for (const item of obj) {
            if (this.findTypeReturn(item.part_item_id) == 'flat') {
              this.order.push({
                ...this.repars(item),
                name: item.part_item_id,
                width: item.width,
                height: item.height,
                color: item.color_id,
                count: item.quantity
              });
            } else {
              this.order.push({
                id: item.part_item_id,
                width: item.width,
                height: item.height,
                quantity: null,
                сrCode: null,
                color: null,
                count: item.quantity,
                handlerId: item.handler_id,
                facade: item.facade_view
              });
            }

          }
          // this.getPrice();
          // this.img = baseUrl.photo + res['payload'].content_image_url;
          // this.oldPhoto = res['payload'].content_image_url;
          // this.sendObject.comment = res['payload'].admin_comment;
          this.systemColorId = res['payload'].constructor_object.system_color_id;
          const facade = res['payload'].parsed_facade_items;
          for (const item of facade) {
            this.materialArray.push({
              door: item.door,
              section: item.section,
              height: item.height,
              width: item.width,
              type: item.facade_material_id,
              material: item.facade_material_option_id,
              imageIndex: item.imageIndex,
              printOption:item.print_material_option_id,
              facade_part: true,
              facade_path: null,
              name: '',
              count: 1,
              edit: null
            });
          }
        });
      }
    }
  }
}
